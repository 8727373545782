import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from 'src/app/components/shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { ProposalListComponent } from './proposal-list/proposal-list.component';
import { ProposalService } from './proposal.service';
import { ProposalDetailsComponent } from './proposal-details/proposal-details.component';

const routes: Routes = [
  { 
      path: 'list', component: ProposalListComponent
  }, {
      path: 'details/:id', component: ProposalDetailsComponent
  }
]

@NgModule({
  declarations: [ProposalListComponent, ProposalDetailsComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule.forChild(routes),
    SharedModule,
    PaginationModule
  ],
  providers: [ProposalService]
})
export class ProposalModule { }
