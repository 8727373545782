import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ConfirmationModalComponent } from '../confirmation-modal/confirmation-modal.component';
import { localStorageService } from '../localstorage.service';
import { MainService } from '../main.service';
import { SharedService } from '../shared.service';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss'],
})
export class MainLayoutComponent implements OnInit {
  menuList: any = [];
  timezoneList: any = [];
  selectedTimezone: any;

  countryList: any = [];
  selectedCountry: any;

  isDummy = false;
  constructor(private _router: Router,private modalService: BsModalService,private _local : localStorageService, private _main: MainService, public _shared: SharedService) {}

  ngOnInit() {
    this._main.menu$.subscribe(menus => {
      this.menuList = menus;
      let all_menu: any = [];
      this.menuList.forEach((item: any) => {
        // if (item['only_path'] === '1') {
        //   main_menu.splice(index, 1);
        // }
        let submenu: any = [];
        if (item['submenu'] && item['submenu'].length > 0) {
          item['submenu'].forEach((sub: any) => {
            if (sub['only_path'] !== '1') {
              if (!submenu) {
                submenu = [];
              }
              submenu.push(sub);
            }
          });
        }
        item.submenu = submenu;
        if (item['only_path'] !== '1') {
          all_menu.push(item);
        }
      });
  
      all_menu.forEach((item: any) => {
        if (item.submenu) {
          item.submenu = item.submenu.sort((a: any, b: any) => {
            return a.in_order - b.in_order;
          });
          if (item.submenu.length === 0) {
            item.submenu = null;
          }
        }
      });
      this.menuList = all_menu;
    })

    this._main.timezoneList$.subscribe((tZList: any) => {
      if (tZList && tZList.length){
        this.timezoneList = tZList;
        let timeZ;
        if (this._local.customGetItem('timezone_val')) {
          timeZ = this.timezoneList.find((tz: any) => tz.val == this._local.customGetItem('timezone_val'));
        } else {
          timeZ = this.timezoneList[0];
        }
        this.selectedTimezone = timeZ;
        this._shared.timezone$.next(timeZ['val']);
        this._shared.timezoneName$.next(timeZ['name']);
        this._shared.zone$.next(timeZ['zone']);
      }
    })

    this._main.country_data$.subscribe((counList: any) => {
      this.countryList = counList;
      this._main.selected_country$.subscribe((selCoun: any) => {
        if (selCoun && selCoun.cocu_id && this.selectedCountry && this.selectedCountry.cocu_id){
          this.selectedCountry = selCoun;
          this._shared.mask = selCoun.country_phone_format;
          this._shared.selected_country$.next(selCoun);
        } else {
          if (counList && counList.length) {
            this.selectedCountry = counList[0];
            this._shared.mask = counList[0].country_phone_format;
            this._shared.selected_country$.next(counList[0]);
          }
        }
        // this._shared.mask$.next(selCoun.country_phone_format);
      })
    })

    // this._main.zone$.subscribe(response => {
    //   if (response) {
    //     this._shared.zone$.next(response);
    //   }
    // })

    // this._main.timezone$.subscribe(response => {
    //   this._shared.timezone$.next(response);
    // })

    // this._main.timezoneName$.subscribe(response => {
    //   this._shared.timezoneName$.next(response);
    // })

    if (this._local.customGetItem('is_dummy')) {
      this.isDummy = this._local.customGetItem('is_dummy') == 1 ? true : false;
      this._shared.isDummy$.next(this.isDummy);
    } else {
      this._local.customSetItem('is_dummy', this.isDummy ? 1 : 0);
      this._shared.isDummy$.next(this.isDummy);
    }
    
  }

  toggleChange() {
    this.isDummy = !this.isDummy;
    this._local.customSetItem('is_dummy', this.isDummy ? 1 : 0);
    this._shared.isDummy$.next(this.isDummy);
  }

  changeCountry(country: any) {
    if (country){
      this.selectedCountry = country;
      this._local.customSetItem('country_id', country.country_id);
      this._shared.selected_country$.next(country);
      this._shared.mask = country.country_phone_format;
    }
    // this._shared.mask$.next(country.country_phone_format);
  }

  changeTimezone(timezone: any) {
    this.selectedTimezone = timezone;
    this._local.customSetItem('timezone_val', timezone.val);
    this._shared.timezone$.next(timezone['val']);
    this._shared.timezoneName$.next(timezone['name']);
    this._shared.zone$.next(timezone['zone']);
  }
  
  toggleMenu(selectedMenu: any) {
    if (selectedMenu.submenu) {
      selectedMenu.isOpen = !selectedMenu.isOpen;
    } else {
      this._router.navigate([selectedMenu.path]);
    }
    // this.menuList.forEach((menu: any) => {
    //   if (menu === selectedMenu) {
    //     if (menu.submenu) {
    //       menu.isOpen = !menu.isOpen;
    //     } else {
    //       this._router.navigate([menu.path]);
    //     }
    //   } else {
    //     menu.isOpen = false;
    //   }
    // });
  }

  openNavMenu() {
    const sidenav = document.getElementById('mySidenav');
    if (sidenav) {
      sidenav.style.transform = 'translate(0%)';
    }
  }

  closeNavMenu() {
    const sidenav = document.getElementById('mySidenav');
    if (sidenav) {
      sidenav.style.transform = 'translate(-100%)';
    }
  }
 
  showLogoutModal() {
    const modelRef = this.modalService.show(ConfirmationModalComponent, {
      initialState: {
        extra_data: {
          label: 'Are you sure you want to Logout ?',
          hideNotes: true
        }
      },
      class : 'modal-dialog-centered',
    })
    modelRef.content?.passEntry.subscribe(response => {
      if (response.success) {
        this._local.customClearAllItems()
        this._router.navigate(['login']);
        // let data = {
        //   admin_id: user.admin_id
        // };
        // this._api.api(data, 'send_credentials_to_admin').subscribe((data: any) => { 
        //   console.log(data); 
        // });
      }
    })
  }
}
