import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ApiService } from '../api.service';
import { SharedService } from '../shared.service';

@Component({
  selector: 'app-special-note',
  templateUrl: './special-note.component.html',
  styleUrls: ['./special-note.component.scss']
})

export class SpecialNoteComponent implements OnInit {
  note: any;
  addNewNote: boolean = false;
  newNote: any;
  noteData: any;
  @Input() type: any;
  @Input() id: any;
  @Output() addedNote = new EventEmitter();
  @Input() onlyBusinessNotes:any;
  timezone!: string;
  timezoneName!: string;
  constructor(public _shared: SharedService,
    private _api: ApiService) { }

  ngOnInit() {
    this.timezone = this._shared.getTimezone();
    this.timezoneName = this._shared.getTimezoneName();
    if (this.type && this.id) {
      this.getNoteDetails();
    } else {
      console.log('Please pass reference id & type to fetch special notes.')
    }
  }

  /** Add new or Update existing note **/
  addUpdateNote() {
    this.addNewNote = !this.addNewNote;
    this.newNote = this.note;
  }

  /** Cancel Add/Edit mode **/
  cancelAddUpdateNote() {
    this.addNewNote = !this.addNewNote;
    this.newNote = '';
  }

  /** Save new/existing note **/
  saveNote() {
    if(!this.newNote || this.newNote.trim().length == 0){
      this._shared.customPopups('Please enter special note', 1);
      return;
    }
    let params: any = {
      note: this.newNote.trim(),
    };
    switch (this.type) {
      case 1:
        params['doctor_id'] = this.id;
        break;
      case 2:
        params['patient_id'] = this.id;
        break;
      case 3:
        params['partner_id'] = this.id;
        break;
      case 4:
        params['contract_id'] = this.id;
        break;
      default:
        return ;
    }
    // if (params.note.trim().length == 0) {
    //   this.mainService.customPopups('Please enter a note', 1);
    // }
    this._api.api(params, 'update_special_note')
      .subscribe((data: any) => {
        if (data && data.is_error == 0) {
          this.note = this.newNote;
          this.addNewNote = !this.addNewNote;
          let updatedData = {
            date_added: new Date(),
            note: this.newNote,
            action: 'Special Note Added',
            action_value: 'Special Note Added',
            class: 'note-added',
            action_type:'special_note_added',
          };
          if(this.noteData && this.noteData.note){
            updatedData.action = 'Special Note Updated';
            updatedData.action_value = 'Special Note Updated';
          }
          this.addedNote.emit(updatedData);
          this.getNoteDetails();
        }
      })

  }

  getNoteDetails() {
    let params: any = {};
    switch (this.type) {
      case 1:
        params['doctor_id'] = this.id;
        break;
      case 2:
        params['patient_id'] = this.id;
        break;
      case 3:
        params['partner_id'] = this.id;
        break;
      case 4:
        params['contract_id'] = this.id;
        break;
      default:
        return ;
    }
    this._api.api(params, 'business/get_special_note', 'get')
      .subscribe((data: any) => {
        console.log(data);
        if (data && data.data && data.data.note) {
          this.noteData = data.data;
          this.note = this.noteData.note;
        }
      })
  }

}
