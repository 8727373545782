<div class="card card-body payment-table-comp border py-3 p-0">
    <div class="row pl-2 pr-2 m-0">
        <div class="col-md-8">
            <h5 class="blink_missing fs-14 mb-2"
                *ngIf="!_shared.routerAuth.restricted_buttons.missing_payments && missing_payment_count>0">
                {{missing_payment_count}} Missing Payment(s)</h5>
            <h5 class="blink_missing fs-14 mb-2"
                *ngIf="orp_payment_count>0 && !_shared.routerAuth.restricted_buttons.orp_payments">
                {{orp_payment_count}}
                ORP Payment(s)</h5>
            <h5 class="blink_missing fs-14 mb-0" *ngIf="claimed_payment_count>0"> {{claimed_payment_count}} Claimed
                Payment(s)
            </h5>
        </div>
        <div class="col-lg-4 col-md-4 text-end" *ngIf="!_shared.routerAuth.restricted_buttons.export ">
            <div class="btn-group" dropdown>
                <button id="button-basic" dropdownToggle type="button"
                    class="btn btn-primary rounded-pill py-1 px-2 dropdown-toggle" aria-controls="dropdown-basic">
                    Export <span class="caret"></span>
                </button>
                <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu dropdown-menu-right animated flipInX"
                    role="menu" aria-labelledby="button-basic">
                    <li role="menuitem">
                        <button class="dropdown-item" tappable (click)="exportPaymentXLSX(0)">Export
                            XLSX</button>
                    </li>
                    <li role="menuitem">
                        <button class="dropdown-item" tappable (click)="exportPaymentXLSX(1)">Export CSV</button>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="row pl-2 m-0">
        <div class="col-lg-3 col-md-3"
            *ngIf="(missing_payments && missing_payments.length>0) && !_shared.routerAuth.restricted_buttons.missing_payments">
            <label class="checkbox-inline c-checkbox pt-3 mb-0">
                <input type="checkbox" id="missing_payment" name="missing_payment" class="primary-checkbox"
                    [(ngModel)]="show_missing_payment" (ngModelChange)="sortPayments('payment_date', 'desc');" />
                <label for="missing_payment">Show Missing Payment(s)</label>
            </label>

        </div>
        <div class="col-lg-3 col-md-3"
            *ngIf="(orp_payments && orp_payments.length>0) && !_shared.routerAuth.restricted_buttons.orp_payments">
            <label class="checkbox-inline c-checkbox pt-3 mb-0">
                <input type="checkbox" id="orp_payments" name="orp_payments" class="primary-checkbox"
                    [(ngModel)]="show_orp_payment" (ngModelChange)="sortPayments('payment_date', 'desc');" />
                <label for="orp_payments">Show ORP Payment(s)</label>
            </label>
        </div>

        <div class="col-lg-3 col-md-3"
            *ngIf="(future_payments && future_payments.length>0) && !_shared.routerAuth.restricted_buttons.future_payments">
            <label class="checkbox-inline c-checkbox pt-3 mb-0">
                <input type="checkbox" id="future_payment" name="future_payment" class="primary-checkbox"
                    [(ngModel)]="show_future_payment" (ngModelChange)="sortPayments('payment_date', 'desc');" />
                <label for="future_payment">Show Future Payment(s)</label>
            </label>
        </div>
        <div class="col-lg-3 col-md-3"
            *ngIf="(gp_payments && gp_payments.length>0) && !_shared.routerAuth.restricted_buttons.gp_payments">
            <label class="checkbox-inline c-checkbox pt-3 mb-0">
                <input type="checkbox" id="gp_payment" name="gp_payment" class="primary-checkbox"
                    [(ngModel)]="show_gp_payment" />
                <label for="gp_payment">Show Protected Payment(s)</label>
            </label>
        </div>
    </div>
    <div class="payment_table mt-2">
        <div class="tWrap">
            <div class="tWrap__head">
                <table class="new_payment_table">
                    <thead class="d-none">
                        <tr>
                            <th>Payment Date</th>
                            <th>Original Due Date</th>
                            <th>Payment Type</th>
                            <th>Payment Status</th>
                            <th>Amount Deducted></th>
                            <th>Business Share</th>
                            <th
                                *ngIf="!_shared.routerAuth.restricted_buttons.superadmin_payment_table || !_shared.routerAuth.restricted_buttons.admin_payment_table">
                                Partner Share</th>
                            <th
                                *ngIf="!_shared.routerAuth.restricted_buttons.stripe_denefits_fee && !_shared.routerAuth.restricted_buttons.superadmin_payment_table">
                                Stripe Fee</th>
                            <th
                                *ngIf="!_shared.routerAuth.restricted_buttons.stripe_denefits_fee && !_shared.routerAuth.restricted_buttons.superadmin_payment_table">
                                Credee Share</th>
                            <th>Claim Status</th>
                            <th
                                *ngIf="!_shared.routerAuth.restricted_buttons.superadmin_payment_table || !_shared.routerAuth.restricted_buttons.admin_payment_table || !_shared.routerAuth.restricted_buttons.manager_payment_table">
                                Action</th>
                        </tr>
                    </thead>
                </table>
            </div>
            <div class="tWrap__body">
                <table class="new_payment_table w-100">
                    <tbody>
                        <tr>
                            <td>Payment Date
                                <span *ngIf="!_shared.routerAuth.restricted_buttons.superadmin_payment_table">
                                    <i class="fa fa-arrow-up cursor_pointer ml-1"
                                        (click)="sortPayments('payment_date', 'asc');payment_desc=true;payment_asc=false;"
                                        data-container="body" data-placement="top" tooltip="Ascending Order"
                                        *ngIf="payment_asc"></i>
                                </span>
                                <span *ngIf="!_shared.routerAuth.restricted_buttons.superadmin_payment_table">
                                    <i class="fa fa-arrow-down cursor_pointer ml-1"
                                        (click)="sortPayments('payment_date', 'desc');payment_asc=true;payment_desc=false;"
                                        data-container="body" data-placement="top" tooltip="Descending Order"
                                        *ngIf="payment_desc">
                                    </i>
                                </span>
                            </td>
                            <td>Original Due Date</td>
                            <td>Payment Type</td>
                            <td>Payment Status</td>
                            <td>Amount Deducted</td>
                            <td class="claim-column">Business Share</td>
                            <td
                                *ngIf="!_shared.routerAuth.restricted_buttons.superadmin_payment_table || !_shared.routerAuth.restricted_buttons.admin_payment_table">
                                Partner Share
                            </td>
                            <td
                                *ngIf="!_shared.routerAuth.restricted_buttons.stripe_denefits_fee && !_shared.routerAuth.restricted_buttons.superadmin_payment_table">
                                Stripe Fee
                            </td>
                            <td
                                *ngIf="!_shared.routerAuth.restricted_buttons.stripe_denefits_fee && !_shared.routerAuth.restricted_buttons.superadmin_payment_table">
                                Credee Share
                            </td>
                            <td>Claim Status</td>
                            <td
                                *ngIf="!_shared.routerAuth.restricted_buttons.superadmin_payment_table || !_shared.routerAuth.restricted_buttons.admin_payment_table || !_shared.routerAuth.restricted_buttons.manager_payment_table">
                                Action
                            </td>
                        </tr>
                        <ng-container *ngFor="let payment of final_payments; let i = index">
                            <tr [ngStyle]="{'background-color': payment.is_orp && contract && contract.orp && contract.orp.bg_color, color: payment.is_orp && contract && contract.orp && contract.orp.color}"
                                [ngClass]="{'orp-layer':payment.is_orp}">
                                <td>
                                    <span
                                        [ngClass]="{'missed_payments_row':((payment.charge_status && payment.charge_status=='Missed') || (payment.payment_status && payment.payment_status=='Missed')),'future_payment_row':(payment.payment_status && payment.payment_status == 'Future')}"></span>
                                    <span>{{payment.date_added | date:'MM/dd/yyyy': timezone}}
                                        <span *ngIf="payment.transaction_initiated_date && payment.webhook_status == 0">
                                            <i class="fa fa-info-circle" data-container="body" data-placement="top"
                                                tooltip="The ACH transaction was initiated on this date"></i>
                                        </span>
                                        <span *ngIf="payment.webhook_status && payment.webhook_status !=0">
                                            <i class="fa fa-info-circle" data-container="body" data-placement="top"
                                                tooltip="The ACH transaction was initiated on {{payment.transaction_initiated_date | date:'MM/dd/yyyy': timezone}}"></i>
                                        </span>
                                    </span>
                                </td>
                                <td>
                                    <div *ngIf="payment.payment_type == 18; else otherPaymentType">
                                        <span> - </span>
                                    </div>

                                    <ng-template #otherPaymentType>
                                        <!-- <span *ngIf="!_shared.routerAuth.restricted_buttons.superadmin_payment_table">
                                            {{payment?.contract_type == 7 ? ((payment.original_payment_date |
                                            date:'MM/dd/yyyy':'+0000') ||
                                            '-') :
                                            ((payment.original_payment_date | date:'MM/dd/yyyy') || '-') }}
                                        </span> -->
                                        <span
                                            *ngIf="!_shared.routerAuth.restricted_buttons.csr_payment_table || !_shared.routerAuth.restricted_buttons.admin_payment_table || !_shared.routerAuth.restricted_buttons.manager_payment_table || !_shared.routerAuth.restricted_buttons.superadmin_payment_table">
                                            {{(payment.original_payment_date | date:'MM/dd/yyyy':'+0000') || '-'}}
                                        </span>

                                    </ng-template>
                                </td>
                                <td>
                                    <span *ngIf="payment.is_gp_recovered && payment.is_gp_recovered == 1">
                                        <i class="fa fa-asterisk circle_icon"
                                            [popover]="payment.gp_recovered_data && payment.gp_recovered_data.length > 0 ? gpRecoveredInfo : ''"
                                            [outsideClick]="true" triggers="click"
                                            popoverTitle="GP Recovered Information" placement="left"
                                            data-container="body"></i>
                                        <ng-template #gpRecoveredInfo>
                                            <div class="row details">
                                                <div *ngFor="let cal of payment.gp_recovered_data; let i=index;"
                                                    class="col-md-12">
                                                    <div class="row">
                                                        <div class="col-md-6">
                                                            <h5>Original Due Date: {{cal.gp_missing_date |
                                                                date:'MM/dd/yyyy':'+0000'}}</h5>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <h5>Recovered Amount:
                                                                {{currency_data.display_logo}}{{cal.gp_recoverd_amount |
                                                                number:'0.2-2'}}</h5>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </ng-template>
                                    </span> {{payment.pt_name || '-'}}<br />
                                    <span *ngIf="payment.last4!=null" style="font-size:12px;">(
                                        <img *ngIf="payment.wallet_type == 2 || payment.wallet_type == 3"
                                            src="{{payment.wallet_type == 2 ? './assets/img/SVG/applepay.svg': './assets/img/SVG/gpay.svg'}}">
                                        <i *ngIf="!(payment.wallet_type == 2 || payment.wallet_type == 3)" class="fa"
                                            [ngClass]="{'fa-bank':payment.pay_from_bank==1, 'fa-credit-card':payment.pay_from_bank!=1}"
                                            [ngStyle]="{'color': payment.non_us_card == 1 ? '#3BA6E1': ''}"></i>
                                        {{payment.last4}})
                                    </span>
                                </td>
                                <td>
                                    <span class="badge status_badge"
                                        [ngClass]="{'bg-success':payment.charge_status == 'Paid', 'bg-disabled':payment.payment_status == 'Pending', 'bg-warning':payment.dispute && payment.dispute!=null,'bg-danger': payment.payment_status == 'Failed' || ((payment.charge_status && payment.charge_status=='Missed') || (payment.payment_status && payment.payment_status=='Missed')), 'p-2':payment.charge_status == 'Paid' || (payment.dispute && payment.dispute!=null) || payment.payment_status == 'Pending' || payment.payment_status == 'Failed' || ((payment.charge_status && payment.charge_status=='Missed') || (payment.payment_status && payment.payment_status=='Missed')) }">
                                        {{payment.charge_status || payment.payment_status || '-'}}
                                    </span>
                                </td>
                                <td
                                    *ngIf="!_shared.routerAuth.restricted_buttons.admin_payment_table || !_shared.routerAuth.restricted_buttons.csr_payment_table || !_shared.routerAuth.restricted_buttons.manager_payment_table">
                                    {{currency_data.display_logo}}
                                    {{payment.amount_deducted?(payment.amount_deducted):'0.00' }}
                                    <span
                                        *ngIf="payment.transaction_fee && payment.transaction_fee != 'NaN' && (payment.dispute_charged_from_business && payment.dispute_charged_from_business!=0)">
                                        <i class="fa fa-info-circle" data-container="body" data-placement="top"
                                            tooltip="Transaction Fee: {{currency_data.display_logo}} {{payment.transaction_fee  | number:'0.2-2'}}, Dispute Payment Charged From Business: {{payment.dispute_charged_from_business  | number:'0.2-2'}}"></i>
                                    </span>
                                    <span
                                        *ngIf="payment.transaction_fee && payment.transaction_fee != 'NaN' && (!payment.dispute_charged_from_business || payment.dispute_charged_from_business==0)">
                                        <i class="fa fa-info-circle" data-container="body" data-placement="top"
                                            tooltip="Transaction Fee: {{currency_data.display_logo}} {{payment.transaction_fee  | number:'0.2-2'}}"></i>
                                    </span>
                                </td>
                                <td *ngIf="!_shared.routerAuth.restricted_buttons.superadmin_payment_table" class="xfx">
                                    {{currency_data.display_logo}} {{payment.pay_to_doctor ? payment.pay_to_doctor :
                                    '0.00'}}
                                    <span
                                        *ngIf="payment.transaction_fee && payment.transaction_fee != 'NaN' && (payment.dispute_charged_from_business && payment.dispute_charged_from_business!=0 && payment.dispute_charged_from_business!=null) && payment.late_fee>0">
                                        <i class="fa fa-info-circle" data-container="body" data-placement="top"
                                            tooltip="Transaction Fee: {{currency_data.display_logo}}{{payment.transaction_fee  | number:'0.2-2'}}, Dispute Payment Charged From Business: {{currency_data.display_logo}}{{payment.dispute_charged_from_business  | number:'0.2-2'}}, Late Fee:  {{currency_data.display_logo}}{{payment.late_fee  | number:'0.2-2'}}"></i>
                                    </span>
                                    <span
                                        *ngIf="payment.transaction_fee && payment.transaction_fee != 'NaN' && (!payment.dispute_charged_from_business || payment.dispute_charged_from_business==0 || payment.dispute_charged_from_business==null) && payment.late_fee>0">
                                        <i class="fa fa-info-circle" data-container="body" data-placement="top"
                                            tooltip="Transaction Fee: {{currency_data.display_logo}}{{payment.transaction_fee  | number:'0.2-2'}}, Late Fee:  {{currency_data.display_logo}}{{payment.late_fee  | number:'0.2-2'}}"></i>
                                    </span>
                                    <span
                                        *ngIf="payment && !payment.transaction_fee && payment.transaction_fee == 0 && (!payment.dispute_charged_from_business || payment.dispute_charged_from_business==0 || payment.dispute_charged_from_business==null) && payment.late_fee>0">
                                        <i class="fa fa-info-circle" data-container="body" data-placement="top"
                                            tooltip=" Late Fee:  {{currency_data.display_logo}}{{payment.late_fee  | number:'0.2-2'}}"></i>
                                    </span>
                                    <span
                                        *ngIf="payment.transaction_fee &&  payment.transaction_fee != 'NaN' && (!payment.dispute_charged_from_business || payment.dispute_charged_from_business==0 || payment.dispute_charged_from_business==null) && payment.late_fee==0">
                                        <i class="fa fa-info-circle" data-container="body" data-placement="top"
                                            tooltip=" Transaction Fee: {{currency_data.display_logo}}{{payment.transaction_fee  | number:'0.2-2'}}"></i>
                                    </span>
                                    <span
                                        *ngIf="payment.transaction_fee &&  payment.transaction_fee != 'NaN' && (payment.dispute_charged_from_business || (payment.dispute_charged_from_business!=null && payment.dispute_charged_from_business!=0)) && payment.late_fee==0">
                                        <i class="fa fa-info-circle" data-container="body" data-placement="top"
                                            tooltip=" Transaction Fee: {{currency_data.display_logo}}{{payment.transaction_fee  | number:'0.2-2'}} , Dispute Payment Charged From Business: {{currency_data.display_logo}}{{payment.dispute_charged_from_business  | number:'0.2-2'}}"></i>
                                    </span>
                                </td>
                                <td>
                                    <span *ngIf="payment.is_orp_payment">
                                        {{ payment.orp_object.admin_claim_status ? payment.orp_object.admin_claim_status
                                        : ''}}
                                        <i class="fa fa-info-circle" *ngIf="payment.orp_object.admin_reason"
                                            triggers="mouseenter:mouseleave" tappable container="body"
                                            [popover]="payment.orp_object.admin_reason"></i>
                                    </span>
                                    <span *ngIf="!payment.is_orp_payment" class="transmit-gap">
                                        <span>{{currency_data.display_logo}} {{payment.pay_to_partner &&
                                            payment.pay_to_partner != 'NaN'
                                            ?(payment.pay_to_partner): ('0.00') }}
                                        </span>
                                        <span
                                            *ngIf="payment.stripe_status == 21 && !_shared.routerAuth.restricted_buttons.superadmin_payment_table"
                                            class="ml-3"
                                            style="color: #0A6B89; font-weight: 600; font-size: 13px;">In-Transit</span>
                                    </span>
                                </td>
                                <td
                                    *ngIf="!_shared.routerAuth.restricted_buttons.superadmin_payment_table || !_shared.routerAuth.restricted_buttons.admin_payment_table">
                                    {{currency_data.display_logo}}{{payment.stripe_fee &&
                                    payment.stripe_fee != 'NaN'
                                    ?(payment.stripe_fee): ('0.00') }}
                                    <span
                                        *ngIf="payment.total_amount_of_partners && payment.total_amount_of_partners != 'NaN' && payment.total_amount_of_partners != payment.partner_share_from_doctor && payment.partner_share_from_doctor">
                                        <i class="fa fa-info-circle" data-container="body" data-placement="top"
                                            tooltip="Paid from Business: {{payment.partner_share_from_doctor  | number:'0.2-2'}}, Paid from Credee: {{(payment.total_amount_of_partners - payment.partner_share_from_doctor) | number:'0.2-2'}}"></i>
                                    </span>
                                </td>
                                <td
                                    *ngIf="!_shared.routerAuth.restricted_buttons.stripe_denefits_fee && !_shared.routerAuth.restricted_buttons.superadmin_payment_table">
                                    <span> {{currency_data.display_logo}} {{payment.pay_to_denefits &&
                                        payment.pay_to_denefits !=
                                        'NaN' ? (payment.pay_to_denefits) : ('0.00') }}</span>
                                </td>
                                <td
                                    *ngIf="!_shared.routerAuth.restricted_buttons.stripe_denefits_fee && !_shared.routerAuth.restricted_buttons.superadmin_payment_table">
                                    {{payment.claim_status ? (payment.claim_status ) :
                                    '-' }}
                                </td>
                                <!-- <td *ngIf="payment.payment_status != 'Future'">
                                    <span
                                        *ngIf="contract && contract.contract_type!=3 && _shared.getClaimStatus(payment.claim_status_id);let claim_data"
                                        [ngStyle]="{'color':claim_data.color}">{{claim_data.value}}
                                    </span>
                                    <span
                                        *ngIf="contract && contract.contract_type==3">
                                        -
                                    </span>
                                </td> -->
                                <td
                                    *ngIf="!_shared.routerAuth.restricted_buttons.superadmin_payment_table || !_shared.routerAuth.restricted_buttons.admin_payment_table || !_shared.routerAuth.restricted_buttons.manager_payment_table">
                                    <span
                                        *ngIf="payment.payment_status!='Missed' && payment.payment_status!='Future' && payment.payment_status!='Pending' && payment.charge_status!='Missed' && payment.charge_status!='Dispute Awaited' && payment.charge_status!='Dispute Lost' && !_shared.routerAuth.restricted_buttons.superadmin_payment_table"
                                        data-placement="top" data-container="body" tooltip="View More">
                                        <i class="fa fa-eye" (click)="setRefundData(payment);refundModal.show();"
                                            *ngIf="0!=payment.enable_refund_action && !_shared.routerAuth.restricted_buttons.refund"></i>
                                    </span>
                                    <span
                                        *ngIf="payment.payment_status!='Missed' && payment.payment_status!='Future' && payment.payment_status!='Pending' && payment.charge_status!='Missed' && (!_shared.routerAuth.restricted_buttons.admin_payment_table || !_shared.routerAuth.restricted_buttons.manager_payment_table)"
                                        data-container="body" data-placement="top" tooltip="View More">
                                        <i class="fa fa-eye" (click)="openStatForPayment(payment)"></i>
                                    </span>
                                    <span
                                        *ngIf="!_shared.routerAuth.restricted_buttons.change_claim_visibility && payment.missing_payment_id && payment.claim_status_id == 0 && payment.expired_by_system == 0 && (payment.force_claim_open == 0 || payment.force_claim_open == 1)">
                                        <button class="c_btn" style="margin-left:-10px;"
                                            tooltip="{{payment.force_claim_open ? 'Close Claim' : 'Open Claim'}}"
                                            data-container="body" data-placement="top"
                                            (click)="openClaimVisibilityPopup(payment)"
                                            [ngStyle]="{'background-color': payment.is_orp ? '#c2f1f7':'#fff'}">
                                            <span>
                                                <img
                                                    src="{{payment.force_claim_open ? './assets/img/SVG/c_close.svg': './assets/img/SVG/c_open.svg'}}" />
                                            </span>
                                        </button>
                                        <span data-placement="top" data-container="body" tooltip="View Notes"
                                            *ngIf="contract && contract.claimInfo[0] && contract.claimInfo[0].claim_id">
                                            <i class="fa fa-eye" (click)="openClaimNotesPopup(payment)"></i>
                                        </span>
                                    </span>
                                    <span
                                        *ngIf="(!(0!=payment.enable_refund_action) && !(!_shared.routerAuth.restricted_buttons.view_claim && (((payment.claim_status_id && -1!=payment.claim_status_id  && 0!=payment.claim_status_id) && payment.missing_payment_id) || (payment.is_orp == 1 && payment.missing_payment_id)))  && !(!_shared.routerAuth.restricted_buttons.change_claim_visibility && payment.missing_payment_id && payment.claim_status_id == 0 && payment.expired_by_system == 0 && (payment.force_claim_open == 0 || payment.force_claim_open == 1)))">-</span>
                                </td>
                            </tr>
                        </ng-container>
                        <tr *ngIf="!payments || (payments && payments.length==0)">
                            <td colspan="8" class="text-center">
                                No Data Available
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="col-md-12" *ngIf="gp_recovered_count>0">
        <p class="mt-2"><strong><i class="fa fa-asterisk circle_icon"></i> Protected Payment Recovered</strong></p>
    </div>
    <div class="col-md-12 text-left mt-2 px-2">
        <i class="fa fa-credit-card" style="color:#3BA6E1"></i> Non US Card
    </div>
</div>


<div bsModal #refundModal="bs-modal" [config]="{ignoreBackdropClick:true}" class="modal fade" tabindex="-1"
    role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered customModal">
        <div class="modal-content border-0" *ngIf="contract">
            <div class="modal-header d-flex align-items-center justify-content-between theme-green-bg">
                <h4 class="modal-title text-white fs-18">Reverse Payment</h4>
                <img src="assets/images/icons/white-cross.svg" alt="" (click)="refundModal.hide()" class="cursor hw-24">
            </div>
            <div class="modal-body" style="max-height: 500px;overflow-y: auto;">
                <div class="row">
                    <div class="col-md-6 col-xs-6"
                        *ngIf="!_shared.routerAuth.restricted_buttons.refund_customer && current_payment_data.pt_name!='First Payment Reversed' && current_payment_data.payment_type!=5">
                        <h4 class="m-0 fs-18 fw-600">Amount Refunded</h4>
                        <h5 class="m-0 fs-14 fw-400">The amount refunded to customer:
                            <strong>{{currency_data.display_logo}}{{current_payment_data.amount_refunded ?
                                (current_payment_data.amount_refunded) : ('0.00') }}</strong>
                        </h5>
                        <h5 class="fs-14 fw-400">Refunded Date : <strong>{{current_payment_data && current_payment_data.refunded_date ?
                                (current_payment_data.refunded_date | date:'MM/dd/yyyy': timezone):
                                'N.A.'}}</strong></h5>
                    </div>
                    <div class="col-md-6 col-xs-6">
                        <h4 class="m-0 fs-18 fw-600">Amount Reversed</h4>
                        <h5 class="m-0 fs-14 fw-400">This is the amount reversed from Business/Partner (If applicable) :
                            <strong>{{currency_data.display_logo}}{{current_payment_data.total_amount_reversed ?
                                (current_payment_data.total_amount_reversed) : ('0.00') }}</strong>
                        </h5>
                        <h5 class="m-0 fs-14 fw-400">Business Reversed Date : <strong>{{current_payment_data &&
                                current_payment_data.doctor_reversed_date ? (current_payment_data.doctor_reversed_date |
                                date:'MM/dd/yyyy': timezone): 'N.A.'}}</strong> </h5>
                        <h5 class="m-0 fs-14 fw-400">Partner Reversed Date : <strong>{{current_payment_data &&
                                current_payment_data.partner_reversed_date ?
                                (current_payment_data.partner_reversed_date | date:'MM/dd/yyyy': timezone):
                                'N.A.'}}</strong></h5>
                    </div>
                </div>
                <hr>
                <div class="form-group">
                    <div class="mb-3">
                        <span (click)="resetReverseData()"
                            *ngIf="!_shared.routerAuth.restricted_buttons.refund_customer && current_payment_data.pt_name!='First Payment Reversed' && current_payment_data.payment_type!=5">
                            <input checked="" name="pType" type="radio" value="1" class="secondary-radio" id="refund_customer" [(ngModel)]="refund.reverse_type">
                            <label for="refund_customer" class="fs-14 fw-600 m-0">Refund to Customer(s)</label>
                        </span>
                        <span class="ml-2" (click)="resetReverseData()"
                            *ngIf="!_shared.routerAuth.restricted_buttons.reverse_business && current_payment_data.doctor_transfers && current_payment_data.doctor_transfers.length >0">
                            <input checked="" name="pType" type="radio" value="3" class="secondary-radio" id="reverse_business" [(ngModel)]="refund.reverse_type">
                            <label for="reverse_business" class="fs-14 fw-600 m-0">Reverse from Business</label>
                        </span>
                        <span class="ml-2" (click)="resetReverseData()"
                            *ngIf="!_shared.routerAuth.restricted_buttons.reverse_partner && current_payment_data.partner_transfers && current_payment_data.partner_transfers.length >0">
                            <input checked="" name="pType" type="radio" value="4" class="secondary-radio" id="reverse_partner" [(ngModel)]="refund.reverse_type">
                            <label for="reverse_partner" class="fs-14 fw-600 m-0">Reverse from Partners</label>
                        </span>
                        <span class="ml-2" (click)="resetReverseData()"
                            *ngIf="!_shared.routerAuth.restricted_buttons.reverse_all && (current_payment_data.payment_type!=27 && current_payment_data.payment_type!=28 && current_payment_data.payment_type!=29) && ((current_payment_data.partner_transfers && current_payment_data.partner_transfers.length >0) || (current_payment_data.doctor_transfers && current_payment_data.doctor_transfers.length >0))">
                            <input checked="" name="pType" type="radio" value="6" class="secondary-radio" id="reverse_all" [(ngModel)]="refund.reverse_type">
                            <label for="reverse_all" class="fs-14 fw-600 m-0">Reverse from All</label>
                        </span>

                        <spa class=""
                            [ngClass]="{'ml-0':(current_payment_data.doctor_transfers && current_payment_data.doctor_transfers.length > 0) &&  ( current_payment_data.partner_transfers && current_payment_data.partner_transfers.length > 0)}"
                            (click)="resetReverseData()"
                            *ngIf="((current_payment_data && current_payment_data.late_fee ? current_payment_data.late_fee : 0) - (current_payment_data && current_payment_data.late_fee_refunded ? current_payment_data.late_fee_refunded : 0)> 0)">
                            <input checked="" name="pType" type="radio" value="7" [(ngModel)]="refund.reverse_type">
                            <span class="fa fa-circle"></span>Refund Late Fee(s)
                        </spa>

                    </div>
                </div>
                <div
                    *ngIf="refund.reverse_type== 1 && !_shared.routerAuth.restricted_buttons.refund_customer && current_payment_data.pt_name!='First Payment Reversed' && current_payment_data.payment_type!=5">
                    <div class="form-group col-12">
                        <label class="fs-14 fw-600 m-0">Amount Refund
                            ({{currency_data.display_logo}}{{ (current_payment_data.amount_deducted -
                            current_payment_data.amount_refunded) | number }})*</label>
                        <input type="text" [(ngModel)]="refund.amount_to_refund" name="refundAmount"
                            class="input_field refund_amount_input" required [min]="0"
                            [max]="current_payment_data.amount_deducted - current_payment_data.amount_refunded"
                            appTwoDigitDecimaNumber>
                        <span style="margin-top: 16px;" class="refund_amount_text"
                            [ngClass]="{'t31':(current_payment_data.doctor_transfers && current_payment_data.doctor_transfers.length >0),'t26':(!current_payment_data.doctor_transfers || current_payment_data.doctor_transfers.length==0)}">{{currency_data.currency_code}}</span>
                    </div>

                    <div class="form-group col-12">
                        <label class="fs-14 fw-600 m-0">Reason*</label>
                        <select [(ngModel)]="refund.reason" name="refundReason" required class="input_field">
                            <option value="-1">Select a reason</option>
                            <option value="duplicate">Duplicate</option>
                            <option value="fraudulent">Fraudulent</option>
                            <option value="requested_by_customer">Requested by customer</option>
                        </select>

                    </div>
                    <div class="form-group col-12">
                        <label class="fs-14 fw-600 m-0">Add Notes*</label>
                        <textarea [(ngModel)]="refund.details" name="refundDetails" required type="text"
                            class="input_field" trim="blur"></textarea>

                    </div>
                    <div class="form-group">
                        <p class="m-0"><i class="fa fa-info-circle"></i>&nbsp;&nbsp;Refund take 5 - 10 days to appear on
                            the customer’s statement.</p>
                    </div>
                </div>
                <div *ngIf="refund.reverse_type== 3 && !_shared.routerAuth.restricted_buttons.reverse_business">
                    <div *ngIf="current_payment_data &&  current_payment_data.stripe_status == 20" class="col-md-12">
                        <p class="text-danger">You can't reverse as payment is in unpaid section.</p>
                    </div>
                    <div *ngIf="current_payment_data.doctor_transfers && current_payment_data.doctor_transfers.length >0"
                        class="pl-3">
                        <div class="form-group">
                            <label style="font-size:16px;">Total Business Shares
                                {{currency_data.display_logo}}({{current_payment_data.total_amount_of_doctor
                                ?(current_payment_data.total_amount_of_doctor): ('0.00')
                                }})</label><br />
                            <label style="font-size:16px;">Total Pending Amount Reverse
                                {{currency_data.display_logo}}({{current_payment_data.pay_to_doctor
                                ?(current_payment_data.pay_to_doctor): ('0.00') }})</label>
                        </div>
                        <div class="form-group"
                            *ngFor="let doctor of current_payment_data.doctor_transfers; let i = index;">
                            <div class="col-12 pl-0">
                                <span><strong>{{i+1}}. Name : </strong><a
                                        [routerLink]="['/app/business/details',doctor.doctor_id]">{{doctor.doctor_name}}</a></span><br />
                                <span><strong> Pending Amount Reverse :
                                    </strong>{{currency_data.display_logo}}{{doctor.amount}}</span><br />
                                <span><strong> Amount Reversed:
                                    </strong>{{currency_data.display_logo}}{{doctor.amount_reversed}}</span><br /><br />

                            </div>
                            <div class="row">
                                <div class="col-8">
                                    <label>Amount Reverse({{currency_data.currency_code}})*</label>
                                    <input type="text" [(ngModel)]="refund['amount_data'][i]" class="form-control"
                                        [min]="0" [max]="doctor.amount"
                                        (change)="setTransactionData(doctor.transfer_id,refund['amount_data'][i],doctor.doctor_email)"
                                        appTwoDigitDecimaNumber>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-8">
                                    <label>Add Notes*</label>
                                    <textarea [(ngModel)]="refund.details" name="refundDetails" required type="text"
                                        class="form-control" trim="blur"></textarea>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-4">
                                    <button class="btn btn-primary refund-action mt-4"
                                        (click)="reverseShares(refundModal, 3)" *ngIf="doctor.transfer_id"
                                        [disabled]="!refund['amount_data'][i] ||current_payment_data && current_payment_data.stripe_status == 20 ">Reverse</button>
                                    <button class="btn btn-primary refund-action mt-4"
                                        (click)="reverseForUnpaidDoctors()" *ngIf="!doctor.transfer_id"
                                        [disabled]="!refund['amount_data'][i]">Reverse</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div *ngIf="refund.reverse_type== 4 && !_shared.routerAuth.restricted_buttons.reverse_partner">
                    <div *ngIf="current_payment_data.partner_transfers && current_payment_data.partner_transfers.length >0"
                        class="pl-3">
                        <div class="form-group">
                            <label style="font-size:16px;">Total Partner Shares
                                {{currency_data.display_logo}}({{current_payment_data.total_amount_partner
                                ?(current_payment_data.total_amount_partner): ('0.00') }})</label><br />
                            <label style="font-size:16px;">Total Pending Amount Reverse
                                {{currency_data.display_logo}}({{current_payment_data.total_amount_of_partners
                                ?(current_payment_data.total_amount_of_partners |
                                currency: currency_data.display_logo): ('0.00') }})</label>

                        </div>
                        <div class="form-group"
                            *ngFor="let partner of current_payment_data.partner_transfers; let i = index;">
                            <div class="col-12 pl-0">
                                <span><strong>{{i+1}}. Name : </strong><a
                                        [routerLink]="['/app/partners/profile',partner.partner_id]">{{partner.title}}
                                        {{partner.partner_name}}</a></span><br />
                                <span><strong>Pending Amount Reverse :
                                    </strong>{{currency_data.display_logo}}{{partner.amount}}</span><br />
                                <span><strong>Amount Reversed:
                                    </strong>{{currency_data.display_logo}}{{partner.amount_reversed
                                    }}</span><br /><br />

                            </div>
                            <div class="row">
                                <div class="col-8">
                                    <label>Amount Reverse({{currency_data.currency_code}})*</label>
                                    <input type="text" [(ngModel)]="refund['amount_data'][i]" class="form-control"
                                        [min]="0" [max]="partner.amount"
                                        (change)="setTransactionData(partner.transfer_id,refund['amount_data'][i],partner.partner_email, partner.papa_id)"
                                        appTwoDigitDecimaNumber>

                                </div>
                            </div>
                            <div class="row">
                                <div class="col-8">
                                    <label>Add Notes*</label>
                                    <textarea [(ngModel)]="refund['notes_detail'][i]" name="refundDetails" required
                                        type="text" class="form-control" trim="blur"></textarea>

                                </div>
                            </div>
                            <div class="col-6">
                                <button class="btn btn-primary refund-action mt-4"
                                    (click)="reverseShares(4, refund['amount_data'][i], refund['notes_detail'][i], partner.transfer_id )"
                                    *ngIf="partner.transfer_id" [disabled]="!refund['amount_data'][i]">Reverse</button>
                                <button class="btn btn-primary refund-action mt-4" (click)="reverseForUnpaidPartners()"
                                    *ngIf="!partner.transfer_id"
                                    [disabled]="!refund['amount_data'][i] ">Reverse</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="refund.reverse_type== 6 && !_shared.routerAuth.restricted_buttons.reverse_all && ((current_payment_data.doctor_transfers && current_payment_data.doctor_transfers.length >0) || current_payment_data.partner_transfers && current_payment_data.partner_transfers.length >0)"
                    class="pl-3">
                    <div *ngIf="current_payment_data && current_payment_data.stripe_status == 20">
                        <p class="text-danger">You can't reverse as payment is in unpaid section.</p>
                    </div>
                    <div class="form-group">
                        <label style="font-size:16px;">It will include <span
                                *ngIf="current_payment_data.pay_to_doctor>0 && !_shared.routerAuth.restricted_buttons.reverse_business">
                                Business Shares </span>
                            <span
                                *ngIf="current_payment_data.pay_to_doctor>0 && !_shared.routerAuth.restricted_buttons.reverse_business && current_payment_data.total_amount_of_partners>0 && !_shared.routerAuth.restricted_buttons.reverse_partner">and</span>
                            <span
                                *ngIf="current_payment_data.total_amount_of_partners>0 && !_shared.routerAuth.restricted_buttons.reverse_partner">
                                Partner Shares</span></label>
                    </div>
                    <div class="form-group">
                        <label style="font-size:16px;">Total Shares
                            {{currency_data.display_logo}}({{((current_payment_data.total_amount)) || ('0.00')
                            }})</label><br />
                        <label style="font-size:16px;">Total Pending Amount Reverse
                            {{currency_data.display_logo}}({{((current_payment_data.pay_to_doctor +
                            current_payment_data.total_amount_of_partners)) || ('0.00') }})</label>
                    </div>
                    <div class="form-group">
                        <label>Add Notes*</label>
                        <textarea [(ngModel)]="refund['details']" name="refundDetails" required type="text"
                            class="form-control" trim="blur"></textarea>

                    </div>
                </div>
                <div *ngIf="refund.reverse_type== 7 && !_shared.routerAuth.restricted_buttons.refund_late_fee">
                    <div class="form-group col-12">
                        <label>Refund late fee(s)
                            ({{currency_data.display_logo}}{{(current_payment_data && current_payment_data.late_fee ?
                            current_payment_data.late_fee : 0 )-(current_payment_data &&
                            current_payment_data.late_fee_refunded ? current_payment_data.late_fee_refunded : 0
                            )}})*</label>
                        <input type="text" [(ngModel)]="refund.amount_to_refund" name="refundLateFeeAmount"
                            class="form-control refund_amount_input" required [min]="0"
                            [max]="(current_payment_data && current_payment_data.late_fee ? current_payment_data.late_fee : 0 )-(current_payment_data && current_payment_data.late_fee_refunded ? current_payment_data.late_fee_refunded : 0 )"
                            appTwoDigitDecimaNumber>
                    </div>
                    <div class="form-group col-12">
                        <label>Add Notes*</label>
                        <textarea [(ngModel)]="refund.details" name="refundDetails" required type="text"
                            class="form-control" trim="blur"></textarea>
                    </div>
                    <div class="form-group">
                        <p class="m-0"><i class="fa fa-info-circle"></i>&nbsp;&nbsp;Refund take 5 - 10 days to appear on
                            the customer’s statement.</p>
                    </div>
                    <div class="col-6">
                        <button class="btn btn-primary refund-action mt-4" (click)="refundLateFee(refundModal)"
                            [disabled]="!refund['amount_to_refund']">Refund
                            late fee(s)</button>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-primary refund-action"
                    *ngIf="refund.reverse_type== 1  && !_shared.routerAuth.restricted_buttons.refund_customer"
                    (click)="refundCharge()"
                    [disabled]="(current_payment_data.amount_deducted - current_payment_data.amount_refunded ==0)">Refund</button>
                <button class="btn btn-primary refund-action" *ngIf="refund.reverse_type== 6"
                    (click)="reverseAll(current_payment_data,(current_payment_data.pay_to_paul + current_payment_data.pay_to_doctor + current_payment_data.total_amount_of_partners))"
                    [disabled]="current_payment_data && current_payment_data.stripe_status == 20  ||(current_payment_data.pay_to_paul==0 && current_payment_data.pay_to_doctor ==0 && current_payment_data.total_amount_of_partners==0) ">Reverse</button>
            </div>
        </div>
    </div>
</div>


<div bsModal #currentPaymentStatsModal="bs-modal" [config]="{ignoreBackdropClick:true}" class="modal fade new_modal"
    tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg  modal-dialog-centered customModal">
        <div class="modal-content border-0">
            <div class="modal-header d-flex align-items-center justify-content-between theme-green-bg">
                <h4 class="modal-title text-white fs-18"> Payment Details - {{current_payment_data.date_added |
                    date:'MM/dd/yyyy':
                    timezone}}</h4>
                <img src="assets/images/icons/white-cross.svg" alt="" (click)="currentPaymentStatsModal.hide();"
                    class="cursor hw-24">
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-12 col-xs-12">
                        <h4 class="mb-0 fs-18 fw-600">Amount Refunded</h4>
                        <h5 class="mb-0 fs-14 fw-400">The amount refunded to customer:
                            <strong>{{currency_data.display_logo}} {{current_payment_data.amount_refunded ?
                                (current_payment_data.amount_refunded) : '0.00'}}</strong>
                        </h5>
                        <h5 class="mb-0 fs-14 fw-400">Refunded Date : <strong>{{current_payment_data && current_payment_data.refunded_date ?
                                (current_payment_data.refunded_date | date:'MM/dd/yyyy': timezone):
                                'N.A.'}}</strong></h5>
                    </div>
                </div>
                <hr>
                <div class="row">
                    <div *ngIf="current_payment_data.doctor_transfers && current_payment_data.partner_transfers.length >0"
                        class="col-md-6 col-xs-12">
                        <h4 class="mb-0">Amount Reversed From Partner</h4>
                        <div class="col-12 pl-0"
                            *ngFor="let partner of current_payment_data.partner_transfers; let i = index;">
                            <span><strong>{{i+1}}. Name : </strong><a
                                    [routerLink]="'/app/partners/profile/'+partner.partner_id">{{partner.title}}
                                    {{partner.partner_name}}</a></span><br />
                            <span><strong>Amount Reversed:
                                </strong>{{currency_data.display_logo}}{{partner.amount_reversed}}</span><br />
                            <span><strong>Reversed Date: </strong>{{partner && partner.partner_reversed_date ?
                                (partner.partner_reversed_date | date:'MM/dd/yyyy': timezone):
                                'N.A.'}}</span><br />
                        </div>
                    </div>
                    <div *ngIf="current_payment_data.doctor_transfers && current_payment_data.doctor_transfers.length >0"
                        class="col-md-6 col-xs-12">
                        <h4 class="mb-0">Amount Reversed From Business</h4>
                        <div class="col-12 pl-0"
                            *ngFor="let doctor of current_payment_data.doctor_transfers; let i = index;">
                            <span><strong>{{i+1}}. Name : </strong><a
                                    [routerLink]="'/app/business/details/'+doctor.doctor_id">{{doctor.doctor_name}}</a></span><br />
                            <span><strong> Amount Reversed:
                                </strong>{{currency_data.display_logo}}{{doctor.amount_reversed}}</span><br />
                            <span><strong>Reversed Date: </strong>{{doctor && doctor.doctor_reversed_date ?
                                (doctor.doctor_reversed_date | date:'MM/dd/yyyy': timezone):
                                'N.A.'}}</span><br />

                        </div>
                    </div>
                </div>
                <ng-container
                    *ngIf="!_shared.routerAuth.restricted_buttons.refund && (((current_payment_data && current_payment_data.late_fee )-(current_payment_data && current_payment_data.late_fee_refunded )) > 0)">
                    <hr>
                    <h4 class="">Refund Late Fee(s)</h4>
                    <div class="row">
                        <div class="col-12">
                            <div class="form-group">
                                <label>Late Fee(s)
                                    ({{currency_data.display_logo}}{{(current_payment_data &&
                                    current_payment_data.late_fee )-(current_payment_data &&
                                    current_payment_data.late_fee_refunded )}})*</label>
                                <input type="text" [(ngModel)]="refund.amount_to_refund" name="refundLateFeeAmount"
                                    class="form-control refund_amount_input" required [min]="0"
                                    [max]="(current_payment_data && current_payment_data.late_fee )-(current_payment_data && current_payment_data.late_fee_refunded )"
                                    appTwoDigitDecimaNumber>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="form-group ">
                                <label>Notes*<i class="fa fa-info-circle" data-placement="top"
                                        tooltip="Refund take 5 - 10 days to appear on the customer’s statement."></i></label>
                                <textarea [(ngModel)]="refund.details" required type="text" class="form-control"
                                    trim="blur"></textarea>

                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 pl-0">
                        <button class="btn btn-primary refund-action"
                            (click)="refundLateFee('currentPaymentStatsModal')"></button>
                        <button class="btn btn-primary refund-action" (click)="refundLateFee(currentPaymentStatsModal)"
                            [disabled]="!refund['amount_to_refund']">Refund</button>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>