import { ChangeDetectorRef, Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ApiService } from 'src/app/components/shared/api.service';
import { localStorageService } from 'src/app/components/shared/localstorage.service';
import { MainService } from 'src/app/components/shared/main.service';
import { SharedService } from 'src/app/components/shared/shared.service';
import { ProposalService } from '../proposal.service';
import { ProposalModalClassComponent } from '../proposal-modal/proposal-list-modal.component';
import { AddNotesComponent } from 'src/app/components/shared/add-notes/add-notes.component';

@Component({
  selector: 'app-proposal-details',
  templateUrl: './proposal-details.component.html',
  styleUrl: './proposal-details.component.scss'
})
export class ProposalDetailsComponent {
  id: any;
  // splitInfo: any = [];
  plansDetail: any = [];
  is_ar_view = window.location.pathname.includes('/app/accounts-receivable/') ? 1 : 0;
  load_notes = false;
  baseUrlCopyLink: string = '';
  timezone: string = '';
  timezoneName: string = '';
  timezoneZone: string = '';

  constructor(private route: ActivatedRoute,
    public mainService: MainService,
    public bsModalRef: BsModalRef,
    private modalService: BsModalService,
    private _local: localStorageService,
    public router: Router,
    public _shared: SharedService,
    private _api: ApiService,
    public _proposal: ProposalService,
    public cd: ChangeDetectorRef) { }

  ngOnInit() {
    this.timezoneName = this._shared.getTimezoneName();
    this.timezone = this._shared.getTimezone();
    this.id = this.route.snapshot.paramMap.get("id");
    this.getSplitDetails(this.id);
  }

  getSplitDetails(id: any) {
    let data = {
      access_token: this._local.customGetItem("adminToken"),
      split_req_id: id,
      is_debt_contract: window.location.pathname.includes('/app/proposal/details/') ? 0 : 1
    };
    this._api.api(data, "finance/get_proposal_details", 'get').subscribe(
      (response: any) => {
        if (response.is_error) {
          this._shared.customPopups(response.original_text, response.is_error);
        } else {
          this._proposal.splitRequest = response.split_request_data[0];
          this._proposal.selected_req = response.split_request_data[0];
          this.plansDetail = response.split_request_data[0]['plans_array'];
          this.baseUrlCopyLink = response['copy_link_base_url'] || '';
          this.plansDetail.forEach((plan: any) => {
            plan.recurring_amount = parseFloat(plan.recurring_amount);
            plan.recurring_extra_amount = response.split_request_data[0].recurring_extra_amount;
            plan.recurring_extra_percent = response.split_request_data[0].recurring_extra_percent;
            plan.threshold_amount = response.split_request_data[0].threshold_amount;
            plan.threshold_recurring_extra_amount = response.split_request_data[0].threshold_recurring_extra_amount;
            plan.threshold_recurring_extra_percent = response.split_request_data[0].threshold_recurring_extra_percent;
            plan.treatment_amount = response.split_request_data[0].treatment_amount;
            plan.downpayment_amount = response.split_request_data[0].downpayment_amount;

            if (response.split_request_data[0].contract_type === 1) {
              if (response.split_request_data[0] && response.split_request_data[0].payment_reserve_on_pay) {
                plan.advance_interest_amount = plan.recurring_amount;
              } else {
                plan.advance_interest_amount = 0
              }
            } else if (response.split_request_data[0].contract_type === 3) {
              if (response.split_request_data[0] && response.split_request_data[0].payment_reserve_on_pay_new_ten) {
                plan.advance_interest_amount = plan.recurring_amount;
              } else {
                plan.advance_interest_amount = 0
              }
            } else {
              plan.advance_interest_amount = plan.advance_interest_amount ? parseFloat(parseFloat(
                plan.advance_interest_amount
              ).toFixed(2)) : 0;
            }
            plan.month = parseInt(plan.month);
            let fixed_flat_fee = response.split_request_data[0].enrollment_enabled ? 0 : plan.enrollment_fee;
            plan.payable_amount_today = parseFloat(plan.downpayment_amount) + this._proposal.getCalculatedTransactionFee(plan, plan.downpayment_amount)
              + parseFloat(plan.advance_interest_amount) + this._proposal.getCalculatedTransactionFee(plan, plan.advance_interest_amount)
              + fixed_flat_fee;
            plan['getTransactionFee'] = this._proposal.GetTransactionFee(plan, plan.recurring_amount?plan.recurring_amount:plan.recurring_amount, plan.display_logo)
          });
          this.load_notes = false;
        }
      });
  }

  addContractNotesModal() {
    let tempdata = {
      assign_type: 6,
      proposal_info: [{ contract_patient_token: this._proposal.splitRequest.contract_patient_token }]
    };
    this.mainService.noteadded = false;
    let initialState = {
      data: tempdata
    };
    this.bsModalRef = this.modalService.show(AddNotesComponent, { 
      initialState
    });
    let count = 0;
    this.modalService.onHide.subscribe((reason: string) => {
      if (this.mainService.noteadded) {
        const _reason = reason ? `, dismissed by ${reason}` : '';
        if (count == 0) {
          this.getSplitDetails(this.id);
          count = 1;
        }
      }
    })
  };

  goToContract(contract_id: number) {
    this.router.navigate(['/app/contracts/' + contract_id]);
  }

  goToPaymentProcess(PPr_id: number) {
    this.router.navigate(['/app/accounts-receivable/payment/' + PPr_id]);
  }

  gotopaymentLink(){
    window.open(`${this._proposal.splitRequest.ar_payment_link}${this._proposal.splitRequest.contract_patient_token}`, "_blank");
  }

  copyLink() {
      if (this.baseUrlCopyLink) {
        const textToCopy = `${this.baseUrlCopyLink}${this._proposal.splitRequest.contract_patient_token}`;
        const isTextCopied = this.mainService.copyToClipboard(textToCopy);
        // tslint:disable-next-line:max-line-length
        isTextCopied ? this._shared.customPopups('Link copied to clipboard', 0) : this._shared.customPopups('Unable to copy to clipboard', 1);
        return;
      }
  }

  resendProposal() {
    const modelRef = this.modalService.show(ProposalModalClassComponent, {
      initialState: {
        modalType: this._proposal.modalTypes.resendProposalModal,
        parentType: this._proposal.parentType.proposal_details,
        extra_data: {
          split_request_id: this._proposal.splitRequest.split_req_id
        }
      }
    })
    modelRef.content?.passEntry.subscribe((receivedEntry: any) => {
      if(receivedEntry && receivedEntry.success) {
        const data = receivedEntry.data;
        if (data.batch_status) {
          this._shared.customPopups('Proposal Email(s) sent successfully', 0);
          this.getSplitDetails(this.id);
        } else {
          this._shared.customPopups(data.err, 1);
        }
      } else {
        this._shared.customPopups('Something went wrong please try again later', 1);
      }
    })
    // modal.show();
  }

  openPaymentSettingsModal() {
    const modelRef = this.modalService.show(ProposalModalClassComponent, {
      initialState: {
        modalType: this._proposal.modalTypes.paymentSettingsModal,
        parentType: this._proposal.parentType.proposal_details
      }
    })
    modelRef.content?.passEntry.subscribe((receivedEntry: any) => {
      if(receivedEntry && receivedEntry.success) {
        const data = receivedEntry.data;
        if (!data.is_error) {
          this.getSplitDetails(this.id);
        } else {
          this._shared.customPopups(data.err, 1);
        }
      } else {
        this._shared.customPopups('Something went wrong please try again later', 1);
      }
    })
  }

  deleteRequest() {
    const modalRef = this.modalService.show(ProposalModalClassComponent, {
      initialState: {
        extra_data: {
          request_id: this._proposal.splitRequest['split_req_id']
        },
        modalType: this._proposal.modalTypes.deleteProposal,
        parentType: this._proposal.parentType.proposal_details
      }
    });
    modalRef.content?.passEntry.subscribe(response => {
      if (response.success) {
        this.router.navigate(["/app/proposal/list"] )
      } else {
        this._shared.customPopups(response.err, 1);
      }
    })
  }

  stop_resume_reminder(eve: any,stop_to: any){
    let is_stop = eve.target.checked ? 0 : 1;
    const modelRef = this.modalService.show(ProposalModalClassComponent, {
      initialState: {
        modalType: this._proposal.modalTypes.stop_resume_reminder,
        extra_data: {
          is_stop: is_stop,
          split_id: this._proposal.splitRequest.split_req_id,
          stop_to
        }
      }
    })

    modelRef.content?.passEntry.subscribe((receivedEntry: any) => {
      if(receivedEntry && receivedEntry.success) {
        const data = receivedEntry.data;
        if (!data.is_error) {
          this.getSplitDetails(this.id);
        } else {
          this._shared.customPopups(data.err, 1);
        }
      } else {
        this._shared.customPopups('Something went wrong please try again later', 1);
      }
    })
  }
}
