import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/components/shared/api.service';
import { SharedService } from 'src/app/components/shared/shared.service';
import { LeadsService } from '../leads.service';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';

@Component({
  selector: 'app-create-lead',
  templateUrl: './create-lead.component.html',
  styleUrl: './create-lead.component.scss'
})
export class CreateLeadComponent {
  public leadCreateForm!: UntypedFormGroup;
  lead_owner_name = '';
  timezone = '';
  timezoneName = '';
  lead_history:any = [];
  comment: any = '';
  update_id = 0;
  note_listing: any = [];
  industriesList: any = [];
  buttonClick: boolean = false;
  minDate = new Date();
  timezone_list: any = [];
  admin_list: any = [];
  can_assign: any;
  leadStatus: any = [];
  specialities: any = [];
  ind_id: any;
  speciality_id = '';
  lead_source: any = [];
  admin_check: any;
  is_dnd = 0;
  previousPage:any;
  save_type = '';
  lead_data: any = [];
  constructor(private _fb: UntypedFormBuilder, public _shared: SharedService, private _api: ApiService, public router: Router, private _leads: LeadsService, private _datePipe: DatePipe) {
    this.timezone = this._shared.getTimezone();
    this.timezoneName = this._shared.getTimezoneName();

    this.leadCreateForm = this._fb.group({
      added_by_name: [this.lead_owner_name, [Validators.required]],
      practice_email: ['',
        [Validators.pattern('^[a-zA-Z0-9]+[a-zA-Z0-9_\.\+-]+@[a-zA-Z0-9]+[a-zA-Z0-9-\.]+\.[a-zA-Z0-9-\.]+[a-zA-Z0-9]$')]],
      first_name: [''],
      last_name: [''],
      practice_name: ['', [Validators.required]],
      website: [''],
      practice_mobile: ['', [Validators.required]],
      practice_address: ['', [Validators.required]],
      city: ['', [Validators.required]],
      state: ['', [Validators.required]],
      zip_code: ['', [Validators.required]],
      lead_source: ['', [Validators.required]],
      ind_name: [''],
      sub_ind_name: [''],
      ind_id: ['', [Validators.required]],
      speciality_id: ['', [Validators.required]],
      comment: [''],
      lead_status: [''],
      assigned_to: [''],
      description: [''],
      timezone: ['', [Validators.required]],
      alternate_email: ['',
        [Validators.pattern('^[a-zA-Z0-9]+[a-zA-Z0-9_\.\+-]+@[a-zA-Z0-9]+[a-zA-Z0-9-\.]+\.[a-zA-Z0-9-\.]+[a-zA-Z0-9]$')]],
      alternate_mobile: [''],
      spoke_to: [''],
      followup_timezone: [''],
      followup_date: [''],
      followup_time: [''],
      // other_ind_name: [''],
      // other_sub_ind_name: ['']
    });
  }

   // create Lead and update lead
   saveLead(type: any) {
    this.save_type = type;
    this.buttonClick = true;
    let form_valid = false;
    if (this.leadCreateForm.controls['lead_status'].value == 2 && !this.leadCreateForm.controls['assigned_to'].value) {
      this._shared.customPopups('Please search assignee', 1);
      return;
    } else {
      if (this.router.url === '/app/leads/create' && this.leadCreateForm.valid) {
        form_valid = true;
      } else if (this.leadCreateForm.controls['lead_status'].value != 10 && this.leadCreateForm.controls['added_by_name'].value && this.leadCreateForm.controls['added_by_name'].value &&
        this.leadCreateForm.controls['practice_name'].value &&
        this.leadCreateForm.controls['practice_mobile'].value &&
        this.leadCreateForm.controls['practice_address'].value &&
        this.leadCreateForm.controls['zip_code'].value &&
        this.leadCreateForm.controls['city'].value &&
        this.leadCreateForm.controls['state'].value &&
        this.leadCreateForm.controls['lead_status'].value &&
        this.leadCreateForm.controls['speciality_id'].value &&
        this.leadCreateForm.controls['timezone'].value &&  this.leadCreateForm.controls['comment'].value) {
        form_valid = true;
      } else if (this.leadCreateForm.controls['lead_status'].value == 10 && this.leadCreateForm.controls['added_by_name'].value && this.leadCreateForm.controls['added_by_name'].value &&
      this.leadCreateForm.controls['practice_name'].value &&
      this.leadCreateForm.controls['practice_mobile'].value &&
      this.leadCreateForm.controls['practice_address'].value &&
      this.leadCreateForm.controls['zip_code'].value &&
      this.leadCreateForm.controls['city'].value &&
      this.leadCreateForm.controls['state'].value &&
      this.leadCreateForm.controls['lead_status'].value &&
      this.leadCreateForm.controls['speciality_id'].value &&
      this.leadCreateForm.controls['spoke_to'].value &&
      this.leadCreateForm.controls['followup_timezone'].value &&
      this.leadCreateForm.controls['timezone'].value &&
      this.leadCreateForm.controls['followup_date'].value &&
      this.leadCreateForm.controls['followup_time'].value && this.leadCreateForm.controls['comment'].value) {
        form_valid = true;
      }

      if (form_valid) {
        this.lead_data = this.leadCreateForm.value;
        // console.log('this.lead_data save data: ',this.lead_data.ind_name, 'this.lead_data.sub_ind_name::',this.lead_data.sub_ind_name);
        // return false;
        this.lead_data['lead_id'] = this.update_id;
        // console.log("this.leadCreateForm", this.lead_data)
        if (this.lead_data['lead_status'] == 10) {
          const follow_date = this._datePipe.transform(this.lead_data['followup_date'], 'yyyy-MM-dd');
          this.lead_data['followup_date'] = follow_date + ' ' + moment(this.lead_data['followup_time']).format('HH:mm') + ':00';
          this.lead_data['followup_time'] = moment(this.lead_data['followup_time']).format('HH:mm');
        }
        let api_name = '';
        this._api.api(this.lead_data, 'validate_add_lead')
        .subscribe((data: any) => {
          if (data.is_duplicate === 1) {
            // this.duplicateDataModal.show();
          } else {
            if (this.router.url === '/app/leads/create') {
              api_name = 'add_lead';
            } else {
              api_name = 'update_lead';
              this.lead_data['lead_id'] = this.update_id;
              // data['comment'] ="lead update";
            }
            this.saveLeadData(this.lead_data, api_name, this.save_type, false)
          }
        });
      } else {
        console.log('Else case when form not valid.');
        return;
      }
    }
  }

  saveLeadData(lead_data: any, api_name: string, type: any, modal_close: any) {
    // console.log('lead_data in saveLeadData::', lead_data);
    if(lead_data.ind_id == "") {
        return;
    }
    this._api.api(lead_data, api_name)
      .subscribe((data: any) => {
        if (data.is_error == 1) {
          if (data.err) {
            this._shared.customPopups(data.err[0], 2);
          }
          return;
        }
        if (data.is_error === 0 && type === 2 && this.router.url === '/app/leads/create') {
          if (modal_close) {
            // this.duplicateDataModal.hide();
          }
          this.leadCreateForm.reset({
            added_by_name: { value: this.lead_owner_name, disabled: true },
            lead_source: '',
            speciality_id: '',
            timezone: ''
          });
          // console.log("this.leadCreateForm--", this.leadCreateForm)
          for (const i in this.leadCreateForm.controls) {
            if (i !== 'added_by_name') {
              this.leadCreateForm.controls[i].setErrors(null);
            }
          }
        } else if (data.is_error === 0 && type === 2 && this.router.url == '/app/leads/update') {
          if (modal_close) {
            // this.duplicateDataModal.hide();
          }
          this.router.navigate(['/app/leads/create']);
        } else {
          if (modal_close) {
            // this.duplicateDataModal.hide();
          }
          if(this.previousPage && this.previousPage!='/'){
            this.router.navigateByUrl(decodeURI(this.previousPage));
          }else{
            this.router.navigate(['/app/leads/list']);
          }
        }
      });
  }

  openFollowUpModal() {

  }

  cancelLead() {
    if (this.leadCreateForm.dirty) {
      // this.confirmationModal.show();
    } else {
      console.log('this.previousPage ---',this.previousPage)
      if(this.previousPage && this.previousPage!='/'){
        this.router.navigateByUrl(decodeURI(this.previousPage));
      }else{
        this.router.navigate(['/app/leads/list']);
      }
    }
  }

  dndLead() {
    this._leads.dndLead(this.update_id, this.is_dnd);
  }

  doctorIndustry(indId?:any, isChange?:any) {
    if(isChange == 'yes') {
      this.leadCreateForm.patchValue({'speciality_id':''});
    } 
    
    let params: any = {};
    this.ind_id = indId;
    
    if(this.ind_id && this.ind_id.target && this.ind_id.target.value){
      params.ind_id = this.ind_id.target.value;
    }
    if(this.ind_id && !this.ind_id.target) {
      params.ind_id = indId;
    }
    params.other_not_required = 1;
    this._api.api(params, 'doctor_speciality_list', 'get')
      .subscribe((data: any) => {
        this.specialities = data.doctor_speciality;
      });
  }

  adminClear(event: any) {
    if (event.length === 0) {
      this.admin_list = [];
    }
  }

  selectAdmin(event: any) {
    this.leadCreateForm.patchValue({
      assigned_to: event
    })
    // this.leadCreateForm.controls['assigned_to'].setValue(event);
  }

   //  get all admin
   getAdminList(event: any) {
    const data = {
      search_string: event
    };
    if (event && (typeof (event) !== 'number') && event.length > 3) {
      this._api.api(data, 'get_admin_list', 'get')
        .subscribe((response: any) => {
          this.admin_list = response.admins;
        });
    } else if (event && (typeof (event) === 'number')) {
      this._api.api(data, 'get_admin_list', 'get')
        .subscribe((response: any) => {
          this.admin_list = response.admins;
        });
    }

  }

  validateEmailPhone(value: any, type: any){
    if(type == 'phone'){
      let phone = value.replace(/\D+/g, '');
      if (phone && phone.length < 10) {
        this._shared.customPopups('Phone number must be 10 digits', 1);
        return;
      } 
      if(phone && phone.length>8){
        let params = {
          practice_mobile: phone
        };
        this._api.api(params, 'validate_lead_email_phone')
          .subscribe((data:any)=>{
            console.log(data);
            if(data && data.is_duplicate == 1){
              this._shared.customPopups('Phone number ' + value + ' already exist!', 2);
              // this.leadCreateForm.controls['practice_mobile'].setValue('');
            }
            if(data && data.is_doctor_exist == 1){
              this._shared.customPopups('There is a Business Owner exist with ' + value + '.', 2);
              // this.leadCreateForm.controls['practice_email'].setValue('');
            }
          }, (error:any)=>{
            console.log(error);
          })
      }
    }
    if(type == 'email'){
      let pattern = new RegExp("^[a-zA-Z0-9]+[a-zA-Z0-9_\.\+-]+@[a-zA-Z0-9]+[a-zA-Z0-9-\.]+\.[a-zA-Z0-9-\.]+[a-zA-Z0-9]$");
      let email = value;
      if(pattern.test(email)){
        let params = {
          practice_email: email
        };
        this._api.api(params, 'validate_lead_email_phone')
          .subscribe((data:any)=>{
            console.log(data);
            if(data && data.is_duplicate == 1){
              this._shared.customPopups('Business with email ' + value + ' already exist!', 2);
              // this.leadCreateForm.controls['practice_email'].setValue('');
            }
            if(data && data.is_doctor_exist == 1){
              this._shared.customPopups('There is a Business Owner exist with ' + value + '  Business Email.', 2);
              // this.leadCreateForm.controls['practice_email'].setValue('');
            }
          }, (error:any)=>{
            console.log(error);
          });
      }
    }
    
  }

  // add notes
  addNotes() {
    if (!this.comment || (this.comment && this.comment.trim().length === 0)) {
      this._shared.customPopups('Please enter note', 1);
      return;
    }
    const data = {
      lead_id: this.update_id,
      type: 1,
      comment: this.comment

    };
    if (this.comment) {
      this._api.api(data, 'add_lead_activity')
        .subscribe((response: any) => {
          this.note_listing.unshift(response.data[0]);
          this.comment = '';
          // console.log("this.add_lead_activity", this.note_listing)
        });
    }

  }
}
