import { Routes } from '@angular/router';
import { MainLayoutComponent } from './components/shared/main-layout/main-layout.component';
import { LoginComponent } from './components/pages/public/login/login.component';
import { DashboardComponent } from './components/pages/dashboard/dashboard.component';
import { authGuard } from './auth.gaurd';
import { NewPasswordComponent } from './components/pages/public/new-password/new-password.component';

export const routes: Routes = [
    { path: '', redirectTo: 'app', pathMatch: 'full' },
    {
        path: 'app',
        component: MainLayoutComponent,
        canActivate: [authGuard],
        children: [
            { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
            { 
                path: 'dashboard', component: DashboardComponent
            },
            {
                path: 'assignments',
                loadChildren:() =>  import('./components/pages/assignments/assignments.module').then(m => m.AssignmentsModule),
            },
            {
                path: 'contracts',
                loadChildren:() =>  import('./components/pages/contracts/contracts.module').then(m => m.ContractsModule),
            },
            {
                path: 'contract-changes',
                loadChildren:() =>  import('./components/pages/contract-changes/contract-changes.module').then(m => m.ContractChangesModule),
            },
            {
                path: 'proposal',
                loadChildren:() =>  import('./components/pages/proposal/proposal.module').then(m => m.ProposalModule),
            },
            {
                path: 'users',
                loadChildren:() =>  import('./components/pages/users/users.module').then(m => m.UsersModule),
            },
            {
                path: 'leads',
                loadChildren:() =>  import('./components/pages/leads/leads.module').then(m => m.LeadsModule),
            },
            {
                path: 'business',
                loadChildren:() =>  import('./components/pages/business/business.module').then(m => m.BusinessModule),
            },
            {
                path: 'debt-recovery',
                loadChildren:() =>  import('./components/pages/debt-recovery/debt-recovery.module').then(m => m.DebtRecoveryModule),
            },
            {
                path: 'claims',
                loadChildren:() =>  import('./components/pages/claims/claims.module').then(m => m.ClaimsModule),
            },
            {
                path: 'training',
                loadChildren:() =>  import('./components/pages/training/training.module').then(m => m.TrainingModule),
            },
            {
                path: 'marketing',
                loadChildren:() =>  import('./components/pages/marketing/marketing.module').then(m => m.MarketingModule),
            },
            {
                path: 'internal-tickets',
                loadChildren:() =>  import('./components/pages/internal-ticket/internal-ticket.module').then(m => m.InternalTicketModule),
            },
            {
              path: 'admin-settings',
              loadChildren: () =>
                import('./components/pages/admin-settings/admin-settings.module').then(
                  (m) => m.AdminSettingModule
                ),
            }
        ]
    },
    // { path: '**', component: LoginComponent },
    { path: 'login', component: LoginComponent },
    { path: 'new-password', component: NewPasswordComponent }
]
