
<h3 id="list" class="fs-24">Manage Contracts</h3>
<div class="mb-2 border-bottom">
  <button class="tab fs-14" [class.active]="contractsType==8" [routerLink]="'/app/contracts/list'" [queryParams]="{type:8}">Today's Contracts( {{ counts && counts.todays_contract ? counts.todays_contract.count : 0 }} )</button>
  <button class="tab fs-14" [class.active]="contractsType==0" [routerLink]="'/app/contracts/list'" [queryParams]="{type:0}">All Contracts( {{ counts && counts.all_contracts ? counts.all_contracts.count : 0 }} )</button>
  <button class="tab fs-14" [class.active]="contractsType==1" [routerLink]="'/app/contracts/list'" [queryParams]="{type:1}">Active( {{ counts && counts.active_contracts ? counts.active_contracts.count : 0 }} )</button>
  <button class="tab fs-14" [class.active]="contractsType==2" [routerLink]="'/app/contracts/list'" [queryParams]="{type:2}">Overdue( {{ counts && counts.overdue_contracts ? counts.overdue_contracts.count : 0 }} )</button>
  <button class="tab fs-14" [class.active]="contractsType==3" [routerLink]="'/app/contracts/list'" [queryParams]="{type:3}">Canceled( {{ counts && counts.cancelled_contracts ? counts.cancelled_contracts.count : 0 }} )</button>
  <button class="tab fs-14" [class.active]="contractsType==4" [routerLink]="'/app/contracts/list'" [queryParams]="{type:4}">Completed( {{ counts && counts.completed_contracts ? counts.completed_contracts.count : 0 }} )</button>
  <button class="tab fs-14" [class.active]="contractsType==5" [routerLink]="'/app/contracts/list'" [queryParams]="{type:5}">Legal( {{ counts && counts.legal_contracts ? counts.legal_contracts.count : 0 }} )</button>
  <button class="tab fs-14" [class.active]="contractsType==6" [routerLink]="'/app/contracts/list'" [queryParams]="{type:6}">Disputed Treatments( {{ counts && counts.disputed_treatments_contracts ? counts.disputed_treatments_contracts.count : 0 }} )</button>
  <button class="tab fs-14" [class.active]="contractsType==7" [routerLink]="'/app/contracts/list'" [queryParams]="{type:7}">Bankruptcy( {{ counts && counts.bankruptcy_contracts ? counts.bankruptcy_contracts.count : 0 }} )</button>
</div> 
