import { DatePipe } from "@angular/common";
import { Injectable } from "@angular/core";
import { ApiService } from "src/app/components/shared/api.service";
import { localStorageService } from "src/app/components/shared/localstorage.service";
import { SharedService } from "src/app/components/shared/shared.service";

@Injectable()
export class ContractService {
  defaultContractType: number = 0;

  public filter: any = {
    start_date: new Date('2024/01/01'),
    end_date: new Date(),
    is_expired: -1,
    status_filter: 0,
    ProposalType: 0// filter for AR 
  };
  dateRangeValue: any;
  public resData: any = {
    pageNumber: 1
  };
  public sortValue: { by: string, order: string, label: string } = {
    by: 'date_added',
    order: 'DESC',
    label: 'Registration Date'
  };
  public modalTypes = {
    bankruptcyModal: 'bankruptcyModal',
    chargeDoctorModal: 'chargeDoctorModal',
    sendWarningMail: 'sendWarningMail',
    moveRemoveUnpaidmodal: 'moveRemoveUnpaidmodal',
    chargeDispute: "chargeDispute",
    refundPopmodal: 'refundPopmodal',
    m2rReportModal: 'm2rReportModal',
    activateModal: 'activateModal',
    chargeLateFeeModal: 'chargeLateFeeModal',
    editRecurringModal: 'editRecurringModal',
    partialPaymentModal: 'partialPaymentModal',
    payMoreModal: 'payMoreModal',
    payOffModal: 'payOffModal',
    contractUpdateModal: 'contractUpdateModal',
    cancelContractModal: 'cancelContractModal',
    makeProtectedNonProtected: 'makeProtectedNonProtected',
    nextRecAmountModal: 'nextRecAmountModal',
    generateAddCardLink: 'generateAddCardLink',
    generateContributionLink: 'generateContributionLink',
    moveToUnpaidFirstRecurringModal: 'moveToUnpaidFirstRecurringModal',
    makePaymentDisputed: 'makePaymentDisputed',
    dndModal: 'dndModal',
    changeTransactionFeeThresholdModal: 'changeTransactionFeeThresholdModal',
    getOrSendMoneyToBusinessModal: 'getOrSendMoneyToBusinessModal',
    paySettledModal: 'paySettledModal',
    sendContractModal: 'sendContractModal',
    contractVerificationEmailModal: 'contractVerificationEmailModal',
    downloadContractModal: 'downloadContractModal',
    reminderTemplateModal: 'reminderTemplateModal',
    pausePaymentSMS: 'pausePaymentSMS',
    openAddBankLinkModal: 'openAddBankLinkModal',
    openContractSettlementModal: 'openContractSettlementModal',
    chargeContractModalOpen: 'chargeContractModalOpen',
    arrangementModal: 'arrangementModal',
    viewArrangementsModal: 'viewArrangementsModal',
    cardPreferenceModal: 'cardPreferenceModal'
  }

  public country_id: any = this._local.customGetItem('country_id');
  total_balance_remaining: number = 0;
  timezone: string = '';
  timezoneName: string = '';
  remaining_amount_with_def: number = 0;
  remaining_amount_after_def: number = 0;
  def_duration: number = 0;
  total_balance_remaining_after_def: number = 0;
  totalCustomArrangementPayable: any;
  totalRecurringAmount: any;
  currentYear = new Date().getFullYear();
  currentMonth = new Date().getMonth() + 1;

  constructor(private _local: localStorageService, public _shared: SharedService) {
    this.timezoneName = this._shared.getTimezoneName();
    this.timezone = this._shared.getTimezone();
  }

  GetTransactionFee(contract: any, comparable_value: any) {
    let text = "";
    if (comparable_value < contract.threshold_amount) {
      if (contract.recurring_extra_percent) {
        text += `${contract.recurring_extra_percent.toFixed(2)}%`
      }
      if (contract.recurring_extra_amount && contract.recurring_extra_percent) {
        text += ' + ';
      }
      if (contract.recurring_extra_amount) {
        text += `$${contract.recurring_extra_amount.toFixed(2)}`
      }
    } else {
      if (contract.threshold_recurring_extra_percent) {
        text += `${contract.threshold_recurring_extra_percent.toFixed(2)}%`
      }
      if (contract.threshold_recurring_extra_amount && contract.threshold_recurring_extra_percent) {
        text += ' + ';
      }
      if (contract.threshold_recurring_extra_amount) {
        text += `$${contract.threshold_recurring_extra_amount.toFixed(2)}`
      }
    }

    this.def_duration = contract.total_deferred_duration - (contract.number_of_payments - contract.remaining_payments);
    if (this.def_duration < 0) {
      this.def_duration = 0
    }

    let rec_amount = contract.recurring_amount == contract.next_recurring_amount ? contract.principal_per_recurring : contract.recurring_amount;
    this.remaining_amount_with_def = rec_amount * contract.remaining_payments;
    let remaining_duration = this.def_duration == 0 ? contract.remaining_payments : (contract.number_of_payments - this.def_duration);

    if (contract.pf_status < 3) {
      this.remaining_amount_after_def = (contract.principal_per_recurring * this.def_duration) + (contract.next_recurring_amount * remaining_duration);
    } else {
      this.remaining_amount_after_def = contract.next_recurring_amount * contract.remaining_payments;
    }
    this.remaining_amount_with_def = parseFloat(this.remaining_amount_with_def.toFixed(2));
    this.remaining_amount_after_def = parseFloat(this.remaining_amount_after_def.toFixed(2));

    if (rec_amount < contract.threshold_amount) {
      this.total_balance_remaining = ((rec_amount + contract.recurring_extra_amount + ((contract.recurring_extra_percent / 100) * rec_amount)) * contract.remaining_payments) + (contract.late_fee * contract.missing_payments);
    } else {
      this.total_balance_remaining = ((rec_amount + contract.threshold_recurring_extra_amount + ((contract.threshold_recurring_extra_percent / 100) * rec_amount)) * contract.remaining_payments) + (contract.late_fee * contract.missing_payments);
    }
    this.total_balance_remaining = parseFloat(this.total_balance_remaining.toFixed(2));

    if (contract.next_recurring_amount < contract.threshold_amount) {
      if (contract.pf_status < 3) {
        this.total_balance_remaining_after_def = ((contract.recurring_amount + contract.recurring_extra_amount + ((contract.recurring_extra_percent / 100) * contract.recurring_amount)) * this.def_duration) + ((contract.next_recurring_amount + contract.recurring_extra_amount + ((contract.recurring_extra_percent / 100) * contract.next_recurring_amount)) * (contract.remaining_payments - this.def_duration)) + (contract.late_fee * contract.missing_payments);
      } else {
        this.total_balance_remaining_after_def = ((contract.next_recurring_amount + contract.recurring_extra_amount + ((contract.recurring_extra_percent / 100) * contract.next_recurring_amount)) * contract.remaining_payments) + (contract.late_fee * contract.missing_payments);
      }
    } else {
      if (contract.pf_status < 3) {
        this.total_balance_remaining_after_def = ((contract.recurring_amount + contract.threshold_recurring_extra_amount + ((contract.threshold_recurring_extra_percent / 100) * contract.recurring_amount)) * this.def_duration) + ((contract.next_recurring_amount + contract.threshold_recurring_extra_amount + ((contract.threshold_recurring_extra_percent / 100) * contract.next_recurring_amount)) * (contract.remaining_payments - this.def_duration)) + (contract.late_fee * contract.missing_payments);
      } else {
        this.total_balance_remaining_after_def = ((contract.next_recurring_amount + contract.threshold_recurring_extra_amount + ((contract.threshold_recurring_extra_percent / 100) * contract.next_recurring_amount)) * contract.remaining_payments) + (contract.late_fee * contract.missing_payments);
      }
      this.total_balance_remaining_after_def = parseFloat(this.total_balance_remaining_after_def.toFixed(2));
    }
    return text;
  }


  GetTransactionFeeContractDetails(contract: any, comparable_value: any, arrangement_number_recurring = 0) {
    let text = '';
    if (comparable_value < contract.threshold_amount) {
      if (contract.recurring_extra_amount == 0 && contract.recurring_extra_percent == 0) {
        // transaction fee promotional check
        text += `${contract.recurring_extra_percent.toFixed(2)}%`
      } else {
        if (contract.recurring_extra_percent) {
          text += `${contract.recurring_extra_percent.toFixed(2)}%`
        }
        if (contract.recurring_extra_amount && contract.recurring_extra_percent) {
          text += ' + ';
        }
        if (contract.recurring_extra_amount) {
          text += `${contract.display_logo}${contract.recurring_extra_amount.toFixed(2)}`
        }
      }
      if (arrangement_number_recurring) {
        this.totalCustomArrangementPayable = (parseFloat(comparable_value) * parseFloat(contract.recurring_extra_percent) / 100) + parseFloat(comparable_value) + parseFloat(contract.recurring_extra_amount);
        this.totalRecurringAmount = ((parseFloat(comparable_value) * parseFloat(contract.recurring_extra_percent) / 100) + parseFloat(comparable_value) + parseFloat(contract.recurring_extra_amount)) * arrangement_number_recurring;
      }
    } else {
      if (contract.threshold_recurring_extra_amount == 0 && contract.threshold_recurring_extra_percent == 0) {
        text += '0%';
      } else {
        if (contract.threshold_recurring_extra_percent) {
          text += `${contract.threshold_recurring_extra_percent.toFixed(2)}%`
        }
        if (contract.threshold_recurring_extra_amount && contract.threshold_recurring_extra_percent) {
          text += ' + ';
        }
        if (contract.threshold_recurring_extra_amount) {
          text += `${contract.display_logo}${contract.threshold_recurring_extra_amount.toFixed(2)}`
        }
      }
      if (arrangement_number_recurring) {
        this.totalRecurringAmount = ((parseFloat(comparable_value) * parseFloat(contract.threshold_recurring_extra_percent) / 100) + parseFloat(comparable_value) + parseFloat(contract.threshold_recurring_extra_amount)) * arrangement_number_recurring;
        this.totalCustomArrangementPayable = (parseFloat(comparable_value) * parseFloat(contract.threshold_recurring_extra_percent) / 100) + parseFloat(comparable_value) + parseFloat(contract.threshold_recurring_extra_amount);
      }
    }
    return text;
  }
}
