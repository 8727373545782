<ng-container *ngIf="modalType == _debtRecovery.modalTypes.resendLinkModal">
    <div class="modal-dialog modal-md  modal-dialog-centered customModal">
        <div class="modal-content">
            <form [formGroup]="resendLinkForm" (ngSubmit)="resendLinkFn()">
                <div class="modal-header">
                    <h4 class="modal-title">
                        Resend Payment Request Link
                    </h4>
                    <button type="button" class="close" aria-label="Close" (click)="hide()">&times;</button>
                </div>
                <div class="modal-body">
                    <div class="col-12 pl-0">
                        <h4 style="font-weight: 500;">Are you sure, you want to resend the payment request link to the
                            following contact information?</h4>
                        <div class="form-group mb-3">
                            <input type="email" email class="form-control" placeholder="Email Address"
                                formControlName="user_email">
                            <p *ngIf="resendLinkForm.controls['user_email'].invalid && resendLinkForm.controls['user_email'].touched"
                                class="text-danger">
                                Please enter a valid email
                            </p>
                        </div>
                        <div class="form-group">
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"
                                        id="inputGroupPrepend3">+1{{extra_data.paymentProcessInfo &&
                                        extra_data.paymentProcessInfo.patient_mobile_country_code}}</span>
                                </div>
                                <input type="text" maxlength="15" minlength="10" class="form-control" [mask]="_shared.mask"
                                    formControlName="user_phone">
                            </div>
                            <p *ngIf="resendLinkForm.controls['user_phone'].invalid && resendLinkForm.controls['user_phone'].touched"
                                class="text-danger">Please enter a valid mobile number
                            </p>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-primary" type="submit">Resend</button>
                </div>
            </form>
        </div>
    </div>
</ng-container>

<!-- View Plans Detail -->
    <ng-container *ngIf="modalType == _debtRecovery.modalTypes.detailsModal">
        <div class="modal-dialog modal-lg  modal-dialog-centered" *ngIf="details_extra_data.data[0].contract_type != 6">
            <div class="modal-content">
                <form [formGroup]="detailsForm">
                    <div class="modal-header">
                        <h4 class="modal-title"  *ngIf="!details_extra_data.view_track_status">
                            Financing Plans
                        </h4>
                        <h4 class="modal-title" *ngIf="details_extra_data.view_track_status">
                            Proposal Status
                         </h4>
                        <button type="button" class="close" aria-label="Close"
                            (click)="cancel()">&times;</button>
                    </div>
                    <div class="modal-body m-3"  *ngIf="!details_extra_data.view_track_status">
                        <div class="row" *ngIf="details_extra_data.data[0].plans_array && details_extra_data.data[0].plans_array.length>0">
                            <div [ngClass]="{'col-6': details_extra_data.data[0].plans_array && details_extra_data.data[0].plans_array.length > 1, 'col-12':  details_extra_data.data[0].plans_array && details_extra_data.data[0].plans_array.length == 1}"
                                *ngFor="let plan of details_extra_data.data[0].plans_array" style="box-shadow:none">
                                <div class="card border plan-box">
                                    <div class="card-header">
                                        <div class="mb-0 pl-3 pr-3 row justify-content-between">
                                            <div>
                                                <h4>{{plan.month}} months</h4>
                                            </div>
                                            <div>
                                                <span
                                                    *ngIf="details_extra_data.data[0].number_of_payments == plan.month && details_extra_data.data[0].apf_id != 0 && details_extra_data.data[0].contract_id == 0"
                                                    class="badge bg-warning ml-auto pull-right">Plan Selected,
                                                    Financing Details Pending</span>
                                                <span
                                                    *ngIf="details_extra_data.data[0].number_of_payments == plan.month && details_extra_data.data[0].apf_id != 0 && details_extra_data.data[0].contract_id != 0"
                                                    class="badge bg-success ml-auto pull-right">Selected</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card-body">
                                        <div class="text-center" style="color: black;">
                                            <h3>{{details_extra_data.data[0].display_logo}}{{plan.recurring_amount | number: '1.2-2'}}</h3>
                                            <h4>per month</h4>
                                        </div>
                                        <div>
                                            <span>Estimated Service Amount:</span>
                                            <span class="pull-right">{{details_extra_data.data[0].display_logo}}{{plan.treatment_amount | number:
                                                '1.2-2'}}</span>
                                        </div>
                                        <div>
                                            <span>Down Payment:</span>
                                            <span
                                                class="pull-right">{{details_extra_data.data[0].display_logo}}{{plan.downpayment?plan.downpayment:plan.downpayment_amount}}</span>
                                        </div>
                                        <div>
                                            <span>Estimated Financed Amount:</span>
                                            <span class="pull-right">{{details_extra_data.data[0].display_logo}}{{(plan.treatment_amount -
                                                (plan.downpayment?plan.downpayment:plan.downpayment_amount)) | number:
                                                '1.2-2'}}</span>
                                        </div>
                                        <div>
                                            <span
                                                *ngIf="details_extra_data.data[0].contract_type == 2 || details_extra_data.data[0].contract_type == 4 || details_extra_data.data[0].contract_type == 5">Upfront
                                                Payment:</span>
                                            <span
                                                *ngIf="details_extra_data.data[0].contract_type == 1 || details_extra_data.data[0].contract_type == 3">First
                                                Recurring Due
                                                Today:</span>
                                            <span class="pull-right">{{details_extra_data.data[0].display_logo}}{{plan.advance_interest_amount |
                                                number: '1.2-2'}}</span>
                                        </div>
                                        <div>
                                            <span>Interest Rate:</span>
                                            <span
                                                class="pull-right">{{plan.interest_rate_to_show?plan.interest_rate_to_show:plan.interest_rate}}%
                                                <sup>{{!plan.interest_rate_to_show || (plan.interest_rate ==
                                                    plan.interest_rate_to_show) ? (plan.interest_rate>0?'C':'') : 'F'}}</sup>
                                            </span>
                                        </div>
                                        <div>
                                            <span>Transaction Fee:</span>
                                            <span
                                                *ngIf="GetTransactionFee(plan, plan.recurring_amount?plan.recurring_amount:plan.recurring_amount)"
                                                class="pull-right">{{GetTransactionFee(plan,
                                                plan.recurring_amount?plan.recurring_amount:plan.recurring_amount)}}
                                                per payment</span>
                                        </div>
                                        <div>
                                            <span>Enrollment Fee:</span>
                                            <span class="pull-right"> {{details_extra_data.data[0].display_logo}}{{plan.enrollment_fee ?
                                                plan.enrollment_fee : 0}} </span>
                                        </div>
                                        <div>
                                            <span> Total Payable Amount:</span>
                                            <span
                                                class="pull-right">{{details_extra_data.data[0].display_logo}}{{plan.amount?plan.amount:plan.remaining_amount
                                                | number: '1.2-2'}}</span>
                                        </div>
                                        <div>
                                            <span><strong>Total Payable Today:</strong></span>
                                            <span class="pull-right"><strong>{{details_extra_data.data[0].display_logo}}{{plan.payable_amount_today |
                                                    number: '1.2-2'}}</strong></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p class="col-sm-12 m-0">F: Flat Interest, C: Compound Interest</p>
                        </div>
                    </div>
                    <div class="modal-body m-3" *ngIf="details_extra_data.view_track_status && details_extra_data.data[0].tracking_reminders && details_extra_data.data[0].tracking_reminders.length>0"
                        style="max-height:calc(100vh - 204px);overflow: auto;">
                        <div class="tracking_status_container border-left pl-5 pr-4 pb-3"
                            [ngClass]="{'border-left': ( status_data.reminder_type!=6 && !last),'upcoming_tracking':status_data.reminder_type==6}"
                            *ngFor="let status_data of details_extra_data.data[0].tracking_reminders;let last = last;">
                            <p class="mb-1">{{status_data.reminder_message ? status_data.reminder_message : ''}}</p>
                            <p class="m-0">{{status_data.reminder_date_message ? status_data.reminder_date_message + ' ' : ''}} {{status_data.date_sent| date:'MM/dd/yyyy (hh:mm a)' : timezone}}</p>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div class="modal-dialog modal-lg  modal-dialog-centered " *ngIf="details_extra_data.data[0].contract_type == 6"
            [ngClass]="{'modal-sm': details_extra_data.data[0].plans_array && details_extra_data.data[0].plans_array.length == 1, 'modal-lg':  details_extra_data.data[0].plans_array && details_extra_data.data[0].plans_array.length > 1}">
            <div class="modal-content">
                <form [formGroup]="detailsForm">
                    <div class="modal-header">
                        <h4 class="modal-title">
                            Financing Plans
                        </h4>
                        <button type="button" class="close" aria-label="Close"
                            (click)="cancel()">&times;</button>
                    </div>
                    <div class="modal-body m-3">
                        <div class="row" *ngIf="details_extra_data.data[0].plans_array && details_extra_data.data[0].plans_array.length>0">
                            <div [ngClass]="{'col-6': details_extra_data.data[0].plans_array && details_extra_data.data[0].plans_array.length > 1, 'col-12':  details_extra_data.data[0].plans_array && details_extra_data.data[0].plans_array.length == 1}"
                                *ngFor="let plan of details_extra_data.data[0].plans_array" style="box-shadow:none">
                                <div class="card border plan-box">
                                    <div class="card-header">
                                        <div class="mb-0 pl-3 pr-3 row justify-content-between">
                                            <div>
                                                <h4>{{plan.month}} months</h4>
                                            </div>
                                            <div>
                                                <span
                                                    *ngIf="details_extra_data.data[0].number_of_payments == plan.month && details_extra_data.data[0].apf_id != 0 && details_extra_data.data[0].contract_id == 0"
                                                    class="badge bg-warning ml-auto pull-right">Plan Selected,
                                                    Financing Details Pending</span>
                                                <span
                                                    *ngIf="details_extra_data.data[0].number_of_payments == plan.month && details_extra_data.data[0].apf_id != 0 && details_extra_data.data[0].contract_id != 0"
                                                    class="badge bg-success ml-auto pull-right">Selected</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card-body">
                                        <div style="border-bottom: 1px solid #e3e1e1;">
                                            <div>
                                                <span  style="font-family: Lato;
                                                font-size: 12px;
                                                font-style: normal;
                                                font-weight: 700;">During Deferred Duration</span>
                                                <h3 style="font-family: Lato;
                                                font-size: 16px;
                                                font-style: normal;
                                                font-weight: 700;
                                                line-height: 24px;">{{details_extra_data.data[0].display_logo}}{{plan.recurring_amount | number: '1.2-2'}} /month</h3>
                                            </div>
                                            <div class="d-flex justify-content-between mb-2" style="
                                            font-family: Lato;
                                            font-size: 14px;
                                            font-style: normal;
                                            font-weight: 700;">
                                                <span >Interest Rate:</span>
                                                <span >No Interest</span>
                                            </div>
                                        </div>
                                        <div style="border-bottom: 1px solid #e3e1e1;" class="mt-2 mb-2"> 
                                            <div>
                                                <span style="font-family: Lato;
                                                font-size: 12px;
                                                font-style: normal;
                                                font-weight: 700;">After Deferred Duration</span>
                                                <h3 style="font-family: Lato;
                                                font-size: 16px;
                                                font-style: normal;
                                                font-weight: 700;
                                                line-height: 24px;">{{details_extra_data.data[0].display_logo}}{{plan.next_recurring_amount | number: '1.2-2'}}/month</h3>
                                            </div>
                                            <div class="d-flex justify-content-between mb-2" style="
                                            font-family: Lato;
                                            font-size: 14px;
                                            font-style: normal;
                                            font-weight: 700;">
                                                <span>Interest Rate:</span>
                                                <span class="pull-right">{{plan.interest_rate_to_show?plan.interest_rate_to_show:plan.interest_rate}}%
                                                <sup>{{!plan.interest_rate_to_show || (plan.interest_rate ==
                                                    plan.interest_rate_to_show) ? 'C' : 'F'}}</sup>
                                            </span>
                                            </div>
                                        </div>
                                        <div class="estimate mt-2">
                                            <span>Estimated Service Amount:</span>
                                            <span class="pull-right">{{details_extra_data.data[0].display_logo}}{{plan.treatment_amount | number:
                                                '1.2-2'}}</span>
                                        </div>
                                        <div class="estimate">
                                            <span>Down Payment:</span>
                                            <span
                                                class="pull-right">{{details_extra_data.data[0].display_logo}}{{plan.downpayment?plan.downpayment:plan.downpayment_amount}}</span>
                                        </div>
                                        <div class="estimate">
                                            <span>Estimated Financed Amount:</span>
                                            <span class="pull-right">{{details_extra_data.data[0].display_logo}}{{(plan.treatment_amount -
                                                (plan.downpayment?plan.downpayment:plan.downpayment_amount)) | number:
                                                '1.2-2'}}</span>
                                        </div>
                                        <div class="estimate">
                                            <span
                                                *ngIf="details_extra_data.data[0].contract_type == 2 || details_extra_data.data[0].contract_type == 4 || details_extra_data.data[0].contract_type == 6">Upfront
                                                Payment:</span>
                                            <span
                                                *ngIf="details_extra_data.data[0].contract_type == 1 || details_extra_data.data[0].contract_type == 3">First
                                                Recurring Due
                                                Today:</span>
                                            <span class="pull-right">{{details_extra_data.data[0].display_logo}}{{plan.advance_interest_amount |
                                                number: '1.2-2'}}</span>
                                        </div>
                                        
                                        <div class="estimate">
                                            <span>Transaction Fee:</span>
                                            <span
                                                *ngIf="GetTransactionFee(plan, plan.recurring_amount?plan.recurring_amount:plan.recurring_amount)"
                                                class="pull-right">{{GetTransactionFee(plan,
                                                plan.recurring_amount?plan.recurring_amount:plan.recurring_amount)}}
                                                per payment</span>
                                        </div>
                                        <div class="estimate">
                                            <span>Enrollment Fee:</span>
                                            <span class="pull-right"> {{details_extra_data.data[0].display_logo}}{{plan.enrollment_fee ?
                                                plan.enrollment_fee : 0}} </span>
                                        </div>
                                        <div class="estimate">
                                            <span> Total Payable Amount:</span>
                                            <span
                                                class="pull-right">{{details_extra_data.data[0].display_logo}}{{plan.amount?plan.amount:plan.remaining_amount
                                                | number: '1.2-2'}}</span>
                                        </div>
                                        <div class="estimate">
                                            <span><strong>Total Payable Today:</strong></span>
                                            <span class="pull-right"><strong>{{details_extra_data.data[0].display_logo}}{{plan.payable_amount_today |
                                                    number: '1.2-2'}}</strong></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p class="col-sm-12 m-0">F: Flat Interest, C: Compound Interest</p>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </ng-container>

    <!-- View payment settings Detail -->
    <ng-container *ngIf="modalType == _debtRecovery.modalTypes.PaymentSettingsModal">
        <div class="modal-dialog modal-lg  modal-dialog-centered ">
            <div class="modal-content">
                <form [formGroup]="paymentSettingsForm" *ngIf="payment_extra_data.data[0] && payment_extra_data.data[0]['payment_matrix_array']">
                    <div class="modal-header">
                        <h4 class="modal-title">Contract Payment Settings</h4>
                        <button type="button" class="close" aria-label="Close"
                            (click)="cancel()">&times;</button>
                    </div>
                    <div class="modal-body m-3">
                        <div class="row">
                            <div class="form-group col-sm-6">
                                <label>Primary Payment Method*</label>
                                <select required formControlName="primary_type"
                                    name="primary_type" class="form-control" style="height: 40px;">
                                    <option value="1">Bank</option>
                                    <option value="2">Card</option>
                                    <option value="3">Any</option>
                                </select>
                            </div>
                            <div class="form-group col-sm-6">
                                <label>Recurring Payment Method*</label>
                                <select required formControlName="recurring_type"
                                    name="recurring_type" class="form-control" style="height: 40px;">
                                    <option value="1">Bank</option>
                                    <option value="2">Card</option>
                                    <option value="3">Any</option>
                                </select>
                            </div>
                            <div class="form-group col-sm-6">
                                <label>Backup Payment Method*</label>
                                <select required formControlName="backup_type"
                                    name="backup_type" class="form-control" style="height: 40px;">
                                    <option value="1">Bank</option>
                                    <option value="2">Card</option>
                                    <option value="3">Any</option>
                                </select>
                            </div>
                            <div class="form-group col-sm-12">
                                <div class="row">
                                    <div class="col-sm-10">
                                        <label>Is Backup card mandatory to customer?</label>
                                    </div>
                                    <div class="col-sm-2">
                                        <div class="form-group mt-1">
                                            <label class="switch switch-sm switch-success ml-1 mr-1">
                                                <input type="checkbox"
                                                    formControlName="backup_required"
                                                    name="backup_required" />
                                                <span></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-sm-12">
                                <div class="row">
                                    <div class="col-sm-10">
                                        <label>Unblock Contract creation for Overdue state?</label>
                                    </div>
                                    <div class="col-sm-2">
                                        <div class="form-group mt-1">
                                            <label class="switch switch-sm switch-success ml-1 mr-1">
                                                <input type="checkbox" formControlName="skip_overdue_check" (change)="toggleBlockContractForOverdue()"
                                                    name="skip_overdue_check" />
                                                <span></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <div class="row">
                            <div class="col">
                                <button class="btn btn-default" (click)="cancel()">Cancel</button>
                            </div>
                            <div class="col">
                                <button class="btn btn-primary"
                                    (click)="editPaymentSettings()">Save</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </ng-container>

<!-- Edit expiry date -->
  <ng-container *ngIf="modalType == _debtRecovery.modalTypes.editExpiryDateModal">
	<div class="modal-dialog modal-md  modal-dialog-centered ">
	  <div class="modal-content">
        <form [formGroup]="editExpiryDateForm">
            <div class="modal-header">
            <h4 class="modal-title">
                Update Proposal Expiry Date
            </h4>
            <button type="button" class="close" aria-label="Close" (click)="cancel()">&times;</button>
            </div>
            <div class="modal-body m-3">
            <div class="row">
                <label>Choose the new proposal expiry date(MM/DD/YYYY)</label>
                <input type="text" class="form-control mt-1" [minDate]="min_date" formControlName="new_expiry_date"
                placeholder="Click here to choose date" readonly #eDate="bsDatepicker" bsDatepicker
                [bsConfig]="bsConfig" />
            </div>
            <div _ngcontent-c10="" class="row mt-2">
                <label _ngcontent-c10="">Notes*</label>
                <textarea _ngcontent-c10="" class="mt-1 form-control ng-pristine ng-invalid ng-touched" name="notes" formControlName="notes"
                placeholder="Enter notes here" required="" rows="4" ng-reflect-required="" ng-reflect-name="notes"></textarea>
            </div>
            </div>
            <div class="modal-footer">
            <div class="row">
                <div class="col pr-1">
                <button class="btn btn-default" (click)="cancel()">No</button>
                </div>
                <div class="col">
                <button class="btn btn-primary" [disabled]="editExpiryDateForm.invalid" (click)="editExpiryDate()">Yes</button>
                </div>
            </div>
            </div>
        </form>
	  </div>
	</div>
  </ng-container>