<div class="modal-dialog modal-lg  modal-dialog-centered customModal">
  <div class="modal-content border-0">
    <div class="modal-header d-flex justify-content-between align-items-center theme-green-bg">
      <h4 class="modal-title fs-18 text-white">
        View Notes
      </h4>
      <img src="assets/images/icons/white-cross.svg" alt="" (click)="hide()" class="cursor hw-24">
    </div>
    <div class="modal-body"
      [ngStyle]="{'height':current_notes.length>4? '500px':'','overflow-y': current_notes.length>4? 'scroll':'auto'}">
      <div class="card b" *ngIf="current_notes">
        <div class="card-header fs-14 fw-400">Notes {{timezone?'(Time in ' + timezoneName+')':''}}</div>
        <div class="card-body">
          <div class="text-center p-4  rounded" *ngIf="current_notes && current_notes.length == 0">
            <span class="fa fa-exclamation-triangle fa-5x"></span>
            <p class="m-0 mt-2 fs-16 fw-400">Notes not added yet!</p>
          </div>
          <div class="b mb-1 p-2 rounded note-item" *ngFor="let admin of current_notes">
            <div class="d-flex align-items-center mb-1">
              <p class="m-0">
                <strong class="admin_name">{{admin.admin_name}}</strong>
                {{(admin.date_added | date:'medium':timezone)}}
              </p>
            </div>
            <p class="speech-bubble">
              <span *ngIf="admin && admin.date_added" class="pr-1"><strong>Changed Date:</strong>
                {{admin.date_added | date:'MM-dd-yyyy':timezone}}</span><br />
              <span class="show-enter"><strong>Notes: </strong> {{admin.notes}} </span>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="hide()">Close</button>
    </div>
  </div>
</div>