import { Component, OnInit, Input,  Output, EventEmitter, OnChanges, ChangeDetectorRef} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, UntypedFormGroup } from '@angular/forms';
// import * as _ from 'underscore';
import { FiltersService } from './filters.service';
import { ActivatedRoute } from '@angular/router';
import { MainService } from '../main.service';
import { localStorageService } from '../localstorage.service';
import { SharedService } from '../shared.service';
import { ApiService } from '../api.service';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { Filters } from '../interface.component';


@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss']
})
export class FiltersComponent implements OnInit, OnChanges {

  @Output() finalValues: any = new EventEmitter();
  @Output() resetAllFilters: any = new EventEmitter();
  @Output() cancelSearched = new EventEmitter<{ title: string }>();
  public filterForm!: UntypedFormGroup;
  minDate: Date = new Date(2024, 0, 1);
  maxDate: Date = new Date();
  timezone: any;
  subIndustryFilter: any;
  public filterData: Array<Filters> = [];
  bsConfig: Partial<BsDatepickerConfig>;
  
  constructor(
    public fb: FormBuilder,
    public mainService: MainService,
    public filterService : FiltersService,
    private cd: ChangeDetectorRef,
    public route: ActivatedRoute,
    private _local: localStorageService,
    public _shared: SharedService,
    private _api: ApiService
  ) {
    this.timezone = this._shared.getTimezone();
    this.bsConfig = Object.assign({}, {
      containerClass: 'theme-green',
      dateInputFormat: 'MM/DD/YYYY',
      placement:'bottom'
    });
  }

  get filters(): Array<Filters> {
    return this.filterData;
  }

  @Input() set filters(val: Array<Filters>) {
    if (val) {
      this.filterData = val;
      console.log(this.filterData);
      this.initForm(1);
    }
  }

  ngOnInit() {
    this.maxDate = new Date(this._shared.getMaxDate(this.timezone));
    this.timezone = this._shared.getTimezone();
    this.maxDate = new Date(this._shared.getMaxDate(this.timezone));
  }

  initForm(id: any): void {
    const group: any = {};
    /** To disable filter on condition initially */
    this.filterData.forEach((element: any) => {
      element.disabled = false;
      if (element.can_disable == true) {
        element.disabled_condition.forEach((ele: any) => {
          let x = this.filterData.filter((i: any) => (ele.control == i.control && (ele.value.includes(i.value)) || (ele.value == i.value)));
          if (x && x.length > 0) {
            element.disabled = true;
          }
        });
      }
      group[element.control] = new FormControl({ value: id == 1 ? element.value : element.default || null, disabled: element.disabled ? true : false });
    });
    this.filterForm = this.fb.group(group);
    console.log(this.filterForm.value);
  }

  changedate(){
    if(this.filterForm && this.filterForm.value &&  this.filterForm.value.webinar_date){
      console.log("this.filterForm.value.webinar_date",this.filterForm.value.webinar_date)
      this.finalValues.emit({ data: this.filterForm.value });
     }
  }

  ngOnChanges() {
    if (this.filterForm) {
      /** To disable filter on condition at runtime */
      this.filterData.forEach((element: any) => {
        element.disabled = false;
        if (element.can_disable == true) {
          var element_disabled;
          element.disabled_condition.forEach((o: any) => {
            let x = this.filterData.filter((i: any) => {
              const control = this.filterForm.get(i.control);
              if (control) {
                let y = control.value
                return (o.control == i.control && o.value.includes(y));
              }
            });
            if (x && x.length > 0) {
              element_disabled = true;
            }
          });
          if (this.filterForm.get(element.control)) {
            const control = this.filterForm.get(element.control);
            if (element_disabled === true) {
              element.disabled = true;
              if (control) {
                control.disable();
              }
              /** Set default value after disabled */
              this.filterForm.controls[element.control].setValue(element.default);
            } else {
              if (control) {
                control.enable();
              }
            }
          }
        }
      });
    }
  }

  mindateFilter:any= this.route.snapshot.queryParams['startDate'] ? new Date(this.route.snapshot.queryParams['startDate']) : new Date(2024, 0, 1);
  maxDateFilter: any = new Date(this._shared.getMaxDate(this._local.customGetItem("timezone")))
  // dateOnChanges(field: any, event: any) {
  //   if(field && field.control == "endDate" ){
  //     this.maxDateFilter = event
  //   }
  //   if(field && field.control == "startDate" ){
  //     this.mindateFilter = event
  //   }
  //   this.filterData.forEach((item) => {
  //     item.minDate = event;
  //     this.filterForm.patchValue({[item.control] : event});
  //     // if(item.control == element.validate[1]){
  //     // }
  //   });
  //   return;
  //   if (this.filterForm) {
  //     /** To add date validation on condition at runtime */
  //     this.filterData.forEach((element: any) => {
  //       if(element.control == field.control && element.validate && element.validate.length){
  //         if(element.validate[0] == "less_than_to" && element.validate[1]){
  //           this.filterData.forEach((item) => {
  //             if(item.control == element.validate[1]){
  //               item.minDate = event;
  //               this.filterForm.patchValue({[item.control] : event});
  //             }
  //           });
  //         }
  //         if(element.validate[0] == "more_than_from" && element.validate[1]){
  //           this.filterData.forEach(item => {
  //             if(item.control == element.validate[1]){
  //               item.maxDate = event;
  //               this.filterForm.patchValue({[item.control] : event});
  //             }
  //           });
  //         }
  //       }
  //     });
  //   }
  // }


  applyFilter(): void {
    if (this.filterForm.value && this.filterForm.value.search) {
      this.filterForm.value.search = this.filterForm.value.search.trim();
    }
    this.finalValues.emit({ data: this.filterForm.value });
  }

  resetAll(): void {
    this.initForm(0);    
    this.resetAllFilters.emit({ data: this.filterForm.value });
  }


  async filterOnChange(eventName: string, data: any) {
    console.log(">>>>>>>>>>>>>>>>>>>>>>",data)
    if (eventName == "" || !eventName) {
      return;
    }
    switch (eventName) {
      case "industryFilter":
        const industries: any = []
        data.forEach((item: any) => {
          industries.indexOf(item.data.value) === -1 ? industries.push(item.data.value) : 0;
        });
        this.filterData.forEach(async element => {
          if (element.control == "subIndustryFilter") {
            if (industries.length) {
              let subIndustries: any = await this.getSubIndustryList(industries);
              this.cd.detectChanges();
              element.options = subIndustries.map((element: any) => {
                return {
                  value: element.doctor_speciality,
                  label: element.speciality_name
                }
              });
            }else{
              element.options = []
            }
          }
        });
        break;
    }
    return;
  }

  getSubIndustryList(industries: any ) {
    return new Promise(async (resolve, reject) => {
      let data = {
        access_token: this._local.customGetItem("adminToken"),
        industry_filter: industries
      };
      this._api.api(data, 'get_specialities')
        .subscribe((data: any) => {
          if (data.is_error == 1) {
            if (data.original_text) {
              this._shared.customPopups(data.original_text, data.is_error);
            } else {
              this._shared.customPopups(data.flag, data.is_error);
            }
            resolve([]);
          } else {
            if (data && data.data) {
              data.data.forEach((item: any, index: any) => {
                item.doctor_speciality = item.doctor_speciality.toString();
              });
              resolve(data.data);
            }
          }
        }, (error: any) => {
          resolve([]);
          console.log(error);
        });
    });
  }

  cancelSearch() {
    this.cancelSearched.emit({ title: 'cancel search' });
  }
  onOpenCalendar(container: any) {
    container.monthSelectHandler = (event: any): void => {
      container._store.dispatch(container._actions.select(event.date));
    };     
    container.setViewMode('month');
  }
}