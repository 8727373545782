import { inject } from "@angular/core";
import { CanActivateFn, Router } from "@angular/router";
import { localStorageService } from "./components/shared/localstorage.service";

export const authGuard: CanActivateFn = async (route, state) => {
    const router = inject(Router);
    const _local = inject(localStorageService);
    if (await isAuthenticated()) {
        // authService.dollarSign$.subscribe(resp => {
        //     _main.document_label = (resp && resp.country && resp.country['identity_label']) || '';
        //     _main.minDocumentIdLength = (resp && resp.country && resp.country['identity_document_min_length']) || '';
        //     _main.maxDocumentIdLength = (resp && resp.country && resp.country['identity_document_max_length']) || '';
        // });
        return true;
    } else {
        _local.customClearAllItems();
        router.navigate(['/login'], { queryParamsHandling: 'merge' });
        return false;
    }
};

async function isAuthenticated() {
    const _local = new localStorageService();
    if (!_local.customGetItem('token') || _local.customGetItem('token') == null || _local.customGetItem('reset_password_token')) {
        return false;
    } else {
        return true;
    }
}