<div class="row justify-content-between m-0 pt-2 blue-bg">
  <div class="col-6">
      <h3 class="m-0 fs-24 fw-700 text-secondary mb-2">Contract Details</h3>
      <span *ngIf="contract && contract.contract_type">
          <span class="badge bg-success h3" *ngIf="contract.contract_type == 2">CredeeFi Financing</span>
          <!-- <span class="badge bg-warning h3" *ngIf="contract.contract_type == 1">10% Platform Fee</span> -->
          <span class="badge bg-primary h3" *ngIf="contract.contract_type == 3">KeepeeFi Financing</span>
          <span class="badge bg-primary h3" *ngIf="contract.contract_type == 4">{{contract.ez_plan_new ? 'ZeroFi
              Financing':
              'SimpleeFi Financing'}}</span>
          <span class="badge bg-primary h3" *ngIf="contract.contract_type == 6">SimpleeFi Financing (With Deferred
              Interest)</span>
          <span class="badge bg-primary h3" *ngIf="contract.contract_type == 5">Credee Bill Payment Plan</span>
          <span class="badge bg-warning h3 ms-2" *ngIf="contract.settlement_amount">Contract Settled</span>
          <span class="badge h3 ms-2"
              [ngStyle]="{'background-color': contract.orp_badge.bg_color, 'color': contract.orp_badge.text_color}"
              *ngIf="contract.orp_badge && contract.orp_badge.is_orp">{{contract.orp_badge.text?contract.orp_badge.text:''}}</span>
      </span>
  </div>
  <div class="col-6">
      <div class="row justify-content-end pt-4">
          <div class="col-5">
              <div class="d-flex align-items-center">
                  <label class="vertical-bottom fs-16 fw-600 me-2">Payment Reminder Email</label>
                  <div class="pt-2">
                      <label class="switch switch-md switch-success vertical-bottom">
                          <input type="checkbox" [checked]="contract && contract.stop_payment_reminder == 0"
                              (change)="toggleStopReminder()" />
                          <span></span>
                      </label>
                  </div>
              </div>
          </div>
          <div class="col-5">
              <div class="d-flex align-items-center">
                  <label class="vertical-bottom fs-16 fw-600 me-2">Payment Reminder SMS</label>
                  <div class="pt-2">
                      <label class="switch switch-md switch-success vertical-bottom">
                          <input type="checkbox" [checked]="contract && contract.stop_payment_reminder_sms == 0"
                              (change)="toggleStopReminderSms()" />
                          <span></span>
                      </label>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>
<div class="col-12 text-center">
  <h4 *ngIf="contract && contract.is_activated == 1 && disable_action && disable_action.length>0">Note: You’ll be able
      to make changes when the pending payment is cleared.</h4>
  <!-- <h4 *ngIf="contract && contract.is_activated == 2" class="text-danger">Note: Bank Verification Pending</h4>
<h4 *ngIf="contract && contract.is_activated == 0" class="text-danger">Note: Contract Creation Failed</h4> -->
</div>
<div class="col-12 text-center" *ngIf="is_error">
  <img src="assets/images/svg/patients.svg">
  <h4 style="color:#8592a5">No Contract Found</h4>
</div>

<div *ngIf="contract && !is_error" class="p-4 blue-bg">
  <div class="row m-0">
      <div class="card col-xl-9 col-xxl-10">
          <div class="card-header  border-bottom bg-white">
              <div class="row py-1 align-items-start justify-content-between m-0">
                  <div class="col-sm-6 col-xxl-4 p-0">
                      <h3 class="fs-24">Contract ID: {{contract.contract_code}}</h3>
                      <span id="contract_payment_status" class="badge p-2"
                          [ngClass]="{'bg-success':contract.pf_status<3||contract.pf_status==4,'bg-warning':contract.pf_status==3,'bg-danger':contract.pf_status==5}">
                          {{contract.payment_status}}
                      </span>
                      <span class="ms-2 badge p-2 bg-info">Payoff: {{contract.show_payoff_btn ? 'Enabled' :
                          'Disabled'}}</span>
                      <span *ngIf="contract && contract.is_activated == 2" class="badge p-2 bg-danger">Contract
                          Payment Pending</span>
                      <span *ngIf="contract && contract.is_activated == 0" class="badge p-2 bg-danger">Contract
                          Payment Failed</span>
                      <span class="badge p-2 ms-2"
                          [ngClass]="{'bg-success':contract.is_contract_verified ==1, 'bg-danger':contract.is_contract_verified !=1}">
                          {{contract.is_contract_verified == 1 ? 'Verified' : 'Non-verified'}}
                      </span>
                  </div>

                  <div class="col-sm-6 col-xxl-4 text-center p-0"
                      *ngIf="contract.bankrupt_stop_payment == 1 || contract.dispute_payment_status == 1 || contract.dnd == 1">
                      <div class="border-left border-right">
                          <div class="text-center">
                              <p *ngIf="contract.bankrupt_stop_payment == 1">
                                  <span class="badge p-2 bg-danger" style="font-size:16px!important">
                                      {{contract.bankrupt_stop_payment == 1 ?'Bankruptcy Filed':''}}
                                  </span>
                              </p>

                              <p *ngIf="contract.dispute_payment_status == 1">
                                  <span class="badge p-2 bg-danger"> Payment/Treatment
                                      Disputed</span>
                              </p>
                              <div class="dndAnimation">
                                  <p *ngIf="contract.dnd == 1">
                                      <span class="badge p-2 bg-danger" style="min-width: 120px;">
                                          <img src='./assets/img/white.svg' class="dnd" /> Do not Disturb
                                      </span>
                                  </p>
                              </div>
                          </div>
                      </div>
                  </div>


                  <div class="col-sm-6 p-0 text-xxl-end">
                      <h3 class="fs-24"
                          *ngIf="(contract.is_guaranteed == 0 || contract.is_guaranteed == 1) && contract.ind_is_guaranteed == 1">
                          Protected Payment</h3>
                      <h3 class="fs-24 fw-600"
                          *ngIf="(contract.is_guaranteed != 0 && contract.is_guaranteed != 1) && contract.ind_is_guaranteed == 1">
                          Non-Protected Payment
                          <i class="fa fa-info-circle" data-container="body" data-placement="top"
                              tooltip="Industry is Protected but Contract is Non-Protected"></i>
                      </h3>
                      <h3 class="fs-24 fw-600"
                          *ngIf="(contract.is_guaranteed == 0 || contract.is_guaranteed == 1)  && contract.ind_is_guaranteed != 1">
                          Non-Protected Payment
                          <i class="fa fa-info-circle" data-container="body" data-placement="top"
                              tooltip="Contract is Non-Protected as Industry is Non-Protected"></i>
                      </h3>
                      <div class="clearfix d-grid">
                          <span clas fs-14>Note : Correct amount will be reflected in 2 to 3 hours</span>
                          <span *ngIf="contract.stripe_account_id == 0 || !contract.stripe_account_id">
                              Note: Business hasn't added his/her bank account
                          </span>
                      </div>
                  </div>

              </div>
          </div>

          <div class="card-body">
              <div class="row">
                  <div class="col-md-7 details">
                      <!--Patient Details Start-->
                      <div class="card card-body border mb-4">
                          <label class="fs-12">Customer Details</label>
                          <h3 class="fs-24 fw-600">
                              {{ contract.patient_first_name + ' '+ contract.patient_last_name }}
                              <span class="badge bg-success h3"
                                  *ngIf="verification_details && verification_copied_details">Verified by
                                  System</span>
                              <span *ngIf="verification_details && verification_copied_details"><i
                                      class="fa fa-info-circle" data-container="body" data-placement="top"
                                      tooltip="{{verification_copied_details}}"></i></span>

                          </h3>
                          <div class="row">
                              <div class="col-sm-4">
                                  <label class="fs-12">Customer Code</label>
                                  <h6 class="fs-16 fw-400">{{contract.patient_code}}</h6>
                              </div>
                              <div *ngIf="(!_shared.routerAuth.restricted_buttons.show_contact_in_dnd && contract.dnd == 1) || contract.dnd == 0"
                                  class="col-sm-4">
                                  <label class="fs-12">Email Address</label>
                                  <h6 *ngIf="contract.patient_email">
                                      <a class="fs-14 fw-400"
                                          [routerLink]="['/app/patients/profile',contract.patient_id]">{{contract.patient_email}}</a>
                                  </h6>
                                  <h6 *ngIf="!contract.patient_email">Not Entered</h6>
                              </div>
                              <div *ngIf="(_shared.routerAuth.restricted_buttons.show_contact_in_dnd && contract.dnd == 1) "
                                  class="col-sm-4">
                                  <label class="fs-12">Email Address</label>
                                  <h6 class="fs-14 fw-400" *ngIf="contract.patient_email">
                                      {{dnd_checks.dnd_patient_email}}</h6>
                                  <h6 class="fs-14 fw-400" *ngIf="!contract.patient_email">Not Entered</h6>
                              </div>
                              <div *ngIf="(!_shared.routerAuth.restricted_buttons.show_contact_in_dnd && contract.dnd == 1) || contract.dnd == 0"
                                  class="col-sm-4">
                                  <div class="row">
                                      <div class="col-sm-7">
                                          <label class="fs-12">Phone</label>
                                          <h6 class="fs-16 fw-400" *ngIf="contract.patient_mobile">
                                              {{contract.patient_mobile? contract.patient_mobile_country_code + '-' +
                                              (contract.patient_mobile):''}}
                                          </h6>
                                          <h6 *ngIf="!contract.patient_mobile">NA</h6>
                                      </div>
                                      <div class="col-sm-5" *ngIf="contract.sms_subscription_status">
                                          <label class="fs-12">SMS status</label>
                                          <h6
                                              class="badge p-2 {{contract.sms_subscription_status == 'STOPPED' ? 'bg-danger' : contract.sms_subscription_status == 'RESUMED' ? 'bg-success' : ''}}">
                                              {{contract.sms_subscription_status}}
                                          </h6>
                                      </div>
                                  </div>
                              </div>
                              <div *ngIf="(_shared.routerAuth.restricted_buttons.show_contact_in_dnd && contract.dnd == 1)"
                                  class="col-sm-4">
                                  <label class="fs-12">Phone</label>
                                  <h6 class="fs-16 fw-400" *ngIf="contract.patient_mobile">
                                      {{contract.patient_mobile? contract.patient_mobile_country_code + '-'
                                      +(dnd_checks.dnd_patient_mobile):''}}</h6>
                                  <h6 *ngIf="!contract.patient_mobile">NA</h6>
                              </div>

                              <div class="col-sm-4">
                                  <label class="fs-12">Date of Birth</label>
                                  <h6 class="fs-16 fw-400" *ngIf="contract.date_of_birth">{{contract.date_of_birth |
                                      date:'MM/dd/yyyy':'+0000'}}</h6>
                                  <h6 *ngIf="!contract.date_of_birth">NA</h6>
                              </div>

                              <div class="col-sm-4">
                                  <label class="fs-12">Address</label>
                                  <h6 class="fs-16 fw-400"
                                      *ngIf="contract.patient_address || contract.city || contract.state || contract.zip">
                                      {{contract.patient_address + (contract.city ?', '+contract.city: '')+
                                      (contract.state?', '+contract.state:'') + (contract.zip?', '+contract.zip:'') +
                                      (contract.patient_country_code?', '+contract.patient_country_code:'')}}

                                  </h6>
                                  <h6 class="fs-16"
                                      *ngIf="!(contract.patient_address || contract.city || contract.state || contract.zip)">
                                      NA
                                  </h6>
                              </div>

                          </div>
                      </div>

                      <!--Business Owner Details-->
                      <div class="card card-body border mb-4">
                          <div class="row">
                              <div class="col-7">
                                  <label class="fs-12">Business Owner Details</label>
                                  <h3 class="fs-24 fw-600">
                                      {{contract.doctor_name}}
                                  </h3>
                              </div>
                              <div class="col-5 pl-0">
                                  <label class="fs-12">System Status:&nbsp;</label>
                                  <span [ngClass]="contract.is_doctor_active == 1 ? 'text-success' : 'text-danger'"
                                      class="fs-14 fw-600">
                                      Business is {{contract.is_doctor_active == 1 ? 'active':'inactive'}}
                                  </span>
                              </div>
                          </div>
                          <div class="row">
                              <div class="col-sm-6" *ngIf="contract.agent_id!=0">
                                  <label class="fs-12">Agent Name</label>
                                  <h6 class="fs-16 fw-400">{{contract.agent_name}}</h6>
                              </div>
                              <div class="col-sm-6" *ngIf="contract.agent_id!=0">
                                  <label class="fs-12">Agent Email</label>
                                  <h6 class="fs-16 fw-400">{{contract.agent_email}}</h6>
                              </div>
                              <div class="clearfix"></div>
                              <div class="col-sm-4">
                                  <label class="fs-12">Business</label>
                                  <h6 class="fs-16 fw-400">{{contract.practice_name||'Not Entered'}}</h6>
                              </div>
                              <div *ngIf="(!_shared.routerAuth.restricted_buttons.show_contact_in_dnd && contract.doctor_dnd == 1) || contract.doctor_dnd == 0"
                                  class="col-sm-4">
                                  <label class="fs-12">Email</label>
                                  <h6 *ngIf="contract.doctor_email"><a class="padd_r5 fs-14 fw-400"
                                          [routerLink]="['/app/business/details',contract.doctor_id]">{{contract.doctor_email}}</a>
                                  </h6>
                                  <h6 class="fs-16 fw-400" *ngIf="!contract.doctor_email">Not Entered</h6>
                              </div>
                              <div *ngIf="(_shared.routerAuth.restricted_buttons.show_contact_in_dnd && contract.doctor_dnd == 1)"
                                  class="col-sm-4">
                                  <label class="fs-12">Email</label>
                                  <h6 class="fs-16 fw-400" *ngIf="contract.doctor_email">
                                      {{dnd_checks.dnd_doctor_email}}</h6>
                                  <h6 class="fs-16 fw-400" *ngIf="!contract.doctor_email">Not Entered</h6>
                              </div>
                              <div *ngIf="(!_shared.routerAuth.restricted_buttons.show_contact_in_dnd && contract.doctor_dnd == 1) || contract.doctor_dnd == 0"
                                  class="col-sm-4">
                                  <label class="fs-12">Phone</label>
                                  <h6 class="fs-16 fw-400">{{contract.doctor_mobile?
                                      contract.doctor_mobile_country_code + '-' +
                                      (contract.doctor_mobile):'Not
                                      Entered'}}</h6>
                              </div>
                              <div *ngIf="(_shared.routerAuth.restricted_buttons.show_contact_in_dnd && contract.doctor_dnd == 1)"
                                  class="col-sm-4">
                                  <label class="fs-12">Phone</label>
                                  <h6 class="fs-16 fw-400">{{contract.doctor_mobile?
                                      contract.doctor_mobile_country_code + '-' +
                                      (dnd_checks.dnd_doctor_mobile):'Not Entered'}}
                                  </h6>
                              </div>

                          </div>
                      </div>

                      <!--Doctor Card Details-->
                      <div class="card card-body border mb-4" *ngIf="doctorCards.length">
                          <label>Doctor Card Details</label>
                          <div style="max-height:250px;overflow-y:auto;padding-right:10px;">
                              <div *ngFor="let card of doctorCards" class="col-12">
                                  <div
                                      *ngIf="doctorCards.length>0&&card.payment_source_type!=1&&(card.source_id==contract.doctor_source_id)">
                                      <div class="row border rounded p-2 mb-2 me-t"
                                          [ngClass]="{'bg-primary':card.source_id==contract.doctor_source_id}">
                                          <p class="col-9 mb-0"><i
                                                  class="fa fa-credit-card"></i>&nbsp;&nbsp;XXXX-XXXX-XXXX-{{card.last4}}
                                          </p>
                                          <div class="col-3 p-0 text-end">
                                              <p class="m-0"><i class="fa fa-check-circle"></i>&nbsp;&nbsp;Enrollment
                                                  Paid</p>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>

                      <!--Patients Card Details-->
                      <div class="card card-body border mb-4">
                          <div style="max-height:250px;overflow-y:auto;padding-right:10px;">
                              <h5 class="d-flex align-items-center justify-content-between">
                                  <span class="text-bold fs-16">Customer Payment Methods</span>
                                  <a href="javascript:void(0)"
                                      (click)="contract.new_secondary_source_id=contract.secondary_source_id; contract.new_source_id=contract.source_id; card_notes = ''; deduct_one_dollar = true; cardPreferenceModal();choose_backup=true;"
                                      class="pull-right fs-14" *ngIf="contract.pf_status<4 && allMethods.length>1">
                                      <i class="fa fa-pencil me-1"></i> Change Payment Method</a>

                                  <!-- <a href="javascript:void(0)"
                                      (click)="changePaymentMethod(contract)"
                                      class="pull-right" *ngIf="contract.pf_status<4 && allMethods.length>1">
                                      <i class="fa fa-pencil me-1"></i> Change Payment Method</a> -->
                              </h5>
                              <div *ngFor="let card of allMethods" class="col-12" style="cursor:pointer">
                                  <div *ngIf="allMethods.length>0">
                                      <div class="row border rounded m-0 p-2 mb-2 me-tv"
                                          [ngClass]="{'bg-primary':card.source_id==contract.source_id && !(card.last_error!='null'&&card.last_error!=null),'bg-danger':card.source_id==contract.source_id && card.last_error!='null' && card.last_error!=null}">
                                          <p class="col-md-12 col-lg-4 col-xs-12 mb-0"
                                              *ngIf="card.payment_source_type!=1"><i
                                                  class="fa fa-credit-card"></i>&nbsp;&nbsp;XXXX-XXXX-XXXX-{{card.last4}}
                                          </p>
                                          <p class="col-md-12 col-lg-4 col-xs-12 mb-0"
                                              *ngIf="card.payment_source_type==1"><i
                                                  class="fa fa-bank"></i>&nbsp;&nbsp;XXXX-XXXX-{{card.last4}} </p>

                                          <div class="col-md-12 col-lg-4 col-xs-12 p-0 text-center">
                                              <span class="m-0 pl-3"
                                                  *ngIf="card.exp_month<10 && (card.source_id==contract.source_id && card.last_error!='null' && card.last_error!=null) && card.payment_source_type!=1">
                                                  <i></i>0{{card.exp_month}}/{{card.exp_year}}
                                              </span>
                                              <span
                                                  [style.color]="  currentYear>card.exp_year || ( currentMonth>card.exp_month && currentYear===card.exp_year )?'red':''"
                                                  class="m-0 pl-3"
                                                  *ngIf="card.exp_month<10 && !(card.source_id==contract.source_id && card.last_error!='null' && card.last_error!=null) && card.payment_source_type!=1">
                                                  <i></i>0{{card.exp_month}}/{{card.exp_year}}
                                              </span>
                                              <span class="m-0 pl-3"
                                                  *ngIf="card.exp_month>=10 && (card.source_id==contract.source_id && card.last_error!='null' && card.last_error!=null && card.payment_source_type!=1)">
                                                  <i></i>{{card.exp_month}}/{{card.exp_year}}
                                              </span>
                                              <span
                                                  [style.color]="  currentYear>card.exp_year || ( currentMonth>card.exp_month && currentYear===card.exp_year )?'red':''"
                                                  class="m-0 pl-3"
                                                  *ngIf="card.exp_month>=10 && !(card.source_id==contract.source_id && card.last_error!='null' && card.last_error!=null) && card.payment_source_type!=1">
                                                  <i></i>{{card.exp_month}}/{{card.exp_year}}
                                              </span>
                                          </div>


                                          <div class="col-md-12 col-lg-4 col-xs-12 p-0 text-end">

                                              <span class="m-0" style="margin-right:10%"
                                                  *ngIf="card.source_id==contract.source_id">
                                                  <i class="fa fa-check-circle"></i>&nbsp;&nbsp;Default
                                              </span>
                                              <span class="m-0 me-2" style="color:#B45207"
                                                  *ngIf="card.source_id == contract.secondary_source_id && card.source_id != contract.source_id">Backup</span>
                                              <!-- <a (click)="deduct_one_dollar = true;defaultCardModal.show(); currentCard = card; make_default_notes = '';"
                        *ngIf="card.source_id && card.source_id!=contract.source_id && contract.pf_status<4"
                        style="text-decoration:underline" class="me-2">Make
                        Default</a> -->
                                              <span class="m-0" style="color:#36af47;"
                                                  *ngIf="card.status && card.status=='verified'">
                                                  <i class="fa fa-check-circle greenIcon"></i>&nbsp;&nbsp;Verified
                                              </span>
                                              <span class="m-0 text-danger "
                                                  *ngIf="card.status && card.status=='reauthenticate'">Reauthentication
                                                  Required</span>
                                              <span class="m-0 text-danger"
                                                  *ngIf="card.status && card.status=='verification_failed'">Verification
                                                  Failed</span>
                                              <i (click)="deleteCard(card.source_id)"
                                                  *ngIf="card.source_id && card.payment_source_type!=1 && card.source_id!=contract.source_id && card.source_id!=contract.secondary_source_id && !_shared.routerAuth.restricted_buttons.patient_card_delete"
                                                  style="cursor:pointer" class="fa fa-trash ms-2"></i>
                                          </div>
                                          <p class="col-12"
                                              [ngClass]="{'text-danger':card.source_id!=contract.source_id}"
                                              *ngIf="card.last_error!='null' && card.last_error!=null">
                                              {{card.last_error.split('=')[0] ||''}}</p>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <label *ngIf="allMethods && allMethods.length==0">No Payment Method Added Yet</label>

                          <a (click)="addCardModal()" *ngIf="contract.pf_status<4" class="text-end pt-2 pointer"
                              style="text-decoration:underline" tappable>Add Card</a>
                      </div>
                      <div class="card card-body border mb-4"
                          *ngIf="contract.settlement_amount != 0 && contract.dispute_payment_status == 0">
                          <!-- <label>Doctor Payment Details</label> -->
                          <h3>Contract Settlement Details</h3>
                          <div class="row" *ngIf="contract.settlement_treatment_amount">
                              <div class="col">
                                  <label>New Service Amount</label>
                                  <h6 class="text-bold">
                                      {{currencyData.display_logo}}{{contract.settlement_treatment_amount|number:'0.2-2'}}
                                  </h6>
                              </div>
                          </div>
                          <div class="row">
                              <div class="col">
                                  <label>Settlement Amount</label>
                                  <h6 class="text-bold">
                                      {{currencyData.display_logo}}{{contract.settlement_amount /
                                      ((100 - (contract.guarantee_percent +
                                      contract.collection_percent)) / 100) |number:'0.2-2'}}
                                  </h6>
                              </div>
                              <div class="col">
                                  <label>Total Amount to be paid to Business</label>
                                  <h6 class="text-bold">
                                      {{currencyData.display_logo}}{{contract.settlement_amount|number:'0.2-2'}}
                                  </h6>
                              </div>
                          </div>
                          <div class="row">
                              <div class="col">
                                  <label>Amount paid to Business till date</label>
                                  <h6 class="text-bold">
                                      {{currencyData.display_logo}}{{contract.settled_payment_to_doctor|number:'0.2-2'}}
                                      <!-- <span *ngIf="contract.missed_payment">(including ${{contract.missed_payment|number:'0.2-2'}} in unpaid payments)</span> -->
                                  </h6>
                              </div>
                              <div class="col">
                                  <label>Remaining Amount to be paid to Business</label>
                                  <h6 class="text-bold">
                                      {{currencyData.display_logo}}{{contract.settlement_amount -
                                      contract.settled_payment_to_doctor|number:'0.2-2'}}
                                      <span><i class="fa fa-info-circle" data-container="body" data-placement="right"
                                              tooltip="Remaining Principal Amount to be paid is {{currencyData.display_logo}}{{contract.max_claim_amount}}"></i></span>
                                  </h6>
                              </div>
                          </div>
                          <div class="row pl-3"
                              *ngIf="contract.settlement_amount - contract.settled_payment_to_doctor < 0">
                              <p class="text-warning">Note: The business is paid more than the settled amount</p>
                          </div>
                      </div>

                      <div class="card card-body border p-3 mb-4">
                          <h3 class="mt-0 fs-24">Protected Payments</h3>
                          <div class="row">
                              <div class="col-sm-6">
                                  <label class="fs-12">Number of missing Business Payments</label>
                                  <h6 class="fs-16 fw-400">{{gpInfo?gpInfo.missed_doctor_transfers : 0}}</h6>
                              </div>
                              <div class="col-sm-6">
                                  <div>
                                      <label class="fs-12">Remaining payments to be recovered from Customer</label>
                                      <h6 class="fs-16 fw-400">{{gpInfo?(gpInfo.transfers_paid_by_denefits -
                                          gpInfo.transfers_paid_to_denefits) : 0}}</h6>
                                  </div>
                              </div>
                              <div class="col-sm-6">
                                  <div>
                                      <label class="fs-12">Total number of payments paid to Business</label>
                                      <h6 class="fs-16 fw-400">{{gpInfo?gpInfo.transfers_paid_by_denefits : 0}}</h6>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div class="cardN">
                          <label class="fs-12">Customer's Signature</label>
                          <div class="col-sm-8 p-3 border text-center">
                              <img [src]="contract.patient_signature?contract.patient_signature:''"
                                  [alt]="contract.patient_first_name" class="img-fluid">
                          </div>
                      </div>
                      <div>
                          <div class="mt-2" *ngIf="contract.split_made==1">
                              <span class="fs-14">Contract made Remotely on {{contract.date_added | date:'MM-dd-yyyy'
                                  : timezone}}
                                  at
                                  {{contract.date_added | date:"hh:mm a" : timezone }} <span
                                      *ngIf="contract.show_ip">through IP:
                                      {{contract.ip_address}}</span></span>
                          </div>
                          <div class="mt-2" *ngIf="contract.split_made==0">
                              <span class="fs-14">Contract made in Office on {{contract.date_added | date:'MM-dd-yyyy'
                                  : timezone}}
                                  at
                                  {{contract.date_added | date:"hh:mm a" : timezone }} <span
                                      *ngIf="contract.show_ip">through IP:
                                      {{contract.ip_address}}</span></span>
                          </div>
                          <p class="m-0 fs-14" *ngIf="contract_created_from">Origin: {{contract_created_from}}</p>
                          <p class="m-0 fs-14">F: Flat Interest, C: Compound Interest</p>
                      </div>

                  </div>
                  <!--Amount Details-->
                  <div class="col-md-5 details">
                      <div class="card card-body border cal-popover mb-4">
                          <div class="row">
                              <div class="col-sm-6">
                                  <label class="fs-12">Service Amount</label>
                                  <h6 class="fs-16 fw-400" id="service_amount">
                                      {{currencyData.display_logo}}{{contract.treatment_amount}}
                                  </h6>
                              </div>
                              <div class="col-sm-6">
                                  <label class="fs-12">Financed Amount</label>
                                  <h6 class="fs-16 fw-400" id="financed_amount">{{contract.financed_amount | currency:
                                      currencyData.display_logo}}</h6>
                              </div>
                              <div class="col-sm-6" *ngIf="contract.contract_type == 5">
                                  <label class="fs-12">Partial Payment Amount</label>
                                  <h6 class="fs-16 fw-400" id="downpayment_amount">{{contract.downpayment_amount |
                                      currency:
                                      currencyData.display_logo}}</h6>
                              </div>
                              <div class="col-sm-6" *ngIf="contract.contract_type != 6 || !showCards">
                                  <label class="fs-12">Interest Rate</label>
                                  <h6 class="fs-16 fw-400"
                                      *ngIf="contract.interest_rate_to_show || contract.interest_rate" id="interest">
                                      {{contract.interest_rate_to_show || contract.interest_rate | number:'0.2-2'}}%
                                      <sup>{{!contract.interest_rate_to_show || (contract.interest_rate ==
                                          contract.interest_rate_to_show) ? (contract.interest_rate>0?'C':'') :
                                          'F'}}</sup>
                                  </h6>
                                  <h6 class="fs-16 fw-400" *ngIf="contract.interest_rate_to_show == 0" id="interest">
                                      No interest</h6>
                              </div>
                              <div class="col-sm-6" *ngIf="contract.contract_type != 6 || !showCards">
                                  <label class="fs-12">Recurring Amount</label>
                                  <h6 class="fs-16 fw-400">
                                      <span id="recurring_amount">{{currencyData.display_logo}}
                                          {{contract.recurring_amount}}</span>
                                      <span id="transaction_fees"
                                          *ngIf="_contract.GetTransactionFeeContractDetails(contract,contract.recurring_amount)">+
                                          {{_contract.GetTransactionFeeContractDetails(contract,contract.recurring_amount)}}</span>
                                  </h6>
                              </div>
                              <div class="col-sm-6">
                                  <label class="fs-12">Upfront Fee</label>
                                  <h6 class="fs-16 fw-400" id="upfront_fee">{{currencyData.display_logo}}{{contract &&
                                      contract.advance_interest_amount &&
                                      contract.advance_interest_amount.toFixed(2)}}</h6>
                              </div>
                              <div class="col-sm-6">
                                  <label class="fs-12">Fixed Credee Fee</label>
                                  <h6 class="fs-16 fw-400" id="enrollmentFee">
                                      {{currencyData.display_logo}}{{contract.enrollmentFee ?
                                      contract.enrollmentFee: '0'}}</h6>
                              </div>
                              <div class="col-sm-6">
                                  <label class="fs-12">Number of Payments</label>
                                  <h6 class="fs-16 fw-400" id="number_of_payments">{{contract.number_of_payments}}
                                  </h6>
                              </div>
                              <div class="col-sm-6">
                                  <label class="fs-12">Remaining Payments</label>
                                  <h6 class="fs-16 fw-400" id="remaining_payments">{{contract.remaining_payments}}
                                  </h6>
                              </div>
                              <div class="col-sm-6" *ngIf="contract.pf_status==3">
                                  <label class="fs-12">Number of missing payments</label>
                                  <h6 class="fs-16 fw-400" id="number_of_missing_payments">
                                      {{contract.number_of_missing_payments}}</h6>
                              </div>
                              <div class="col-sm-6" *ngIf="contract.pf_status==3">
                                  <label class="fs-12">Overdue Amount</label>
                                  <h6 class="fs-16 fw-400" id="overdue_amount">{{currencyData.display_logo}}
                                      {{contract.overdue_amount}}
                                      <span
                                          *ngIf="_contract.GetTransactionFeeContractDetails(contract,contract.recurring_amount)">+
                                          {{_contract.GetTransactionFeeContractDetails(contract,contract.recurring_amount)}}</span>
                                      <span> x{{contract.number_of_missing_payments}} Missing Payments </span>
                                  </h6>
                              </div>
                              <div class="col-sm-6" *ngIf="contract.missing_payments>=0">
                                  <label class="fs-12">Maximum Chargeable Late Fees</label>
                                  <h6 class="fs-16 fw-400" id="missing_payments">
                                      {{currencyData.display_logo}}{{contract.late_fee | number}} x
                                      {{contract.missing_payments}}</h6>
                              </div>
                              <div class="col-sm-6">
                                  <label class="fs-12">Enrollment Date</label>
                                  <h6 class="fs-16 fw-400">{{contract.date_added | date:"MM/dd/yyyy" : timezone }}
                                  </h6>
                              </div>
                              <div class="col-sm-6">
                                  <label class="fs-12">Next Payment Date</label>
                                  <!--PASSING DATE OFFSET IN UTC TO DISPLAY AS IT IS-->
                                  <h6 class="fs-16 fw-400" id="next_payment_date">{{contract.next_payment_date |
                                      date:"MM/dd/yyyy":
                                      '+0000'}}</h6>
                              </div>
                              <div class="col-sm-6">
                                  <label class="fs-12">Expected Monthly Payout to Business</label>
                                  <h6 class="fs-16 fw-400" id="expected_monthly_pay_to_doctor">
                                      {{contract.expected_monthly_pay_to_doctor||'0' | currency:
                                      currencyData.display_logo}}</h6>
                              </div>

                              <div class="col-sm-6"
                                  [ngClass]="{'change-hover-color': !_shared.routerAuth.restricted_buttons.contract_calculation_stats, 'change-select-color': (popoverColorChange == 'expectedPayoutCal')}"
                                  (onShown)="calculationPopShow('expectedPayoutCal')"
                                  (onHidden)="calculationPopHide('expectedPayoutCal')"
                                  [popover]="!_shared.routerAuth.restricted_buttons.contract_calculation_stats ? expectedBusinessCalculation : undefined"
                                  [outsideClick]="true" triggers="click"
                                  popoverTitle="Total Expected Payout to Business" placement="left">
                                  <label class="fs-12">Total Expected Payout to Business</label>
                                  <h6 class="fs-16 fw-400" id="doctor_expected_payout">
                                      {{contract.doctor_expected_payout||'0' | currency:
                                      currencyData.display_logo}}</h6>
                                  <ng-template #expectedBusinessCalculation>
                                      <!-- <app-calculation-details [content]="contract.calculation_object.pay_to_doctor">
                                      </app-calculation-details> -->
                                  </ng-template>
                              </div>

                              <div class="col-sm-6" *ngIf="contract.number_of_missing_payments>0">
                                  <label class="fs-12">Late Fees </label>
                                  <h6 class="fs-16 fw-400" id="late_fee">{{contract.late_fee||'0' | currency:
                                      currencyData.display_logo}}</h6>
                              </div>

                              <div class="col-sm-6"
                                  [ngClass]="{'change-hover-color': !_shared.routerAuth.restricted_buttons.contract_calculation_stats, 'change-select-color': (popoverColorChange == 'paidToBusinessCal')}"
                                  (onShown)="calculationPopShow('paidToBusinessCal')"
                                  (onHidden)="calculationPopHide('paidToBusinessCal')"
                                  [popover]="!_shared.routerAuth.restricted_buttons.contract_calculation_stats ? paidToBusinessCalculation : undefined"
                                  [outsideClick]="true" triggers="click" popoverTitle="Paid to Business - Till Date"
                                  placement="left">
                                  <label class="fs-12">Paid to Business - Till Date</label>
                                  <h6 class="fs-16 fw-400" id="paid_to_doctor">{{contract.paid_to_doctor||'0' |
                                      currency:
                                      currencyData.display_logo}}</h6>
                                  <ng-template #paidToBusinessCalculation>
                                      <!-- <app-calculation-details [content]="contract.calculation_object.paid_to_doctor">
                                      </app-calculation-details> -->
                                  </ng-template>
                              </div>

                              <div class="col-sm-6" *ngIf="contract.contract_type != 6  || !showCards"
                                  [ngClass]="{'change-hover-color': !_shared.routerAuth.restricted_buttons.contract_calculation_stats, 'change-select-color': (popoverColorChange == 'totalRemAmountCal')}"
                                  (onShown)="calculationPopShow('totalRemAmountCal')"
                                  (onHidden)="calculationPopHide('totalRemAmountCal')"
                                  [popover]="!_shared.routerAuth.restricted_buttons.contract_calculation_stats ? totalRemainingCalculation : undefined"
                                  [outsideClick]="true" trigger="click" popoverTitle="Total Remaining Amount"
                                  placement="left">
                                  <label class="fs-12">Total Remaining Amount</label>
                                  <h6 class="fs-16 fw-400" id="remaining_amount">{{currencyData.display_logo}}
                                      {{contract.remaining_amount||'0'}}
                                  </h6>
                                  <ng-template #totalRemainingCalculation>
                                      <!-- <app-calculation-details
                                          [content]="contract.calculation_object.remaining_amount">
                                      </app-calculation-details> -->
                                  </ng-template>
                              </div>

                              <div class="col-sm-6">
                                  <label class="fs-12">Donated Amount</label>
                                  <h6 class="fs-16 fw-400" id="donated_amount">{{currencyData.display_logo}}
                                      {{contract.donated_amount||'0'}}</h6>
                              </div>

                              <div class="col-sm-6"
                                  [ngClass]="{'change-hover-color': !_shared.routerAuth.restricted_buttons.contract_calculation_stats, 'change-select-color': (popoverColorChange == 'customerPayoffCal')}"
                                  (onShown)="calculationPopShow('customerPayoffCal')"
                                  (onHidden)="calculationPopHide('customerPayoffCal')"
                                  [popover]="!_shared.routerAuth.restricted_buttons.contract_calculation_stats ? cusomerPayoffCalculation : undefined"
                                  [outsideClick]="true" triggers="click" popoverTitle="Customer Payoff Amount"
                                  placement="left">
                                  <label class="fs-12">Customer Payoff Amount</label>
                                  <h6 class="fs-16 fw-400" id="patient_payoff_amount"> {{currencyData.display_logo}}
                                      {{contract.patient_payoff_amount||'0'}}</h6>
                                  <ng-template #cusomerPayoffCalculation>
                                      <!-- <app-calculation-details
                                          [content]="contract.calculation_object.payoff_patient_amount">
                                      </app-calculation-details> -->
                                  </ng-template>
                              </div>

                              <div class="col-sm-6"
                                  [ngClass]="{'change-hover-color': !_shared.routerAuth.restricted_buttons.contract_calculation_stats, 'change-select-color': (popoverColorChange == 'businessExpectedPayoffCal')}"
                                  (onShown)="calculationPopShow('businessExpectedPayoffCal')"
                                  (onHidden)="calculationPopHide('businessExpectedPayoffCal')"
                                  [popover]="!_shared.routerAuth.restricted_buttons.contract_calculation_stats ? businessPayoffCalculation : undefined"
                                  [outsideClick]="true" triggers="click" popoverTitle="Business Expected Payoff"
                                  placement="left">
                                  <label class="fs-12">Business Expected Payoff</label>
                                  <h6 class="fs-16 fw-400" id="doctor_expected_payoff"> {{currencyData.display_logo}}
                                      {{contract.doctor_expected_payoff||'0'}}</h6>
                                  <ng-template #businessPayoffCalculation>
                                      <!-- <app-calculation-details
                                          [content]="contract.calculation_object.payoff_doctor_expected">
                                      </app-calculation-details> -->
                                  </ng-template>
                              </div>

                              <div class="col-sm-6">
                                  <label class="fs-12">Principal Amount</label>
                                  <h6 class="fs-16 fw-400" id="recurring_without_interest">
                                      {{currencyData.display_logo}}
                                      {{contract.recurring_without_interest||'0'}}</h6>
                              </div>
                              <div class="col-sm-6">
                                  <label class="fs-12">Contract Edited Count</label>
                                  <h6 class="fs-16 fw-400" id="edit_contract_count">{{contract.edit_contract_count}}
                                  </h6>
                              </div>
                              <div class="col-sm-6" *ngIf="contract.contract_type == 6 && contract.pf_status != 4">
                                  <label class="fs-12">Remaining Deferred Duration</label>
                                  <h6 class="fs-16 fw-400" id="di_remaining_duration">
                                      {{contract.di_remaining_duration}}</h6>
                              </div>

                              <div class="col-sm-12 pt-2"
                                  *ngIf="contract.contract_type == 6 && contract.pf_status == 3 && contract.missing_payments == 0 && contract.is_contract_deferred == 1">
                                  <h4 style="color: #e14a13 !important;">“Waiting for Payment”</h4>
                              </div>

                              <div class="w-100 px-3 py-2" *ngIf="contract.contract_type == 6">
                                  <div class="row justify-content-between flex-wrap">
                                      <div class="card flex-grow-1 col-6">
                                          <div class="card-body" style="background: #F5F5F5;">
                                              <span *ngIf="contract.is_contract_deferred && contract.pf_status < 4"
                                                  class="active-def">Active</span>
                                              <span *ngIf="!contract.is_contract_deferred && contract.pf_status < 4"
                                                  class="inactive-def">Inactive</span>
                                              <p class="mb-3 mt-2"
                                                  style="font-style: italic;font-weight: 500;font-size: 14px; line-height: 17px;color: #2D3748;">
                                                  During Deferred Duration:
                                              </p>
                                              <p class="mb-2"
                                                  style="font-weight: 400; font-size: 14px;line-height: 17px;color: #677489">
                                                  Recurring Amount</p>
                                              <p class="mb-3"
                                                  style="font-weight: 700; font-size: 18px; line-height: 22px;align-items: center; color: #1A202C;">
                                                  <span id="before_deferred_recurring_amount">
                                                      {{currencyData.display_logo}}
                                                      {{(contract.is_contract_deferred == 1 ?
                                                      contract.recurring_amount :
                                                      contract.principal_per_recurring)}} </span>+
                                                  <span
                                                      id="before_deferred_transaction_fee">{{_contract.GetTransactionFeeContractDetails(contract,(contract.is_contract_deferred
                                                      == 1 ?
                                                      contract.recurring_amount : contract.principal_per_recurring
                                                      ))}}</span>
                                              </p>
                                              <p class="mb-1"
                                                  style="font-weight: 400; font-size: 14px;line-height: 17px;color: #677489">
                                                  Interest Rate</p>
                                              <p style="font-weight: 700; font-size: 18px; line-height: 22px;align-items: center; color: #1A202C;"
                                                  id="no_interest_rate_deferred">
                                                  No Interest
                                              </p>
                                              <p *ngIf="contract.pf_status != 4" class="mb-1"
                                                  style="font-weight: 400; font-size: 14px;line-height: 17px;color: #677489">
                                                  Total Remaining Amount</p>
                                              <p *ngIf="contract.pf_status != 4"
                                                  style="font-weight: 700; font-size: 18px; line-height: 22px;align-items: center; color: #1A202C;"
                                                  id="before_deferred_total_remaining_amount">
                                                  {{currencyData.display_logo}}
                                                  {{(((contract.recurring_amount == contract.next_recurring_amount) ?
                                                  contract.principal_per_recurring : contract.recurring_amount) *
                                                  contract.remaining_payments).toFixed(2) }}
                                              </p>
                                              <p class="mb-1"
                                                  style="font-weight: 400; font-size: 14px;line-height: 17px;color: #677489"
                                                  id="total_balance_remaining_late_fee">
                                                  Total Balance Remaining (Including Transaction & Late Fees, if any)
                                              </p>
                                              <h6 *ngIf="contract.pf_status != 4"><span
                                                      *ngIf="contract.missing_payments">1.</span>{{currencyData.display_logo}}
                                                  ({{(contract.recurring_amount == contract.next_recurring_amount ?
                                                  contract.principal_per_recurring : contract.recurring_amount )}}
                                                  <span id="transaction_fees"
                                                      *ngIf="_contract.GetTransactionFeeContractDetails(contract,contract.recurring_amount)"
                                                      class="font-size-16">+
                                                      {{_contract.GetTransactionFeeContractDetails(contract,contract.recurring_amount)}}</span>
                                                  <span> ) x {{contract.remaining_payments}} Remaining Payments</span>
                                              </h6>
                                              <h6 *ngIf="contract.missing_payments && contract.pf_status != 4">2.
                                                  {{currencyData.display_logo}} ({{contract.late_fee |
                                                  number}} Late Fees)
                                                  x {{contract.missing_payments}} Missing Payments
                                              </h6>
                                              <p style="font-weight: 700; font-size: 18px; line-height: 22px;align-items: center; color: #1A202C;"
                                                  id="before_total_remaining_amount">
                                                  {{currencyData.display_logo}} {{
                                                  ((contract.patient_payoff_amount))}}
                                              </p>
                                          </div>
                                      </div>
                                      <div class="card flex-grow-1 col-6">
                                          <div class="card-body" style="background: #F5F5F5;">
                                              <span *ngIf="!contract.is_contract_deferred && contract.pf_status != 4"
                                                  class="active-def">Active</span>
                                              <span *ngIf="contract.is_contract_deferred && contract.pf_status != 4"
                                                  class="inactive-def">Inactive</span>
                                              <p class="mb-3 mt-2"
                                                  style="font-style: italic;font-weight: 500;font-size: 14px; line-height: 17px;color: #2D3748;">
                                                  After Deferred Duration:
                                              </p>
                                              <p class="mb-2"
                                                  style="font-weight: 400; font-size: 14px;line-height: 17px;color: #677489">
                                                  Recurring Amount</p>
                                              <p class="mb-3"
                                                  style="font-weight: 700; font-size: 18px; line-height: 22px;align-items: center; color: #1A202C;"
                                                  id="after_deferred_recurring_amount">
                                                  {{currencyData.display_logo}}
                                                  {{ contract.next_recurring_amount }}
                                                  + <span
                                                      id="after_deferred_transaction_fee">{{_contract.GetTransactionFeeContractDetails(contract,
                                                      contract.next_recurring_amount)}}</span>
                                              </p>
                                              <p class="mb-1"
                                                  style="font-weight: 400; font-size: 14px;line-height: 17px;color: #677489">
                                                  Interest Rate</p>
                                              <p style="font-weight: 700; font-size: 18px; line-height: 22px;align-items: center; color: #1A202C;"
                                                  id="after_deferred_interest_rate">
                                                  {{contract.interest_rate_to_show}}%
                                                  <sup>{{!contract.interest_rate_to_show || (contract.interest_rate ==
                                                      contract.interest_rate_to_show) ?
                                                      (contract.interest_rate>0?'C':'') : 'F'}}</sup>
                                              </p>
                                              <p *ngIf="contract.pf_status != 4" class="mb-1"
                                                  style="font-weight: 400; font-size: 14px;line-height: 17px;color: #677489">
                                                  Total Remaining Amount</p>
                                              <p *ngIf="contract.pf_status != 4"
                                                  style="font-weight: 700; font-size: 18px; line-height: 22px;align-items: center; color: #1A202C;"
                                                  id="after_deferred_total_remaining_amount">
                                                  {{currencyData.display_logo}}
                                                  {{contract.remaining_amount}}
                                              </p>
                                              <p class="mb-1"
                                                  style="font-weight: 400; font-size: 14px;line-height: 17px;color: #677489">
                                                  Total Balance Remaining (Including Transaction & Late Fees, if any)
                                              </p>
                                              <h6
                                                  *ngIf="contract.def_duration > 0 && contract.pf_status <= 3 && contract.is_contract_deferred == 1 && (contract.number_of_payments > contract.di_start_month)">
                                                  <span
                                                      *ngIf="contract.missing_payments">1.</span>({{currencyData.display_logo}}
                                                  {{contract.is_contract_deferred == 1 ? contract.recurring_amount :
                                                  contract.principal_per_recurring }}
                                                  <span
                                                      *ngIf="_contract.GetTransactionFeeContractDetails(contract,contract.recurring_amount)"
                                                      class="font-size-16">+
                                                      {{_contract.GetTransactionFeeContractDetails(contract,contract.is_contract_deferred
                                                      == 1
                                                      ? contract.recurring_amount :
                                                      contract.principal_per_recurring)}}</span>
                                                  <span> ) x {{contract.def_duration}} Remaining Payments</span>
                                              </h6>
                                              <h6 id="after_deferred_total_balance_remaining"
                                                  *ngIf="contract.pf_status != 4"><span
                                                      *ngIf="contract.missing_payments">1.</span>({{currencyData.display_logo}}
                                                  {{contract.next_recurring_amount}}
                                                  <span
                                                      *ngIf="_contract.GetTransactionFeeContractDetails(contract,contract.recurring_amount)"
                                                      class="font-size-16">+
                                                      {{_contract.GetTransactionFeeContractDetails(contract,
                                                      contract.next_recurring_amount)}}</span>
                                                  <span *ngIf=" contract.def_duration != 0"> ) x
                                                      {{(contract.number_of_payments > contract.di_start_month) ?
                                                      contract.remaining_payments - contract.def_duration:
                                                      contract.def_duration}}
                                                      Remaining Payments</span>
                                                  <span *ngIf=" contract.def_duration == 0"> ) x
                                                      {{contract.remaining_payments}} Remaining Payments</span>
                                              </h6>
                                              <h6 *ngIf="contract.missing_payments && contract.pf_status != 4">2.
                                                  (${{contract.late_fee | number}} Late Fees)
                                                  x {{contract.missing_payments}} Missing Payments
                                              </h6>
                                              <p style="font-weight: 700; font-size: 18px; line-height: 22px;align-items: center; color: #1A202C;"
                                                  id="after_total_remaining_amount">
                                                  {{currencyData.display_logo}}
                                                  {{(contract.total_remaining_amount || '0' )}}
                                              </p>
                                          </div>
                                      </div>
                                  </div>
                                  <div class="row justify-content-between flex-wrap text-end"
                                      *ngIf="contract.contract_type == 6 && contract.is_contract_deferred == 1 && contract.number_of_payments > contract.di_start_month">
                                      <button class="list-group-item list-group-item-action fs-14"
                                          (click)="afterDeferredDetailsModal.show()">
                                          After Deferred Info (if payment missed)</button>
                                  </div>
                              </div>

                              <div class="col-sm-12"
                                  *ngIf="contract.pf_status<4 && contract.contract_type != 6 || !showCards">
                                  <label class="fs-12">Total Balance Remaining (Including Transaction & Late Fees, if
                                      any)</label>
                                  <h6 class="fs-16 fw-400"><span *ngIf="contract.missing_payments"
                                          id="recurring_amount">1.
                                      </span>({{currencyData.display_logo}}{{contract.recurring_amount}}
                                      <span
                                          *ngIf="_contract.GetTransactionFeeContractDetails(contract,contract.recurring_amount)"
                                          class="font-size-16" id="recurring_amount1">+
                                          {{_contract.GetTransactionFeeContractDetails(contract,contract.recurring_amount)}}</span>
                                      <span> ) x {{contract.remaining_payments}} Remaining Payments</span>
                                  </h6>
                                  <h6 class="fs-16 fw-400" *ngIf="contract.missing_payments">2.
                                      ({{currencyData.display_logo}}{{contract.late_fee | number}}
                                      Late Fees)
                                      x {{contract.missing_payments}} Missing Payments</h6>

                                  <h6 class="fs-16 fw-400" id="total_balance_remaining">Total:
                                      {{currencyData.display_logo}}
                                      {{total_balance_remaining}}
                                  </h6>
                              </div>
                          </div>
                      </div>
                      <div class="card card-body border mb-4">
                          <div class="row">
                              <h4 class="col-lg-12 fs-18">Contract Service Cost Breakdown
                                  <i class="fa fa-info-circle" data-placement="right"
                                      tooltip="Services provided by the business, costs of services, and dates of service completion."></i>
                                  <a class="pull-right ms-2 f-s-18"
                                      (click)="changeContractServiceBreakdownDetailsModal.show()"
                                      *ngIf="!_shared.routerAuth.restricted_buttons.edit_contract_service_breakdown">
                                      <i tooltip="Edit Contract Service Breakdown" data-container="body"
                                          class="fa fa-pencil text-black"></i>
                                  </a>
                              </h4>
                              <div class="col-lg-12 fs-14"
                                  *ngIf="contract.service_record && contract.service_record.length == 0">
                                  Not added
                              </div>
                              <div style="max-height: 350px;overflow-y: auto;">
                                  <table class="table table-responsive"
                                      *ngIf="contract.service_record && contract.service_record.length > 0">
                                      <thead>
                                          <tr>
                                              <th>S No.</th>
                                              <th>Service</th>
                                              <th>Price</th>
                                          </tr>
                                      </thead>
                                      <tbody>
                                          <tr *ngFor="let data of contract.service_record; let i=index;">
                                              <td>{{i+1}}</td>
                                              <td>{{data.service}}</td>
                                              <td>{{data.price | currency:
                                                  currencyData.display_logo}}</td>
                                          </tr>
                                      </tbody>
                                  </table>
                              </div>
                          </div>
                      </div>
                      <div class="card card-body border mb-4"
                          *ngIf="contract && contract.claimInfo && contract.claimInfo[0] && contract.claimInfo[0].service_added == 1">
                          <div class="row">
                              <h4 class="col-lg-12">Service Completed: {{contract.claimInfo[0].treatment_completed ==
                                  0 ? 'No' : 'Yes'}}</h4>
                          </div>
                          <div class="row">
                              <h4 class="col-lg-12">Total Service Cost:
                                  {{currencyData.display_logo}}{{contract.claimInfo[0].service_breakdown_amount
                                  | number:'0.2-2' }}</h4>
                          </div>
                          <div class="row">
                              <h4 class="col-lg-12">Claim Service Cost Breakdown
                                  <i class="fa fa-info-circle" data-placement="right"
                                      tooltip="Services provided by the business, costs of services, and dates of service completion."></i>
                                  <a *ngIf="!_shared.routerAuth.restricted_buttons.edit_claim_service_breakdown && contract.claimInfo && contract.claimInfo[0] && contract.claimInfo[0].service_breakdown && contract.claimInfo[0].service_breakdown.length > 0"
                                      class="pull-right ms-2 f-s-18"
                                      (click)="changeClaimServiceBreakdownDetailsModal.show()">
                                      <i tooltip="Edit Claim Service Breakdown" data-container="body"
                                          class="fa fa-pencil"></i>
                                  </a>
                              </h4>
                              <div class="col-lg-12"
                                  *ngIf="!(contract.claimInfo && contract.claimInfo[0] && contract.claimInfo[0].service_breakdown && contract.claimInfo[0].service_breakdown.length > 0)"> Not added
                              </div>
                              <div style="max-height: 350px;overflow-y: auto;">
                                  <table class="table"
                                      *ngIf="contract.claimInfo && contract.claimInfo[0] && contract.claimInfo[0].service_breakdown && contract.claimInfo[0].service_breakdown.length > 0">
                                      <thead>
                                          <tr>
                                              <th>S No.</th>
                                              <th>Service Completion Date</th>
                                              <th>Service</th>
                                              <th>Price</th>
                                          </tr>
                                      </thead>
                                      <tbody>
                                          <tr
                                              *ngFor="let data of contract.claimInfo[0].service_breakdown; let i=index;">
                                              <td>{{i+1}}</td>
                                              <td>{{data.service_date}}</td>
                                              <td>{{data.service}}</td>
                                              <td>{{data.price | currency:
                                                  currencyData.display_logo}}</td>
                                          </tr>
                                      </tbody>
                                  </table>
                              </div>
                          </div>
                      </div>
                      <div class="card card-body border p-2 mb-4">
                          <!-- <div *ngIf="notesList && notesList.length>0">
              <div class="col-12 mb-1 border" *ngFor="let note of notesList; let i=index">
                <label>{{note.name}} - [{{note.added_on | date:'medium':timezone}} ({{timezone}})]</label>
                <p [innerHTML]="note.notes" ></p>
              </div>
            </div> -->
                          <button class="btn btn-secondary border bg-white rounded-pill text-black"
                              (click)="addNotesModal.show()">Add Legacy Notes</button>
                      </div>
                      <!--Customers DL Details-->
                      <!--  <div class="card card-body border">
                          <div class="col-md-12 col-sm-12 col-xs-12 pl-0">
                              <label style="font-size:16px;"><strong>Proof of ID</strong></label>
                              <div class="col-12 col-md-12 col-lg-12 ">
                                  <div class="row pt-2 pb-2" style="background-color: #F9F9F9;"
                                      style="align-items: center; display: flex;">
                                      <div class="col-8 col-md-10 ">
                                          <span style="font-size: 13px;color: #313131;"
                                              *ngIf="!contract.dl_doc || contract.dl_doc==null">Please upload a clear
                                              copy of your Driver’s License or another government-issued proof of
                                              ID.</span>
                                          <span style="font-size: 13px"
                                              *ngIf="contract.dl_doc && contract.dl_doc!=null"><a
                                                  href="{{contract.dl_doc}}" target="_blank"
                                                  style="text-decoration: none;" class="me-2"><i
                                                      class="fa fa-file-text-o me-2"
                                                      style="font-size:20px;cursor: pointer;"></i>Proof of ID
                                              </a></span>
                                      </div>
                                      <div class="col-4 col-md-2 col-lg-2 text-end">
                                          <div *ngIf="!contract.dl_doc || contract.dl_doc==null">
                                              <label class="text-primary text-center" for="fileInputNew"
                                                  style="cursor:pointer;"> <img
                                                      src="./assets/img/SVG/upload-icon.svg">
                                              </label>
                                              <input class="d-none upload " name="profilePic" id="fileInputNew"
                                                  accept="image/*,.pdf" #fileUpload type="file"
                                                  [(ngModel)]="imageUploaderDL" name="imgUploader"
                                                  (change)="fileChangeEventDL($event)" (click)="onFileInputClick()">
                                          </div>
                                          <div *ngIf="contract.dl_doc && contract.dl_doc!=null">
                                              <span class="m-l-15">
                                                  <i class="fa fa-trash"
                                                      style="color: #f05050;font-size:22px;cursor: pointer;"
                                                      (click)="deleteUploadedDoc(contract.dl_id)"></i>
                                              </span>
                                          </div>

                                      </div>
                                  </div>

                              </div>
                          </div>
                      </div>  -->

                  </div>
              </div>
          </div>
      </div>

      <div class="col-xl-3 col-xxl-2">
          <div class="card bg-transparent mb-0 open-ticket-box mb-4">
              <div class="card" style="min-height:88px;">
                  <div class="p-3 bg-primary card-body d-flex rounded justify-content-between">
                      <div>
                          <div class="fs-24 fw-600">{{ticketCount}}</div>
                          <p class="ticket-text fs-16 fw-500 m-0">Open Ticket{{ticketCount > 1 ? 's' : ''}}</p>
                      </div>
                      <div class="btn-group" dropdown placement="bottom right">
                          <span id="button-animated" tooltip="View More" dropdownToggle tappable
                              class="float-end  arrow-icon" [ngClass]="{'active':dpOpen}">
                              <i class="fa fa-angle-down"></i>
                          </span>
                          <ul id="dropdown-animated" *dropdownMenu class="dropdown-menu dropdown-menu-right"
                              role="menu" aria-labelledby="button-animated">
                              <li role="menuitem">
                                  <a class="dropdown-item" [routerLink]="'/app/assignments/'"
                                      [queryParams]="{type:'4',page:'1',sD:startDate,eD:endDate,offset:0,filters:'0',search_string:contract.contract_code}"
                                      [class.disabled]="!contract.pending_requests">Pending:
                                      {{contract.pending_requests || 0}}</a>
                              </li>
                              <li>
                                  <a class="dropdown-item" [routerLink]="'/app/assignments/'"
                                      [queryParams]="{type:'4',page:'1',sD:startDate,eD:endDate,offset:0,filters:'3',search_string:contract.contract_code}"
                                      [class.disabled]="!contract.followup_requests">Follow-up:
                                      {{contract.followup_requests || 0}}</a>
                              </li>
                              <li role="menuitem">
                                  <a class="dropdown-item" [routerLink]="'/app/assignments/'"
                                      [queryParams]="{type:'4',page:'1',sD:startDate,eD:endDate,offset:0,filters:'2',search_string:contract.contract_code}"
                                      [class.disabled]="!contract.reopen_requests">Re-open: {{contract.reopen_requests
                                      || 0}}</a>
                              </li>
                              <li role="menuitem">
                                  <a class="dropdown-item" [routerLink]="'/app/assignments/'"
                                      [queryParams]="{type:'4',page:'1',sD:startDate,eD:endDate,offset:0,filters:'1',search_string:contract.contract_code}"
                                      [class.disabled]="!contract.closed_requests">Closed: {{contract.closed_requests
                                      || 0}}</a>
                              </li>
                          </ul>
                      </div>
                  </div>
              </div>
          </div>
          <div class="list-group">
              <h4 class="list-group-item text-center m-0 fs-18">Actions</h4>
              <button class="list-group-item list-group-item-action fs-14 btn text-primary rounded-0"
                  (click)="goBack()">
                  <i class="fa fa-angle-left"></i> Back
              </button>
              <button class="list-group-item list-group-item-action fs-14"
                  (click)="bankruptcyM2rReport(_contract.modalTypes.bankruptcyModal)">
                  Bankruptcy Filed
              </button>
              <button class="list-group-item list-group-item-action fs-14"
                  (click)="bankruptcyM2rReport(_contract.modalTypes.m2rReportModal)"
                  *ngIf="!_shared.routerAuth.restricted_buttons.changeM2Rstatus">Report to credit bureau</button>
              <button
                  *ngIf="contract.is_activated == 0 || contract.is_activated == 2 && !_shared.routerAuth.restricted_buttons.activate_contract"
                  class="list-group-item list-group-item-action fs-14" (click)="activateModal()">
                  Activate Contract
              </button>

              <div class="bankrupty" *ngIf="contract.bankrupt_stop_payment != 1 ">
                  <button *ngIf="contract.pf_status<4 && contract.missing_payments > 0"
                      class="list-group-item list-group-item-action fs-14" (click)="chargeLateFeeModalOpen()"
                      [disabled]="action_json && action_json.charge_late_fee == 1">
                      Charge Late Fee</button>
                  <button *ngIf="contract.pf_status < 4" class="list-group-item list-group-item-action fs-14"
                      (click)="send_customer_notification = false; editRecurringModalOpen()"
                      [disabled]="action_json && action_json.edit_recurring_date == 1">
                      Edit Recurring Date </button>
                  <button
                      *ngIf="contract.pf_status<4 && contract.is_contract_deferred == 0"
                      [disabled]="contract.recurring_amount==0 || action_json && action_json.charge_partial_payment == 1"
                      class="list-group-item list-group-item-action fs-14"
                      (click)="contract.recurring_amount>0 && payMorePartialOff(_contract.modalTypes.partialPaymentModal)">
                      Charge Partial Payment</button>
                  <button
                      *ngIf="contract.pf_status<3 && contract.is_contract_deferred == 1 && contract.contract_type == 6"
                      [disabled]="contract.recurring_amount==0 || action_json && action_json.charge_partial_payment == 1"
                      class="list-group-item list-group-item-action fs-14"
                      (click)="contract.recurring_amount>0 && payMorePartialOff(_contract.modalTypes.payMoreModal)">
                      Charge Pay More</button>
                  <button *ngIf="contract.pf_status<4 && contract.recurring_amount>0"
                      class="list-group-item list-group-item-action fs-14"
                      (click)="payMorePartialOff(_contract.modalTypes.payOffModal)"
                      [disabled]="action_json && action_json.charge_payoff == 1">
                      Charge Payoff</button>
              </div>
              <button *ngIf="contract.pf_status<4" class="list-group-item list-group-item-action fs-14"
                  [routerLink]="'/app/contracts/edit-personal-info/'+contract.contract_id">
                  Edit Personal Info</button>
              <button
                  *ngIf="contract.pf_status<4 && contract.contract_type != 6 && !_shared.routerAuth.restricted_buttons.edit_contract"
                  class="list-group-item list-group-item-action fs-14"
                  [disabled]="contract.settlement_amount || contract.gp_recovered == 0 || (action_json && action_json.edit_contract == 1)"
                  (click)="OpenEditContractPopup()">
                  Edit Financial Details</button>
              <button
                  *ngIf="contract.pf_status<4 && contract.contract_type == 6 && contract.is_contract_deferred != 1 && !_shared.routerAuth.restricted_buttons.edit_contract"
                  class="list-group-item list-group-item-action fs-14"
                  [disabled]="contract.settlement_amount || contract.gp_recovered == 0 || (action_json && action_json.edit_contract == 1)"
                  (click)="OpenEditContractPopup()">
                  Edit Financial Details</button>
              <button
                  *ngIf="contract.pf_status<4 && contract.contract_type != 6 && !_shared.routerAuth.restricted_buttons.edit_contract_values"
                  class="list-group-item list-group-item-action fs-14" (click)="contractUpdateModal()">
                  Edit Contract Financial Values</button>
              <button
                  *ngIf="contract.pf_status<4 && contract.contract_type == 6 && contract.is_contract_deferred != 1 && !_shared.routerAuth.restricted_buttons.edit_contract_values"
                  class="list-group-item list-group-item-action fs-14" (click)="contractUpdateModal()">
                  Edit Contract Financial Values</button>
              <button *ngIf="contract.pf_status<4" class="list-group-item list-group-item-action fs-14"
                  (click)="cancelContractModal()"
                  [disabled]="contract.transfers_paid_by_denefits > 0 || (action_json && action_json.cancel_contract == 1)">
                  Cancel Contract</button>
              <button *ngIf="contract.pf_status<4  && contract.contract_type!=3 && contract.ind_is_guaranteed == 1"
                  class="list-group-item list-group-item-action fs-14" (click)="makeProtectedNonProtected()"
                  [hidden]="(admin_profile && admin_profile.make_guaranteed==0) || contract.bankrupt_stop_payment==1"
                  [disabled]="action_json && action_json.guaranteed == 1">
                  <span *ngIf="contract.is_guaranteed == 2 || contract.is_guaranteed == 4">Make Protected</span>
                  <span *ngIf="contract.is_guaranteed == 1 || contract.is_guaranteed == 0">Make
                      Non Protected
                  </span>
              </button>
              <div class="bankrupty" *ngIf="contract.bankrupt_stop_payment != 1 ">
                  <button class="list-group-item list-group-item-action fs-14"
                      *ngIf="contract.recurring_amount!=0 && contract.pf_status<3 && contract.showMakePayBtn == 1"
                      (click)="nextRecAmountModal()"
                      [disabled]="action_json && action_json.pay_next_recurring == 1">Pay Next Recurring
                      Amount</button>
                  <button class="list-group-item list-group-item-action fs-14" *ngIf="contract.pf_status == 3"
                      (click)="nextRecAmountModal()"
                      [disabled]="action_json && action_json.pay_next_recurring == 1 ">Pay Now
                  </button>
                  <button *ngIf="contract.pf_status<4" class="list-group-item list-group-item-action fs-14"
                      title="Send Email" (click)="generateLink()"
                      [disabled]="action_json && action_json.add_card_link == 1 ">
                      Generate Add Card Link
                  </button>
                  <button *ngIf="contract.pf_status<4" class="list-group-item list-group-item-action fs-14"
                      title="Contribute" (click)="contributionLink()"
                      [disabled]="action_json && action_json.contribution_link == 1">
                      Generate Contribution Link
                  </button>
                  <button class="list-group-item list-group-item-action fs-14" title="Charge Contract"
                      (click)="chargeContractModalOpen()" *ngIf="contract.pf_status<4"
                      [disabled]="action_json && action_json.charge_contract == 1">Charge
                      Contract
                  </button>
                  <button class="list-group-item list-group-item-action fs-14" title="Move to Unpaid"
                      (click)="moveToUnpaidFirstRecurring()"
                      *ngIf="contract.pf_status == 5 && contract.one_paid_to_denefits == 1 && contract.paid_by_denefits == 3  && !_shared.routerAuth.restricted_buttons.move_to_unpaid_cancelled_contract"
                      [disabled]="action_json && action_json.move_to_unpaid == 1">
                      Move to Unpaid
                  </button>
              </div>

              <button *ngIf="contract.pf_status<4 && contract.ind_is_guaranteed == 1"
                  class="list-group-item list-group-item-action fs-14" (click)="makePaymentDisputed()"
                  [hidden]="admin_profile && admin_profile.make_guaranteed==0"
                  [disabled]="action_json && action_json.payment_disputed == 1">
                  <span *ngIf="contract.dispute_payment_status == 1"> Mark Payment/Service Resolved</span>
                  <span *ngIf="contract.dispute_payment_status == 0">Mark Payment/Service Disputed</span>
              </button>
              <button *ngIf="contract.pf_status<4 && contract.dnd < 2"
                  class="list-group-item list-group-item-action fs-14" (click)="dndModal()"
                  [disabled]="action_json && action_json.dnd == 1">
                  <span *ngIf="contract.dnd == 0">Enable Do Not Disturb</span>
                  <span *ngIf="contract.dnd == 1">Disable Do Not Disturb</span>
              </button>
              <button *ngIf="contract.pf_status<4 && !_shared.routerAuth.restricted_buttons.transaction_fees"
                  class="list-group-item list-group-item-action fs-14" (click)="changeTransactionFeeThresholdModal()"
                  [disabled]="action_json && action_json.change_transaction_fee == 1">
                  Change Transaction Fee
              </button>
              <button *ngIf="!_shared.routerAuth.restricted_buttons.get_money_from_business"
                  class="list-group-item list-group-item-action fs-14" (click)="getOrSendMoneyToBusiness(1)">
                  Get Money from Business/Partner
              </button>
              <button *ngIf="!_shared.routerAuth.restricted_buttons.send_money_to_business"
                  class="list-group-item list-group-item-action fs-14" (click)="getOrSendMoneyToBusiness(2)">
                  Send Money to Business/Partner
              </button>
              <button
                  *ngIf="contract.settlement_amount && contract.settlement_amount > contract.settled_payment_to_doctor && !_shared.routerAuth.restricted_buttons.can_pay_settled_balance"
                  class="list-group-item list-group-item-action fs-14" (click)="goToPaySettlement()"
                  [disabled]="action_json && action_json.pay_balance == 1">
                  Pay Balance to Business
              </button>
              <button class="list-group-item list-group-item-action fs-14" (click)="sendContractModal()"
                  [disabled]="action_json && action_json.download_contract == 1"
                  *ngIf="!_shared.routerAuth.restricted_buttons.send_contract_email">
                  <i class="fa fa-envelope"></i> Send Contract Email
              </button>
              <button *ngIf="!_shared.routerAuth.restricted_buttons.send_contract_email"
                  class="list-group-item list-group-item-action fs-14" (click)="contractVerificationEmailModal()">
                  <i class="fa fa-envelope"></i> Contract Verification Email
              </button>

              <button class="list-group-item list-group-item-action fs-14" (click)="downloadContractModal()"
                  [disabled]="action_json && action_json.download_contract == 1">
                  <i class="fa fa-download"></i> Download Contract
              </button>
              <button class="list-group-item list-group-item-action fs-14" (click)="downloadConsent(0)"
                  [disabled]="action_json && action_json.download_consent_form == 1">
                  <i class="fa fa-download"></i> Download Consent Form
              </button>
              <button class="list-group-item list-group-item-action fs-14"
                  [disabled]="_shared.routerAuth.restricted_buttons.show_contact_in_dnd && contract.dnd == 1" *ngIf="contract && contract.pf_status===3 && contract.reminder_data?.fetchReminderTemplate
             && contract.reminder_data?.fetchReminderTemplate.length > 0" (click)="reminderTemplateModal()">
                  <i class="fa fa-clock-o"></i> Send Reminder
              </button>

              <button class="list-group-item list-group-item-action fs-14"
                  [disabled]="action_json && action_json.add_bank_link == 1" (click)="openAddBankLinkModal();"
                  *ngIf="contract && contract.show_ach==1 && plaid_accounts && plaid_accounts.length==0 &&  bank_accounts.length==0 && !_shared.routerAuth.restricted_buttons.add_bank_link && bank_support_type ">
                  <i class="fa fa-bank"></i>Send Add Bank Account Link
              </button>
              <button class="list-group-item list-group-item-action fs-14" (click)="openContractSettlementModal();"
                  *ngIf="contract  && contract.remaining_payments > 0 && contract.pf_status == 3 && contract.contract_type != 3 && !_shared.routerAuth.restricted_buttons.settlement">
                  <i class="fa fa-handshake-o pr-1"></i>Settlement
              </button>

              <button class="list-group-item list-group-item-action fs-14"
                  [disabled]="action_json && action_json.verify_bank_link == 1"
                  (click)="pauseResumePaymentSMS('payment');">
                  {{cronPayment.is_payments_paused == 1 ? 'Resume Payment' : 'Pause Payment'}}
              </button>
              <button class="list-group-item list-group-item-action fs-14"
                  [disabled]="action_json && action_json.verify_bank_link == 1"
                  (click)="pauseResumePaymentSMS('sms');">
                  {{cronPayment && cronPayment.sms_availability_status == 1 ? 'Stop SMS' : 'Resume SMS'}}
              </button>

              <button class="btn btn-primary rounded  my-2 w-100" (click)="addContractNotesModal()">Add Note</button>
              <button class="btn btn-primary rounded  mb-2 w-100"
                  (click)="arrangement_date = today_date; arrangementModal()" *ngIf="contract.pf_status==3"
                  [disabled]="action_json && action_json.add_arrangement == 1">Add
                  Arrangement</button>

              <button class="btn btn-primary rounded  mb-2 w-100" (click)="getSettlementArrangement()"
                  [disabled]="action_json && action_json.view_arrangement == 1"> View Arrangement
                  <span *ngIf="arrangement_active_count && arrangement_active_count>0"
                      class="badge bg-count badge bg-warning">{{arrangement_active_count}}</span>
              </button>
          </div>
      </div>

      <div *ngIf="show_new_payment_table && payments && payments.length" class="p-0 mt-4">
          <app-payment-table
              [payment_data]="{payments: payments, contract_data: contract, future_payments: future_payments, missing_payments: contract['missing_payments']}"
              [currency_data]="currencyData">
          </app-payment-table>
      </div>

      <div *ngIf="!_shared.routerAuth.restricted_buttons.special_notes" class="p-0">
          <app-special-note [type]="1" [id]="contract.doctor_id" [onlyBusinessNotes]="true">
          </app-special-note>
          <app-special-note [type]="4" [id]="id">
          </app-special-note>
      </div>

      <!--All NOtes-->
      <div class="text-center" *ngIf="contract && contract.contract_id && !load_notes">
          <button class="btn btn-primary rounded my-2 blink" (click)="load_notes = true">View notes</button>
      </div>
      <app-common-notes-listing [notesId]="id" [type]="'contract'" [data]="contract"
          style="padding: 0; margin-top: 10px;" *ngIf="contract && contract.contract_id && load_notes">
      </app-common-notes-listing>
  </div>
</div>