<div class= "col-12 p-0" *ngIf="filterData && filterData.length">
    <form [formGroup]="filterForm">
      <div class="row">
        <ng-container *ngFor="let field of filterData; let i = index;">
          <ng-container [ngSwitch]="field.type">
            <ng-container *ngSwitchCase="'datepicker'">
              <div class="col-sm-6 col-md-4 col-lg-4 col-xl-4 col-xxl-3 form-group mb-2" *ngIf="!field.hide" >
                <label class="fs-14 fw-400 mb-0">{{field.label}}</label>
                <div class="d-flex align-items-center input_field p-0 overflow-hidden">
                  <input type="text" class="input_field border-0 border-end m-0 flex-fill rounded-0" value="{{ filterForm.controls[field.control].value | date: 'MM/d/y' }}" [minDate]="field.minDate ? field.minDate : minDate"
                  [maxDate]="field.maxDate ? field.maxDate : maxDate" [placeholder]="field.placeholder" [formControlName]="field.control" readonly  bsDatepicker [bsConfig]="bsConfig"/> 
                    <!-- (blur)="filterForm.controls[field.control].setValue(filterForm.controls[field.control].value.toString().trim())" -->
                
                    <button type="button" class="bg-transparent border-0 h-100 hw-40" [disabled]="field.disabled == true" >
                      <i class="fa fa-calendar"></i>
                    </button>
                  
                </div>
              </div>
            </ng-container>
            <ng-container *ngSwitchCase="'search'">
              <div class="col-sm-6 col-md-4 col-lg-4 col-xl-4 col-xxl-3 form-group mb-2" *ngIf="!field.hide" >
                <label class="fs-14 fw-400 mb-0">{{field.label}}</label>
                <div class="d-flex align-items-center input_field p-0 overflow-hidden">
                  <input type="text" class="input_field border-0 border-end m-0 flex-fill rounded-0" [formControlName]="field.control" [placeholder]="field.placeholder" (keyup.enter)="applyFilter()" />
                
                    <button *ngIf="!filterForm.value.search || filterForm.value.search=='' " class="bg-transparent border-0 h-100 hw-40">
                      <i class="fa fa-search"></i>
                    </button>
                    <button type="button" *ngIf="filterForm.value.search" (click)="filterForm.controls[field.control].setValue(''); cancelSearch()" class="bg-transparent border-0 h-100 hw-40">
                      <i class="fa fa-close"></i>
                    </button>
                 
                </div>
              </div>
            </ng-container>
            <ng-container *ngSwitchCase="'dropdown'">
              <div class="col-sm-6 col-md-4 col-lg-4 col-xl-4 col-xxl-3 form-group mb-2" *ngIf="!field.hide" >
                <label class="fs-14 fw-400 mb-0">{{field.label}}</label>
                <div class="d-flex align-items-center input_field p-0 overflow-hidden">
                  <select  class="input_field border-0 border-end m-0 flex-fill rounded-0" [formControlName]="field.control" (change)="ngOnChanges();changedate()">
                    <option value="{{data.value}}" *ngFor="let data of field.options">
                      {{data.label}}
                    </option>
                  </select>
                    <button type="button" class="bg-transparent border-0 h-100 hw-40 ffff">
                    <i class="fa fa-filter"></i>
                    </button>
                </div>
              </div>
            </ng-container>
            <ng-container *ngSwitchCase="'country'">
              <div class="col-sm-6 col-md-4 col-lg-4 col-xl-4 col-xxl-3 form-group mb-2" *ngIf="!field.hide" >
                <label class="fs-14 fw-400 mb-0">{{field.label}}</label>
                <div class="d-flex align-items-center input_field p-0 overflow-hidden">
                  <select  class="input_field border-0 border-end m-0 flex-fill rounded-0" [formControlName]="field.control" (change)="ngOnChanges();changedate()">
                    <option value="{{data.country_id}}" *ngFor="let data of field.options">
                      {{data.country_name}}
                    </option>
                  </select>
                <button type="button" class="bg-transparent border-0 h-100 hw-40">
                    <i class="fa fa-filter"></i>
                    </button>
                  </div>
              </div>
            </ng-container>
            <ng-container *ngSwitchCase="'country_currency'">
              <div class="col-sm-6 col-md-4 col-lg-4 col-xl-4 col-xxl-3 form-group mb-2" *ngIf="!field.hide" >
                <label class="fs-14 fw-400 mb-0">{{field.label}}</label>
                <div class="d-flex align-items-center input_field p-0 overflow-hidden">
                  <select  class="input_field border-0 border-end m-0 flex-fill rounded-0" [formControlName]="field.control" (change)="ngOnChanges();changedate()">
                    <option value="{{data.country_id}}" *ngFor="let data of field.options">
                      {{data.country_name}}
                    </option>
                  </select>
                
                    <button type="button" class="bg-transparent border-0 h-100 hw-40">
                    <i class="fa fa-filter"></i>
                    </button>
                  
                </div>
              </div>
            </ng-container>
            <ng-container *ngSwitchCase="'multiselect'">
              <div class="col-sm-6 col-md-4 col-lg-4 col-xl-4 col-xxl-3 form-group mb-2" *ngIf="!field.hide" >
                <label class="fs-14 fw-400 mb-2">{{field.label}}</label>
                <div class="input-group">
                  <ngx-select style="width:100%" [formControlName]="field.control" (selectionChanges)="filterOnChange(field.onChangeFunc, $event)" [items]="field.options" optionTextField="label" optionValueField="value" class="multi-select select-dropedon" [multiple]="true" [allowClear]="true" [placeholder]="'No Filter'" [autoClearSearch]="true" [disabled]="!field.options || (field.options && !field.options.length)">
                  </ngx-select>
                </div>
              </div>
            </ng-container>
  
            <ng-container *ngSwitchCase="'multiDatePicker'">
              <div class="col-sm-6 col-md-4 col-lg-4 col-xl-4 col-xxl-3 form-group mb-2" *ngIf="!field.hide">
                <label class="fs-14 fw-400 mb-0">{{field.label}}</label>
                <div class="d-flex align-items-center input_field p-0 overflow-hidden">
                  <input readonly type="text" bsDaterangepicker class="input_field border-0 border-end m-0 flex-fill rounded-0" [formControlName]="field.control"
                  [bsConfig]="bsConfig" [minDate]="minDate" [maxDate]="maxDate"
                    #dp="bsDaterangepicker">
                  
                      <button type="button" class="bg-transparent border-0 h-100 hw-40" (click)="dp.toggle()">
                        <i class="fa fa-calendar"></i>
                      </button>
                    
                </div>
              </div>
            </ng-container>
            <ng-container *ngSwitchCase="'monthAndYearPicker'">
              <div class="col-sm-6 col-md-4 col-lg-4 col-xl-4 col-xxl-3 form-group mb-2" *ngIf="!field.hide" >
                <label class="fs-14 fw-400 mb-0">{{field.label}}</label>
                <div class="d-flex align-items-center input_field p-0 overflow-hidden">
                  <input type="text" class="input_field border-0 border-end m-0 flex-fill rounded-0"  [minDate]="mindateFilter"
                  [maxDate]="maxDateFilter" [placeholder]="field.placeholder" [formControlName]="field.control" readonly #eDate="bsDatepicker" bsDatepicker [bsConfig]="bsConfig" (onShown)="onOpenCalendar($event)"/> 
                  
                    <button type="button" class="bg-transparent border-0 h-100 hw-40" [disabled]="field.disabled == true" (click)="eDate.toggle()" >
                      <i class="fa fa-calendar"></i>
                    </button>
                  
                </div>
              </div>
            </ng-container>
          </ng-container>
        </ng-container>
        
        <div class="col-sm-6 col-md-4 col-lg-4 col-xl-4 col-xxl-3 mt-3 pt-1 d-flex align-items-center mb-2" >
          <button type="button" tappable class="me-2 btn btn-outline btn-sm fw-600" (click)="resetAll()">Reset</button>
          <button type="button" tappable class="btn btn-primary btn-sm me-2" (click)="applyFilter()">Apply</button>
          <button tappable class="me-2 btn btn-outline btn-sm fw-600" (click)="applyFilter()">Refresh</button>
        </div>
  
      </div>
    </form>
  </div>
  