import { CommonModule } from "@angular/common";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormsModule } from '@angular/forms';
import { currency_data } from "src/app/components/shared/interface.component";
import { ProposalService } from "../proposal.service";
import { localStorageService } from "src/app/components/shared/localstorage.service";
import { ApiService } from "src/app/components/shared/api.service";
import { BsModalRef } from "ngx-bootstrap/modal";

@Component({
  selector: 'app-proposal-list-modal',
  templateUrl: './proposal-list-modal.component.html',
  standalone: true,
  imports: [CommonModule, FormsModule]
})

export class ProposalModalClassComponent implements OnInit {
  modalType: string = '';
  currency_data: currency_data = {
    bank_support_type: ['us_bank_account'],
    currency_id: 1,
    currency_code: "US Dollar",
    display_logo: "$"
  };
  parentType: string = this._proposal.parentType.proposal_list;
  extra_data: any = {
    allSelected: '',
    view_track_status: 0,
    status_track_value: '',
    request_id: '',
    notes: '',
    split_request_id: '',
    stop_to: 0,
    is_stop: 0
  }
  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  constructor(public _proposal: ProposalService, private _local: localStorageService, private _api: ApiService, public bsModalRef: BsModalRef) { }

  ngOnInit(): void {
    // console.log(this.extra_data.split_request_id);
  }

  async resendEmail() {
    // console.log(this.extra_data.split_request_id);
    let split_req_id_arr: any[] = [];
    if (this.parentType == this._proposal.parentType.proposal_list) {
      this._proposal.proposalList.filter((item: any) => {
        if (item.is_selected) {
          split_req_id_arr.push((item.split_req_id).toString());
        }
      });
      if (!split_req_id_arr.includes(this._proposal.splitRequest['split_req_id']) && this._proposal.splitRequest) {
        split_req_id_arr.push((this._proposal.splitRequest['split_req_id']).toString());
      }
      let split_req_ids: any = [];
      if (this.extra_data.allSelected) {
        split_req_ids = await this._proposal.getAllProposalIds();
      }
      split_req_id_arr = this.extra_data.allSelected ? split_req_ids : split_req_id_arr;
    } else {
      split_req_id_arr.push(this.extra_data.split_request_id);
    }
    let params = {
      
      split_req_id_arr
      // doctor_id: this._proposal.splitRequest.doctor_id
    };

    // modal.hide();
    // hidemodal here
    this._api.api(params, 'resend_split_plans')
      .subscribe((data: any) => {
        if (data.is_error == 0) {
          if (data.batch_status) {
            this.passEntry.emit({ success: true, data });
            this.modalHide()
          }
        } else {
          this.passEntry.emit({ success: false, data });
        }
      }, (error: any) => {
        console.log(error)
      }
      )
  }

  modalHide() {
    this.bsModalRef.hide();
  }

  toggleBlockContractForOverdue() {
    let params = {
      
      split_req_id: this._proposal.selected_req['split_req_id'],
      skip_overdue_contract_check: this._proposal.selected_req.skip_overdue_contract_check ? 1 : 0,
    };
    this._api.api(params, 'edit_split_overdue_check')
      .subscribe((data: any) => {
      }, (error: any) => {
        console.log(error)
      })
  }

  editPaymentSettings() {
    let params = {
      
      split_req_id: this._proposal.selected_req['split_req_id'],
      primary_type: this._proposal.selected_req['selected_payment_matrix']['primary_type'],
      backup_type: this._proposal.selected_req['selected_payment_matrix']['backup_type'],
      recurring_type: this._proposal.selected_req['selected_payment_matrix']['recurring_type'],
      backup_required: this._proposal.selected_req['selected_payment_matrix']['backup_required'] ? 1 : 0,
    };
    this._api.api(params, 'edit_split_payment_settings')
      .subscribe((data: any) => {
        if (data.is_error == 0) {
          this.passEntry.emit({ success: true, data });
          this.modalHide()
        }
      }, (error: any) => {
        console.log(error)
      })
  }

  deletePropopsal() {
    if (!this.extra_data.notes || (this.extra_data.notes && !(this.extra_data.notes.trim()))) {
      this.passEntry.emit({ success: false, err: 'Please enter notes' });
      return;
    }
    let params = {
      
      split_req_id: this.extra_data.request_id,
      notes: this.extra_data.notes
    }
    this._api.api(params, 'delete_split_request')
      .subscribe((data: any) => {
        if (data.is_error == 0) {
          this.passEntry.emit({ success: true, data });
          this.modalHide()
        }
      }, (error: any) => {
        console.log(error)
      })
  }

  stopResume() {
    if (!this.extra_data.notes || (this.extra_data.notes && !(this.extra_data.notes.trim()))) {
      this.passEntry.emit({ success: false, err: 'Please enter notes' });
      return;
    }

    let params = {
      access_token: localStorage.getItem('adminToken'),
      split_req_id: this.extra_data.split_request_id,
      notes: this.extra_data.notes,
      stop_reminder: this.extra_data.is_stop,
      stop_email: this.extra_data.stop_to == 1 ? 1 : null,
      stop_sms: this.extra_data.stop_to == 2 ? 1 : null
    };
    this._api.api(params, 'stop_resume_proposal_reminder')
    .subscribe((data: any) => {
      if (data.is_error == 0) {
        this.passEntry.emit({ success: true, data });
        this.modalHide()
      }
    }, (error: any) => {
      console.log(error)
    })
  }
}
