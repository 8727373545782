<div class="row">
    <h3 class="col-xs-12 col-sm-6 col-md-4 col-lg-4">{{is_ar_view ? 'CredeeBill' : '' }} Proposal Requests</h3>
    <div class="col-xs-12 col-sm-6 col-md-8 col-lg-8 text-right">
        <span class="count rounded py-2">{{'Total Requests: '+ (resData.totalRecords?resData.totalRecords:0) }}</span>
    </div>
</div>

<div class="row m-0 align-items-center mb-2" [hidden]="!show_filters">
    <app-filters class="row m-0 w-100" [filters]="filters" (finalValues)="filterValues($event)" (resetAllFilters)="resetFilters($event)">
    </app-filters>
</div>

<div class="card" *ngIf="splitAppList">
    <div class="row m-0">
        <div class="col-sm-4 col-md-4 col-lg-4 pt-4">
            <p class="h4 text-bold">
               {{dateRangeValue && dateRangeValue[0]?(dateRangeValue[0] | date:'mediumDate'):''}} to {{dateRangeValue
                && dateRangeValue[1]?(dateRangeValue[1]|date:'mediumDate'):''}}
            </p>
        </div>
        <div class="col-sm-8 col-md-8 pt-2 text-right d-flex justify-content-end" *ngIf="!_shared.routerAuth.restricted_buttons.export">
            <ng-container *ngIf="filter.status_filter != '4'">
                <button class="btn btn-primary mr-2" [disabled]="!isAnySelected" (click)="openPlansDetail('')">Send Selected</button>
                <button class="btn btn-primary mr-2" *ngIf="isSendAllAllowedToAdmin" [disabled]="!isSendAllAllowed" (click)="sendProposalToAll()">Send All</button>
            </ng-container>
            <button (click)="showFilter(!show_filters)" type="button" class="btn mr-2" [ngClass]="{'btn-primary':show_filters, 'btn-default': !show_filters}" >
                <span style="font-size:16px">{{show_filters ? "Hide Filters" : "Show Filters"}}</span>
                <i class="fa fa-filter"></i>
            </button>
            <div class="btn-group" dropdown>
                <button id="button-basic" dropdownToggle type="button" class="btn btn-primary dropdown-toggle"
                    aria-controls="dropdown-basic">
                    Export <span class="caret"></span>
                </button>
                <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                    <li role="menuitem">
                        <button class="dropdown-item" tappable (click)="exportXLSX(0, 1)">Export XLSX</button>
                    </li>
                    <li role="menuitem">
                        <button class="dropdown-item" tappable (click)="exportXLSX(1, 1)">Export CSV</button>
                    </li>
                </ul>
            </div>

        </div>
    </div>
    <div class="table-responsive" *ngIf="splitAppList && splitAppList.length">
        <table class="table">
            <thead>
                <tr>
                    <th style="min-width: 60px;" class="text-center position-relative">
                        <input type="checkbox" class="form-check-input" *ngIf="filter.status_filter != '4'" [(ngModel)]="allSelected" (click)="updateSelection(0, 'all')" name="sr-no" id="sr-no">
                        S No.
                    </th>
                    <th style="min-width: 250px;">Customer info</th>
                    <th style="min-width: 250px;">Business Owner Info</th>
                    <th style="min-width: 250px;">Proposal info</th>
                    <th style="min-width: 150px;">Current Status</th>
                    <th style="min-width: 150px; padding-left: 50px;">Actions</th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let singleSplitAppList of splitAppList; let i=index;">
                    <tr>
                        <td class="text-center position-relative">
                            <input type="checkbox" class="form-check-input" *ngIf="filter.status_filter != '4'"
                                [disabled]="singleSplitAppList.is_expired != 0 || singleSplitAppList.contract_current_status != 'Proposal Sent' || (singleSplitAppList.ppr_status && singleSplitAppList.ppr_status != 1)"
                                [(ngModel)]="singleSplitAppList.is_selected" (click)="updateSelection(i)" name="sr-no" id="sr-no">
                            {{((resData.pageNumber-1)*10)+(i+1)}}
                        </td>
                        <td>
                            <div class="row m-0 ">
                                <div class="col-3 p-0 text-muted">Name:</div>
                                <div class="col-9 p-0 text-left"
                                    *ngIf="singleSplitAppList.patient_first_name || singleSplitAppList.patient_last_name">
                                    <a [routerLink]="'/app/patients/profile/'+singleSplitAppList.patient_id"
                                        *ngIf="singleSplitAppList.patient_id" class="text-primary text-bold">
                                        {{ singleSplitAppList.patient_name}}</a>
                                    <span *ngIf="!singleSplitAppList.patient_id">{{ singleSplitAppList.patient_name}}</span>
                                </div>
                                <span *ngIf="!singleSplitAppList.patient_first_name && !singleSplitAppList.patient_last_name">
                                    N.A.</span>
                            </div>
                            <div class="row m-0">
                                <div class="col-3 p-0 text-muted">Email:</div>
                                <div class="col-9 p-0 text-left">
                                    {{ singleSplitAppList.patient_email?singleSplitAppList.patient_email:'N.A.'}}</div>
                            </div>
                            <div class="row m-0">
                                <div class="col-3 p-0 text-muted">Phone:</div>
                                <div class="col-9 p-0 text-left">
                                    {{ (singleSplitAppList.patient_mobile && singleSplitAppList.patient_mobile_country_code )?
                                    (singleSplitAppList.patient_mobile_country_code)+'
                                    '+(singleSplitAppList.patient_mobile):
                                    'N.A.'}}
                                </div>
                            </div>
                            <div class="row m-0">
                                <div class="col-3 p-0 text-muted">Address:</div>
                                <div class="col-9 p-0 text-left">
                                    {{ singleSplitAppList.patient_address?singleSplitAppList.patient_address:'N.A.'}}
                                </div>
                            </div>
                        </td>
                        <td>
                            <div class="row m-0">
                                <div class="col-3 p-0 text-muted">Name:</div>
                                <div class="col-9 p-0 text-left" *ngIf="singleSplitAppList.doctor_name">
                                    <a [routerLink]="'/app/business/details/'+singleSplitAppList.doctor_id"
                                        class="text-primary text-bold">
                                        {{ singleSplitAppList.doctor_name}}</a>
                                </div>
                                <span *ngIf="!singleSplitAppList.doctor_name">N.A.</span>
                            </div>
                            <div class="row m-0">
                                <div class="col-3 p-0 text-muted">Email:</div>
                                <div class="col-9 p-0 text-left">
                                    {{ singleSplitAppList.doctor_email?singleSplitAppList.doctor_email:'N.A.'}}</div>
                            </div>
                        </td>
                        <td>
                            <div class="row m-0">
                                <div class="col-5 p-0 text-muted">Contract Type:</div>
                                <div class="col-7 p-0 text-left">
                                    <span *ngIf="singleSplitAppList.contract_type==2" class="badge bg-success">
                                        CredeeFi
                                    </span>
                                    <span *ngIf="singleSplitAppList.contract_type==4" class="badge bg-success">
                                        {{ singleSplitAppList.ez_plan_new ? 'ZeroFi Financing': 'SimpleeFi Financing' }}
                                    </span>
                                    <span *ngIf="singleSplitAppList.contract_type==6" class="badge bg-primary"
                                        style="white-space: normal;">
                                        SimpleeFi Payment Plan (With Deferred Interest)
                                    </span>
                                    <!-- <span *ngIf="singleSplitAppList.contract_type==1" class="badge bg-warning">
                                                                    10% Service Fee
                                                                </span> -->
                                    <span *ngIf="singleSplitAppList.contract_type==3" class="badge bg-primary">
                                        KeepeeFi
                                    </span>
                                    <span *ngIf="singleSplitAppList.contract_type==5" class="badge bg-success">
                                        Credee Bill Payment Plan
                                    </span>
                                </div>
                            </div>
                            <div class="row m-0">
                                <div class="col-5 p-0 text-muted">Request Date:</div>
                                <div class="col-7 p-0 text-left">
                                    {{ singleSplitAppList.date_added?(singleSplitAppList.date_added|date: 'MM-dd-yyyy':timezone):'N.A.'
                                    }}
                                </div>
                            </div>
                            <div class="row m-0">
                                <div class="col-5 p-0 text-muted">Date Range:</div>
                                <div class="col-7 p-0 text-left">
                                    {{ singleSplitAppList.start_date?(singleSplitAppList.start_date|date: 'MM-dd-yyyy': '+0000'):'N.A.'
                                    }}
                                    to<br>
                                    {{ singleSplitAppList.end_date?(singleSplitAppList.end_date|date: 'MM-dd-yyyy': '+0000'):'N.A.' }}
                                </div>
                            </div>
                        </td>
                        <td>
                            <div class="row m-0">
                                <div class="col-12 p-0">Proposal Resent:
                                    {{singleSplitAppList.resend_email_count ? singleSplitAppList.resend_email_count : 0}}
                                </div>
                                <div class="col-12 p-0">Last Resent:
                                    {{singleSplitAppList.last_email_sent ? (singleSplitAppList.last_email_sent | date:'MM-dd-yyyy hh:mm
                                    a':timezone) : 'N.A.'}}
                                </div>
                                <div class="col-12 p-0">Settings Changed Count:
                                    {{singleSplitAppList.settings_changed_count || 0}}
                                </div>
                                <div class="col-12 p-0">Last Action:
                                    {{singleSplitAppList.last_action ? (singleSplitAppList.last_action | date:'MM-dd-yyyy hh:mm
                                    a':timezone)
                                    : 'N.A.'}}
                                </div>
                                <div class="col-12 p-0">
                                    <span class="m-1 badge" *ngIf="!is_ar_view" [ngClass]="{
                                                                        'bg-info': singleSplitAppList.split_status_id == 1 || singleSplitAppList.split_status_id == 2 || singleSplitAppList.split_status_id == 3,
                                                                        'bg-success': singleSplitAppList.split_status_id == 4,
                                                                        'bg-danger': singleSplitAppList.split_status_id == 5
                                                                    }">
                                        {{singleSplitAppList.split_status}}
                                    </span>
                                    <span class="m-1 badge bg-yellow" *ngIf="is_ar_view && singleSplitAppList.is_expired != 1" [ngClass]="{
                                                                    'bg-info': singleSplitAppList.split_status_id == 1 || singleSplitAppList.split_status_id == 2 || singleSplitAppList.split_status_id == 3,
                                                                    'bg-success': singleSplitAppList.split_status_id == 4,
                                                                    'bg-danger': singleSplitAppList.split_status_id == 5
                                                                }">
                                        {{singleSplitAppList.proposal_status}}
                                    </span>
                                    <span class="m-1 badge bg-info bg-red">
                                        {{singleSplitAppList.request_from ? 'Send Payment Link' : 'Send Proposal'}}
                                    </span>
                                </div>
                                <!-- 1- request sent, 2-Link opened, 3-plan selected, 4-Financed, 5-Cancelled split_status_id -->
                                <div>
                                    <a class="padd_r5" [routerLink]="'/app/contracts/'+singleSplitAppList.contract_id"
                                        *ngIf="singleSplitAppList.doctor_status == 2 && singleSplitAppList.patient_status == 2 && singleSplitAppList.contract_id && singleSplitAppList.contract_code">
                                        {{singleSplitAppList.contract_code}}
                                    </a>
                                    <span *ngIf="singleSplitAppList.is_expired == 1" class="badge bg-danger">Expired</span>
                                    <button class="btn btn-primary btn-xs m-1" *ngIf="is_ar_view"
                                        (click)="viewDetails(singleSplitAppList,1)"> View Status
                                    </button>
                                </div>
                            </div>
                        </td>
                
                        <td class="">
                            <ng-container
                                *ngIf="singleSplitAppList">
                                <div class="col-12 p-0 text-center mb-2"
                                    *ngIf="(singleSplitAppList.is_expired == 0 && singleSplitAppList.contract_current_status == 'Proposal Sent') && singleSplitAppList.is_deleted == 0 && singleSplitAppList.ppr_status != 2 && singleSplitAppList.ppr_status != 4">
                                    <button class="btn btn-primary"
                                        [disabled]="singleSplitAppList.is_selected || isAnySelected || singleSplitAppList.ppr_status == 7"
                                        (click)="openPlansDetail(singleSplitAppList)"> Resend Proposal
                                    </button>
                                </div>
                                
                                <div class="delete">
                                    <div class="" (click)="gotoproposaldetails(singleSplitAppList.split_req_id)">
                                        <img src="assets/images/png/eyebtn.png" alt="">
                                    </div>
                                    <div class="" (click)="deleteRequest(singleSplitAppList.split_req_id)"
                                        *ngIf="singleSplitAppList.can_delete == 1 && singleSplitAppList.is_financed != 1 && singleSplitAppList.is_deleted == 0">
                                        <img src="assets/images/png/delete.png" alt="">
                                    </div>
                                </div>
                                <div class="col-12 p-0 text-center mt-2">
                                    <button class="btn btn-primary"
                                        *ngIf="singleSplitAppList.doctor_status == 2 && singleSplitAppList.patient_status == 2 && singleSplitAppList.contract_id"
                                        (click)="goToContract(singleSplitAppList.contract_id)"> View Contract
                                    </button>
                                    <button class="btn btn-primary mt-2"
                                        *ngIf="singleSplitAppList.ppr_status && singleSplitAppList.ppr_status==2"
                                        (click)="goToPaymentProcess(singleSplitAppList.ppr_id)"> View Payment Details
                                    </button>
                                </div>
                            </ng-container>
                            <!-- <div class="col-12 p-0">
                                <button class="btn btn-primary btn-xs m-1"
                                    *ngIf="singleSplitAppList.ppr_status && singleSplitAppList.ppr_status==2"
                                    (click)="goToPaymentProcess(singleSplitAppList.ppr_id)"> View Payment Details
                                </button>
                            </div> -->
                        </td>
                    </tr>
                    <tr>
                        <td colspan="6" class="border-0">
                            <table style="width: 100%;">
                                <tbody>
                                    <tr>
                                        <td class="border-bottom py-2">
                                            <ng-container *ngIf="!singleSplitAppList.ppr_status ||  (singleSplitAppList.ppr_status && singleSplitAppList.ppr_status!=2)">
                                            <div class="table_data"
                                                    *ngIf="singleSplitAppList.is_expired == 0   &&  singleSplitAppList.contract_current_status == 'Proposal Sent'">
                                                    <div class="">
                                                        <div class="reminder links">
                                                            <label class="font-weight-bold">Reminder Email</label>
                                                            <label class="switch switch-md switch-success ml-1 mr-1">
                                                                <input type="checkbox"
                                                                    [checked]="singleSplitAppList.stop_reminder == 0  || singleSplitAppList.stop_reminder == 2"
                                                                    (change)="stop_resume_reminder($event,singleSplitAppList.split_req_id,1)" />
                                                                <span></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div class="">
                                                        <div class="reminder links">
                                                            <label class="font-weight-bold">Reminder SMS</label>
                                                            <label class="switch switch-md switch-success ml-1 mr-1">
                                                                <input type="checkbox"
                                                                    [checked]="singleSplitAppList.stop_reminder == 0 || singleSplitAppList.stop_reminder == 1"
                                                                    (change)="stop_resume_reminder($event,singleSplitAppList.split_req_id,2)" />
                                                                <span></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div class="links">
                                                        <button class=""
                                                        *ngIf="!(singleSplitAppList.doctor_status == 2 && singleSplitAppList.patient_status == 2 && singleSplitAppList.contract_id)"
                                                        (click)="viewDetails(singleSplitAppList)"> <img src="assets/images/png/fileeye.png" alt=""> View Plans
                                                    </button>
                                                    </div>
                                                    <div class="links">
                                                        <button class=""
                                                            *ngIf="(singleSplitAppList.split_status_id == 1 || singleSplitAppList.split_status_id == 2 || singleSplitAppList.split_status_id == 3) && singleSplitAppList.is_expired == 0"
                                                            (click)="ViewPaymentSettings(singleSplitAppList)"> <img src="assets/images/png/Gear.png" alt=""> Change Settings
                                                        </button>
                                                    </div>
                                                    <div class="links" *ngIf="baseUrlCopyLink">
                                                        <button class=""
                                                            *ngIf="singleSplitAppList.contract_patient_token && baseUrlCopyLink"
                                                            (click)="copyLink(singleSplitAppList)"> <img src="assets/images/png/Link.png" alt=""> Copy Link
                                                        </button>
                                                    </div>
                                                    <div class="col-12 p-0 payment_link links" *ngIf="singleSplitAppList.contract_patient_token && baseUrlCopyLink">
                                                        <a [href]="baseUrlCopyLink + singleSplitAppList.contract_patient_token" target="_blank"> 
                                                        <button> <img src="assets/images/png/Links.png" alt="">
                                                            Payment Link
                                                        </button></a>
                                                    </div>
                                                </div>
                                            </ng-container>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                    
                </ng-container>
            </tbody>
        </table>
    </div>
    <div *ngIf="splitAppList && splitAppList.length">
        <div class="row m-0 align-items-center mt-2">
            <div class="col">
                <p class="text-bold">
                    Showing {{(1+(resData.pageNumber - 1) * 10)}} to {{(splitAppList.length + (resData.pageNumber - 1) *
                10)}} of {{resData.totalRecords || 'all'}}
                </p>
            </div>
            <div class="col ">
                <pagination class="float-right pagination-sm" [totalItems]="resData.totalRecords" [maxSize]="5"
                    [boundaryLinks]="true" [rotate]="false" (pageChanged)="pageChanged($event)"
                    [(ngModel)]="resData.pageNumber">
                </pagination>
            </div>
        </div>
    </div>

    <div class="col-12 text-center mt-3" *ngIf="!splitAppList || !splitAppList.length">
        <img src="./assets/img/SVG/empty/patients.svg">
        <h4 style="color:#8592a5">No data Found</h4>
    </div>

    

    <!-- Prompt modal for send proposal -->
    <!-- <div bsModal #sendAllSuccess="bs-modal" [config]="{ignoreBackdropClick:true}" class="modal fade new_modal" tabindex="-1"
        role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-md  modal-dialog-centered ">
            <div class="modal-content">
                
                <div class="modal-header">
                    <h4 class="modal-title">
                        Resend Proposal Email
                    </h4>
                    <button type="button" class="close" aria-label="Close" (click)="sendAllSuccess.hide()">&times;</button>
                </div>
                <div class="modal-body m-3">
                    <div class="row" *ngIf="!failedProposals || !failedProposals.length">
                        <h5>Data is large will notify once complete via mail or notification.</h5>
                    </div>
                    <div class="row" *ngIf="failedProposals.length">
                        <h5 class="font-weight-bold">Proposal Reminder(s) succeeded for {{succededProposals.length}}, failed for the following:- </h5><br>
                        <ng-container *ngFor="let proposal of failedProposals">
                            <div *ngIf="proposal && proposal.split_req_id" class="w-100 mb-1">
                                <span>Proposal Link: &nbsp;<a [href]="env.url + '/app/contracts/split-details/' + proposal.split_req_id " target="_blank">{{proposal.split_req_id}}</a></span>
                                
                            </div><br>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div> -->