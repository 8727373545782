import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SeminarLeadsListComponent } from './seminar-leads-list/seminar-leads-list.component';
import { LeadsDashboardComponent } from './leads-dashboard/leads-dashboard.component';
import { LeadsListComponent } from './leads-list/leads-list.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/components/shared/shared.module';
import { RouterModule, Routes } from '@angular/router';
import { LeadsDetailsComponent } from './leads-details/leads-details.component';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { CreateLeadComponent } from './create-lead/create-lead.component';
import { LeadsService } from './leads.service';
import { LeadAssignmentListComponent } from './lead-assignment-list/lead-assignment-list.component';

const routes: Routes = [
  { 
    path: '', redirectTo: 'dashboard', pathMatch: 'full'
  },
  { 
    path: 'dashboard', component: LeadsDashboardComponent
  },
  { 
    path: 'create', component: CreateLeadComponent
  },
  { 
      path: 'list', component: LeadsListComponent
  },
  {
      path: 'details/:id', component: LeadsDetailsComponent
  },
  {
      path: 'lead-assignments', component: LeadAssignmentListComponent
  }
]

@NgModule({
  declarations: [SeminarLeadsListComponent, LeadsDashboardComponent, LeadsListComponent, LeadsDetailsComponent, CreateLeadComponent, LeadAssignmentListComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    RouterModule.forChild(routes),
    PaginationModule,
  ],
  providers: [LeadsService],
  exports: [SeminarLeadsListComponent, LeadsDashboardComponent, LeadsListComponent, LeadsDetailsComponent, CreateLeadComponent, LeadAssignmentListComponent]
})
export class LeadsModule { }
