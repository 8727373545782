<div class="modal-header d-flex align-items-center justify-content-between theme-green-bg">
  <h4 class="modal-title text-white fs-18">Add a Note</h4>
  <img src="assets/images/icons/white-cross.svg" alt="" class="cursor hw-24" (click)="hideModal()">
  
</div>
<form [formGroup]="notesForm" (ngSubmit)="handleAddNotes()">
  <div class="modal-body">
    <div class="row">
     
      <div class="col-12">
        <div class="form-group">
          <label class="fs-14 fw-400">Notes*</label>
          <textarea required formControlName="note" class="input_field" placeholder="Enter notes here"
            rows="4"></textarea>
        </div>
      </div>
      <div class="w-100 my-1"></div>
      <div class="col-6">
        <div class="text-left">
          <label for="images" class="btn btn-primary btn-sm rounded">Attach Image/Document</label>
          <input type="file" name="" id="images" #imageElement
            accept=".gif, .jpeg, .jpg, .png, .doc,.docx,application/msword, .pdf, .xls,.xlsx, .csv"
            (change)="fileSelect($event)" class="d-none">
        </div>
        
        <div *ngFor="let file of images; let i=index">
          <div *ngIf="file.subs && file.subs[0]">
            <div class="d-flex" *ngIf="file.subs[0].message">
              <progressbar [value]="file.subs[0].message" type="warning" [striped]="true">
                {{file.subs[0].message}}%</progressbar>
              <i class="fa fa-close ml-2" tappable tooltip="Cancel upload" (click)="cancelImageUpload(file, i)"></i>
            </div>


            <div *ngIf="file.subs[0].images && file.subs[0].images[0]"
              class="d-flex justify-content-between align-items-center border mb-1">
              <span>
                <i class="fa fa-trash text-danger mr-2" (click)="deletFile(file.image, i)"
                  tooltip="Delete uploaded file" tappable></i>
                <a [href]="file.image" target="_blank">Click here to view</a>
              </span>
              <i class="fa fa-check text-success" tooltip="File Uploaded"></i>
            </div>
          </div>
        </div>


      </div>
    </div>
  </div>
  <div class="modal-footer">
      <div>
        <button type="submit" class="btn btn-primary" [disabled]="notesForm.invalid">Add</button>
      </div>
  </div>
</form>