import { DatePipe } from "@angular/common";
import { Injectable } from "@angular/core";
import { ApiService } from "src/app/components/shared/api.service";
import { localStorageService } from "src/app/components/shared/localstorage.service";

@Injectable()
export class ProposalService {
    public filter: any = {
        start_date: new Date('2024/01/01'),
        end_date: new Date(),
        is_expired: -1,
        status_filter: 0,
        ProposalType: 0// filter for AR 
    };
    dateRangeValue: any;
    public resData: any = {
        pageNumber: 1
    };
    public sortValue: { by: string, order: string, label: string } = {
        by: 'date_added',
        order: 'DESC',
        label: 'Registration Date'
    };
    public country_id: any = this._local.customGetItem('country_id');
    public search = '';
    plansDetail: any = [];
    splitRequest: any;
    proposalList: any;
    selected_req: any = {};
    parentType = {
      proposal_list: 'proposal_list',
      proposal_details: 'proposal_details',
    }
    modalTypes = {
      resendProposalModal: 'resendProposalModal',
      plansDetailModal: 'plansDetailModal',
      paymentSettingsModal: 'paymentSettingsModal',
      deleteProposal: 'deleteProposal',
      addContractNotesModal: 'addContractNotesModal',
      stop_resume_reminder: 'stop_resume_reminder'
    }

    constructor (private _local: localStorageService, private datePipe: DatePipe, private _api: ApiService) {}


    getAllProposalIds() {
        return new Promise((resolve, reject) => {
          let params = {
            access_token: this._local.customGetItem("adminToken"),
            offset: (this.resData.pageNumber - 1) * 10,
            limit: 10,
            search_string: this.search,
            status_filter: this.filter.status_filter,
            is_expired: (this.filter.status_filter != 0 && this.filter.status_filter != 1 && this.filter.status_filter != 2 && this.filter.status_filter != 3 && this.filter.status_filter != 6  && this.filter.status_filter != 8 && this.filter.status_filter != 9 && this.filter.status_filter != 13) ? -1 : this.filter.is_expired,
            sort_by: this.sortValue.by,
            sort_order: this.sortValue.order,
            min_date_added: this.datePipe.transform(this.dateRangeValue[0], 'yyyy-MM-dd'),
            max_date_added: this.datePipe.transform(this.dateRangeValue[1], 'yyyy-MM-dd'),
            is_debt_contract:window.location.pathname.includes('/app/contracts/split-list')?0:1,
            ProposalType: this.filter.ProposalType,
            last_resent: this.filter.last_resent,
            send_all: 1
          };
          if (this.search && this.search.length > 0) {
            params['search_string'] = this.search;
          } else {
            // delete params['search_string'];
          }
          this._api.api(params, "list_split_requests").subscribe((send_proposal: any) => {
            if (send_proposal && send_proposal.split_req_id_all && send_proposal.split_req_id_all.length) {
              resolve(send_proposal.split_req_id_all);
            }
          });
        });
    }

    GetTransactionFee(contract: any, comparable_value: any, display_logo: string) {
      comparable_value = parseFloat(comparable_value);
      contract.recurring_extra_amount = parseFloat(contract.recurring_extra_amount);
      contract.recurring_extra_percent = parseFloat(contract.recurring_extra_percent);
      contract.threshold_amount = parseFloat(contract.threshold_amount);
      contract.threshold_recurring_extra_amount = parseFloat(contract.threshold_recurring_extra_amount);
      contract.threshold_recurring_extra_percent = parseFloat(contract.threshold_recurring_extra_percent);
      let text = '';
      if (comparable_value < contract.threshold_amount) {
        if (contract.recurring_extra_percent) {
          text += `${parseFloat(contract.recurring_extra_percent).toFixed(2)}%`
        }
        if (contract.recurring_extra_amount && contract.recurring_extra_percent) {
          text += ' + ';
        }
        if (contract.recurring_extra_amount) {
          text += `${display_logo}${parseFloat(contract.recurring_extra_amount).toFixed(2)}`
        }
        if (!contract.recurring_extra_percent && !contract.recurring_extra_amount) {
          text = display_logo + '0'
        }
      } else {
        if (contract.threshold_recurring_extra_percent) {
          text += `${parseFloat(contract.threshold_recurring_extra_percent).toFixed(2)}%`
        }
        if (contract.threshold_recurring_extra_amount && contract.threshold_recurring_extra_percent) {
          text += ' + ';
        }
        if (contract.threshold_recurring_extra_amount) {
          text += `${display_logo}${parseFloat(contract.threshold_recurring_extra_amount).toFixed(2)}`
        }
  
        if (!contract.threshold_recurring_extra_percent && !contract.threshold_recurring_extra_amount) {
          text = '0%'
        }
      }
      return text;
    }
  
    getCalculatedTransactionFee(contract: any, comparable_value: any) {
      comparable_value = parseFloat(comparable_value);
      contract.recurring_extra_amount = parseFloat(contract.recurring_extra_amount);
      contract.recurring_extra_percent = parseFloat(contract.recurring_extra_percent);
      contract.threshold_amount = parseFloat(contract.threshold_amount);
      contract.threshold_recurring_extra_amount = parseFloat(contract.threshold_recurring_extra_amount);
      contract.threshold_recurring_extra_percent = parseFloat(contract.threshold_recurring_extra_percent);
      let transaction_fee = 0;
      if (comparable_value === 0) {
        return 0
      } else if ((comparable_value < contract.threshold_amount) || (contract.threshold_amount === undefined && contract.recurring_extra_amount !== undefined)) {
        if (contract.recurring_extra_percent) {
          transaction_fee += (comparable_value * contract.recurring_extra_percent) / 100;
        }
        if (contract.recurring_extra_amount) {
          transaction_fee += contract.recurring_extra_amount;
        }
      } else {
        if (contract.threshold_recurring_extra_percent) {
          transaction_fee += (comparable_value * contract.threshold_recurring_extra_percent) / 100;
        }
        if (contract.threshold_recurring_extra_amount) {
          transaction_fee += contract.threshold_recurring_extra_amount;
        }
      }
      return transaction_fee;
    }
}