import { Component, OnInit } from '@angular/core';
import { LoaderService } from './loader.service';

@Component({
  selector: 'app-loader',
  template: `
    <div
      [ngStyle]="{'visibility' : showLoader ? 'visible' : 'hidden'}"
      class="overlay position-fixed p-3 h-100 w-100 d-flex justify-content-center align-items-center"
    >
      <div
        class="fm-spinner p-3 d-flex justify-content-center align-items-center rounded-circle"
      >
        <img src="assets/images/icons/logo-icon.svg" alt="" class="h-100" />
      </div>
    </div>
  `,
})
export class LoaderComponent implements OnInit {
  showLoader: boolean = false;
  constructor(public _loader: LoaderService) {}

  ngOnInit() {
    this._loader.loader$.subscribe((loader: boolean) => {
      this.showLoader = loader;
    });
  }
}
