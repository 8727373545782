import { Component, OnInit, EventEmitter } from "@angular/core";
import { localStorageService } from "../localstorage.service";
import { ApiService } from "../api.service";
import { CommonModule } from "@angular/common";
import { BsModalRef } from "ngx-bootstrap/modal";

@Component({
  selector: "app-doctor-stats",
  standalone: true,
  imports: [CommonModule],
  templateUrl: "./doctor-stats.component.html",
  styleUrls: ["./doctor-stats.component.scss"]
})
export class DoctorStatsComponent implements OnInit {
  public data: any;
  display_logo = '';
  public event: EventEmitter<any> = new EventEmitter();

  stats: any = [
    {
      key: "last_contract_date",
      label: "Last Contract Date",
      value: "",
      addCurrencyLogo: false
    },
    {
      key: "claimed_approved_amount",
      label: "Current Claim Approved Amount",
      value: "",
      addCurrencyLogo: true
    },
    {
      key: "claim_paid_amount",
      label: "Claim Paid Amount",
      value: "",
      addCurrencyLogo: true
    },
    {
      key: "paid_to_paul_amount",
      label: "Paid To Credee Amount",
      value: "",
      addCurrencyLogo: true
    },
    {
      key: "ptp_amt_last3m",
      label: "Paid To Credee Last 3 Month",
      value: "",
      addCurrencyLogo: true
    },
    {
      key: "ptp_amt_last6m",
      label: "Paid To Credee Last 6 Month",
      value: "",
      addCurrencyLogo: true
    },
    {
      key: "ptp_cl_paid_diff",
      label: "Difference(Paid To Credee Amount - Claim Paid Amount)",
      value: "",
      addCurrencyLogo: true
    },
    {
      key: "ptp_cl_paid_percent",
      label: "Current profit/Loss percentage (%)",
      value: "",
      addCurrencyLogo: true
    },
    {
      key: "ptp_cl_paid_approved_percent",
      label:
        "Profit/ loss percentage after paying approved claims this month (%)",
      value: "",
      addCurrencyLogo: false
    },
    {
      key: 'ptp_cl_paid_percent_non_orp',
      label: '(Pure GP) - Current profit/loss percentage(%)',
      value: '',
      addCurrencyLogo: false
    },
    {
      key: 'ptp_cl_paid_approved_percent_non_orp',
      label: '(Pure GP) - Profit/loss percentage after paying approved claims this month(%)',
      value: '',
      addCurrencyLogo: false
    },
  ];
  constructor(public bsModalRef: BsModalRef, public _local: localStorageService, public _api: ApiService) {}

  ngOnInit() {
    this.getFinancingStats();
    this.appendCurrencyLogoInStats();
  }
  getFinancingStats() {
    let data = {
      access_token: this._local.customGetItem("adminToken"),
      doctor_id: this.data.doctor_id
    };
    this._api.api(data, "get_doctor_financing_stats").subscribe(
      (data: any) => {
        let tableData = data.data[0];

        this.stats.map((item: any) => {
          item.value = tableData[item.key];
        });
      },
      (error: any) => {
        console.log(error);
      }
    );
  }

  hideModal(value: number) {
    if (value == 1) {
      this.event.emit({ data: 1, docStats: this.stats });
      this.bsModalRef.hide();
    } else {
      this.bsModalRef.hide();

    }
  }
  appendCurrencyLogoInStats() {
    let currency_logo = this.display_logo;
    if (!currency_logo) {
      let selected_country_data = this._local.customGetItem('adminSelectedCountry');
      selected_country_data ? selected_country_data = JSON.parse(selected_country_data) : selected_country_data = {};
      currency_logo = selected_country_data['display_logo'] || '$';
    }
    this.stats.forEach((stat: any) => {
      if (stat && stat.addCurrencyLogo) {
        stat.label = `${stat.label}(${currency_logo || '$'})`;
      }
    });
  }

}
