import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { DebtRecoveryListComponent } from './debt-recovery-list/debt-recovery-list.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { SharedModule } from '../../shared/shared.module';
import { DebtRecoveryService } from './debt-recovery.service';
import { DebtRecoveryDetailsComponent } from './debt-recovery-details/debt-recovery-details.component';

const routes: Routes = [
  {path: '', redirectTo: 'list', pathMatch: 'full'},
  {path: 'list', component: DebtRecoveryListComponent},
  {path: 'details/:id', component: DebtRecoveryDetailsComponent}
]

@NgModule({
  declarations: [DebtRecoveryListComponent, DebtRecoveryDetailsComponent],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule.forChild(routes),
    SharedModule,
    ReactiveFormsModule
  ],
  // exports: [DebtRecoveryListComponent, DebtRecoveryDetailsComponent],
  providers: [DebtRecoveryService]
})
export class DebtRecoveryModule { }
