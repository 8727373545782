<div style="font-weight: 600;font-size: 17px;" class="pb-2">
    <label>Payment Method</label>
</div>

<!-- card/bank element -->
<div style="border: none;padding: 0;" class="-stripe-card" id="payment-element"></div>

<!-- wallets option if enabled -->
<div id="payment_request_button"></div>

<!-- Address/Billing Element -->
<div *ngIf="!bankType" class="col-md-12">
    <div style="font-weight: 600;font-size: 17px;margin-top: 20px;">
        <label>Billing details</label>
    </div>
    <div id="address-element"></div>
</div>

<div (click)="confirmPaymentSetUp()" class="d-flex justify-content-center">
    <button button-primary-auth class="mb-2 mt-3 px-5 w-auto">Add</button>
</div>
