<div class="p-4">


<app-assignment-tabs></app-assignment-tabs>
<div class="row">
  <div class="col-lg-4 col-12"></div>
  <div class="col-lg-8 col-12 text-end">
    <div class="btn-group" dropdown *ngIf="!_shared.routerAuth.restricted_buttons.export">
      <button id="button-basic" dropdownToggle type="button" class="btn btn-primary rounded dropdown-toggle"
        aria-controls="dropdown-basic">
        Export <span class="caret"></span>
      </button>
      <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu dropdown-menu-right animated flipInX" role="menu"
        aria-labelledby="button-basic">
        <li role="menuitem">
          <button class="dropdown-item" tappable (click)="exportXLSX(0)">Export XLSX</button>
        </li>
        <li role="menuitem">
          <button class="dropdown-item" tappable (click)="exportXLSX(1)">Export CSV</button>
        </li>
      </ul>
    </div>
  </div>

  <div class="col-sm-6 col-md-4">
    <div>
      <label class="fs-14 me-2 m-0">
      
          Select Date Range: 
       
      </label>
      <div class="d-flex align-items-center input_field p-0 overflow-hidden ">
        <input readonly type="text" #rangePicker="bsDaterangepicker" bsDaterangepicker class="input_field border-0 border-end m-0 flex-fill rounded-0"
        [(ngModel)]="dateRangeValue" (ngModelChange)="dateChange()"
        [bsConfig]="bsConfig">
        <span class="bg-transparent border-0 h-100 hw-40 d-flex align-items-center justify-content-center"  *ngIf="!resetBtn" (click)="rangePicker.toggle()"> <i class="fa fa-calendar" ></i></span>
        <span class="bg-transparent border-0 h-100 hw-40 d-flex align-items-center justify-content-center"  *ngIf="resetBtn" (click)="resetDate()"> <i class="fa fa-close"></i></span>
        </div>
    </div>
  </div>
  <div class="col-sm-6 col-md-4">
    <label class="fs-14 fw-700">Search <i tooltip="Search by all field's data" class="fa fa-info-circle"></i></label>
 
    <div class="d-flex align-items-center input_field p-0 overflow-hidden">
      <input type="text" class="input_field border-0 border-end m-0 flex-fill rounded-0" [(ngModel)]="dataArguements.search_string"
      (keyup.enter)="dataArguements.search_string && searchRecords()" placeholder="Type and press enter to search"
      required>
      <span class="bg-transparent border-0 h-100 hw-40 d-flex align-items-center justify-content-center"  *ngIf="dataArguements.search_string || searched==true"  (click)="searched=false;dataArguements.search_string='';resetRecords()"> <i class="fa fa-close"></i></span>
      </div>
  </div>


  <div class="col-md-4 col-sm-6" *ngIf="!_shared.routerAuth.restricted_buttons.admin_list">


    <!-- <div class=" form-group">
          <label>Select Admin</label>
          <ngx-select [items]="_shared.global_admin_list" optionValueField="admin_id" optionTextField="admin_name"
            (select)="selectAdmin($event)" [allowClear]="true" (selectionChanges)="adminClear($event)" placeholder="please enter admin name">
            <ng-template ngx-select-option-selected let-option>
              {{option.data.admin_name + ' ('+option.data.admin_email+')'}}
            </ng-template>
            <ng-template ngx-select-option let-option let-text="text">
              {{option.data.admin_name}} <br>
              <small>{{option.data.admin_email}}</small>
            </ng-template>
            <ng-template ngx-select-option-not-found>
              Not found
            </ng-template>
          </ngx-select>
        </div> -->
  </div>


</div>




<div class="card card-default">

  <div class="card-body">
    <div class="table-responsive"
      *ngIf="leads && leads.paignated_leads && leads.paignated_leads.length; else emptyData">
      <table class="table">
        <thead class="">
          <tr>
            <th>S No.</th>
            <th>Created On</th>
            <th>Lead Info.</th>
            <th>Added By</th>
            <th>Assigned To</th>
            <th>Follow Up</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of leads.paignated_leads; let i=index">
            <td>{{((pageNumber-1)*10)+(i + 1)}}</td>
            <td>{{data.date_added | date: 'MM-dd-yyyy':timezone}}</td>
            <td>
              <span class="text-muted">Name:
              </span>{{data.first_name?data.first_name+' ':''}}{{data.last_name?data.last_name:''}}<br>
              <span class="text-muted">Business: </span>{{data.practice_name || '-'}}<br>
              <span class="text-muted">Email: </span>{{data.practice_email || '-'}}<br>
              <span class="text-muted">Phone: </span>{{data.practice_mobile || '-'}}<br>
              <span class="text-muted">Industry: </span>{{data.ind_name || '-'}}<br>
              <span class="text-muted">Sub Industry: </span>{{data.speciality_name || '-'}}<br>
              <span class="text-muted">Address:
              </span>{{data.practice_address? data.practice_address + ', ':''}}{{data.city? data.city + ', ':''}}
              <br>
              {{data.state_code?data.state_code+', ':''}}{{data.zip?data.zip:''}}
            </td>
            <td>{{data.added_by_name}}</td>
            <td>{{data.assigned_to_name || '-'}}</td>
            <td>
              Date: {{data.followup_date?(data.followup_date | date:'MM-dd-yyyy':'+0000'):'N.A.'}}<br>
              Time: {{data.followup_time?data.followup_time:'N.A.'}}<br>
              Timezone: {{data.followup_timezone?data.followup_timezone:'N.A.'}}
            </td>
            <td>{{data.status_name || '-'}}</td>
            <td><a class="btn btn-primary btn-sm text-light" [routerLink]="'/app/leads/update/'+data.lead_id"
                [queryParams]="{from: 'assign'}">View Details</a></td>
          </tr>
        </tbody>
      </table>
      <div class="row border-top pt-2 align-items-center" *ngIf="leads.paignated_leads.length">
        <div class="col-md-6 col-sm-12">
          <p class="text-bold">
            Showing {{(1+(pageNumber - 1) * 10)}} to {{(leads.paignated_leads.length + (pageNumber - 1) *
                    10)}} of {{leads.number_of_records || 'all'}}
          </p>
        </div>
        <div class="col-md-6 col-sm-12">
          <pagination class="pagination-sm float-md-end" [totalItems]="leads.number_of_records" [maxSize]="5"
            [boundaryLinks]="true" [rotate]="false" (pageChanged)="pageChanged($event)" [(ngModel)]="pageNumber">
          </pagination>
        </div>
      </div>
    </div>
    <ng-template #emptyData>
      <div class="text-center">
        <h4><i class="fa fa-warning fa-5x"></i></h4>
        <h3>No Data Found!</h3>
      </div>
    </ng-template>
  </div>
</div>
</div>