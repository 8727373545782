import { Injectable, ModuleWithProviders, NgModule } from "@angular/core";
import { LoaderComponent } from "./loader/loader.component";
import { CommonModule, DatePipe, DecimalPipe } from "@angular/common";
import { ToasterService } from "./toaster/toaster.service";
import { ToasterContainerComponent } from "./toaster/toaster-container-component";
import { ToasterComponent } from "./toaster/toaster.component";
import { ApiService } from "./api.service";
import { localStorageService } from "./localstorage.service";
import { RouterModule } from "@angular/router";
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgxSelectModule, INgxSelectOptions } from 'ngx-select-ex';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import {PaymentTableComponent} from './payment-table/payment-table.component';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FiltersComponent } from "./filters/filters.component";
import { AddNotesComponent } from "./add-notes/add-notes.component";
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { EditContractModalComponent } from "./edit-contract-modal/edit-contract-modal.component";
import { GoogleZipComponent } from "./google-zip/google-zip.component";
import { NgxGpAutocompleteModule } from "@angular-magic/ngx-gp-autocomplete";
import { Loader } from "@googlemaps/js-api-loader";
import { ViewNotesComponent } from "./view-notes/view-notes.component";
import { GenerateStripeElementComponent } from "./generate-stripe-element/generate-stripe-element.component";
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { CommonNotesListingComponent } from "./common-notes-listing/common-notes-listing.component";
import { NotesListComponent } from "./hidden-notes/notes-list/notes-list.component";
import { AddNoteComponent } from "./hidden-notes/add-note/add-note.component";
import { SpecialNoteComponent } from "./special-note/special-note.component";
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { PaginationModule } from "ngx-bootstrap/pagination";
import { AssignmentTabsComponent } from "./assignment-tabs/assignment-tabs.component";

const CustomSelectOptions: INgxSelectOptions = { // Check the interface for more options
  optionValueField: 'id',
  optionTextField: 'name'
};

@Injectable({
    providedIn: 'root'
})
@NgModule({
    declarations: [LoaderComponent,ToasterComponent,ToasterContainerComponent, PaymentTableComponent, FiltersComponent, AddNotesComponent, EditContractModalComponent, GoogleZipComponent, ViewNotesComponent, GenerateStripeElementComponent, CommonNotesListingComponent, NotesListComponent, AddNoteComponent, SpecialNoteComponent, AssignmentTabsComponent],
    imports: [
      CommonModule,
      NgxMaskDirective,
      RouterModule,
      FormsModule,
      ModalModule.forRoot(),
      NgxSelectModule.forRoot(CustomSelectOptions),
      BsDatepickerModule.forRoot(),
      PopoverModule.forRoot(),
      TooltipModule.forRoot(),
      ReactiveFormsModule,
      ProgressbarModule.forRoot(),
      NgxGpAutocompleteModule,
      BsDropdownModule,
      NgxMaskDirective,
      NgxMaskPipe,
      PaginationModule
    ],
    providers: [localStorageService, ApiService,ToasterService, DatePipe, DecimalPipe,provideNgxMask(),
      {
        provide: Loader,
        useValue: new Loader({
          apiKey: window.location.href.includes('.financemutual.com') ? 'AIzaSyAC-sYjuX1Avr6W_kNtfL06HQtNnWluAvs' :'AIzaSyDfsOQdPjaofT7MKQFzlazJn1rnZANi91w',
          libraries: ['places']
        })
      },provideNgxMask()
    ],
    exports: [LoaderComponent,ToasterContainerComponent, NgxSelectModule, BsDatepickerModule, PopoverModule, TooltipModule,PaymentTableComponent,NgxGpAutocompleteModule, FiltersComponent, AddNotesComponent, EditContractModalComponent, GoogleZipComponent, ViewNotesComponent, GenerateStripeElementComponent, BsDropdownModule, CommonNotesListingComponent, NotesListComponent, AddNoteComponent, SpecialNoteComponent, NgxMaskDirective, NgxMaskPipe, PaginationModule, AssignmentTabsComponent]
})

export class SharedModule {
    static forRoot(): ModuleWithProviders<SharedModule> {
      return {
        ngModule: SharedModule
      };
    }
  }