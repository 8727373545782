import { Component } from '@angular/core';
import {UntypedFormBuilder,UntypedFormGroup,Validators,} from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/components/shared/api.service';
import { localStorageService } from 'src/app/components/shared/localstorage.service';
import { MainService } from 'src/app/components/shared/main.service';
import { SharedService } from 'src/app/components/shared/shared.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  public loginForm: UntypedFormGroup;
  public forgotPasswordForm: UntypedFormGroup;
  hidePwd: boolean = false;
  step = {
    EMAIL: 'EMAIL',
    PASSWORD: 'PASSWORD',
    VERIFY_OTP: 'VERIFY_OTP',
    FORGOT_PASSWORD: 'FORGOT_PASSWORD'
  };
  loginStep = this.step.EMAIL;
  interval: any;
  refreshTimer = '';

  constructor(private fb: UntypedFormBuilder, public _shared: SharedService, private _main: MainService, private _api: ApiService, private _local: localStorageService, private _router: Router) {
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.maxLength(100)]],
      password: ['', [Validators.required,Validators.maxLength(100)]],
      otp: ['', [Validators.required,Validators.maxLength(6)]],
    });
    this.forgotPasswordForm = this.fb.group({
      email: ['', [Validators.required, Validators.maxLength(100)]],
    });
  }

  public login() {
    if (!this.loginForm.value.email) {
      this._shared.customPopups('Please enter valid email', 1);
      return;
    }
    
    if (!this.loginForm.value.password) {
      this._shared.customPopups('Please enter valid password', 1);
      return;
    }

    const data = {
      admin_email: this.loginForm.value.email,
      admin_password: this._shared.crypto(this.loginForm.value.password)
    };

    this._api.api(data, 'auth/email_login').subscribe(response => {
      if (!response.is_error) {
        if (response.reset_password_token){
          if (response.reset_password_token) {
            this._local.customSetItem('token', response.access_token);
            this._local.customSetItem('reset_password_token', response.reset_password_token);
            this._local.customSetItem('reset_email', response.admin_email);
            this._router.navigate(['new-password']);
            return;
          }
        } else {
          this._local.customClearAllItems();
          this._local.customSetItem('token', response.access_token);
          this._local.customSetItem('admin_profile', response.admin_profile ? response.admin_profile[0] : {});
          if (response.menu && response.menu.length) {
            this._main.menu$.next(response.menu);
          }
          this._router.navigate(['app', 'dashboard']);
        }
      }
    })
  }
  forgotPassword() {
    if (this.forgotPasswordForm.invalid) {
      this.forgotPasswordForm.markAsDirty();
      this.forgotPasswordForm.markAllAsTouched();
      return;
    }

    const data = {
      admin_email: this.forgotPasswordForm.value.email
    };

    this._api.api(data, 'auth/forgot_password').subscribe(response => {
      if (!response.is_error) {
        this.forgotPasswordForm.reset();
        this._shared.customPopups('Password has been sent successfully', 0);
        this.loginStep = this.step.EMAIL;
      }
    })
  }
  changeloginStep(type: any) {
    if (type === this.step.PASSWORD || type === this.step.VERIFY_OTP) {
      if (!this.loginForm.controls['email'].value) {
        this.loginForm.markAsDirty();
        this.loginForm.markAllAsTouched();
        return;
      } else {
        if (type === this.step.PASSWORD) {
          this.loginStep = type;
        } else if (type === this.step.VERIFY_OTP) {
          this.loginStep = type;
          this.countDown(90);
        } else {
          console.log('Invalid Type');
        }
      }
    } else if (type === this.step.EMAIL){
      this.loginStep = type;
      this.clearCountDown();
    }
  }
  clearCountDown() {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = null;
      this.refreshTimer = '';
    }
  }
  countDown(time?: any): void {
    let duration: number;
    duration = time;
    this.clearCountDown(); 
    this.interval = setInterval(() => {
      const minutes = parseInt((duration / 60).toString());
      const seconds = parseInt((duration % 60).toString());
      this.refreshTimer = (minutes < 10 ? '0' + minutes : minutes) + 'm' + ':' + (seconds < 10 ? '0' + seconds : seconds) + 's';
      duration--;
      if (duration < 0) {
        this.clearCountDown();
      }
    }, 1000);
  }
}
