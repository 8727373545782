import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/components/shared/api.service';
import { SharedService } from 'src/app/components/shared/shared.service';

@Component({
  selector: 'app-leads-dashboard',
  templateUrl: './leads-dashboard.component.html',
  styleUrl: './leads-dashboard.component.scss'
})
export class LeadsDashboardComponent implements OnInit {

  public dashboard_data: DashboardData = {
    todays_leads: 0,
    total_added_leads: 0,
    deleted_leads: 0,
    unassigned_leads: 0,
    assigned_leads: 0,
    today_duplicate_leads: 0,
    duplicate_leads: 0,
    todays_followups: 0,
    upcoming_followups: 0,
    overdue_followups: 0
  };
  today_date = new Date();

  constructor(public _shared: SharedService, private _datePipe: DatePipe, private _api: ApiService) {}

  ngOnInit(): void {
    this.getDashboardData();
  }

  getFormattedTodayDate(){
    var todayDate = new Date();
    return this.getFormattedDateFromDateObject(todayDate, "yyyy-MM-dd")
  }

  getFormattedYesterdayDate(){
    var todayDate = new Date();
    todayDate.setDate(todayDate.getDate() - 1);
    return this.getFormattedDateFromDateObject(todayDate, "yyyy-MM-dd")
  }

  getFormattedTomorrowDate(){
    let todayDate = new Date();
    todayDate.setDate(todayDate.getDate() + 1);
    return this.getFormattedDateFromDateObject(todayDate, "yyyy-MM-dd")
  }

  getFormattedDateFromDateObject(dateObj: any, dateFormat: any){
    return this._datePipe.transform(new Date(dateObj), dateFormat);
  }

  // get dashboard data
  getDashboardData(){
    this._api.api({}, 'leads/lead_dashboard_data', 'get')
    .subscribe((response: any) => {
      this.dashboard_data = response.result;
      // this.chart_data = this.dashboard_data['pie_chart_data'];
      // this.labels =  _.pluck(this.chart_data, 'label');
      // this.counts = _.pluck(this.chart_data, 'data');
      // this.colors = _.pluck(this.chart_data, 'color');
      // this.doughnutData = {
      //   labels: this.labels,
      //   datasets: [{
      //     data:  this.counts
      //   }]
      // };
      // this.doughnutColors = [{
      //   borderColor: this.colors,
      //   backgroundColor:
      //     this.colors,
      // }];
      // console.log(' this.labels-', this.labels,this.counts, this.doughnutColors)
    });
  }
}

interface DashboardData {
  todays_leads: number,
  total_added_leads: number,
  deleted_leads: number,
  unassigned_leads: number,
  assigned_leads: number,
  today_duplicate_leads: number,
  duplicate_leads: number,
  todays_followups: number,
  upcoming_followups: number,
  overdue_followups: number
}