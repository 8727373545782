<div *ngIf="noteData && noteData.length > 0; else emptyNotes"  style="max-height:500px;overflow:auto;">
  <div class="text-right">
      <button class="btn btn-primary btn-sm" (click)="addNotesModal()">
          Add Note
        </button>
  </div>
  <div class="b mb-1 p-2 rounded note-item" *ngFor="let admin of noteData">
    <div class="d-flex align-items-center mb-1">
      <p class="m-0">
        <strong class="admin_name" *ngIf="admin.admin_name">{{admin.admin_name}}</strong>
        {{admin.date_added | date:'medium':timezone}} {{timezone && timezoneName?'('+timezoneName+')':''}}
      </p>
    </div>
    <p class="speech-bubble">
      <span class="show-enter"><strong>Notes: </strong> {{admin.note}} </span>
    </p>
    <div *ngIf="admin && admin.csr_docs_urls && admin.csr_docs_urls.length>0" class="d-flex align-items-center">
      <a tappable [href]="doc.file_url" target="_blank"
        class="d-flex align-items-center justify-content-center m-1 border bg-dark"
        *ngFor="let doc of admin.csr_docs_urls" style="height: 80px; width: 80px;">
        <img [src]="doc.thumb_url" alt="" class="img-fluid"
          onError="this.src='../../../assets/img/PNG/attachment.png';">
      </a>
    </div>

    <p *ngIf="admin.is_deleted" class="text-danger m-0">This note has been deleted.</p>
  </div>
</div>

<ng-template #emptyNotes>
  <p class="text-center">No Note Available</p>
  <div class="text-center">
    <button class="btn btn-primary" (click)="addNotesModal()">
      Add Note
    </button>
  </div>
</ng-template>