import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import { SharedService } from 'src/app/components/shared/shared.service';

@Component({
  selector: 'app-assignment-tabs',
  // standalone: true,
  // imports: [],
  templateUrl: './assignment-tabs.component.html',
  styleUrl: './assignment-tabs.component.scss'
})
export class AssignmentTabsComponent implements OnInit {
  assignmentType:number=4;
  constructor(
    public router: ActivatedRoute,
    public _shared: SharedService
    ) { }

    ngOnInit() {
      this.router.url.subscribe((url:any)=>{
        this.router.queryParams.subscribe((data:any)=>{
          if(url && url[0] && url[0].path=="assignments"){
            if(data && data.type){
              this.assignmentType = data.type; 
            }else{
              this.assignmentType = 4;
            }
          }else{
            this.assignmentType = 0;
          }
     
        })
      })
     
    }
}
