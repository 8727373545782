

<div class="main-wrapper p-3 pt-1">
    <div class="d-flex justify-content-between px-2 pt- pb-2 border-bottom align-items-center hw-65 w-100">
        <h1 class="m-0 fs-30 fw-600 text-secondary"> Charge Info </h1>
        <button class="btn btn-primary bg-primary rounded-pill" (click)="goBack()">Back</button>
    </div>
    <div class="card card-body">

        <div class="row">
            <div class="col-4">
                <h4 class="fs-14 m-0">
                   {{chargeInfo.is_ppr ? 'Payment ID' : "Contract Code"}}
                </h4>
                
                <p class="fs-14 fw-400 mb-3">
                    {{chargeInfo.is_ppr ? chargeInfo.payment_process_code : chargeInfo.contract_code}}
                </p>
                <h4 class="fs-14 m-0">
                    Total Transferred
                </h4>
                <p class="fs-14 fw-400 mb-3">
                    {{chargeInfo.total_transferred | number}}
                </p>
                <h4 class="fs-14 m-0">
                    Amount Refunded
                </h4>
                <p class="fs-14 fw-400 mb-3">
                    {{chargeInfo.amount_refunded | currency: chargeInfo.display_logo}}
                </p>
                <h4 class="fs-14 m-0">
                    Paid to Paul
                </h4>
                <p class="fs-14 fw-400 mb-3">
                    {{chargeInfo.paid_to_paul | currency: chargeInfo.display_logo}}
                </p>

            </div>
            <div class="col-4">
                <h4 class="fs-14 m-0">
                    Payment Date
                </h4>
                <p class="fs-14 fw-400 mb-3">
                    {{chargeInfo.date_added | date:'MM-dd-yyyy':timezone}}
                </p>
                <h4 class="fs-14 m-0">
                    Payment Type
                </h4>
                <p class="fs-14 fw-400 mb-3">
                    {{chargeInfo.payment_type}}
                </p>
                <h4 class="fs-14 m-0">
                    Paid to Credee
                </h4>
                <p class="fs-14 fw-400 mb-3">
                    {{chargeInfo.paid_to_denefits | currency: chargeInfo.display_logo}}
                </p>
                <h4 class="fs-14 m-0">
                    Paid to Business
                </h4>
                <p class="fs-14 fw-400 mb-3">
                    {{chargeInfo.paid_to_doctor | currency: chargeInfo.display_logo}}
                </p>
            </div>
            <div class="col-4">
                <h4 class="fs-14 m-0">
                    Customer Name
                </h4>
                <p class="fs-14 fw-400 mb-3">
                    {{chargeInfo.patient_title}} {{chargeInfo.patient_name}}
                </p>
                <h4 class="fs-14 m-0">
                    Amount Deducted
                </h4>
                <p class="fs-14 fw-400 mb-3">
                    {{chargeInfo.amount_deducted | currency: chargeInfo.display_logo}}
                </p>
                <h4 class="fs-14 m-0">
                    Paid to Partner
                </h4>
                <p class="fs-14 fw-400 mb-3">
                    {{chargeInfo.paid_to_partner | currency: chargeInfo.display_logo}}
                </p>
                <h4 class="fs-14 m-0">
                    Stripe Fees
                </h4>
                <p class="fs-14 fw-400 mb-3">
                    {{chargeInfo.stripe_fee | currency: chargeInfo.display_logo}}
                </p>
            </div>
        </div>
    </div>
    <div class="d-flex align-items-center mt-2 ">
        <h3 class="fs-24  fw-600 text-secondary">Transfers</h3>
    </div>
    <div class="table-responsive card card-body">
        <table class="table">
            <thead>
                <tr>
                    <th>S No.</th>
                    <th>Amount Reversed</th>
                    <th>Amount Transferred</th>
                    <th>Destination</th>
                    <th>Payout Date</th>
                    <th>Transfer Date</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let transfer of transfers ; let i = index;">
                    <td>{{i + 1}}</td>
                    <td>{{transfer.amount_reversed | currency: chargeInfo.display_logo : '$'}}</td>
                    <td>{{transfer.amount_transferred| currency: chargeInfo.display_logo : '$'}}</td>
                    <td>{{transfer.destination_account}}</td>
                    <td>{{transfer.payout_date!= 'Not paid yet.'?(transfer.payout_date |
                        date:'MM-dd-yyyy':'+0000'):transfer.payout_date}}</td>
                    <td>{{transfer.transfer_date?(transfer.transfer_date | date:'MM-dd-yyyy':'+0000'):'' }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>