import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';
import { BsModalService, ModalDirective } from 'ngx-bootstrap/modal';
import { NgForm, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
// import { LeadsService } from '../../../../core/services/leads/leads.service';
// import { ClaimNotesComponent } from '../../../../shared/claim-notes/claim-notes.component';
// import { GpReverseComponent } from '../../../../shared/gp-reverse/gp-reverse.component';
import { ApiService } from '../api.service';
import { localStorageService } from '../localstorage.service';
import { SharedService } from '../shared.service';

@Component({
  selector: 'app-payment-table',
  templateUrl: './payment-table.component.html',
  styleUrl: './payment-table.component.scss'
})
export class PaymentTableComponent implements OnInit {

  @Input('payment_data') payment_data: any;
  @Input('currency_data') currency_data: any
  @Output() paymentResponse = new EventEmitter();
  @Output() openShowHideClaim = new EventEmitter();
  @Output() openClaimNotes = new EventEmitter();
  missing_payments: any = [];
  future_payments: any = [];
  orp_payments: any = [];
  payments: any = [];
  gp_payments: any = [];
  final_payments: any = [];
  show_future_payment: boolean = false;
  show_missing_payment: boolean = false;
  show_orp_payment: boolean = false;
  show_gp_payment: boolean = true;
  contract: any;
  missing_payment_count: any;
  orp_payment_count: any;
  current_payment_data: any ={
  // current_payment_data: {
  //   dopa_id: '',
  //   depa_id: '',
  //   paul_transfers: Array<[]>,
  //   transfer_id: '',
  //   charge_id: '',
  //   late_fee_refunded: number,
  //   late_fee: 0,
  //   amount_refunded: 0,
  //   payment_type: number,
  //   doctor_transfers: [],
  //   partner_transfers: [],
  //   refunded_date: Date,
  //   date_added: Date,
  //   total_amount_of_partners: number,
  //   pay_to_doctor: number,
  //   pay_to_paul: number,
  //   stripe_status: number,
  //   amount_deducted: number,
  //   doctor_reversed_date: Date,
  //   amount_reversed: number
  // } = {
    dopa_id: '',
    depa_id: '',
    paul_transfers: [],
    transfer_id: '',
    charge_id: '',
    late_fee_refunded: 0,
    late_fee: 0,
    amount_refunded: 0,
    payment_type: 0,
    doctor_transfers: [],
    partner_transfers: [],
    refunded_date: new Date(),
    date_added: new Date(),
    total_amount_of_partners: 0,
    pay_to_doctor: 0,
    pay_to_paul: 0,
    stripe_status : 0,
    amount_deducted: 0,
    doctor_reversed_date: new Date(),
    amount_reversed: 0
  };
  documents: any = [];
  neededDocs: any = [];
  more_docs: any;
  payment_asc: boolean = true;
  payment_desc: boolean = false;
  refund: any = {};
  admin_id: any;
  admin_name: any;
  transfers_array: any = [];
  claimed_payment_count: any;
  gp_recovered_count: any = 0;

  timezone = '';
  timezoneName = '';
  @ViewChild('currentPaymentStatsModal') currentPaymentStatsModal!: ModalDirective;
  @ViewChild('claimNotesModal') claimNotesModal!: ModalDirective;
  @ViewChild('reverseGpModal') reverseGpModal!: ModalDirective;
  @ViewChild('refundModal') refundModal!: ModalDirective;
  constructor(
    private fb: UntypedFormBuilder,
    private _local: localStorageService,
    public _shared: SharedService,
    private _api: ApiService,
    private datePipe: DatePipe,
    public modalService: BsModalService) {
    }

  ngOnInit() {
    this.admin_id = 330 //JSON.parse(this._local.customGetItem('profileData'))[0]['admin_id'];
    this.admin_name = this._local.customGetItem('admin_name');
    this.timezoneName = this._shared.getTimezoneName();
    this.timezone = this._shared.getTimezone();

    this.contract = this.payment_data.contract_data;
    this.future_payments = this.payment_data.future_payments;
    this.missing_payments = this.payment_data.missing_payments;
    this.payments = this.payment_data.payments.filter((data: any) => {
      return (data.payment_status != 'Missed' && data.charge_status != 'Missed') && data.payment_type != 12;
    });
    let missing_count = this.payment_data.payments.filter((data: any) => {
      return (data.payment_status == 'Missed' || data.charge_status == 'Missed') && data.is_orp != 1;
    });
    this.missing_payment_count = missing_count.length;

    let orp_count = this.payment_data.payments.filter((data: any) => {
      return (data.payment_status == 'Missed' || data.charge_status == 'Missed') && data.is_orp == 1;
    });

    this.orp_payments = this.payment_data.payments.filter((data: any) => {
      return (data.payment_status == 'Missed' || data.charge_status == 'Missed') && data.is_orp == 1;
    });

    this.orp_payment_count = orp_count.length;

    this.gp_payments = this.payment_data.payments.filter((data: any) => {
      return data.payment_type == 12;
    });
    let claim_count = this.payment_data.payments.filter((data: any) => {
      return (data.claim_status_id == 1);
    });
    this.claimed_payment_count = claim_count.length;

    let gp_recovered_count = this.payment_data.payments.filter((data: any) => {
      return data.is_gp_recovered && data.is_gp_recovered == 1;
    });
    this.gp_recovered_count = gp_recovered_count.length;
    this.sortPayments('payment_date', 'desc');

    console.log('payment_data---', this.payments, this.missing_payments, this.future_payments);
  }

  sortPayments(key: string, order: string) {
    let new_payment: Array<[]> = [];
    let missed_paid_payments = [];
    let sort_orp_payments = [];
    let sort_payments: Array<[]> = [];
    let missed_orp_payments = [];
    if (key == 'payment_date') {
      new_payment = this._shared.sortByFun(this.payments, 'date_added');
      if (order == 'desc') {
        new_payment.reverse();
      }
    }
    if (this.show_missing_payment) {
      missed_paid_payments = [...new_payment, ...this.missing_payments];
    } else {
      missed_paid_payments = new_payment;
    }

    if (this.show_orp_payment) {
      sort_orp_payments = [...missed_paid_payments, ...this.orp_payments];
    } else {
      sort_orp_payments = missed_paid_payments;
    }
    if (sort_orp_payments && sort_orp_payments.length > 0) {
      missed_orp_payments = sort_orp_payments.filter((data: any) => {
        return (data.payment_status == 'Missed' || data.charge_status == 'Missed');
      });
      if (missed_orp_payments && missed_orp_payments.length > 0) {
        missed_orp_payments = this._shared.sortByFun(missed_orp_payments, 'original_payment_date');
        missed_orp_payments.reverse();
      }
      sort_payments = [...new_payment, ...missed_orp_payments];
    }

    if (this.show_future_payment) {
      this.final_payments = [...sort_payments, ...this.future_payments];
    } else {
      this.final_payments = sort_payments;
    }
  }

  exportPaymentXLSX(ext: any) {
    let list: any = [];
    let payments = [...this.final_payments];
    if (!this._shared.routerAuth.restricted_buttons.superadmin_payment_table) {
      list[0] = {
        date_added: 'Payment Date',
        original_payment_date: 'Original Due Date',
        amount_deducted: 'Amount Deducted',
        charge_status: 'Payment Status',
        amount_refunded: 'Amount Refunded',
        amount_reversed: 'Amount Reversed',
        pt_name: 'Payment Type',
        guaranteed_payment: 'Protected Payment Paid',
        pay_to_doctor: 'Business Share',
        total_amount_of_partners: 'Partner Share',
        stripe_fee: 'Stripe Fee',
        collection_fee: 'Collection Fee',
        late_fee: 'Late Fee',
        claim_status_id: 'Claim Status'
      }
      for (var i = 0; i < payments.length; i++) {
  
        list[i + 1] = {};
        list[i + 1].date_added = this.datePipe.transform(payments[i].date_added, 'MM-dd-yyyy', this.timezone) || '-';
        list[i + 1].original_payment_date = payments[i].payment_type != 18 ? this.datePipe.transform(payments[i].original_payment_date, 'MM-dd-yyyy', '+0000') : '-';
        list[i + 1].amount_deducted = this.currency_data.display_logo + (payments[i].amount_deducted ? (payments[i].amount_deducted.toFixed(2)) : '0.00');
        list[i + 1].charge_status = (payments[i].charge_status ? payments[i].charge_status : payments[i].payment_status) || '-';
        list[i + 1].amount_refunded = this.currency_data.display_logo + ((payments[i].amount_refunded != null || payments[i].amount_refunded != undefined) ? (payments[i].amount_refunded.toFixed(2)) : '0.00');
        list[i + 1].amount_reversed = this.currency_data.display_logo + ((payments[i].total_amount_reversed != null || payments[i].total_amount_reversed != undefined) ? (payments[i].total_amount_reversed.toFixed(2)) : '0.00');
        list[i + 1].pt_name = payments[i].pt_name || '-';
        list[i + 1].guaranteed_payment = this.currency_data.display_logo + (payments[i].guaranteed_payment ? (payments[i].guaranteed_payment.toFixed(2)) : '0.00');
        list[i + 1].pay_to_doctor = this.currency_data.display_logo + (payments[i].pay_to_doctor ? (parseFloat(payments[i].pay_to_doctor).toFixed(2)) : '0.00');
        list[i + 1].total_amount_of_partners = this.currency_data.display_logo + (payments[i].total_amount_of_partners ? payments[i].total_amount_of_partners : '$.00');
        list[i + 1].stripe_fee = this.currency_data.display_logo + (payments[i].stripe_fee ? (parseFloat(payments[i].stripe_fee).toFixed(2)) : '0.00');
        list[i + 1].collection_fee = this.currency_data.display_logo + (payments[i].collection_fee ? (parseFloat(payments[i].collection_fee).toFixed(2)) : '0.00');
        list[i + 1].late_fee = this.currency_data.display_logo + (payments[i].late_fee ? (parseFloat(payments[i].late_fee).toFixed(2)) : '0.00');
        if (this.contract && this.contract.contract_type == 3) {
          payments[i].claim_status_id = '-1';
        }
        switch (parseInt(payments[i].claim_status_id)) {
          case 0: list[i + 1].claim_status_id = 'Not Claimed'; break;
          case 1: list[i + 1].claim_status_id = 'Pending'; break;
          case 2: list[i + 1].claim_status_id = 'Conditionally Approved'; break;
          case 3: list[i + 1].claim_status_id = 'Rejected'; break;
          case 4: list[i + 1].claim_status_id = 'On Hold'; break;
          case 5: list[i + 1].claim_status_id = 'Paid'; break;
          case 6: list[i + 1].claim_status_id = 'Need More Document(s)'; break;
          case 7: list[i + 1].claim_status_id = 'Documents uploaded'; break;
          case 8: list[i + 1].claim_status_id = 'Unpaid'; break;
          default: list[i + 1].claim_status_id = '-'; break;
        }
      }
    } else if (!this._shared.routerAuth.restricted_buttons.manager_payment_table || !this._shared.routerAuth.restricted_buttons.admin_payment_table) {
      list[0] = {
        date_added: 'Payment Date',
        original_payment_date: 'Original Due Date',
        amount_deducted: 'Amount Deducted',
        amount_refunded: 'Amount Refunded',
        charge_status: 'Payment Status',
        pt_name: 'Payment Type',
        pay_to_doctor: 'Business Share',
        pay_to_partner: 'Partner Share',
        amount_reversed_from_business: 'Amount Reversed From Business',
        amount_reversed_from_partner: 'Amount Reversed From Partner'
      }
  
      for (var i = 0; i < payments.length; i++) {
        list[i + 1] = {};
        list[i + 1].date_added = this.datePipe.transform(payments[i].date_added, 'MM-dd-yyyy', this.timezone) || '-';
        list[i + 1].original_payment_date = payments[i].original_payment_date && payments[i].original_payment_date!=null && payments[i].payment_type!=18 ? this.datePipe.transform(payments[i].original_payment_date, 'MM-dd-yyyy', '+0000') : '-';
        list[i + 1].amount_deducted = payments[i].amount_deducted ? '$' + (payments[i].amount_deducted.toFixed(2)) : '$0.00';
        list[i + 1].amount_refunded = (payments[i].amount_refunded != null || payments[i].amount_refunded != undefined) ? '$' + (payments[i].amount_refunded.toFixed(2)) : '$0.00';
        list[i + 1].charge_status = (payments[i].charge_status ? payments[i].charge_status : payments[i].payment_status) || '-';
        list[i + 1].pt_name = payments[i].pt_name || '-';
        list[i + 1].pay_to_doctor = payments[i].pay_to_doctor ? '$' + (parseFloat(payments[i].pay_to_doctor).toFixed(2)) : '$0.00';
        list[i + 1].pay_to_partner = (payments[i].total_amount_of_partners ? '$' + payments[i].total_amount_of_partners : '$0.00');
        list[i + 1].amount_reversed_from_business = (payments[i].doctor_transfers &&  payments[i].doctor_transfers.length>0) ? '$' + ( payments[i].doctor_transfers.reduce((s: any, f: any) => s + f.amount_reversed, 0).toFixed(2)) : '$0.00';
        list[i + 1].amount_reversed_from_partner = (payments[i].partner_transfers &&  payments[i].partner_transfers.length>0) ? '$' + ( payments[i].partner_transfers.reduce((s: any, f: any) => s + f.amount_reversed, 0).toFixed(2)) : '$0.00';
      }
    } else if (!this._shared.routerAuth.restricted_buttons.csr_payment_table) {
      list[0] = {
        date_added: 'Payment Date',
        original_payment_date: 'Original Due Date',
        amount_deducted: 'Amount Deducted',
        charge_status: 'Payment Status',
        pt_name: 'Payment Type',
        pay_to_doctor: 'Business Share'
      }
      let timezone: any = '';
      this._shared.getTimezone().subscribe((timeZone: any) => {
        timezone = timeZone;
      })
      for (var i = 0; i < payments.length; i++) {
        list[i + 1] = {};
        list[i + 1].date_added = this.datePipe.transform(payments[i].date_added, 'MM-dd-yyyy', this.timezone) || '-';
        list[i + 1].original_payment_date = payments[i].original_payment_date && payments[i].original_payment_date!=null && payments[i].payment_type!=18 ? this.datePipe.transform(payments[i].original_payment_date, 'MM-dd-yyyy', '+0000') : '-';
        list[i + 1].amount_deducted = payments[i].amount_deducted ? '$' + (payments[i].amount_deducted.toFixed(2)) : '$0.00';
        list[i + 1].charge_status = (payments[i].charge_status ? payments[i].charge_status : payments[i].payment_status) || '-';
        list[i + 1].pt_name = payments[i].pt_name || '-';
        list[i + 1].pay_to_doctor = payments[i].pay_to_doctor ? '$' + (parseFloat(payments[i].pay_to_doctor).toFixed(2)) : '$0.00';
      }
    }
    console.log('Payments to update::: ', payments);

    // if (ext == 0) {
    //   this.excelService.exportAsExcelFile(list, 'payments', '.xlsx');
    // } else if (ext == 1) {
    //   this.excelService.exportAsExcelFile(list, 'payments', '.csv');
    // }
  }

  openStatForPayment(payment: any) {
    this.current_payment_data = payment;
    this.refund['amount_to_refund'] = '';
    this.refund['details'] = '';
    this.currentPaymentStatsModal.show();
  }

  refundLateFee(modal: any) {
    if (this.refund['amount_to_refund'] && parseFloat(this.refund['amount_to_refund']) == 0) {
      this._shared.customPopups('Amount should be greater than 0', 2);
      return false;
    }
    let errorMessage;
    let late_fee_to_refund;
    let transfer_id;

    this.refund['admin_id'] = parseInt(this.admin_id);
    this.refund['dopa_id'] = this.current_payment_data.dopa_id;
    this.refund['depa_id'] = this.current_payment_data.depa_id;
    this.refund['cd_payment_type'] = 37;
    this.refund['notes'] = this.refund['details'];   //this is add notes field...
    this.refund['name'] = this.admin_name;
    this.refund['contract_id'] = this.contract.contract_id;
    this.refund['pf_id'] = this.contract.pf_id;
    this.refund['payment_type'] = 37;
    late_fee_to_refund = this.refund['amount_to_refund'];

    //get maximum account from paul account array (some times the count is more than one); 
    if (this.current_payment_data && this.current_payment_data.paul_transfers && this.current_payment_data.paul_transfers.length > 0) {
      var maxAmount: any = this.current_payment_data.paul_transfers.reduce(function (prev: any, current: any) {
        return (prev.amount > current.amount) ? prev : current;
      });
      transfer_id = maxAmount.transfer_id;
    } else {
      transfer_id = this.current_payment_data.transfer_id;
    }
    this.refund['transfer_id'] = transfer_id;
    this.refund['amount_to_refund'] = parseInt(late_fee_to_refund);
    this.refund['amount'] = parseInt(late_fee_to_refund);
    this.refund['charge_id'] = this.current_payment_data.charge_id;
    this.refund['previous_late_fee_refunded'] = this.current_payment_data.late_fee_refunded ? (this.current_payment_data.late_fee_refunded) : 0;
    this.refund['previous_stripe_amount_refunded'] = this.current_payment_data.amount_refunded;
    let multipleOfNumber = this.contract.late_fee;
    var multipleRes = this.refund['amount_to_refund'] % multipleOfNumber * 100;
    if (multipleRes != 0) {
      this._shared.customPopups("Please enter multiple of " + multipleOfNumber, 2);
      return false;
    }
    this._api.api(this.refund, 'refund_late_fee_charge')
      .subscribe((data: any) => {
        if (data.is_error == 0) {
          this.paymentResponse.emit();
          this._shared.customPopups('Amount Refunded Successfully', 0);
          modal.hide();
        } else if (data.is_error == 1) {
          if (data.err.type && data.err.type == 'StripeInvalidRequestError') {
            if (data.err.code == 'charge_already_refunded') {
              errorMessage = 'Cann\'t Refund, all amount is already been refunded.';
            }
            else if (data.err.code == 'parameter_invalid_integer') {
              errorMessage = 'Please enter correct amount to refund.';
            } else {
              errorMessage = data.err.message;
            }
            this._shared.customPopups(errorMessage, 2);
          } else if (data.flag == 589) {
            this._shared.customPopups(data.err, 1);
          } else {
            this._shared.customPopups('Enter Valid Amount', 1);
          }
        } else {
          this._shared.customPopups(data.flag, data.is_error);
        }
      }, (error: any) => {
        console.log(error);
      });
    return false;
  }

  setRefundData(data: any) {
    this.refund['dopa_id'] = data.dopa_id;
    this.refund['depa_id'] = data.depa_id;
    this.current_payment_data = data;
    this.refund['reason'] = '-1';
    this.refund['reverse_type'] = '1';
    this.refund['payment_date'] = data.date_added;
    this.resetReverseData();
    if (!this._shared.routerAuth.restricted_buttons.refund_customer && data.pt_name != 'First Payment Reversed' && data.payment_type != 5) {
      this.refund['reverse_type'] = '1';
    }
    // else if (!this._shared.routerAuth.restricted_buttons.reverse_denefits && this.current_payment_data.pay_to_paul && this.current_payment_data.pay_to_paul > 0 && (this.current_payment_data.payment_type == 9 || this.current_payment_data.payment_type == 10 || this.current_payment_data.payment_type == 7)) {
    //   this.refund['reverse_type'] = '2';
    // } 
    else if (!this._shared.routerAuth.restricted_buttons.reverse_business) {
      this.refund['reverse_type'] = '3';
    } else if (!this._shared.routerAuth.restricted_buttons.reverse_partner) {
      this.refund['reverse_type'] = '4';
    } else if (!this._shared.routerAuth.restricted_buttons.reverse_denefits && this.current_payment_data.paul_transfers && this.current_payment_data.paul_transfers.length > 0 && (this.current_payment_data.payment_type != 9 && this.current_payment_data.payment_type != 10 && this.current_payment_data.payment_type != 7)) {
      this.refund['reverse_type'] = '5';
    } else if (!this._shared.routerAuth.restricted_buttons.reverse_all) {
      this.refund['reverse_type'] = '6';
    } else if (!this._shared.routerAuth.restricted_buttons.refund_late_fee) {
      this.refund['reverse_type'] = '7';
    }
    console.log(" this.refund['dopa_id']----", this.refund['dopa_id'], '---', this.refund['depa_id'], 'data:', data);
  }

  resetReverseData() {
    this.refund['amount_data'] = [];
    this.refund['notes_detail'] = [];
    this.refund['transfer_id'] = undefined;
    this.refund['amount'] = undefined;
    this.refund['email'] = undefined;
    this.refund['details'] = '';
  }

  reverseShares(reverseType?: any, reverseAmount?: any, reverseNote?: any, transfer_id?: any) {
    // return false;
    if (this.refund['reverse_type'] > 1 && this.refund['transfer_id'] && this.refund['amount']) {
      this.refund['admin_id'] = parseInt(this.admin_id);
      let cd_payment_type;
      let payment_type;
      let note;

      if (reverseType && reverseType == 3) { // Reverse From Business
        cd_payment_type = 33;
        payment_type = 33;
        note = this.refund.details ? this.refund.details : '';
      }
      if (reverseType && reverseType == 4) { // Reverse From Partner
        cd_payment_type = 34;
        payment_type = 34;
        note = this.refund.details ? this.refund.details : '';

        if (reverseNote) {
          note = reverseNote;
        } if (reverseAmount) {
          this.refund['amount'] = reverseAmount;
        } if (transfer_id) {
          this.refund['transfer_id'] = transfer_id;
        }
      }
      if (this.refund['amount'] && parseFloat(this.refund['amount']) == 0) {
        this._shared.customPopups('Amount should be greater than 0', 2);
        return false;
      }
      this.refund['cd_payment_type'] = cd_payment_type;
      this.refund['notes'] = note;   //this is add notes field...
      this.refund['name'] = this.admin_name;
      this.refund['contract_id'] = this.contract.contract_id;
      this.refund['payment_type'] = payment_type;
      delete this.refund['amount_to_refund'];
      console.log('this.refund--------------', this.refund);

      this._api.api(this.refund, 'reverse_transfer')
        .subscribe((data: any) => {
          if (data.is_error == 0) {
            this.resetReverseData();
            this.paymentResponse.emit();
            this._shared.customPopups('Amount Reversed Sucessfully', 0);
          } else if (data.is_error == 1 && data.original_text) {
            this._shared.customPopups('Enter Valid Amount', 1);
          } else {
            this._shared.customPopups(data.is_error, 1);
          }
        });
    }
    return;
  }

  reverseForUnpaidDoctors() {
    // console.log('form---', form, this.refund);
    if (this.refund['amount'] && parseFloat(this.refund['amount']) == 0) {
      this._shared.customPopups('Amount should be greater than 0', 2);
      return false;
    }
    if (this.refund['dopa_id'] && this.refund['amount']) {
      let reverse_data = {
        admin_id: parseInt(this.admin_id),
        contract_id: parseInt(this.contract.contract_id),
        dopa_id: this.refund['dopa_id'],
        amount_reversed: this.refund['amount'],
        cd_payment_type: 44,
        notes: this.refund.details ? this.refund.details : '',   //this is add notes field...
        name: this.admin_name,
        payment_type: 44,
        payment_date: this.refund['payment_date']
      }
      this._api.api(reverse_data, 'reverse_for_unpaid_doctors')
        .subscribe((data: any) => {
          this._shared.customPopups(data.is_error, 1);
          if (data.is_error == 0) {
            this.resetReverseData();
            this.paymentResponse.emit();
          }
        });
    }
    return;
  }

  reverseForUnpaidPartners() {
    if (this.refund['amount'] && parseFloat(this.refund['amount']) == 0) {
      this._shared.customPopups('Amount should be greater than 0', 2);
    } else {
      if (this.refund['papa_id'] && this.refund['amount']) {
        let reverse_data = {
          admin_id: parseInt(this.admin_id),
          contract_id: parseInt(this.contract.contract_id),
          papa_id: this.refund['papa_id'],
          amount_reversed: this.refund['amount'],
          cd_payment_type: 35,
          notes: this.refund.details ? this.refund.details : '',   //this is add notes field...
          name: this.admin_name,
          payment_type: 35,
          payment_date: this.refund['payment_date']
        }
        this._api.api(reverse_data, 'reverse_for_unpaid_partners')
          .subscribe((data: any) => {
            this._shared.customPopups(data.is_error, 1);
            if (data.is_error == 0) {
              this.resetReverseData();
              this.paymentResponse.emit();
            }
          });
      }
    }
  }

  refundCharge() {
    if (this.refund['reason'] == "-1") {
      this._shared.customPopups('Please select reason', 2);
    } else if (this.refund['amount_to_refund'] && parseFloat(this.refund['amount_to_refund']) == 0) {
      this._shared.customPopups('Amount should be greater than 0', 2);
    } else {
      this.refund['refund_type'] = 1;
      this.refund['cd_payment_type'] = 30;
      this.refund['notes'] = this.refund.details;   //this is more details about the Refund...
      this.refund['admin_id'] = this.admin_id;
      this.refund['name'] = this.admin_name;
      this.refund['contract_id'] = this.contract.contract_id;
      this.refund['payment_type'] = 30;
      this._api.api(this.refund, 'refund_charge')
        .subscribe((data: any) => {
          if (data.is_error == 0) {
            this._shared.customPopups(data.is_error, 1);
            this.refundModal.hide();
            this.paymentResponse.emit();
          }
          if (data.is_error == 1) {
            console.log('Please enter valid amount...');
            this._shared.customPopups('Enter Valid Amount', 2);
          }
        });
    }
  }

  reverseAll(data: any, totalAmount?: any) {
    let unpaid_reverse_array: any = [];
    let unpaid_doctor_reverse_array: any = [];
    if (data || (data.doctor_transfers && data.doctor_transfers.length > 0) || (data.partner_transfers && data.partner_transfers.length > 0)) {
      if (data.partner_transfers && data.partner_transfers.length > 0) {
        data.partner_transfers.forEach((item: any) => {
          if (item.transfer_id && item.stripe_status == 0) {
            this.transfers_array.push(item.transfer_id);
          } else {
            unpaid_reverse_array.push(item.papa_id);
          }
        })
      }
      if (data.doctor_transfers && data.doctor_transfers.length > 0) {
        data.doctor_transfers.forEach((item: any) => {
          if (item.transfer_id) {
            this.transfers_array.push(item.transfer_id);
          } else {
            unpaid_doctor_reverse_array.push(item.dopa_id);
          }
        })
      }

      let reverse_data = {
        admin_id: parseInt(this.admin_id),
        contract_id: parseInt(this.contract.contract_id),
        transfers_array: this.transfers_array,
        unpaid_transfer_array: unpaid_reverse_array.length > 0 ? unpaid_reverse_array : undefined,
        unpaid_doctor_reverse_array: unpaid_doctor_reverse_array.length > 0 ? unpaid_doctor_reverse_array : undefined,
        details: this.refund.details,
        notes: this.refund.details,    //this is more details about the Refund...
        cd_payment_type: 31,
        payment_type: 31,
        name: this.admin_name,
        amount: totalAmount ? totalAmount : 0
      }

      if (!reverse_data.notes || reverse_data.notes.length <= 0) {
        this._shared.customPopups('Please add notes', 1);
        return false;
      }
      this._api.api(reverse_data, 'reverse_all_transfers')
        .subscribe((data: any) => {
          this._shared.customPopups(data.is_error, 1);
          if (data.is_error == 0) {
            this.transfers_array = [];
            this.refundModal.hide();
            // form.resetForm();
            this.paymentResponse.emit();
          }
        });
    }
    return;
  }

  sendPaymentResponse() {
    this.paymentResponse.emit();
  }

  setTransactionData(id: any, amount: any, email?: any, papa_id?: any) {
    this.refund['transfer_id'] = id;
    this.refund['amount'] = amount;
    this.refund['email'] = email;
    this.refund['papa_id'] = papa_id;
  }

  openClaimVisibilityPopup(payment: any) {
    this.openShowHideClaim.emit(payment);
  }

  openClaimNotesPopup(payment: any) {
    console.log('payment--', payment);
    this.openClaimNotes.emit(payment);
  }
}
