<div style="font-weight: 600;font-size: 17px;">
    <label>Payment Method</label>
</div>

<!-- card/bank element -->
<div style="border: none;padding: 0;" class="-stripe-card" id="payment-element"></div>

<!-- wallets option if enabled -->
<div id="payment_request_button"></div>

<!-- Address/Billing Element -->
<div *ngIf="!bankType" class="col-md-12">
    <div style="font-weight: 600;font-size: 17px;margin-top: 20px;"><label>Billing details</label></div>
    <div id="address-element"></div>
</div>

<div (click)="confirmPaymentSetUp()">
    <button button-primary-auth class="px-5 w-100 mt-3">Add</button>
</div>

