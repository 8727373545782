<div class="main-wrapper p-3">
    <div class="d-flex justify-content-between px-2 pt-2 border-bottom pb-2 align-items-center hw-65 w-100">
        <h1 class="m-0 fs-30 fw-600 text-secondary"> Card Mismatched Names</h1>
        <span class="btn btn-primary rounded py-2 fs-18 fw-700">Total records :
            {{resData.totalRecords || 0}}</span>
    </div>
    <div class="card card-body m-0 flex-fill">
        <div class="table-responsive">
            <table class="table">
                <thead>
                    <tr>
                        <th>S No.</th>
                        <th>Contract ID</th>
                        <th>Amount</th>
                        <th>Payment Type</th>
                        <th>Cradholder Name</th>
                        <th>Customer Name</th>
                        <th>Contract Customer Name</th>
                        <th>Customer Email</th>
                        <th>Payment Date</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let listObj of cardHolderNameMismatchListing; let i=index;">
                        <td>{{((resData.pageNumber-1)*10)+(i+1)}}</td>
                        <td>
                            <a [routerLink]="'/app/contracts/details/'+listObj.contract_id">{{listObj.contract_id}}

                            </a>
                        </td>
                        <td>
                            {{listObj.raw_json && listObj.raw_json.amount}}
                        </td>
                        <td>
                            {{listObj.raw_json && listObj.raw_json.payment_type}}
                        </td>
                        <td>
                            <span>{{listObj.raw_json && listObj.raw_json.card_holder_name}}</span>
                        </td>
                        <td>
                            <span>{{listObj.patient_first_name}}{{listObj.patient_last_name}}</span>
                        </td>
                        <td>
                            <span>{{listObj.raw_json && listObj.raw_json.contractFullName}}</span>
                        </td>
                        <td>
                            <span>{{listObj.patient_email}}</span>
                        </td>
                        <td>
                            <span>{{listObj.created_at | date:'MM-dd-yyyy': timezone}}</span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="row border-top pt-2 align-items-center  w-100" *ngIf="cardHolderNameMismatchListing && cardHolderNameMismatchListing.length">
            <div class="col-md-6 col-sm-12">
                <p class="text-bold">
                    Showing {{(1+(resData.pageNumber - 1) * 10)}} to {{(cardHolderNameMismatchListing.length + (resData.pageNumber - 1) *
                    10)}} of {{resData.totalRecords || 'all'}}
                </p>
            </div>
            <div class="col-md-6 col-sm-12">
                <pagination class="pagination-sm float-md-end" [totalItems]="resData.totalRecords" [maxSize]="5"
                    [boundaryLinks]="true" [rotate]="false" (pageChanged)="pageChanged($event)"
                    [(ngModel)]="resData.pageNumber">
                </pagination>
            </div>

        </div>
    </div>
    <div class="col-12 text-center mt-3" *ngIf="!cardHolderNameMismatchListing || !cardHolderNameMismatchListing.length">
        <img src="./assets/img/SVG/empty/patients.svg">
        <h4 style="color:#8592a5">No data Found</h4>
    </div>
</div>