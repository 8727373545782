
           <div class="row details">
             <div class="col-sm-6">
               <label>Service Amount</label>
               <h6> {{contract.display_logo}} {{contract.treatment_amount }}</h6>
             </div>
             <div class="col-sm-6">
               <label>Financed Amount</label>
               <h6>{{contract.display_logo}} {{contract.financed_amount }}</h6>
             </div>
             <div class="col-sm-6">
               <label>{{(contract.contract_type == 5 && contract.downpayment_amount > 0) ? 'Partial Payment Amount' : 'Down Payment Amount'}}</label>
               <h6>{{contract.display_logo}} {{contract.downpayment_amount }}</h6>
             </div>
             <div class="col-sm-6" *ngIf="contract.contract_type != 6">
               <label>Interest Rate</label>
               <h6>{{(contract.interest_rate_to_show || contract.interest_rate) ? (contract.interest_rate_to_show || contract.interest_rate)+'%' : 'No Interest'}}</h6>
             </div>
             <div class="col-sm-6" *ngIf="contract.contract_type == 6 && contract.di_interest_rate > 0">
              <label>Interest Rate</label>
              <h6>{{contract.di_interest_rate}}%</h6>
            </div>
             <div class="col-sm-6" *ngIf="contract.contract_type != 6">
               <label>Recurring Amount</label>
               <h6>
                 <span>{{contract.display_logo}}{{contract.recurring_amount}}</span>
                 <span *ngIf="_contract.GetTransactionFee(contract,contract.recurring_amount)">+
                  {{_contract.GetTransactionFee(contract,contract.recurring_amount)}}</span>
               </h6>
             </div>
             <div class="col-sm-6">
               <label>Fixed Credee Fee</label>
               <h6>{{contract.display_logo}}{{contract.enrollment_fee ? (contract.enrollment_fee ) : ('0') }}</h6>
             </div>
             <div class="col-sm-6">
               <label>Number of Payments</label>
               <h6>{{contract.number_of_payments}}</h6>
             </div>
             <div class="col-sm-6">
               <label>Remaining Payments</label>
               <h6>{{contract.remaining_payments}}</h6>
             </div>
             <div class="col-sm-6" *ngIf="contract.pf_status==3">
               <label>Number of missing payments</label>
               <h6>{{contract.missing_payments}}</h6>
             </div>
             <div class="col-sm-6" *ngIf="contract.pf_status==3">
               <label>Overdue Amount</label>
               <h6>{{contract.display_logo}}{{contract.overdue_amount }}
                <span *ngIf="_contract.GetTransactionFee(contract,contract.recurring_amount)">+ {{_contract.GetTransactionFee(contract,contract.recurring_amount)}}</span>
                 <span> x {{contract.missing_payments}} Missing Payments </span>
               </h6>
             </div>
             <div class="col-sm-6" *ngIf="contract.missing_payments>=0">
               <label>Maximum Chargeable Late Fees</label>
               <h6>{{contract.display_logo}}{{contract.late_fee || ('0') | number }} x {{contract.missing_payments}}</h6>
             </div>
             <div class="col-sm-6">
               <label>Enrollment Date</label>
               <h6>{{contract.date_added | date:"MM/dd/yyyy" : _contract.timezone }}</h6>
             </div>
             <div class="col-sm-6">
               <label>Next Payment Date</label>
               <!--PASSING DATE OFFSET IN UTC TO DISPLAY AS IT IS-->
               <h6>{{contract.next_payment_date | date:"MM/dd/yyyy": '+0000'}}</h6>
             </div>
             <!-- <div class="col-sm-6">
               <label>Expected Monthly Payout to Business</label>
               <h6>{{contract.expected_monthly_pay_to_doctor||'0' }}</h6>
             </div>
             <div class="col-sm-6">
               <label>Total Expected Payout to Doctor</label>
               <h6>{{contract.doctor_expected_payout||'0' }}</h6>
             </div> -->
             <div class="col-sm-6" *ngIf="contract.missing_payments>0">
               <label>Late Fees </label>
               <h6>{{contract.display_logo}}{{contract.late_fee || ('0') }}</h6>
             </div>
             <!-- <div class="col-sm-6">
               <label>Paid to Doctor - Till Date</label>
               <h6>{{contract.paidToDoctor||'0' }}</h6>
             </div> -->
             <div class="col-sm-6">
               <label>Total Remaining Amount</label>
               <h6>{{contract.display_logo}}{{contract.remaining_amount || ('0')| currency: contract.display_logo }}</h6>
             </div>
             <div class="col-sm-6">
               <label>Contract Edited Count</label>
               <h6>{{contract.edit_contract_count}}</h6>
             </div>
             <div class="col-sm-12" *ngIf="contract.pf_status<4 && contract.contract_type != 6">
              <label>Total Balance Remaining (Including Transaction & Late Fees, if any)</label>
              <h6><span *ngIf="contract.missing_payments">1.
                </span>({{contract.display_logo}}{{contract.recurring_amount}}
                <span *ngIf="_contract.GetTransactionFee(contract,contract.recurring_amount)" class="font-size-16">+
                 {{_contract.GetTransactionFee(contract,contract.recurring_amount)}}</span>
                <span> ) x {{contract.remaining_payments}} Remaining Payments</span>
              </h6>
              <h6 *ngIf="contract.missing_payments">2.
                ({{contract.display_logo}}{{contract.late_fee | number}} Late Fees) x
                {{contract.missing_payments}} Missing Payments</h6>
    
              <h6>Total:
                {{contract.display_logo}}{{_contract.total_balance_remaining}}
              </h6>
            </div>

             <div class="col-sm-12" *ngIf="contract.contract_type == 6">
               <label>Total Balance Remaining (Including Transaction & Late Fees, if any)</label>
               <span *ngIf="contract.contract_type == 6">During Deferred Duration</span>
               <h6><span *ngIf="contract.missing_payments">1.
                 </span>({{contract.display_logo}}{{contract.recurring_amount == contract.next_recurring_amount ? contract.principal_per_recurring : contract.recurring_amount}}
                 <span *ngIf="_contract.GetTransactionFee(contract,contract.recurring_amount)" class="font-size-16">+
                  {{_contract.GetTransactionFee(contract,contract.recurring_amount)}}</span>
                 <span> ) x {{contract.remaining_payments}} Remaining Payments</span>
               </h6>
               <h6 *ngIf="contract.missing_payments">2.
                 ({{contract.display_logo}}{{contract.late_fee | number}} Late Fees) x
                 {{contract.missing_payments}} Missing Payments</h6>
     
               <h6>Total:
                {{contract.display_logo}}{{_contract.total_balance_remaining }}
               </h6>
               <h6 *ngIf="contract.pf_status < 4">Total Remaining Amount:
                {{contract.display_logo}}{{_contract.remaining_amount_with_def||'0'}}
              </h6>
             </div>

             <div class="col-sm-12" *ngIf="contract.contract_type == 6">
              <label>Total Balance Remaining (Including Transaction & Late Fees, if any)</label>
              <span>After Deferred Duration</span>
              <h6><span *ngIf="contract.missing_payments">1.
                </span>({{contract.display_logo}}{{contract.next_recurring_amount}}
                <span *ngIf="_contract.GetTransactionFee(contract,contract.recurring_amount)" class="font-size-16">+
                 {{_contract.GetTransactionFee(contract,contract.next_recurring_amount)}}</span>
                <span> ) x {{contract.remaining_payments - _contract.def_duration}} Remaining Payments</span>
              </h6>
              <h6 *ngIf="contract.missing_payments">2.
                ({{contract.display_logo}}{{contract.late_fee | number}} Late Fees) x
                {{contract.missing_payments}} Missing Payments</h6>
    
              <h6>Total:
                {{contract.display_logo}}{{_contract.total_balance_remaining_after_def }}
              </h6>
              <h6 *ngIf="contract.pf_status < 4">Total Remaining Amount:
                {{contract.display_logo}}{{_contract.remaining_amount_after_def||'0'}}
              </h6>
            </div>

           </div>


       