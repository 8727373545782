import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, NgZone, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService, ModalDirective } from 'ngx-bootstrap/modal';
import { ApiService } from 'src/app/components/shared/api.service';
import { MainService } from 'src/app/components/shared/main.service';
import { SharedService } from 'src/app/components/shared/shared.service';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { localStorageService } from 'src/app/components/shared/localstorage.service';
import { AddNotesComponent } from 'src/app/components/shared/add-notes/add-notes.component';
import { DoctorStatsComponent } from 'src/app/components/shared/doctor-stats/doctor-stats.component';
import { ContractService } from '../contracts.service';
import { CompressImageService } from 'src/app/components/shared/compress-image.service';
import { EditContractModalComponent } from 'src/app/components/shared/edit-contract-modal/edit-contract-modal.component';
import { ContractModalComponent } from '../contract-modal/contract-modal.component';
import { GenerateStripeElementComponent } from 'src/app/components/shared/generate-stripe-element/generate-stripe-element.component';
import { take } from 'rxjs';

@Component({
  selector: 'app-contracts-details',
  templateUrl: './contracts-details.component.html',
  styleUrl: './contracts-details.component.scss'
})
export class ContractsDetailsComponent {

  @ViewChild('fileUpload') fileUpload!: ElementRef;
  arrangement_time_slot_type: any;  // arragement time slot
  settlement_arrangement_time_slot_type: any = [];  // settlement arragement time slot

  id: any;
  claimId: any;
  contract: any;
  contractDetails: any;
  payments: any = [];
  future_payments: any;
  gpInfo: any;
  notesList: any;
  is_error: boolean = false;
  minDate: any = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 1);
  partnerListAmount: any = {};
  linkPartnerWithDoctorSend: boolean = false;
  lateFeeCharge: any = 25;
  // waiver = {
  //   allow_waiver: false,
  //   bypass_waiver: false,
  //   bypass_notes: ''
  // }
  currencyData = {
    bank_support_type: ['us_bank_account'],
    currency_code: 1,
    currency_id: "US Dollar",
    display_logo: "$"
  }
  countryData = {
    country_code: "US",
    country_date_format: "MM/dd/yyyy",
    country_id: 1,
    country_name: "United States",
    country_phone_format: "###-###-####",
    mobile_code: "+1"
  }
  late_fee: any;

  allPayments: any;
  num_pages: any;
  pageLength: any;
  paymentPages: any = [];
  lastPageFooterPx: any;
  lastPageNum: any;
  lastPageHeightLeft: any;
  doctorCards: any = [];

  currentYear = new Date().getFullYear();
  currentMonth = new Date().getMonth() + 1;
  missingDetails: any = [];
  doctorStats: any = {};
  claimNotes: any = [];
  claimInfo: any = [];
  documents: any = [];
  neededDocs: any = [];
  more_docs: any;
  notes: any = [];

  claimPayment: any;
  doctorAmount: any;
  timezone: any;
  timezoneName: any;
  bsModalRef!: BsModalRef;
  admin_id: any;
  send_customer_notification = false;
  continue_deferred_benefits = false;
  total_balance_remaining!: number;
  array: any = [];


  showNewNotes: number = 1;
  reminder_type = '';
  reminder_data: { reminder_id: string, email: boolean, mobile: boolean };
  admin_name: any;
  refund: any = { reason: "" };
  isPaymentTabActive = true;
  claimDoc: any = [];
  cur_payment_obj: any = {};
  status: any;
  arrangement_date = new Date();
  today_date: any = new Date();
  today: any = new Date();
  endDate = this.datePipe.transform(this.today, 'yyyy-MM-dd');
  startDate = '2024-03-01';
  expired_reason: any;
  settlement_data: any;
  action_active = false;
  receiveAmount!: number;

  charge: any = { cancellationCharge: 30 };
  recurring: any = { recurringAmount: 10 };

  cancel_note: any;

  fixedTransactionFee!: number;
  isFixedTransactionFee!: boolean;

  variableTransactionFee!: number;
  isVariableTransactionFee!: boolean;

  future_payment: boolean = false;
  paidToDoctor = 0;
  arrangementTimeSlot: any;
  arrangement: any;
  settlement_arrangement: any;
  settlement_treatment_amount: any;
  settlement_amount: any;
  action_json: any;
  disable_action: any = [];
  bank_accounts: any = [];
  verification_copied_details = '';
  verification_details: any;
  business_money: any = {
    moneyValueNote: '',
    moneyValue: 0,
    amount: 0,
    customer_pt_name: 'Cheque Payment',
    business_pt_name: 'Cheque Payment',
    show_to_customer: true,
    amount_deducted: 0
  }
  popoverColorChange!: string;

  deduct_one_dollar = true;
  choose_backup = true;

  contract_created_from: String = "";

  amount1 = [];
  allMethods: any = [];

  missingTransfers: any = [];
  tempVariableTransactionFee: any;
  tempFixedTransactionFee: any;
  contract_transaction_fee: any = {
    recurring_extra_amount: 0,
    recurring_extra_percent: 0,
    threshold_amount: 0,
    threshold_recurring_extra_amount: 0,
    threshold_recurring_extra_percent: 0,
    note: '',
    contract_id: '',
    access_token: ''
  };

  dnd_checks: any = {
    dnd_patient_email: '',
    dnd_patient_mobile: '',
    dnd_doctor_email: '',
    dnd_doctor_mobile: '',
    dnd_employer_email: '',
    dnd_employer_mobile: ''
  };

  @ViewChild("bankruptcyModal") bankruptcyModal!: ModalDirective;
  @ViewChild("afterDeferredDetailsModal") afterDeferredDetailsModal!: ModalDirective;
  @ViewChild("changeContractServiceBreakdownDetailsModal") changeContractServiceBreakdownDetailsModal!: ModalDirective;

  @ViewChild("changeClaimServiceBreakdownDetailsModal") changeClaimServiceBreakdownDetailsModal!: ModalDirective;
  @ViewChild("addNotesModal") addNotesModal!: ModalDirective;
  @ViewChild("payToDoctorModal") payToDoctorModal!: ModalDirective;



  achNote: any;
  updateAcc: any = {};
  user_phone1: any;
  user_email1: any;
  verify_link_phone: any;
  verify_link_email: any;
  bank_data: any = {};
  plaid_accounts: any = [];
  deductions = [];
  selected_payment = {
    missing_payment_id: null,
    force_claim_open: 0
  };
  make_default_notes = '';
  contract_changes = {
    edit_treatment_amount: 0,
    treatment_amount: null,
    edit_finance_amount: 0,
    finance_amount: null,
    edit_number_of_payments: 0,
    number_of_payments: null,
    edit_remaining_payments: 0,
    remaining_payments: null,
    edit_recurring_amount: 0,
    recurring_amount: null,
    edit_principal_per_recurring: 0,
    principal_per_recurring: null,
    edit_downpayment_amount: 0,
    downpayment_amount: null,
    edit_payment_date: 0,
    payment_date: null,
    edit_pf_status: 0,
    pf_status: null,
    cancelled_date: null,
    reverse_first_recurring: 0,
    notes: ''
  }
  newNotes = '';
  service_breakdown = [];
  service_notes = '';
  min_service_date = new Date(2024, 0, 1);
  max_service_date = new Date();
  arrangement_active_count: any = 0;
  actual_edit_recurring_amount = 0;
  waiverObject: any;
  contract_service_breakdown: any = [];
  contract_service_notes = '';
  imageUploaderDL: any;
  show_old_payment_table: any = false;
  show_new_payment_table: any = true;
  card_notes = '';
  add_consent_form: any = true;
  load_notes = false;
  subscription: any;
  subscription1: any;
  subscription2: any;
  settlement_values = [];
  paid_settlement_amount = [];
  total_paid: any;
  step: any;
  ContractSettlementModal2: any;
  ModalRef!: BsModalRef;
  patient_email: any;
  patient_name: any;
  patient_mobile: any;
  contract_id: any;
  is_valid_amt: boolean = true;
  contract_intimation: any;
  language_data: any;
  selectedLanguage: any;
  bank_support_type = true;
  cronPayment: any;
  showCards: boolean = true;
  selected_verification = '';

  isArrangementError: boolean = false;
  isArrangementButtonPressed: boolean = false
  arrangementFormError = ""
  admin_profile: any;
  config = {
    backdrop: true,
    class: 'modal-md modal-dialog-centered',
    ignoreBackdropClick: true
  };
  reason_array: any = []
  reject_reason: any
  totalCustomArrangementPayable: any;
  totalRecurringAmount: any;
  /* Get Ticket Comments */
  latestTicketComment: any = [];
  idExist = false;
  // open ticket dropdown state
  dpOpen: boolean = false;
  ticketCount: number = 0;
  constructor(
    private route: ActivatedRoute,
    // private contractService: ContractService,
    public mainService: MainService,
    public cd: ChangeDetectorRef,
    private location: Location,
    private datePipe: DatePipe,
    // private leadService: LeadsService,
    private modalService: BsModalService,
    public zone: NgZone,
    public _shared: SharedService,
    private _api: ApiService,
    private _local: localStorageService,
    public _contract: ContractService,
    // public excelService: ExcelService,
    private compressImage: CompressImageService

  ) {
    this.reminder_data = { reminder_id: '', mobile: false, email: false };
    this.admin_profile = this._local.customGetItem('profileData')
  }


  goBack() {
    this.location.back();
  }

  ngOnInit() {
    this.timezoneName = this._shared.getTimezoneName();
    this.timezone = this._shared.getTimezone();
    let datePipeTrans: any = this.datePipe.transform(new Date().toISOString(), 'short', this.timezone)
    if (datePipeTrans) {
      datePipeTrans = new Date(datePipeTrans)
    }
    let arrangement_date = datePipeTrans;
    const today_date = datePipeTrans;
    if (this.arrangement_date) {
      this.arrangement_date = arrangement_date;
      if (this.arrangement_date.getDate()) {
        this.arrangement_date.setDate(this.arrangement_date.getDate() + 1);
      }
    }
    if (this.today_date) {
      this.today_date = today_date;
    }
    this.today_date.setDate(this.today_date.getDate() + 1);
    this.id = this.route.snapshot.paramMap.get('id');
    this.claimId = this.route.snapshot.queryParams['claim_id'];
    this.admin_id = '330' // JSON.parse(this._local.customGetItem('profileData'))[0]['admin_id'];
    this.admin_name = this._local.customGetItem('admin_name');
    this.today_date.setDate(this.today_date.getDate() + 1);
    this.subscription = this.route.queryParams.subscribe(() => {
      this.getContractInfo(this.id, this.isPaymentTabActive);
    });
    // this.subscription1 = this.plaidService.plaid_error_subject.subscribe((error: any) => {
    //   this.getContractInfo(this.id, this.isPaymentTabActive);
    // });

    // this.subscription2 = this.plaidService.plaid_success_subject.subscribe((value: any) => {
    //   this.getContractInfo(this.id, this.isPaymentTabActive);
    // });

    //this.fetchTicketTypes();

    this._shared.getlanguage_data().subscribe((data: any) => {
      if (data && data.length) {
        this.language_data = data
      }
    })
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.subscription1) {
      this.subscription1.unsubscribe();
    }
    if (this.subscription2) {
      this.subscription2.unsubscribe();
    }
  }

  // get payments
  async getPaidPaymentsTable() {
    return new Promise((resolve, reject) => {
      const data = {
        contract_id: this.id
      };
      this._api.api(data, 'finance/get_paid_payments', 'get').subscribe(response => {
        if (!response.is_error) {
          resolve(response.payments);
        } else {
          resolve([]);
        }
      })
    })
  }

  // get future payments
  async getFuturePaymentsTable() {
    return new Promise((resolve, reject) => {
      const data = {
        contract_id: this.id
      };
      this._api.api(data, 'finance/get_future_payments', 'get').subscribe(response => {
        if (!response.is_error) {
          resolve(response.future_payments);
        } else {
          resolve([]);
        }
      })
    })
  }

  // get future payments
  async getMissingPaymentsTable() {
    return new Promise((resolve, reject) => {
      const data = {
        contract_id: this.id
      };
      this._api.api(data, 'finance/get_missing_payments', 'get').subscribe(response => {
        if (!response.is_error) {
          resolve(response.missing_payments);
        } else {
          resolve([]);
        }
      })
    })
  }

  // getPaymentSources
  async getPaymentSources(patient_id: number) {
    return new Promise((resolve, reject) => {
      const data = {
        patient_id
      };
      this._api.api(data, 'finance/get_customer_payment_source', 'get').subscribe(response => {
        if (!response.is_error) {
          resolve(response.payment_sources);
        } else {
          resolve([]);
        }
      })

    })
  }

  //  Get Contract Details
  async getContractInfo(id: any, tab: any) {
    let url;
    if (this.contract && this.contract.reminder_data) {
      console.log(this.contract);

      this.contract.reminder_data.fetchReminderTemplate = []
    }
    url = 'finance/get_contract_info';
    let data = {
      contract_id: id,
    };
    this._api.api(data, url, 'get')
      .subscribe(async (res: any) => {
        this.cronPayment = res && res.contractInfo && res.contractInfo[0]
        // this.sms_subscription_status = this.cronPayment.sms_subscription_status
        this.arrangementTimeSlot = res.arrangement_cron_slots;
        if (!res.contractInfo || !res.contractInfo[0].contract_id) {
          this.is_error = true;
          this.contract = {};
          this.payments = [];
        } else {
          this.load_notes = false;
          this.contract = res && res.contractInfo && res.contractInfo[0];
          this.contract_created_from = res && res.contract_created_from;
          this.ticketCount = (this.contract.pending_requests +
            this.contract.reopen_requests + this.contract.followup_requests) || 0;
          this.contract['payment_sources'] = await this.getPaymentSources(this.contract['patient_id']);
          this.contract['missing_payments'] = await this.getMissingPaymentsTable();
          this.payments = await this.getPaidPaymentsTable();
          this.future_payments = await this.getFuturePaymentsTable();

          this.contract.emp_address = this.contract && this.contract.employer_address ? this.contract.employer_address + ', ' + this.contract.employer_city + ', ' + this.contract.employer_state + ', ' + this.contract.employer_zip : 'Not Entered';
          this.lateFeeCharge = this.contract.late_fee;
          // this.waiverObject = _.filter(this.contract.contractStatsArray, function (data) {
          //   return data.order == 5;
          // });
          // this.waiver.bypass_waiver = this.contract.bypass_waiver_limit;

          // if(this.contract.contract_type == 6 && this.contract.number_of_payments <= this.contract.deferred_duration && this.contract.is_contract_deferred == 1 && this.contract.missing_payments == 0){
          //   this.showCards = false;
          // }
          this.continue_deferred_benefits = this.contract.contract_type == 6 && this.contract.continue_deferred_benefits == 1 ? true : false;
          // if (res.contractInfo[0].payment_sources) {
          //   this.payment_sources_list = res.contractInfo[0].payment_sources;
          // }
          if (res.country_currency_info && res.country_currency_info.currency && res.country_currency_info.currency.length) {
            this.bank_support_type = res.country_currency_info.currency[0]['bank_support_type'] ? true : false;
          }
          if (this.contract.partner_details && this.contract.partner_details.length) {

            this.contract.partner_details.forEach((element: any) => {
              this.partnerListAmount[element.partner_id] = {
                partner_id: element.partner_id,
                amount: 0,
                is_selected: false
              }
            });
          }

          if (this.contract.payment_sources) {
            this.bank_accounts = this.contract.payment_sources.filter((data: any) => {
              return data.payment_source_type == 1;
            });
            this.contract['bank_accounts'] = this.bank_accounts;
            console.log(this.bank_accounts);
            this.plaid_accounts = this.contract.plaidAccount;
            this.allMethods = this.contract.payment_sources;
            this.allMethods = this.contract.payment_sources.filter((o: any) => {
              return o.payment_source_type != 1
            });
            if (this.bank_accounts && this.bank_accounts.length) {
              this.allMethods.unshift(this.bank_accounts[0]);
            }
          }

          if (this.contract && this.contract.actions_json) {
            this.action_json = this.contract.actions_json;
            for (const [key, value] of Object.entries(this.action_json)) {
              if (value == 1) {
                this.disable_action.push(data);
              }
            };
          } else {
            this.action_json = {};
          }
          if (!this.contract.patient_name) {
            if (this.contract.patient_first_name || this.contract.patient_last_name) {
              this.contract.patient_name = this.contract.patient_first_name + ' ' + this.contract.patient_last_name;
            }
          }
          if (this.contract && this.contract.paid_to_doctor != 'NaN') {
            this.contract.paid_to_doctor = this.contract.paid_to_doctor;
          } else {
            this.contract.paid_to_doctor = 0;
          }
          this.contractDetails = res;
          this.claimInfo = res.contractInfo[0].claimInfo && res.contractInfo[0].claimInfo[0];
          this.late_fee = this.contract.missing_payments > 0 ? this.contract.late_fee : 0;
          if (this.contract.recurring_amount < this.contract.threshold_amount) {
            this.total_balance_remaining = ((this.contract.recurring_amount + this.contract.recurring_extra_amount + ((this.contract.recurring_extra_percent / 100) * this.contract.recurring_amount)) * this.contract.remaining_payments) + (this.contract.late_fee * this.contract.missing_payments);
          } else {
            this.total_balance_remaining = ((this.contract.recurring_amount + this.contract.threshold_recurring_extra_amount + ((this.contract.threshold_recurring_extra_percent / 100) * this.contract.recurring_amount)) * this.contract.remaining_payments) + (this.contract.late_fee * this.contract.missing_payments);
          }

          this.contract_id = res.contractInfo[0].contract_id
          this.patient_email = res.contractInfo[0].patient_email
          this.patient_name = res.contractInfo[0].patient_name
          this.patient_mobile = res.contractInfo[0].patient_mobile
          this.settlement_values = res.settlement_values
          this.paid_settlement_amount = res.settlement_amount;
          if (this.settlement_values && this.settlement_values.length > 0) {
            for (let i = 0; i < this.settlement_values.length; i++) {
              let check = typeof (this.settlement_values[i]['amount'])
              if (check !== 'string') {
                this.array.push(this.settlement_values[i]['amount'])
              } else {
                this.array.push(parseFloat(this.settlement_values[i]['amount']))
              }
              this.total_paid = Math.min(...this.array)
            }
          }

          this.notesList = res.pf_notes;
          this.missingTransfers = res.advance_payments;
          // console.log(this.contract.patient_ssn.substr(-4));

          if (this.contract && this.contract.patient_ssn) {
            this.contract.patient_ssn_display = '* * * * *' + this.contract.patient_ssn.substr(-4);
          }
          if (this.contract.guarantee_percent == 10) {
            this.contract.totalPendingDoctor = this.contract.remaining_payments * this.contract.recurring_amount * 0.9;
            this.contract.expectedMonthlyPayout = this.contract.recurring_amount * 0.9;
          } else {
            this.contract.totalPendingDoctor = this.contract.remaining_payments * this.contract.recurring_amount * 0.95;
            this.contract.expectedMonthlyPayout = this.contract.recurring_amount * 0.95;
          }

          this.contract.revive_payment_date = this.contract.next_payment_date ? this.datePipe.transform(this.contract.next_payment_date, 'MM/dd/yyyy', '+0000') : '';
          if (res.arrangements && res.arrangements.length > 0) {
            this.arrangement_active_count = res.arrangements.filter((data: any) => {
              return data.active_arrangement == 1;
            }).length;
          }

          if (url === 'finance/get_contract_info') {
            this.paidToDoctor = 0;
            this.contract.missed_payment = 0;
            let dopa_id_checked: any = [];
            let percentage = 0;
            // let percentage = parseFloat(this.contract.guarantee_percent) + parseFloat(this.contract.collection_percent);
            percentage = (100 - percentage) / 100
            this.payments.forEach((item: any) => {
              if (item.dopa_id && dopa_id_checked.indexOf(item.dopa_id) === -1) {
                dopa_id_checked.push(item.dopa_id);
                if (item.missing_payment_id) {
                  let paid_to_doctor = parseFloat(item.pay_to_doctor);
                  if (item.guaranteed_payment) {
                    paid_to_doctor = parseFloat(item.guaranteed_payment);
                  }
                  let amount = paid_to_doctor + parseFloat(item.payout_fee);
                  this.paidToDoctor += (amount / percentage);
                  if (item.claim_status_id != 5) {
                    this.contract.missed_payment += (amount / percentage);
                  }
                } else {
                  if (item.pay_to_doctor_status || item.doctor_id === 0) {
                    this.paidToDoctor += parseFloat(item.principal_amount);
                  }
                }
              }
            });
            if (this.missingTransfers) {
              this.missingTransfers.forEach((item: any) => {
                if (item.dopa_id && dopa_id_checked.indexOf(item.dopa_id) === -1) {
                  dopa_id_checked.push(item.dopa_id);
                  if (item.missing_payment_id) {
                    let paid_to_doctor = parseFloat(item.pay_to_doctor);
                    if (item.guaranteed_payment) {
                      paid_to_doctor = parseFloat(item.guaranteed_payment);
                    }
                    let amount = paid_to_doctor + parseFloat(item.payout_fee);
                    this.paidToDoctor += (amount / percentage);
                    if (item.claim_status_id != 5) {
                      this.contract.missed_payment += (amount / percentage);
                    }
                  } else {
                    this.paidToDoctor += parseFloat(item.principal_amount);
                  }
                }
              });
            }
          }
          this.paidToDoctor = this.contract && this.contract.settled_payment_to_doctor ? this.contract.settled_payment_to_doctor.toFixed(2) : 0;
          console.log('calculated settled_payment_to_doctor->>', this.paidToDoctor);
          if (this.contract.settlement_amount) {
            console.log('can be paid more $', this.contract.settlement_amount - this.contract.settled_payment_to_doctor);
            // if ((this.contract.settlement_amount - this.paidToDoctor) < 0.5 ) {
            //   this.paidToDoctor = this.contract.settlement_amount;
            // }
            let percentage1 = parseFloat(this.contract.guarantee_percent) + parseFloat(this.contract.collection_percent);
            percentage1 = (100 - percentage1) / 100
            this.contract.max_claim_amount = parseFloat(((this.contract.settlement_amount - this.contract.settled_payment_to_doctor) / percentage1).toFixed(2));
            console.log('max_claim_amount--', this.contract.max_claim_amount);
          }

          if (this.contract && this.contract.dnd == 1) {
            this.dnd_checks.dnd_patient_mobile = this.contract.patient_mobile ? (this.contract.patient_mobile.substr(3, 1) + ' * * * * * * * * ' + this.contract.patient_mobile.substr(12, 1)) : "Not Entered";

            this.dnd_checks.dnd_patient_email = this.contract.patient_email ? (this.contract.patient_email.slice(0, 1) + "****************" + this.contract.patient_email.slice(-1)) : "Not Entered";

          }

          if (this.contract && this.contract.doctor_dnd == 1) {
            this.dnd_checks.dnd_doctor_mobile = this.contract.doctor_mobile ? (this.contract.doctor_mobile.substr(3, 1) + ' * * * * * * * * ' + this.contract.doctor_mobile.substr(12, 1)) : "Not Entered";

            this.dnd_checks.dnd_doctor_email = this.contract.doctor_email ? (this.contract.doctor_email.slice(0, 1) + "****************" + this.contract.doctor_email.slice(-1)) : "Not Entered";

            this.dnd_checks.dnd_employer_mobile = this.contract.employer_mobile ? (this.contract.employer_mobile.substr(3, 1) + ' * * * * * * * * ' + this.contract.employer_mobile.substr(12, 1)) : "Not Entered";

            this.dnd_checks.dnd_employer_email = this.contract.employer_email ? (this.contract.employer_email.slice(0, 1) + "****************" + this.contract.employer_email.slice(-1)) : "Not Entered";
          }

          this.contract.paidToDoctor = 0;

          this.payments.forEach((item: any) => {
            this.contract.paidToDoctor += parseFloat(item.pay_to_doctor);
          });

          this.contract.doctor_source_id ? this.getDoctorCards() : '';
          this.settingDownloadContractPages();
          this.fetchGPDetails();

          if (this.refund && this.refund['dopa_id']) {
            this.cur_payment_obj = this.payments.filter((data: any) => data.dopa_id == this.refund['dopa_id'])[0];
            console.log('this.cur_payment_obj--', this.cur_payment_obj)
          } else if (this.refund && this.refund['depa_id']) {
            this.cur_payment_obj = this.payments.filter((data: any) => data.depa_id == this.refund['depa_id'])[0];
          }

          this.cd.detectChanges();
          this.resetContractTransactionFee();
          if (this.contract && this.contract.claimInfo && this.contract.claimInfo[0] && this.contract.claimInfo[0].service_breakdown && this.contract.claimInfo[0].service_breakdown.length > 0) {
            this.contract.claimInfo[0].service_breakdown = JSON.parse(this.contract.claimInfo[0].service_breakdown);
            this.contract.claimInfo[0].service_breakdown_amount = this.contract.claimInfo[0].service_breakdown.map((item: any) => {
              if (item.price == ".") {
                item.price = 0.00;
              }
              return parseFloat(item.price)
            }).reduce((a: any, b: any) => a + b, 0);
            this.service_breakdown = this.contract.claimInfo[0].service_breakdown;
            this.contract.claimInfo[0].service_added = 1;
          }
          if (this.contract && this.contract.claimInfo && this.contract.claimInfo[0] && this.contract.claimInfo[0].treatment_completed == 1 && !(this.contract.claimInfo[0].service_breakdown && this.contract.claimInfo[0].service_breakdown.length > 0)) {
            this.contract.claimInfo[0].service_breakdown_amount = this.contract.treatment_amount;
          }
        }
        if (this.claimId) {
          this.fetchContractClaimDetails();
          this.fetchClaimDetails();
        }

        this.variableTransactionFee = this.contract.recurring_extra_percent;
        this.fixedTransactionFee = this.contract.recurring_extra_amount;

        this.tempVariableTransactionFee = this.contract.recurring_extra_percent;
        this.tempFixedTransactionFee = this.contract.recurring_extra_amount;

        this.isFixedTransactionFee = this.fixedTransactionFee ? true : false;
        this.isVariableTransactionFee = this.variableTransactionFee ? true : false;
        this.contract.partnerShareFromDoctor = 0;
        if (this.contract && this.contract.partner_share_from_doctor_json && JSON.parse(this.contract.partner_share_from_doctor_json).length > 0) {
          let partner_share_from_doctor = JSON.parse(this.contract.partner_share_from_doctor_json);
          this.contract.partnerShareFromDoctor = partner_share_from_doctor.reduce((sum: any, n: any) => {
            return sum + parseFloat(n.partner_share_from_doctor || 0);
          }, 0);
        }
        this.verification_details = res.verification_details;
        if (this.verification_details) {
          let array = [];
          // array.push(`Status: Verified by customer itself`);
          if (this.verification_details.verification_date) {
            array.push(`Verification Date: ${this.datePipe.transform(this.verification_details.verification_date, 'MM-dd-yyyy', this.timezone)}`);
          }
          if (this.verification_details.verification_ip) {
            if (this.verification_details.verification_ip.substr(0, 7) == "::ffff:") {
              this.verification_details.verification_ip = this.verification_details.verification_ip.substr(7, 30);
            }
            array.push(`IP: ${this.verification_details.verification_ip}`);
          }
          this.verification_copied_details = array.join(', ');
        }
        let adjusted_payments = this.payments.filter((payment: any) => payment.payment_type == 5 || payment.payment_type == 6);
        this.contract.adjusted_payments = adjusted_payments && adjusted_payments.length > 0 ? 1 : 0;
        this.resetMoneyValue();


        if (this.contractDetails && this.contractDetails.country_currency_info) {
          // this.mainService.currency_data$.next(this.contractDetails.country_currency_info);
          // tslint:disable:max-line-length
          this.currencyData = this.contractDetails.country_currency_info['currency'] && this.contractDetails.country_currency_info['currency'][0] ? this.contractDetails.country_currency_info['currency'][0] : {}
          this.countryData = this.contractDetails.country_currency_info['country'] ? this.contractDetails.country_currency_info['country'] : {};
          // this.mainService.currency_data.default_mobile_code = this.contractDetails.country_currency_info['country'] && this.contractDetails.country_currency_info['country']['mobile_code'];
          // this.currencyData.display_logo = this.contractDetails.country_currency_info['currency'][0]['display_logo'];
          // this.mainService.currency_data.country_code = this.contractDetails.country_currency_info['country']['country_code'];
          // this.mainService.currency_data.currency_code = (this.contractDetails.country_currency_info['currency'][0]['currency_code']).toUpperCase();
        }
      });
  }

  getDoctorCards() {

    let data = {
      doctor_id: this.contract.doctor_id
    }
    this._api.api(data, 'business/list_all_cards', 'get')
      .subscribe((data: any) => {
        console.log(data);
        this.doctorCards = data.data;
      });
  }

  fetchGPDetails() {
    let data = {
      contract_id: this.contract.contract_id
    }
    this._api.api(data, 'finance/guaranteed_details', 'get')
      .subscribe((res: any) => {
        if (res.data) {
          this.gpInfo = res.data[0];
        }
      });
  }

  sendResponse(reminder_data: any, modal: BsModalRef) {
    console.log('reminder_data--', reminder_data);
    const _this = this;
    // const { ring_central_state_id, ring_central_redirect_uri } = environment.r;
    let ring_central_pk;
    // this.mainService.ring_central_key.subscribe((key: string) => {
    //   ring_central_pk = key;
    // });
    let auth_url = '';
    // if (environment.production) {
    //   auth_url = `https://platform.ringcentral.com/restapi/oauth/authorize?client_id=${ring_central_pk}&redirect_uri=${ring_central_redirect_uri}&response_type=code&state=${ring_central_state_id}&prompt=login%26sso`;
    // } else {
    //   auth_url = `https://platform.devtest.ringcentral.com/restapi/oauth/authorize?client_id=${ring_central_pk}&redirect_uri=${ring_central_redirect_uri}&response_type=code&state=${ring_central_state_id}&prompt=login%26sso`;
    // }
    let mobile_template, email_template;
    if ((reminder_data.mobile || reminder_data.email) && this.contract.reminder_data) {
      this.contract.reminder_data.fetchReminderTemplate.forEach((data: any) => {
        if (Number(data.reminder_template_id) === Number(reminder_data.reminder_id)) {
          mobile_template = data.mobile_template;
          email_template = data.email_template;
        }
      });
    }
    if (reminder_data.reminder_id && (reminder_data.email || reminder_data.mobile)) {
      this._api.api(
        {
          contract_id: this.contract.contract_id,
          send_customer_notification: this.send_customer_notification ? 1 : 0,
          reminder_type: reminder_data.mobile && reminder_data.email ? 3 : reminder_data.mobile ? 1 : 2,
          email_template: reminder_data.email ? email_template : undefined,
          mobile_template: reminder_data.mobile ? mobile_template : undefined
        },
        'manual_reminder_contract_v2')
        .subscribe(data => {
          if (data.is_error && (data.flag === 400 || data.flag === 202)) {
            if (data.flag === 202) {
              this._shared.customPopups('Email sent successfully but SMS failed.  ' +
                'You will be redirected to authorize again in 1 seconds to authorize your account again.', 0);
            } else {
              this._shared.customPopups('Please authorise your Ring Central account and try again later', 1);
            }
            setTimeout(() => {
              const newWin = window.open(auth_url, '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
              if (!newWin || newWin.closed || typeof newWin.closed === 'undefined') {
                _this._shared.customPopups('Browser popups are blocked. Please allow it and try again.', 1);
              }
            }, 1000);
          } else if (data.is_error) {
            this._shared.customPopups(data.err, 1);
          } else {
            this._shared.customPopups('Reminder sent successfully', 0);
          }
          setTimeout(() => {
            this.getContractInfo(this.id, this.isPaymentTabActive);
          }, 2000);
          this.reminder_data.reminder_id = '';
          this.reminder_data.mobile = false;
          this.reminder_data.email = false;
          modal.hide();
          console.log(data);
          // if (data.is_error) {
          //   /*toaster with Your authorization is require reauthorization we will redirect you in 3 seconds to authorize again*/
          //   window.open(`https://platform.devtest.ringcentral.com/restapi/oauth/authorize
          // ?client_id=${ring_central_client_id}&redirect_uri=${ring_central_redirect_uri}
          // &response_type=code&state=${ring_central_state_id}&ui_options=external_popup`);
          // }
        }, (error) => {
          // window.open(`https://platform.devtest.ringcentral.com/restapi/oauth/authorize
          // ?client_id=${ring_central_client_id}&redirect_uri=${ring_central_redirect_uri}
          // &response_type=code&state=${ring_central_state_id}&ui_options=external_popup`);
        });
    }
  }

  // Contract Claim Details
  fetchContractClaimDetails() {
    let data = {
      contract_id: this.contract.contract_id,
      claim_id: this.claimId || this.contract.claimInfo[0].claim_id
    }
    this._api.api(data, 'contract_claim_details')
      .subscribe((res: any) => {
        this.missingDetails = res.data.missing_info;
        if (this.missingDetails && this.missingDetails.length) {
          this.missingDetails.forEach((o: any) => {
            if (o.claim_status_id == 2) {
              o.claim_status = "Conditionally Approved"
            }
          });
        }
        this.claimNotes = res.data.claim_notes;
      });
  }

  // Claim Details
  fetchClaimDetails() {
    let data = {
      claim_id: this.claimId
    }
    this._api.api(data, 'claim_details')
      .subscribe((res: any) => {
        if (res && res.claim_info[0].length) {
          res.claim_info[0].forEach((o: any) => {
            if (o.claim_status_id == 2) {
              o.claim_status = "Conditionally Approved"
            }
          });
        }
        this.claimInfo = res.claim_info[0];
        this.claimInfo.doctor_payable_amount = res.claim_info[0].doctor_payable_amount;
        this.documents = res.document_types;
        this.neededDocs = res.notes_documents;
        this.more_docs = [{
          document_type: -1,
          reason: ''
        }];
        for (let i = 0; i < this.notes.length; i++) {
          console.log(this.notes[i].document_needed);
          if (this.notes[i].document_needed && this.notes[i].document_needed.length > 0) {
            this.notes[i].document_needed_array = this.notes[i].document_needed.split(',');
          }
        }
      });
  }

  // Pay To Business Modal
  openPayToDoctorModal(data: any, action_active: any) {
    this.action_active = false;
    if (action_active) {
      this.action_active = true;
    }
    // if (this.contract.is_guaranteed == 2) {
    //   this._shared.customPopups('Cannot approve a non-guaranteed contract', 2);
    //   return false;
    // }
    // if (!this.contract.settlement_amount) {
    //   if (this.contract.pf_status == 4) {
    //     this._shared.customPopups('Contract is already completed', 2);
    //     return false;
    //   }
    //   if (this.contract.pf_status == 5) {
    //     this._shared.customPopups('Contract is canceled', 2);
    //     return false;
    //   }
    // }
    this.claimPayment = data;

    setTimeout(() => {
      this.getGPDeduction();
    }, 1000);


  }

  getGPDeduction() {
    let data = {
      missing_payment_id: this.claimPayment.missing_payment_id,
      guaranteed_amount: 100,
      amount: 100,
      collection_percent: parseFloat(this.contract.collection_percent),
      original_payment_date: this.datePipe.transform(this.claimPayment.original_payment_date, 'yyyy-MM-dd') || '',
      doctor_stats: Object.keys(this.doctorStats).length === 0 ? '' : JSON.stringify(this.doctorStats),
      current_admin_id: parseInt(this.admin_id)
    };
    this._api.api(data, 'get_gp_deduction')
      .subscribe((data: any) => {
        console.log(data);
        this.deductions = data.deductionArray;
        this.contract.dedcution_text = this.deductions.map((item: any) => item.show_name).join(' + ');
        this.doctorAmount = '';
        this.newNotes = '';
        this.payToDoctorModal.show();
      }, (error: any) => {
        console.log(error);
      });
  }

  resetContractTransactionFee(modal?: any) {
    if (this.contract) {
      this.contract_transaction_fee.recurring_extra_amount = this.contract.recurring_extra_amount;
      this.contract_transaction_fee.recurring_extra_percent = this.contract.recurring_extra_percent;
      this.contract_transaction_fee.threshold_amount = this.contract.threshold_amount;
      this.contract_transaction_fee.threshold_recurring_extra_amount = this.contract.threshold_recurring_extra_amount;
      this.contract_transaction_fee.threshold_recurring_extra_percent = this.contract.threshold_recurring_extra_percent;
      this.contract_transaction_fee.note = '';
    } else {
      this.contract_transaction_fee = {
        recurring_extra_amount: 0,
        recurring_extra_percent: 0,
        threshold_amount: 0,
        threshold_recurring_extra_amount: 0,
        threshold_recurring_extra_percent: 0,
        note: ''
      };
    }
    if (modal) {
      modal.hide();
    }
  }

  resetMoneyValue(modal?: any) {
    this.business_money.moneyValueNote = '';
    this.business_money.moneyValue = 0;
    this.business_money.amount = 0;
    this.business_money.amount_deducted = 0;
    this.business_money.customer_pt_name = 'Cheque Payment';
    this.business_money.business_pt_name = 'Cheque Payment';
    this.business_money.show_to_customer = true;
    this.linkPartnerWithDoctorSend = false;
    if (this.contract.partner_details && this.contract.partner_details.length) {
      this.contract.partner_details.forEach((element: any) => {
        this.partnerListAmount[element.partner_id] = {
          partner_id: element.partner_id,
          amount: 0,
          is_selected: false
        }
      });
    }
    if (modal) {
      modal.hide();
    }
  }

  toggleStopReminder() {
    let stop_payment_reminder = 1;
    if (this.contract && this.contract.stop_payment_reminder == 0) {
      stop_payment_reminder = 1
    }
    else {
      stop_payment_reminder = 0
    }
    let params = {
      access_token: this._local.customGetItem("adminToken"),
      contract_id: this.contract.contract_id,
      stop_payment_reminder: stop_payment_reminder,
      stop_payment_reminder_sms: this.contract.stop_payment_reminder_sms
    };
    this._api.api(params, 'finance/toggle_stop_payment_reminder')
      .subscribe((data: any) => {
        if (data.is_error == 0) {
          this.getContractInfo(this.id, this.isPaymentTabActive);
          console.log("Toggle Payment Reminder")
        } else {
          this._shared.customPopups(data.original_text, 2);
        }
      }, (error: any) => {
        console.log(error);
      })
  }

  toggleStopReminderSms() {
    let stop_payment_reminder_sms = 1;
    if (this.contract && this.contract.stop_payment_reminder_sms == 0) {
      stop_payment_reminder_sms = 1
    }
    else {
      stop_payment_reminder_sms = 0
    }
    let params = {
      access_token: this._local.customGetItem("adminToken"),
      contract_id: this.contract.contract_id,
      stop_payment_reminder: this.contract.stop_payment_reminder,
      stop_payment_reminder_sms
    };
    this._api.api(params, 'finance/toggle_stop_payment_reminder')
      .subscribe((data: any) => {
        if (data.is_error == 0) {
          this.getContractInfo(this.id, this.isPaymentTabActive);
          console.log("Toggle Payment Reminder")
        } else {
          this._shared.customPopups(data.original_text, 2);
        }
      }, (error: any) => {
        console.log(error);
      })
  }

  deleteCard(source_id: any) {
    // swal(
    //   {
    //     title: 'Confirmation',
    //     text: `Are you sure you want to delete this card?`,
    //     type: 'input',
    //     showCancelButton: true,
    //     confirmButtonColor: '#5d9cec',
    //     confirmButtonText: 'Yes',
    //     cancelButtonText: 'Cancel',
    //     closeOnConfirm: false,
    //     inputPlaceholder: "Please enter notes...",
    //     closeOnCancel: true
    //   }, (inputValue) => {
    //     inputValue = inputValue && inputValue.trim();
    //     if (inputValue === '' || inputValue.length == 0) {
    //       swal.showInputError(' You need to write something! ');
    //       return false;
    //     }
    //     if (inputValue) {
    //       swal.close();
    //       let params = {
    //         access_token: MainService.customGetItem("adminToken"),
    //         patient_card_id: source_id,
    //         note: inputValue,
    //         action_type: 26
    //       };
    //       this.mainService.api(params, 'delete_patient_card', true)
    //         .subscribe((data: any) => {
    //           if (data.is_error == 0) {
    //             this.getContractInfo(this.id, this.isPaymentTabActive);
    //           }
    //         }, (error: any) => {
    //           console.log(error);
    //         })
    //     } else {

    //     }
    //   }
    // );

  }

  fileChangeEventDL(event: any) {
    if (event && event.target && event.target.files && event.target.files.length > 0) {
      const file = event.target.files;
      if (file[0].size <= 0) {
        this._shared.customPopups('Invalid file.', 1);
        return false;
      }

      if (file[0].type !== "image/png" && file[0].type !== 'application/pdf' && file[0].type !== 'image/jpeg' && file[0].type !== 'image/jpg') {
        this._shared.customPopups('File type not supported. Please upload a valid file(.pdf, .jpeg, .png).', 1);
        return false;
      }

      if (file[0].size > 10485760) {
        this._shared.customPopups('File size must be less than 10MB.', 1);
        return false;
      }

      if (file[0].type === 'application/pdf') {
        this.uploadedDoc(file[0])
      } else {
        if (file[0].type === 'image/png' || file[0].type === 'image/jpeg' || file[0].type === 'image/jpg') {
          const image: File = file[0];
          this.compressImage.compress(image)
            .pipe(take(1))
            .subscribe(compressedImage => {
              this.uploadedDoc(compressedImage)
            });
        }
      }
    }
    return;
  }

  uploadedDoc(file: any) {
    if (file) {
      let formData = new FormData();
      formData.append('dl_doc', file);
      formData.append('patient_id', this.contract.patient_id);
      this._api.uploadApi(formData, 'upload_cus_dl')
        .subscribe((data: any) => {
          if (data.is_error == 1) {
            if (data.original_text) {
              this._shared.customPopups(data.original_text, data.is_error);
            } else {
              this._shared.customPopups(data.flag, data.is_error);
            }
          } else if (data && data.data && data.data.url) {
            this.contract.dl_doc = data.data.url;
            this.contract.dl_id = data.data.id;
            this._shared.customPopups(data.original_text ? data.original_text : 'Document uploaded successfully', 0);
          } else {
            this.getContractInfo(this.id, 1);
          }
        });
    } else {
      this._shared.customPopups('Please select valid file', 1);
    }
  }

  onFileInputClick() {
    this.fileUpload.nativeElement.value = '';
  }

  deleteUploadedDoc(doc_id: any) {
    // swal({
    //   title: 'Confirmation',
    //   text: `Are you sure you want to delete the uploaded Proof of ID?`,
    //   type: 'info',
    //   showCancelButton: true,
    //   confirmButtonColor: '#f05050',
    //   confirmButtonText: 'Delete',
    //   cancelButtonText: 'Cancel',
    //   closeOnConfirm: true,
    //   closeOnCancel: true
    // }, (isConfirm) => {
    //   if (isConfirm) {
    //     let params = {
    //       doc_id: doc_id,
    //       patient_id: this.contract.patient_id,
    //       access_token: MainService.customGetItem('adminToken')
    //     };
    //     this.mainService.api(params, 'delete_cus_dl', false)
    //       .subscribe((data: any) => {
    //         if (data.is_error == 0) {
    //           this.contract.dl_doc = null;
    //           this.mainService.toaster.pop('success', data.original_text ? data.original_text : 'Document deleted successfully');
    //         }
    //         if (data.is_error == 1) {
    //           if (data.flag == 584) {
    //             this.contract.dl_doc = null;
    //             this.contract.dl_id = null;
    //           }
    //           if (data.original_text) {
    //             this.mainService.customPopups(data.original_text, data.is_error);
    //           } else {
    //             this.mainService.flagPopUps(data.flag, data.is_error);
    //           }
    //         }
    //       }, (error: any) => {
    //         console.log(error);
    //       })
    //   }
    // })
  }

  calculationPopShow(idName?: any) {
    this.popoverColorChange = idName
  }

  calculationPopHide(idName?: any) {
    if (idName == this.popoverColorChange) {
      this.popoverColorChange = ''
    }
  }

  settingDownloadContractPages() {
    this.allPayments = [];
    for (var x = 0; x < this.payments.length; x++) {
      this.allPayments.push(this.payments[x]);
    }
    this.paymentPages = [];
    this.num_pages = 1;
    this.num_pages += this.allPayments.length;
    this.pageLength = [];
    for (var j = 0; j < this.num_pages; j++) {
      this.paymentPages[j] = [];
      if (j == 0) this.pageLength[j] = 100;
      else this.pageLength[j] = 650;
    }


    for (var j = 0; j < this.pageLength.length;) {
      for (var i = 0; i < this.allPayments.length; i++) {
        if (40 < this.pageLength[j]) {
          this.pageLength[j] -= 40;
          this.paymentPages[j].push(this.allPayments[i]);
        } else {
          this.pageLength[j] = 0;
          j++;
          this.pageLength[j] -= 40;
          this.paymentPages[j].push(this.allPayments[i]);
        }
      }
      if (i == this.allPayments.length) {
        break;
      }
    }

    if (this.pageLength.length > 0) {
      this.lastPageHeightLeft = this.pageLength[j];
      if (this.lastPageHeightLeft > 40) {
        this.lastPageHeightLeft = 40
      }
      //  else lastPageHeightLeft = 40;
      this.lastPageNum = j;
      this.lastPageFooterPx = this.lastPageHeightLeft + 'px';
      console.log(this.lastPageFooterPx);
      console.log(this.lastPageHeightLeft);
      //  document.getElementById("footerPadding").style.padding = vm.lastPageHeightLeft + "px 0px 0px 0px";
    }
    //console.log(this.paymentPages);

  }

  downloadConsent(view: any) {
    let dataParams = {
      doctor_name: this.contract.practice_name ? this.contract.practice_name : this.contract.doctor_name,
      patient_name: this.contract.title ? (this.contract.title + this.contract.patient_first_name + this.contract.patient_last_name) : (this.contract.patient_first_name + this.contract.patient_last_name),
      date: moment(this.contract.date_added).format('MM-DD-YYYY'),
      doctor_id: this.contract.doctor_id,
      contract_id: this.contract_id
    };
    this._api.downloadApi(dataParams, 'download_consent_form', view)
      .subscribe((data: any) => {
        console.log(data);
      }, (error: any) => {
        console.log(error);
      })
  }

  // modal functions
  bankruptcyM2rReport(modalType: string) {
    const modelRef = this.modalService.show(ContractModalComponent, {
      initialState: {
        modalType,
        extra_data: {
          contract: this.contract,
          countryData: this.countryData,
          currencyData: this.currencyData
        },
      },
      class: 'modal-dialog-centered',
    })
    modelRef.content?.passEntry.subscribe(response => {
      if (response.success) {
        this.getContractInfo(this.id, this.isPaymentTabActive);
      }
    })
  }

  activateModal() {
    const modelRef = this.modalService.show(ContractModalComponent, {
      initialState: {
        modalType: this._contract.modalTypes.activateModal,
        extra_data: {
          contract: this.contract,
          countryData: this.countryData,
          currencyData: this.currencyData
        }
      }
    })
    modelRef.content?.passEntry.subscribe(response => {
      if (response.success) {
        this.getContractInfo(this.id, this.isPaymentTabActive);
      }
    })
  }

  chargeLateFeeModalOpen() {
    this.recurring['all_cards'] = this.contract['payment_sources'];
    this.recurring['payment_for'] = '2';
    this.recurring['payment_mode'] = '1';
    this.recurring['paymentMode'] = 1;
    const modelRef = this.modalService.show(ContractModalComponent, {
      initialState: {
        modalType: this._contract.modalTypes.chargeLateFeeModal,
        extra_data: {
          contract: this.contract,
          countryData: this.countryData,
          currencyData: this.currencyData,
          recurring: this.recurring
        }
      },
      class: 'modal-dialog-centered modal-lg',
    })
    modelRef.content?.passEntry.subscribe(response => {
      if (response.success) {
        this.getContractInfo(this.id, this.isPaymentTabActive);
        this.lateFeeCharge = this.contract.late_fee;
      }
    })
  }

  editRecurringModalOpen() {
    this.recurring['editRecurringAmount'] = 0;
    this.recurring['all_cards'] = this.contract['payment_sources'];
    this.recurring['payment_for'] = '2';
    this.recurring['payment_mode'] = '1';
    this.recurring['late_fee'] = this.contract.late_fee * this.contract.missing_payments;
    this.recurring.total_amount = parseFloat((this.recurring.editRecurringAmount + this.recurring.late_fee).toFixed(2));
    this.recurring['hideBtn'] = true;
    const modelRef = this.modalService.show(ContractModalComponent, {
      initialState: {
        modalType: this._contract.modalTypes.editRecurringModal,
        extra_data: {
          contract: this.contract,
          countryData: this.countryData,
          currencyData: this.currencyData,
          recurring: this.recurring
        }
      },
      class: 'modal-dialog-centered modal-lg'
    })
    modelRef.content?.passEntry.subscribe(response => {
      if (response.success) {
        this.getContractInfo(this.id, this.isPaymentTabActive);
        this.lateFeeCharge = this.contract.late_fee;
      }
    })
  }

  payMorePartialOff(type: string) {
    const modelRef = this.modalService.show(ContractModalComponent, {
      initialState: {
        modalType: type,
        extra_data: {
          contract: this.contract,
          countryData: this.countryData,
          currencyData: this.currencyData
        }
      },
      class: 'modal-dialog-centered modal-lg'
    })
    modelRef.content?.passEntry.subscribe(response => {
      if (response.success) {
        this.getContractInfo(this.id, this.isPaymentTabActive);
        this.lateFeeCharge = this.contract.late_fee;
      }
    })
  }

  OpenEditContractPopup() {
    let initialState = {
      contract_details: this.contract,
      currency_logo: this.currencyData.display_logo
    };
    let bsModalRef = this.modalService.show(EditContractModalComponent, { initialState, class: 'modal-lg modal-dialog-centered ', ignoreBackdropClick: true });
    let count = 0;
    this.modalService.onHide.subscribe((reason: string) => {
      if (reason === 'true') {
        const _reason = reason ? `, dismissed by ${reason}` : '';
        if (count == 0) {
          this.getContractInfo(this.id, this.isPaymentTabActive);
          count = 1;
        }
      }
    })
  }

  contractUpdateModal() {
    const modelRef = this.modalService.show(ContractModalComponent, {
      initialState: {
        modalType: this._contract.modalTypes.contractUpdateModal,
        extra_data: {
          contract: this.contract,
          countryData: this.countryData,
          currencyData: this.currencyData
        }
      },
      class: 'modal-dialog-centered modal-lg'
    })
    modelRef.content?.passEntry.subscribe(response => {
      if (response.success) {
        this.getContractInfo(this.id, this.isPaymentTabActive);
      }
    })
  }

  cancelContractModal() {
    const modelRef = this.modalService.show(ContractModalComponent, {
      initialState: {
        modalType: this._contract.modalTypes.cancelContractModal,
        extra_data: {
          contract: this.contract,
          countryData: this.countryData,
          currencyData: this.currencyData
        }
      },
      class: 'modal-dialog-centered '
    })
    modelRef.content?.passEntry.subscribe(response => {
      if (response.success) {
        this.getContractInfo(this.id, this.isPaymentTabActive);
      }
    })
  }

  makeProtectedNonProtected() {
    const modelRef = this.modalService.show(ContractModalComponent, {
      initialState: {
        modalType: this._contract.modalTypes.makeProtectedNonProtected,
        extra_data: {
          contract: this.contract,
          countryData: this.countryData,
          currencyData: this.currencyData
        }
      },
      class: 'modal-dialog-centered '
    })
    modelRef.content?.passEntry.subscribe(response => {
      if (response.success) {
        this.getContractInfo(this.id, this.isPaymentTabActive);
      }
    })
  }

  nextRecAmountModal() {
    this.late_fee = this.contract.missing_payments > 0 ? this.contract.late_fee : 0;
    const contract: any = JSON.parse(JSON.stringify(this.contract));
    contract['late_fee'] = this.late_fee;
    contract['disable_action'] = this.disable_action;
    const modelRef = this.modalService.show(ContractModalComponent, {
      initialState: {
        modalType: this._contract.modalTypes.nextRecAmountModal,
        extra_data: {
          contract: contract,
          countryData: this.countryData,
          currencyData: this.currencyData
        }
      },
      class: 'modal-dialog-centered modal-lg'
    })
    modelRef.content?.passEntry.subscribe(response => {
      if (response.success) {
        this.getContractInfo(this.id, this.isPaymentTabActive);
      }
    })
  }

  generateLink() {
    let data = {
      contract_id: this.contract.contract_id
    };
    this._api.api(data, 'finance/generate_link', 'get')
      .subscribe((data: any) => {
        console.log(data);
        const contract: any = JSON.parse(JSON.stringify(this.contract));
        contract['link'] = data.link ? data.link : '';
        const modelRef = this.modalService.show(ContractModalComponent, {
          initialState: {
            modalType: this._contract.modalTypes.generateAddCardLink,
            extra_data: {
              contract: contract,
              countryData: this.countryData,
              currencyData: this.currencyData
            }
          },
          class: 'modal-dialog-centered modal-lg'
        })
        modelRef.content?.passEntry.subscribe(response => {
          if (response.success) {
            this.getContractInfo(this.id, this.isPaymentTabActive);
          }
        })
      }, (error: any) => {
        console.log(error);
      })
  }

  contributionLink() {
    if (this.contract.stripe_account_id == 0) {
      this._shared.customPopups('Banking Details Required! Contact support@credee.com or 833-336-3348', 2);
      return false;
    }
    if (this.contract.remaining_amount < 5) {
      this._shared.customPopups('Minimum remaining amount should be greater than or equals to $5 to generate the contribution link', 2);
      return false;
    }
    if (this.contract.remaining_amount == 0 && this.contract.missing_payments > 0) {
      this._shared.customPopups('Contact Credee, 833-336-3346 - Outstanding late fees due, donations not approved', 2);
      return false;
    }

    let data = {
      contract_id: this.contract.contract_id
    };

    this._api.api(data, 'finance/generate_social_link', 'get')
      .subscribe((data: any) => {
        if (data.is_error == 0) {
          const contract: any = JSON.parse(JSON.stringify(this.contract));
          contract['contributionUrl'] = data.link ? data.link : '';
          contract['language_data'] = this.language_data;
          const modelRef = this.modalService.show(ContractModalComponent, {
            initialState: {
              modalType: this._contract.modalTypes.generateContributionLink,
              extra_data: {
                contract: contract,
                countryData: this.countryData,
                currencyData: this.currencyData,
              }
            },
            class: 'modal-dialog-centered modal-lg'
          })
          this.modalService.onHide.subscribe(response => {
          })
        } else {
          this._shared.customPopups(data.original_text, 1);
          return;
        }
      }, (error: any) => {
        console.log(error);
      })
    return;
  }

  moveToUnpaidFirstRecurring() {
    if (!this.contract.stripe_account_id) {
      this._shared.customPopups('The Business hasn\'t added his bank account yet', 2);
      return;
    }
    const contract: any = JSON.parse(JSON.stringify(this.contract));
    const modelRef = this.modalService.show(ContractModalComponent, {
      initialState: {
        modalType: this._contract.modalTypes.moveToUnpaidFirstRecurringModal,
        extra_data: {
          contract: contract,
          countryData: this.countryData,
          currencyData: this.currencyData,
        }
      },
      class: 'modal-dialog-centered '
    })
    modelRef.content?.passEntry.subscribe(response => {
      if (response.success) {
        this.getContractInfo(this.id, this.isPaymentTabActive);
      }
    })
  }

  makePaymentDisputed() {
    const contract: any = JSON.parse(JSON.stringify(this.contract));
    const modelRef = this.modalService.show(ContractModalComponent, {
      initialState: {
        modalType: this._contract.modalTypes.makePaymentDisputed,
        extra_data: {
          contract: contract,
          countryData: this.countryData,
          currencyData: this.currencyData,
        }
      },
      class: 'modal-dialog-centered '
    })
    modelRef.content?.passEntry.subscribe(response => {
      if (response.success) {
        this.getContractInfo(this.id, this.isPaymentTabActive);
      }
    })
  }

  dndModal() {
    const contract: any = JSON.parse(JSON.stringify(this.contract));
    const modelRef = this.modalService.show(ContractModalComponent, {
      initialState: {
        modalType: this._contract.modalTypes.dndModal,
        extra_data: {
          contract: contract,
          countryData: this.countryData,
          currencyData: this.currencyData,
        }
      },
      class: 'modal-dialog-centered '
    })
    modelRef.content?.passEntry.subscribe(response => {
      if (response.success) {
        this.getContractInfo(this.id, this.isPaymentTabActive);
      }
    })
  }

  changeTransactionFeeThresholdModal() {
    const contract: any = JSON.parse(JSON.stringify(this.contract));
    const modelRef = this.modalService.show(ContractModalComponent, {
      initialState: {
        modalType: this._contract.modalTypes.changeTransactionFeeThresholdModal,
        extra_data: {
          contract: contract,
          countryData: this.countryData,
          currencyData: this.currencyData,
        }
      },
      class: 'modal-dialog-centered '
    })
    modelRef.content?.passEntry.subscribe(response => {
      if (response.success) {
        this.getContractInfo(this.id, this.isPaymentTabActive);
      }
    })
  }

  getOrSendMoneyToBusiness(type: number) {
    const contract: any = JSON.parse(JSON.stringify(this.contract));
    contract['moneyType'] = type;
    contract['partnerListAmount'] = this.partnerListAmount;
    const modelRef = this.modalService.show(ContractModalComponent, {
      initialState: {
        modalType: this._contract.modalTypes.getOrSendMoneyToBusinessModal,
        extra_data: {
          contract: contract,
          countryData: this.countryData,
          currencyData: this.currencyData,
        }
      },
      class: 'modal-dialog-centered '
    })
    modelRef.content?.passEntry.subscribe(response => {
      if (response.success) {
        this.getContractInfo(this.id, this.isPaymentTabActive);
      }
    })
  }

  goToPaySettlement() {
    let initialState = {
      data: { doctor_id: this.contract.doctor_id, value: 2 }
    };
    let bsModalRef: BsModalRef = this.modalService.show(DoctorStatsComponent, { initialState, class: 'modal-lg', ignoreBackdropClick: true });
    bsModalRef.content.event.subscribe((data: any) => {
      if (data.data == 1) {
        setTimeout(() => {
          const contract: any = JSON.parse(JSON.stringify(this.contract));
          contract['receiveAmount'] = this.receiveAmount;
          contract['deductions'] = this.deductions;
          contract['doctorAmount'] = this.doctorAmount;
          this.doctorAmount = null;
          const modelRef = this.modalService.show(ContractModalComponent, {
            initialState: {
              modalType: this._contract.modalTypes.paySettledModal,
              extra_data: {
                contract: contract,
                countryData: this.countryData,
                currencyData: this.currencyData,
              }
            },
            class: 'modal-dialog-centered '
          })
          this.modalService.onHide.subscribe(response => {
            if (response.success) {
              this.getContractInfo(this.id, this.isPaymentTabActive);
            }
          })
        }, 1000);
      }
    });
  }

  sendContractModal() {
    const contract: any = JSON.parse(JSON.stringify(this.contract));
    const modelRef = this.modalService.show(ContractModalComponent, {
      initialState: {
        modalType: this._contract.modalTypes.sendContractModal,
        extra_data: {
          contract: contract,
          countryData: this.countryData,
          currencyData: this.currencyData,
        }
      },
      class: 'modal-dialog-centered'
    })
    modelRef.content?.passEntry.subscribe(response => {
      if (response.success) {
        this.add_consent_form = true;
      }
    })
  }

  contractVerificationEmailModal() {
    const contract: any = JSON.parse(JSON.stringify(this.contract));
    const modelRef = this.modalService.show(ContractModalComponent, {
      initialState: {
        modalType: this._contract.modalTypes.contractVerificationEmailModal,
        extra_data: {
          contract: contract,
          countryData: this.countryData,
          currencyData: this.currencyData,
          download_contract: this.action_json && this.action_json.download_contract
        }
      },
      class: 'modal-dialog-centered modal-lg'
    })
    modelRef.content?.passEntry.subscribe(response => {
      if (response.success) {
        this.add_consent_form = true;
        this.getContractInfo(this.id, this.isPaymentTabActive);
      }
    })
  }

  downloadContractModal() {
    const contract: any = JSON.parse(JSON.stringify(this.contract));
    contract['can_admin_download'] = this._shared.routerAuth.restricted_buttons.can_admin_download;
    const modelRef = this.modalService.show(ContractModalComponent, {
      initialState: {
        modalType: this._contract.modalTypes.downloadContractModal,
        extra_data: {
          contract: contract,
          countryData: this.countryData,
          currencyData: this.currencyData,
        }
      },
      class: 'modal-dialog-centered'
    })
    modelRef.content?.passEntry.subscribe(response => {
      if (response.success) {
        this.add_consent_form = true;
      }
    })
  }

  reminderTemplateModal() {
    const contract: any = JSON.parse(JSON.stringify(this.contract));
    const modelRef = this.modalService.show(ContractModalComponent, {
      initialState: {
        modalType: this._contract.modalTypes.reminderTemplateModal,
        extra_data: {
          contract: contract,
          countryData: this.countryData,
          currencyData: this.currencyData,
        }
      },
      class: 'modal-dialog-centered modal-lg'
    })
    modelRef.content?.passEntry.subscribe(response => {
      if (response.success) {
        this.add_consent_form = true;
      }
    })
  }

  pauseResumePaymentSMS(type: string) {
    const contract: any = JSON.parse(JSON.stringify(this.contract));
    contract['cronType'] = type;
    contract['cronPayment'] = this.cronPayment;
    const modelRef = this.modalService.show(ContractModalComponent, {
      initialState: {
        modalType: this._contract.modalTypes.pausePaymentSMS,
        extra_data: {
          contract: contract,
          countryData: this.countryData,
          currencyData: this.currencyData,
        }
      },
      class: 'modal-dialog-centered'
    })
    modelRef.content?.passEntry.subscribe(response => {
      if (response.success) {
        this.getContractInfo(this.id, this.isPaymentTabActive);
      }
    })
  }

  openAddBankLinkModal() {
    const contract: any = JSON.parse(JSON.stringify(this.contract));
    const modelRef = this.modalService.show(ContractModalComponent, {
      initialState: {
        modalType: this._contract.modalTypes.openAddBankLinkModal,
        extra_data: {
          contract: contract,
          countryData: this.countryData,
          currencyData: this.currencyData,
        }
      },
      class: 'modal-dialog-centered modal-lg'
    })
    modelRef.content?.passEntry.subscribe(response => {
      if (response.success) {
        this.getContractInfo(this.id, this.isPaymentTabActive);
      }
    })
  }

  openContractSettlementModal() {
    const contract: any = JSON.parse(JSON.stringify(this.contract));
    contract['paid_settlement_amount'] = this.paid_settlement_amount;
    contract['arrangementTimeSlot'] = this.arrangementTimeSlot;
    contract['arrangement_time_slot_type'] = this.arrangement_time_slot_type;
    contract['payment_sources_list'] = this.contract.payment_sources;
    const modelRef = this.modalService.show(ContractModalComponent, {
      initialState: {
        modalType: this._contract.modalTypes.openContractSettlementModal,
        extra_data: {
          contract: contract,
          countryData: this.countryData,
          currencyData: this.currencyData,
        }
      },
      class: 'modal-dialog-centered modal-lg'
    })
    modelRef.content?.passEntry.subscribe(response => {
      if (response.success) {
        this.getContractInfo(this.id, this.isPaymentTabActive);
      }
    })
  }

  chargeContractModalOpen() {
    let data = {
      doctor_id: this.contract.doctor_id
    };
    this._api.api(data, 'business/list_all_cards', 'get').subscribe(response => {
      if (response.is_error == 0) {
        this.charge['all_cards'] = response.data;
        const contract: any = JSON.parse(JSON.stringify(this.contract));
        const modelRef = this.modalService.show(ContractModalComponent, {
          initialState: {
            modalType: this._contract.modalTypes.chargeContractModalOpen,
            extra_data: {
              contract: contract,
              countryData: this.countryData,
              currencyData: this.currencyData,
              charge: this.charge
            }
          },
          class: 'modal-dialog-centered modal-lg'
        })
        modelRef.content?.passEntry.subscribe(response => {
          if (response.success) {
            this.getContractInfo(this.id, this.isPaymentTabActive);
          }
        })
      }
    })
  }

  addContractNotesModal() {
    this.contractDetails['assign_type'] = 4;
    let initialState = {
      data: this.contractDetails
    };
    this.mainService.noteadded = false;
    this.bsModalRef = this.modalService.show(AddNotesComponent, { initialState, class: 'modal-dialog-centered modal-lg', ignoreBackdropClick: true });
    let count = 0;
    this.modalService.onHide.subscribe((reason: string) => {
      if (this.mainService.noteadded) {
        const _reason = reason ? `, dismissed by ${reason}` : '';
        if (count == 0) {
          this.getContractInfo(this.id, this.isPaymentTabActive);
          count = 1;
        }
      }
    })
  }

  arrangementModal() {
    const contract: any = JSON.parse(JSON.stringify(this.contract));
    contract['arrangementTimeSlot'] = this.arrangementTimeSlot;
    const modelRef = this.modalService.show(ContractModalComponent, {
      initialState: {
        modalType: this._contract.modalTypes.arrangementModal,
        extra_data: {
          contract: contract,
          countryData: this.countryData,
          currencyData: this.currencyData,
        }
      },
      class: 'modal-dialog-centered modal-lg'
    })
    modelRef.content?.passEntry.subscribe(response => {
      if (response.success) {
        this.getContractInfo(this.id, this.isPaymentTabActive);
      }
    })
  }

  getSettlementArrangement() {
    let api_data = {
      contract_id: this.contract.contract_id
    };
    this._api.api(api_data, 'view_settlement_arrangement')
      .subscribe((data: any) => {
        if (data.is_error == 0 && data.data && data.data.length > 0) {
          const contract: any = JSON.parse(JSON.stringify(this.contract));
          this.settlement_data = data.data;
          contract['settlement_data'] = this.settlement_data;
          const modelRef = this.modalService.show(ContractModalComponent, {
            initialState: {
              modalType: this._contract.modalTypes.viewArrangementsModal,
              extra_data: {
                contract: contract,
                countryData: this.countryData,
                currencyData: this.currencyData,
              }
            },
            class: 'modal-dialog-centered modal-lg'
          })
          modelRef.content?.passEntry.subscribe(response => {
            if (response.success) {
              this.getContractInfo(this.id, this.isPaymentTabActive);
            }
          })
        }
      });
  }

  cardPreferenceModal() {
    const contract: any = JSON.parse(JSON.stringify(this.contract));
    contract['allMethods'] = this.allMethods;
    contract['choose_backup'] = this.choose_backup;
    contract['deduct_one_dollar'] = this.deduct_one_dollar;
    const modelRef = this.modalService.show(ContractModalComponent, {
      initialState: {
        modalType: this._contract.modalTypes.cardPreferenceModal,
        extra_data: {
          contract: contract,
          countryData: this.countryData,
          currencyData: this.currencyData,
        }
      },
      class: 'modal-dialog-centered modal-lg'
    })
    modelRef.content?.passEntry.subscribe(response => {
      if (response.success) {
        this.choose_backup = true;
        this.getContractInfo(this.id, this.isPaymentTabActive);
      }
    })
  }

  addCardModal() {
    const modelRef = this.modalService.show(GenerateStripeElementComponent, {
      initialState: {
        stripeData: {
          patient_profile: this.contract,
          payment_source_type: this._shared.paymentSourceType.CARD,
          currency_data: this.currencyData,
          contract_id: this.contract.contract_id,
          pf_id: this.contract.pf_id
        }
      },
      class: 'modal-dialog-centered modal-lg px-4 py-2'
    })

    modelRef.content?.returnStripeData.subscribe(stripeResp => {
      if (stripeResp.success) {
        modelRef.hide();
        this.getContractInfo(this.id, this.isPaymentTabActive);
      }
    })
  }

}
