import { Injectable } from "@angular/core";

@Injectable()
export class DebtRecoveryService {
    public modalTypes = {
        resendLinkModal: 'resendLinkModal',
        sendReceiptLinkModal: 'sendReceiptLinkModal',
        refundPaymentModal: 'refundPaymentModal',
		detailsModal: 'detailsModal',
		PaymentSettingsModal: 'PaymentSettingsModal',
		ResendPlanModal: 'ResendPlanModal',
		editExpiryDateModal: 'editExpiryDateModal'
    }

    getCalculatedTransactionFee(contract: any, comparable_value: any) {
		if (!contract) {
			contract = {}
		}
		contract = this.setTransactionFee(contract);
		comparable_value = parseFloat(comparable_value);
		let transaction_fee = 0;
		if (comparable_value === 0) {
			return 0;
		} else if ((comparable_value < contract.threshold_amount) || (contract.threshold_amount === undefined && contract.recurring_extra_amount !== undefined)) {
			if (contract.recurring_extra_percent) {
				transaction_fee += (comparable_value * contract.recurring_extra_percent) / 100;
			}
			if (contract.recurring_extra_amount) {
				transaction_fee += contract.recurring_extra_amount;
			}
		} else {
			if (contract.threshold_recurring_extra_percent) {
				transaction_fee += (comparable_value * contract.threshold_recurring_extra_percent) / 100;
			}
			if (contract.threshold_recurring_extra_amount) {
				transaction_fee += contract.threshold_recurring_extra_amount;
			}
		}
		return transaction_fee;
	}

    GetTransactionFee(contract: any, comparable_value: any) {
		comparable_value = parseFloat(comparable_value);
		contract = this.setTransactionFee(contract);		
		let text = '';
		if (comparable_value < contract.threshold_amount) {
		  if (contract.recurring_extra_percent) {
			text += `${parseFloat(contract.recurring_extra_percent).toFixed(2)}%`
		  }
		  if (contract.recurring_extra_amount && contract.recurring_extra_percent) {
			text += ' + ';
		  }
		  if (contract.recurring_extra_amount) {
			text += `$${parseFloat(contract.recurring_extra_amount).toFixed(2)}`
		  }
		} else {
		  if (contract.threshold_recurring_extra_percent) {
			text += `${parseFloat(contract.threshold_recurring_extra_percent).toFixed(2)}%`
		  }
		  if (contract.threshold_recurring_extra_amount && contract.threshold_recurring_extra_percent) {
			text += ' + ';
		  }
		  if (contract.threshold_recurring_extra_amount) {
			text += `$${parseFloat(contract.threshold_recurring_extra_amount).toFixed(2)}`
		  }
		}
		return text;
	  }

	setTransactionFee(contract: any) {
		if (contract) {
			contract.recurring_extra_amount = contract.recurring_extra_amount;
			contract.recurring_extra_percent = contract.recurring_extra_percent;
			contract.threshold_amount = contract.threshold_amount;
			contract.threshold_recurring_extra_amount = contract.threshold_recurring_extra_amount;
			contract.threshold_recurring_extra_percent = contract.threshold_recurring_extra_percent;
		} else {
			contract.recurring_extra_amount = 3;
			contract.recurring_extra_percent = 0;
			contract.threshold_amount = 100;
			contract.threshold_recurring_extra_amount = 0;
			contract.threshold_recurring_extra_percent = 3;
		}
		return contract;
	}
}