<div class="lead_content">
    <form [formGroup]="leadCreateForm">
        <div class="row align-items-center m-4">
            <div class="col-md-5 col-sm-4 padd_lr0">
                <div class="left_lead">
                    <!-- <img src="assets/img/PNG/lead_icon.png"> -->
                    <a routerLink="/app/leads/list"><span class="fs-30 fw-700 decoration-none text-black">LEADS /</span></a>
                    <span class="fs-20 fw-500" *ngIf="router.url == '/app/leads/create'">CREATE LEAD</span>
                    <span class="fs-20 fw-500" *ngIf="router.url != '/app/leads/create'">UPDATE LEAD</span>
                </div>
            </div>
            <div class="col-md-7 col-sm-8 padd_lr0">
                <button class="create_btn btn btn-primary col-md-3 me-3" (click)="saveLead(1)" *ngIf="router.url == '/app/leads/create'">Save
                    and
                    Close</button>
                <button class="save_new_btn col-md-4 btn btn-secondary col-md-3 me-3" (click)="saveLead(2)"
                    *ngIf="router.url == '/app/leads/create'">Save and
                    New</button>
                <button class="save_new_btn btn btn-secondary col-md-3 me-3" (click)="saveLead(1)"
                    *ngIf="router.url != '/app/leads/create'">Update
                    Lead</button>
                
                    <button class="cancel_btn btn bg-white fs-16 fw-400 me-3 cursor col-md-4"  (click)="cancelLead()">Cancel</button>
                    <button class="cancel_btn btn bg-white fs-16 fw-400 me-3 cursor col-md-4" *ngIf="router.url != '/app/leads/create'"  (click)="dndLead()">{{is_dnd == 0
                        ? 'Enable DND':'Disable DND'}}</button>
                <button class="save_new_btn btn btn-secondary col-md-3 me-3"
                    *ngIf="router.url != '/app/leads/create' && (admin_check &&  admin_check['role_id'] == 10)"
                    (click)="openFollowUpModal()">Add Follow
                    Up</button>
            </div>
        </div>
        <div class="row card lead-card-body m-4">
            <div class="row p-3 m-0 mb-4 border-bottom">
                <div class="total_leads col-md-4 col-sm-6">
                    <span class="col-md-2">
                        <!-- <img src="assets/img/PNG/total_lead.png"> -->
                        <span>Lead Information</span>
                    </span>
                </div>
            </div>
            <div class="lead_create_body col-md-12 col-sm-12">
                <div class="row height_70">
                    <div class="col-md-6 col-sm-12">
                        <div class="form-group row m-0 mb-4">
                            <label for="lead_owner" class="col-sm-3 fs-14 fw-400">Lead Owner</label>
                            <div class="col-sm-9">
                                <input type="text" class="form-control" id="lead_owner" formControlName="added_by_name"
                                    readonly>
                                <!-- <div *ngIf="(buttonClick || leadCreateForm.added_by_name.touched) && leadCreateForm.added_by_name.errors"
                    class="invalid-feedback">
                    <div *ngIf="leadCreateForm.added_by_name.errors['required']">Lead owner is required</div>
                  </div> -->
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-12">
                        <div class="form-group row m-0 mb-4">
                            <label for="practice_name" class="col-sm-3 fs-14 fw-400">Business Name*</label>
                            <div class="col-sm-9">
                                <input type="text" class="form-control" id="practice_name"
                                    formControlName="practice_name"
                                    [ngClass]="{ 'is-invalid': (buttonClick || leadCreateForm.controls['practice_name'].touched)  &&  leadCreateForm.controls['practice_name'].errors }"
                                    trim="blur">
                                <div *ngIf="(buttonClick || leadCreateForm.controls['practice_name'].touched) && leadCreateForm.controls['practice_name'].errors"
                                    class="invalid-feedback">
                                    <div *ngIf="leadCreateForm.controls['practice_name'].errors['required']">Business name is required</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row height_70">
                    <div class="col-md-6 col-sm-12">
                        <div class="form-group row m-0 mb-4">
                            <label for="lead_name" class="col-sm-3 fs-14 fw-400">First Name</label>
                            <div class="col-sm-9">
                                <input type="text" class="form-control" id="lead_name" formControlName="first_name"
                                    [ngClass]="{ 'is-invalid': (buttonClick || leadCreateForm.controls['first_name'].touched)  &&  leadCreateForm.controls['first_name'].errors }"
                                    trim="blur">
                                <div *ngIf="(buttonClick || leadCreateForm.controls['first_name'].touched) && leadCreateForm.controls['first_name'].errors"
                                    class="invalid-feedback">
                                    <div *ngIf="leadCreateForm.controls['first_name'].errors['required']">First name is required</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-12">
                        <div class="form-group row m-0 mb-4">
                            <label for="last_name" class="col-sm-3 fs-14 fw-400">Last Name</label>
                            <div class="col-sm-9">
                                <input type="text" class="form-control" id="last_name" formControlName="last_name"
                                    [ngClass]="{ 'is-invalid': (buttonClick || leadCreateForm.controls['last_name'].touched)  &&  leadCreateForm.controls['last_name'].errors }"
                                    trim="blur">
                                <div *ngIf="(buttonClick || leadCreateForm.controls['last_name'].touched) && leadCreateForm.controls['last_name'].errors"
                                    class="invalid-feedback">
                                    <div *ngIf="leadCreateForm.controls['last_name'].errors['required']">Last name is required</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row height_70">
                    <div class="col-md-6 col-sm-12">
                        <div class="form-group row m-0 mb-4">
                            <label for="practice_email" class="col-sm-3 fs-14 fw-400">Business Email</label>
                            <div class="col-sm-9">
                                <div class="input-group">
                                    <input type="email" class="form-control" id="practice_email"
                                        formControlName="practice_email"
                                        [ngClass]="{ 'is-invalid': (buttonClick || leadCreateForm.controls['practice_email'].touched)  &&  leadCreateForm.controls['practice_email'].errors }">
                                    <div class="input-group-append">
                                        <button class="btn rounded btn-primary ms-3"
                                            (click)="validateEmailPhone(leadCreateForm.controls['practice_email'].value, 'email')">
                                            Check Duplicate
                                        </button>
                                    </div>
                                </div>
                                <!-- <input type="email" class="form-control" id="practice_email" formControlName="practice_email"
                    [ngClass]="{ 'is-invalid': (buttonClick || leadCreateForm.controls['practice_email'].touched)  &&  leadCreateForm.controls['practice_email'].errors }"
                    (blur)="validateEmailPhone($event.target.value, 'email')"> -->
                                <div *ngIf="(buttonClick || leadCreateForm.controls['practice_email'].touched) && leadCreateForm.controls['practice_email'].errors"
                                    class="invalid-feedback">
                                    <!-- <div *ngIf="leadCreateForm.controls['practice_email'].errors['required']">Email is required</div> -->
                                    <div *ngIf="leadCreateForm.controls['practice_email'] && leadCreateForm.controls['practice_email'].errors && leadCreateForm.controls['practice_email'].errors['pattern']">Business email is invalid</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-12">
                        <div class="form-group row m-0 mb-4">
                            <label for="alternate_email" class="col-sm-3 fs-14 fw-400">Alternate Email</label>
                            <div class="col-sm-9">
                                <input type="email" class="form-control" id="alternate_email"
                                    formControlName="alternate_email"
                                    [ngClass]="{ 'is-invalid': (buttonClick || leadCreateForm.controls['alternate_email'].touched)  &&  leadCreateForm.controls['alternate_email'].errors }">
                                <div *ngIf="(buttonClick || leadCreateForm.controls['alternate_email'].touched) && leadCreateForm.controls['alternate_email'].errors"
                                    class="invalid-feedback">
                                    <!-- <div *ngIf="leadCreateForm.controls['practice_email'].errors['required']">Email is required</div> -->
                                    <div *ngIf="leadCreateForm.controls['alternate_email'] && leadCreateForm.controls['alternate_email'].errors && leadCreateForm.controls['alternate_email'].errors['pattern']">Alternate email is invalid
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row height_70">
                    <div class="col-md-6 col-sm-12">
                        <div class="form-group row m-0 mb-4">
                            <label for="phone_no" class="col-sm-3 fs-14 fw-400">Business Phone Number*</label>
                            <div class="col-sm-9">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text h-100">+1</span>
                                    </div>
                                    <input type="text" class="form-control"
                                        id="phone_no" formControlName="practice_mobile" [mask]="_shared.mask"
                                        [ngClass]="{ 'is-invalid': (buttonClick || leadCreateForm.controls['practice_mobile'].touched)  &&  leadCreateForm.controls['practice_mobile'].errors }">
                                    <div class="input-group-append">
                                        <button class="btn rounded  btn-primary ms-3"
                                            (click)="validateEmailPhone(leadCreateForm.controls['practice_mobile'].value, 'phone')">
                                            Check Duplicate
                                        </button>
                                    </div>
                                    <div *ngIf="(buttonClick || leadCreateForm.controls['practice_mobile'].touched) && leadCreateForm.controls['practice_mobile'].errors"
                                        class="invalid-feedback">
                                        <div *ngIf="leadCreateForm.controls['practice_mobile'].errors['required']">Business phone number is
                                            required</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-12">
                        <div class="form-group row m-0 mb-4">
                            <label for="alternate_mobile" class="col-sm-3 fs-14 fw-400">Alternate Phone Number</label>
                            <div class="col-sm-9">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">+1</span>
                                    </div>
                                    <input type="text" class="form-control" [mask]="_shared.mask"
                                        id="alternate_mobile" formControlName="alternate_mobile">
                                </div>
                                <!-- <div *ngIf="(buttonClick || leadCreateForm.alternate_mobile.touched) && leadCreateForm.alternate_mobile.errors"
                    class="invalid-feedback">
                    <div *ngIf="leadCreateForm.alternate_mobile.errors['required']">doctor phone number is required</div>
                  </div> -->
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row height_70">
                    <div class="col-md-6 col-sm-12">
                        <div class="form-group row m-0 mb-4">
                            <label for="website" class="col-sm-3 fs-14 fw-400">Website</label>
                            <div class="col-sm-9">
                                <input type="text" class="form-control" id="website" formControlName="website">
                                <!-- <div *ngIf="(buttonClick || leadCreateForm.website.touched) && leadCreateForm.website.errors"
                    class="invalid-feedback">
                    <div *ngIf="leadCreateForm.website.errors.pattern">Website url is invalid</div>
                  </div> -->
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6 col-sm-12">
                        <div class="form-group row m-0 mb-4">
                            <label for="lead_source" class="col-sm-3 fs-14 fw-400">Lead Source*</label>
                            <div class="col-sm-9">
                                <select id="lead_source" class="form-control" formControlName="lead_source"
                                    [ngClass]="{ 'is-invalid': (buttonClick || leadCreateForm.controls['lead_source'].touched)  &&  leadCreateForm.controls['lead_source'].errors }">
                                    <option value="">Select your lead source</option>
                                    <option *ngFor="let lead of lead_source" [value]="lead.lead_source_id">
                                        {{lead.source_name}}</option>
                                </select>
                                <div *ngIf="(buttonClick || leadCreateForm.controls['lead_source'].touched) && leadCreateForm.controls['lead_source'].errors"
                                    class="invalid-feedback">
                                    <div *ngIf="leadCreateForm.controls['lead_source'].errors['required']">Lead source is required</div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="row height_70">
                    <div class="col-sm-12 col-md-6">
                        <div class="form-group row m-0 mb-4">
                            <label for="Industry" class="col-sm-3 fs-14 fw-400">Industry*</label>
                            <div class="col-sm-9">
                                <select required id="speciality" formControlName="ind_id"
                                    [ngClass]="{ 'is-invalid': (buttonClick || leadCreateForm.controls['ind_id'].touched)  &&  leadCreateForm.controls['ind_id'].errors }"
                                    name="industry" class="form-control" style="height: 40px;"
                                    (change)="speciality_id='';doctorIndustry($event, 'yes')">
                                    <option value="">Select your industry*</option>
                                    <option *ngFor="let ind of industriesList" [value]="ind.ind_id">
                                        {{ind.ind_name}}</option>
                                </select>
                                <div *ngIf="(buttonClick || leadCreateForm.controls['ind_id'].touched) && leadCreateForm.controls['ind_id'].errors"
                                    class="invalid-feedback">
                                    <div *ngIf="leadCreateForm.controls['ind_id'].errors['required']">Industry is required</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6 col-sm-12">
                        <div class="form-group row m-0 mb-4">
                            <label for="speciality" class="col-sm-3 fs-14 fw-400">Sub Industry*</label>
                            <div class="col-sm-9">
                                <select id="speciality" class="form-control" formControlName="speciality_id"
                                    [ngClass]="{ 'is-invalid': (buttonClick || leadCreateForm.controls['speciality_id'].touched)  &&  leadCreateForm.controls['speciality_id'].errors }">
                                    <option value="">Select your sub industry</option>
                                    <option *ngFor="let speciality of specialities"
                                        [value]="speciality.doctor_speciality">
                                        {{speciality.speciality_name}}</option>
                                </select>
                                <div *ngIf="(buttonClick || leadCreateForm.controls['speciality_id'].touched) && leadCreateForm.controls['speciality_id'].errors"
                                    class="invalid-feedback">
                                    <div *ngIf="leadCreateForm.controls['speciality_id'].errors['required']">Sub Industry is required</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="row height_70">
                    <div class="col-md-6 col-sm-12">
                        <div class="form-group row m-0 mb-4">
                            <label for="zip_code" class="col-sm-3 fs-14 fw-400">Zip Code*</label>
                            <div class="col-sm-9">
                                <!-- implement zip code -->
                                <!-- <input type="text" class="form-control" id="zip_code"
                                    (input)="getZip($event.target.value)" [typeahead]="zip"
                                    [typeaheadOptionField]="'zip'" (typeaheadOnSelect)="typeaheadOnSelect($event)"
                                    formControlName="zip_code"
                                    [ngClass]="{ 'is-invalid': (buttonClick || leadCreateForm.zip_code.touched)  &&  leadCreateForm.zip_code.errors }"
                                    autocomplete="nope">
                                <div *ngIf="(buttonClick || leadCreateForm.zip_code.touched) && leadCreateForm.zip_code.errors"
                                    class="invalid-feedback">
                                    <div *ngIf="leadCreateForm.zip_code.errors['required']">Zip code is required</div>
                                    <div *ngIf="leadCreateForm.zip_code.errors.pattern">Only number allowed</div>
                                </div> -->
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6 col-sm-12">
                        <div class="form-group row m-0 mb-4">
                            <label for="city" class="col-sm-3 fs-14 fw-400">City*</label>
                            <div class="col-sm-9">
                                <input type="text" readonly class="form-control" id="state" formControlName="city"
                                    [ngClass]="{ 'is-invalid': (buttonClick || leadCreateForm.controls['city'].touched)  &&  leadCreateForm.controls['city'].errors }">
                                <div *ngIf="(buttonClick || leadCreateForm.controls['city'].touched) && leadCreateForm.controls['city'].errors"
                                    class="invalid-feedback">
                                    <div *ngIf="leadCreateForm.controls['city'].errors['required']">City is required</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row height_70">
                    <div class="col-md-6 col-sm-12">
                        <div class="form-group row m-0 mb-4">
                            <label for="state" class="col-sm-3 fs-14 fw-400">State*</label>
                            <div class="col-sm-9">
                                <input type="text" readonly class="form-control" id="state" formControlName="state"
                                    [ngClass]="{ 'is-invalid': (buttonClick || leadCreateForm.controls['state'].touched)  &&  leadCreateForm.controls['state'].errors }">
                                <div *ngIf="(buttonClick || leadCreateForm.controls['state'].touched) && leadCreateForm.controls['state'].errors"
                                    class="invalid-feedback">
                                    <div *ngIf="leadCreateForm.controls['state'].errors['required']">State is required</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6 col-sm-12">
                        <div class="form-group row m-0 mb-4">
                            <label for="timezone" class="col-sm-3 fs-14 fw-400">Timezone*</label>
                            <div class="col-sm-9">
                                <select class="form-control" id="timezone" formControlName="timezone"
                                    [ngClass]="{ 'is-invalid': (buttonClick || leadCreateForm.controls['timezone'].touched)  &&  leadCreateForm.controls['timezone'].errors }">

                                    <option value="">Select your timezone</option>
                                    <option *ngFor="let timezone of timezone_list" [value]="timezone.offset">
                                        {{timezone.offset}}</option>
                                </select>
                                <div *ngIf="(buttonClick || leadCreateForm.controls['timezone'].touched) && leadCreateForm.controls['timezone'].errors"
                                    class="invalid-feedback">
                                    <div *ngIf="leadCreateForm.controls['timezone'].errors['required']">Timezone is required</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="col-md-6 col-sm-12">
              <div class="form-group row">
                <label for="lead_source" class="col-sm-3 fs-14 fw-400">Lead Source*</label>
                <div class="col-sm-9">
                  <select id="lead_source" class="form-control" formControlName="lead_source"
                    [ngClass]="{ 'is-invalid': (buttonClick || leadCreateForm.controls['lead_source'].touched)  &&  leadCreateForm.controls['lead_source'].errors }">
                    <option value="">Select your lead source</option>
                    <option *ngFor="let lead of lead_source" [value]="lead.lead_source_id">{{lead.source_name}}</option>
                  </select>
                  <div *ngIf="(buttonClick || leadCreateForm.controls['lead_source'].touched) && leadCreateForm.controls['lead_source'].errors"
                    class="invalid-feedback">
                    <div *ngIf="leadCreateForm.controls['lead_source'].errors['required']">Lead source is required</div>
                  </div>
                </div>
              </div>
            </div> -->
                </div>
                <div class="row height_100">
                    <div class="col-md-6 col-sm-12">
                        <div class="form-group row m-0 mb-4">
                            <label for="practice_address" class="col-sm-3 fs-14 fw-400">Address*</label>
                            <div class="col-sm-9">
                                <textarea class="form-control" rows="2" id="practice_address"
                                    formControlName="practice_address"
                                    [ngClass]="{ 'is-invalid': (buttonClick || leadCreateForm.controls['practice_address'].touched)  &&  leadCreateForm.controls['practice_address'].errors }"
                                    trim="blur"></textarea>
                                <div *ngIf="(buttonClick || leadCreateForm.controls['practice_address'].touched) && leadCreateForm.controls['practice_address'].errors"
                                    class="invalid-feedback">
                                    <div *ngIf="leadCreateForm.controls['practice_address'].errors['required']">Address is required</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6 col-sm-12" *ngIf="router.url == '/app/leads/create'">
                        <div class="form-group row m-0 mb-4">
                            <label for="comment" class="col-sm-3 fs-14 fw-400">Remarks</label>
                            <div class="col-sm-9">
                                <textarea class="form-control" id="comment" rows="2"
                                    formControlName="comment"></textarea>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-12" *ngIf="router.url != '/app/leads/create'">
                        <div class="form-group row m-0 mb-4">
                            <label for="comment" class="col-sm-3 fs-14 fw-400">Remarks*</label>
                            <div class="col-sm-9">
                                <textarea class="form-control" id="comment" rows="2" formControlName="comment"
                                    [ngClass]="{ 'is-invalid': (buttonClick || leadCreateForm.controls['comment'].touched)  &&  leadCreateForm.controls['comment'].errors }"
                                    trim="blur"></textarea>
                                <div *ngIf="(buttonClick || leadCreateForm.controls['comment'].touched) && leadCreateForm.controls['comment'].errors"
                                    class="invalid-feedback">
                                    <div *ngIf="leadCreateForm.controls['comment'].errors['required']">Remarks required</div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="row height_90">
                    <div class="col-md-6 col-sm-12">
                        <div class="form-group row m-0 mb-4">
                            <label for="description" class="col-md-3 col-sm-3 fs-14 fw-400">Description</label>
                            <div class="col-sm-9">
                                <textarea class="form-control" id="description" rows="2"
                                    formControlName="description"></textarea>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row height_90">

                    <div class="col-md-6 col-sm-12" *ngIf="router.url != '/app/leads/create'">
                        <div class="form-group row m-0 mb-4">
                            <label for="lead_status" class="col-sm-3 fs-14 fw-400">Lead Status* </label>
                            <div class="col-sm-9">
                                <select id="lead_status" class="form-control" formControlName="lead_status"
                                    [ngClass]="{ 'is-invalid': (buttonClick || leadCreateForm.controls['lead_status'].touched)  &&  leadCreateForm.controls['lead_status'].errors }">
                                    <option value="">Select lead status</option>
                                    <option *ngFor="let lead of leadStatus" [value]="lead.status_id"
                                        [disabled]="can_assign == 0 && (lead.status_id==1 || lead.status_id==2)">
                                        {{lead.status_name}}</option>
                                </select>
                                <div *ngIf="(buttonClick || leadCreateForm.controls['lead_status'].touched) && leadCreateForm.controls['lead_status'].errors"
                                    class="invalid-feedback">
                                    <div *ngIf="leadCreateForm.controls['lead_status'].errors['required']">Lead Status is required</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-12"
                        *ngIf="can_assign == 1 && this.leadCreateForm.controls['lead_status'].value == 2">
                        <div class="form-group row m-0 mb-4">
                            <label for="spoke_to" class="col-sm-3 fs-14 fw-400">Search Assignee*</label>
                            <div class="col-sm-9">
                                <ngx-select formControlName="assigned_to" [items]="admin_list"
                                    optionValueField="admin_id" optionTextField="admin_name"
                                    (select)="selectAdmin($event)" [allowClear]="true"
                                    (selectionChanges)="adminClear($event)" (typed)="getAdminList($event)"
                                    placeholder="please search assignee">
                                    <ng-template ngx-select-option-selected let-option>
                                        {{option.data.admin_name + ' ('+option.data.admin_email+')'}}
                                    </ng-template>
                                    <ng-template ngx-select-option let-option let-text="text">
                                        {{option.data.admin_name}} <br>
                                        <small>{{option.data.admin_email}}</small>
                                    </ng-template>
                                    <ng-template ngx-select-option-not-found>
                                        Not found
                                    </ng-template>
                                </ngx-select>
                            </div>
                        </div>
                    </div>


                    <div class="col-md-6 col-sm-12" *ngIf="this.leadCreateForm.controls['lead_status'].value == 10">
                        <div class="form-group row m-0 mb-4">
                            <label for="followup_timezone" class="col-sm-3 fs-14 fw-400">Follow Up Timezone*</label>
                            <div class="col-sm-9">
                                <select class="form-control" id="followup_timezone" formControlName="followup_timezone"
                                    [ngClass]="{ 'is-invalid': (buttonClick || leadCreateForm.controls['followup_timezone'].touched)  &&  leadCreateForm.controls['followup_timezone'].errors }">
                                    <option value="">Select your timezone</option>
                                    <option *ngFor="let timezone of timezone_list" [value]="timezone.offset">
                                        {{timezone.offset}}</option>
                                </select>
                                <div *ngIf="(buttonClick || leadCreateForm.controls['followup_timezone'].touched) && leadCreateForm.controls['followup_timezone'].errors"
                                    class="invalid-feedback">
                                    <div *ngIf="leadCreateForm.controls['followup_timezone'].errors['required']">Follow Up timezone is
                                        required</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row height_90"
                    *ngIf="this.leadCreateForm.controls['lead_status'].value == 10 || this.leadCreateForm.controls['lead_status'].value == 11 ">
                    <div class="col-md-6 col-sm-12">
                        <div class="form-group row m-0 mb-4">
                            <label for="followup_timezone" class="col-sm-3 fs-14 fw-400">Spoke To*</label>
                            <div class="col-sm-9">
                                <input type="text" class="form-control" id="spoke_to" formControlName="spoke_to"
                                    [ngClass]="{ 'is-invalid': (buttonClick || leadCreateForm.controls['spoke_to'].touched)  &&  leadCreateForm.controls['spoke_to'].errors }">
                                <div *ngIf="(buttonClick || leadCreateForm.controls['spoke_to'].touched) && leadCreateForm.controls['spoke_to'].errors"
                                    class="invalid-feedback color_red">
                                    <div *ngIf="leadCreateForm.controls['spoke_to'].errors['required']">Spoke to is required</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6 col-sm-12"
                        *ngIf="this.leadCreateForm.controls['lead_status'].value == 10 || this.leadCreateForm.controls['lead_status'].value == 11">
                        <div class="form-group row m-0 mb-4">
                            <label for="followup_date" class="col-sm-3 fs-14 fw-400"
                                *ngIf="this.leadCreateForm.controls['lead_status'].value == 10">Follow Up Date*</label>
                            <label for="followup_date" class="col-sm-3 fs-14 fw-400"
                                *ngIf="this.leadCreateForm.controls['lead_status'].value == 11">Meeting Date*</label>
                            <div class="col-sm-9">
                                <input type="text" class="form-control" formControlName="followup_date"
                                    name="followup_date" bsDatepicker [bsConfig]="{containerClass: 'theme-blue' }"
                                    [minDate]="minDate" readonly>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="row height_100"
                    *ngIf="this.leadCreateForm.controls['lead_status'].value == 10 || this.leadCreateForm.controls['lead_status'].value == 11">
                    <div class="col-md-6 col-sm-12">
                        <div class="form-group row m-0 mb-4">

                            <label for="followup_time" class="col-sm-3 fs-14 fw-400"
                                *ngIf="this.leadCreateForm.controls['lead_status'].value == 10">Follow Up Time*</label>
                            <label for="followup_time" class="col-sm-3 fs-14 fw-400"
                                *ngIf="this.leadCreateForm.controls['lead_status'].value == 11">Meeting Time*</label>
                            <div class="col-sm-9">
                                <timepicker formControlName="followup_time" name="followup_time"
                                    [ngClass]="{ 'is-invalid': (buttonClick || leadCreateForm.controls['followup_time'].touched)  &&  leadCreateForm.controls['followup_time'].errors }"
                                    trim="blur"></timepicker>
                                <div *ngIf="(buttonClick || leadCreateForm.controls['followup_time'].touched) && leadCreateForm.controls['followup_time'].errors"
                                    class="invalid-feedback"
                                    [ngStyle]="{'display': (buttonClick || leadCreateForm.controls['followup_time'].touched) && leadCreateForm.controls['followup_time'].errors ? 'block' : 'none' }">
                                    <div *ngIf="leadCreateForm.controls['followup_time'].errors['required']">Follow up time is required</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </div>
    </form>

    <!-- note section -->
    <div class="row m-4 card lead-card-body" *ngIf="note_listing && note_listing.length>0">
        <div class="row total_leads_row">
            <div class="total_leads col-md-4 col-sm-6">
                <span class="col-md-2">
                    <img src="assets/img/PNG/total_lead.png">
                    <span>Note Information</span>
                </span>
            </div>
        </div>
        <div class="lead_note_section col-md-12 col-sm-12">
            <div class="row">
                <div class="col-md-8 col-sm-6 card card-body border p-2">
                    <div *ngIf="note_listing && note_listing.length>0"
                        [ngStyle]="{'overflow-y':note_listing.length>3 ? 'scroll':'none'}" style="height: 340px;">
                        <label class="col-sm-12 fs-14 fw-400">Notes {{timezone?'(Time in ' +
                            timezoneName+')':''}}</label>
                        <!-- <div class="col-12 mb-1 border" *ngFor="let note of note_listing; let i=index">
                <h5>{{note.date_added | date:'mediumDate'}}</h5>
                <p>{{note.comment}}</p>
              </div> -->

                        <div class="col-12 b mb-1 p-2 rounded" *ngFor="let note of note_listing; ">
                            <div class="d-flex align-items-center mb-1">
                                <i tappable class="fa fa-user mr-2"></i>
                                <p class="m-0">
                                    <strong>{{note.action_taken_by}}</strong> {{note.date_added |
                                    date:'medium':timezone}}
                                </p>
                            </div>
                            <p class="speech-bubble show-enter"><span class="note_action_type">{{note.action_type}} :
                                </span> <span [innerHTML]="note.comment"></span></p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 col-sm-6">
                    <div class="form-group row">
                        <label for="note" class="col-sm-3 fs-14 fw-400 text-right">Note</label>
                        <div class="col-sm-9">
                            <textarea class="form-control" id="note" rows="10" [(ngModel)]="comment"></textarea>
                        </div>
                    </div>
                    <button class="btn save_btn" (click)="addNotes()">Add</button>
                </div>
            </div>
        </div>
    </div>

    <!-- lead history details-->
    <div class="row m-4 card lead-card-body" *ngIf="lead_history && lead_history.length>0">
        <div class="row total_leads_row">
            <div class="total_leads col-md-4 col-sm-6">
                <span class="col-md-2">
                    <img src="assets/img/PNG/total_lead.png">
                    <span>Lead History</span>
                </span>
            </div>
        </div>
        <div class="table-responsive col-md-12"
            [ngStyle]="{'overflow-y':lead_history.length>10 ? 'scroll':'','height':lead_history.length>10 ? '450px':''}">
            <table class="table table-striped">
                <thead>
                    <tr>
                        <th>S No.</th>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Business Name</th>
                        <th>Email</th>
                        <th>Phone</th>
                        <th>Specialty</th>
                        <th>Lead Status</th>
                        <th>Assigned By</th>
                        <th>Updated By</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let lead of lead_history; let i=index;">
                        <td>{{i+1}}</td>
                        <td>{{lead.first_name || '-'}}</td>
                        <td>{{lead.last_name || '-'}}</td>
                        <td>{{lead.practice_name || '-'}}</td>
                        <td>{{lead.practice_email || '-'}}</td>
                        <td>{{lead.practice_mobile !=0 ?'+1-'+(lead.practice_mobile):'N.A.'}}</td>
                        <td>{{lead.speciality_name || '-'}}</td>
                        <td>
                            <span class="lead_txt" [ngStyle]="{'color': lead.color_code}">{{lead.status_name}}</span>
                        </td>
                        <td>{{lead.assigned_by_name || '-'}} </td>
                        <td>{{lead.updated_by_name || '-'}} </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>