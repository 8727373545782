
<div class="p-3">

<h3 class="m-0 fs-24 fw-700 text-secondary mb-3">Customer Profile</h3>
<span
  class="badge badge-success h3"
  *ngIf="patient && patient.verification_date"
  >Verified by System
  <span>
    <i
      class="fa fa-info-circle"
      data-container="body"
      data-placement="top"
      tooltip="Verification Date: {{
        patient.verification_date | date : 'MM-dd-yyyy' : timezone
      }}, IP: {{ patient.verification_ip }}"
    ></i>
  </span>
</span>
<div class="row">
  <div class="col-12 col-sm-10">
    <div class="card">
      <form
        *ngIf="patient"
        [formGroup]="profileForm"
        (ngSubmit)="updatePatientProfile()"
      >
        <tabset [justified]="true">
          <div class="p-10 row">
            <div class="col-md-4 col-12">
              <div class="card border rounded">
                <div class="card-body text-center">
                  <div class="pv-lg">
                    <a
                      style="
                        margin-top: 0px;
                        margin-right: 0px;
                        font-size: 0;
                        opacity: 1;
                      "
                    >
                      <img
                        [src]="
                          patient.patient_image
                            ? patient.patient_image
                            : 'assets/images/svg/avatar.svg'
                        "
                        class="rounded-circle mb-2 img-fluid proPic"
                        onError="this.src='assets/images/svg/avatar.svg'"
                      />
                    </a>
                    <input
                      class="d-none upload profileUpload"
                      id="fileInput"
                      data-button-text="Single"
                      uploader="uploader"
                      accept="image/*"
                      type="file"
                      formControlName="imgUploader"
                      (change)="fileChangeEvent($event)"
                    />
                  </div>
                  <div class="text-center">
                    <label for="fileInput" class="btn btn-primary"
                      >Upload Profile Picture</label
                    >
                  </div>
                  <div class="text-center dndAnimation">
                    <span *ngIf="patient.dnd == 1" class="h4">
                      <img src="./assets/images/red.svg" class="dnd" /> Do not
                      Disturb
                    </span>
                  </div>
                </div>
                <!-- Small modal -->
                <div
                  bsModal
                  [config]="{ ignoreBackdropClick: true }"
                  #smModal="bs-modal"
                  class="modal fade"
                  tabindex="-1"
                  role="dialog"
                  aria-labelledby="mySmallModalLabel"
                  aria-hidden="true"
                >
                  <div class="modal-dialog modal-md">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h4 class="modal-title">Crop Profile Image</h4>
                        <button
                          type="button"
                          class="close"
                          aria-label="Close"
                          (click)="
                            smModal.hide();
                            this.data1.image = '';
                            imageUploader = ''
                          "
                        >
                          &times;
                        </button>
                      </div>
                      <div class="modal-body">
                        <div class="image-cropper-container">
                          <div class="row text-center">
                            <div class="col-12">
                              <div class="card">
                                <div class="card-body">
                                  <div class="cropArea">
                                    <image-cropper
                                      style="max-height: 300px"
                                      [imageChangedEvent]="croppedImage"
                                      [maintainAspectRatio]="true"
                                      [aspectRatio]="1 / 1"
                                      [cropperMinHeight]="100"
                                      [cropperMinWidth]="100"
                                      [output]="'blob'"
                                      format="png"
                                      (imageCropped)="imageCropped($event)"
                                    >
                                    </image-cropper>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-12">
                              <div class="card">
                                <div class="card-body">
                                  <div class="result" *ngIf="croppedImage">
                                    <p class="m-0 fs-14 fw-400">
                                      Cropped image
                                    </p>
                                    <img
                                      [src]="croppedImage"
                                      class="img-fluid"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="text-center">
                        <button class="btn btn-primary mb-2" type="submit">
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--Patients Verification Details-->
              <div
                class="card card-body border"
                *ngIf="patient && patient.verification_date"
              >
                <label>Verification Details</label>
                <div class="col-12 border rounded p-2 mb-2 mr-t">
                  <div class="row">
                    <div class="col-6 pr-0">Status:</div>
                    <div class="col-6 p-0">
                      <p class="m-0">
                        Verified&nbsp;&nbsp;<i
                          style="color: #36af47"
                          class="fa fa-check-circle greenIcon"
                        ></i>
                      </p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-6 pr-0">Verified on:</div>
                    <div class="col-6 p-0">
                      {{
                        patient.verification_date | date : "medium" : timezone
                      }}
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-6 pr-0">Verified from:</div>
                    <div class="col-6 p-0">
                      {{ patient.verification_ip }}
                    </div>
                  </div>
                </div>
              </div>
              <!--Patients Card Details-->

              <div class="card card-body border">
                <label class="text-bold mb-2">Customer Card Details</label>
                <div
                  style="max-height: 250px;overflow-y: auto;padding-right: 10px;">
                  <div *ngFor="let card of patient.payment_sources">
                      <div class="d-flex justify-content-between align-items-center rounded p-2 mb-2 border" *ngIf=" patient.payment_sources.length > 0 && card.payment_source_type != 1 " >
                        <span>
                          <i class="fa fa-credit-card"></i>
                          &nbsp;&nbsp;XXXX-XXXX-XXXX-{{ card.last4 }}
                          <i *ngIf=" !_shared.routerAuth.restricted_buttons.patient_card_delete " (click)=" deleteCard( card.source_id, card.payment_source_type ) " class="fa fa-trash pt-1 pull-end cursor" >
                          </i>
                         </span>
                          <span [style.color]=" currentYear > card.exp_year || (currentMonth > card.exp_month && currentYear === card.exp_year) ? 'red' : '' " class="m-0 ps-3"
                             *ngIf="card.exp_month" >
                           {{ card.exp_month }}/{{ card.exp_year }}
                          </span>
                      </div>
                  </div>
                </div>
                <label *ngIf=" patient.payment_sources && patient.payment_sources.length == 0 " >No Card Added Yet</label >
                <p (click)="GetStripeToken('clear'); clearAddCardNote()" class="text-right" style="text-decoration: underline" tappable >
                  Add Card
                </p>
              </div>
              <!--Customers Bank Details-->
              <div class="card card-body border" *ngIf="patient.show_ach == 1">
                <label class="text-bold">Customer Bank Account Details</label>
                <div
                  style="
                    max-height: 250px;
                    overflow-y: auto;
                    padding-right: 10px;
                  "
                >
                  <div
                    *ngFor="let card of bank_accounts"
                    class="col-12"
                    style="cursor: pointer"
                  >
                    <div
                      *ngIf="
                        bank_accounts &&
                        bank_accounts.length > 0 &&
                        card.payment_source_type == 1
                      "
                    >
                      <div class="row border rounded p-2 mb-2 mr-t">
                        <p class="col-md-8 col-sm-12 mb-0">
                          <i class="fa fa-bank"></i>&nbsp;&nbsp;XXXX-XXXX-{{
                            card.last4
                          }}
                        </p>

                        <div class="col-md-4 col-sm-12 p-0 text-right">
                          <!-- <p class="m-0" style="color:#36af47;" *ngIf="card.bank_status=='succeeded'">
                              <i class="fa fa-check-circle greenIcon"></i>&nbsp;&nbsp;Verified
                            </p> -->
                          <i
                            *ngIf="
                              !_shared.routerAuth.restricted_buttons
                                .patient_card_delete && !card.default
                            "
                            (click)="
                              deleteCard(
                                card.source_id,
                                card.payment_source_type
                              )
                            "
                            style="cursor: pointer"
                            class="fa fa-trash pt-1 pull-right pr-2"
                          ></i>
                        </div>
                        <!-- <div class="col-sm-12 p-0 text-right">
                            <p class="m-0 text-danger " *ngIf="card.bank_status=='reauthenticate'"> Reauthentication Required
                            </p>
                          </div> -->
                      </div>
                    </div>
                  </div>
                </div>
                <label
                  *ngIf="
                    plaid_accounts &&
                    plaid_accounts.length == 0 &&
                    bank_accounts.length == 0
                  "
                  >No Bank Account Added Yet
                </label>
                <div
                  class="row"
                  *ngIf="
                    plaid_accounts &&
                    plaid_accounts.length > 0 &&
                    plaid_accounts[0].type != 0 &&
                    plaid_accounts[0].status == 'pending' &&
                    !_shared.routerAuth.restricted_buttons.verify_bank
                  "
                >
                  <p class="col-md-6 text-left">XXXX-XXXX-XXXX</p>
                  <p class="text-right col-md-6" tappable>
                    <u>Verify Bank Account Details</u>
                  </p>
                </div>

                <p
                  *ngIf="
                    patient &&
                    patient.show_ach == 1 &&
                    plaid_accounts &&
                    plaid_accounts.length &&
                    plaid_accounts[0].status == 'verification_failed' &&
                    plaid_accounts[0].type == 1
                  "
                  class="text-danger text-right"
                >
                  Verification Failed
                </p>
                <!-- <div class="row" *ngIf="!mainService.routerAuth.restricted_buttons.check_balance" >
                    <div class="col-md-6">
                      <span class="text-left font-size-16" tappable (click)="checkPatientBalance();"><strong><u>Check Balance</u></strong></span>
                      <span *ngIf="patient_balance" class="font-size-16">: ${{patient_balance}}</span>
                    </div>
                  </div> -->
              </div>

              <!--Customers DL Details-->
              <div class="card card-body border">
                <div class="col-md-12 col-sm-12 col-xs-12 pl-0">
                  <label class="text-bold">Proof of ID</label>
                  <div class="col-12 col-md-12 col-lg-12">
                    <div
                      class="row pt-2 pb-2"
                      style="background-color: #f9f9f9"
                      style="align-items: center; display: flex"
                    >
                      <div class="col-8 col-md-10">
                        <span
                          style="font-size: 13px; color: #313131"
                          *ngIf="!patient.dl_doc || patient.dl_doc == null"
                          >Please upload a clear copy of your Driver’s License
                          or another government-issued proof of ID.</span
                        >
                        <span
                          style="font-size: 13px"
                          *ngIf="patient.dl_doc && patient.dl_doc != null"
                          ><a
                            href="{{ patient.dl_doc }}"
                            target="_blank"
                            style="text-decoration: none"
                            class="mr-2"
                            ><i
                              class="fa fa-file-text-o mr-2"
                              style="font-size: 20px; cursor: pointer"
                            ></i
                            >Proof of ID
                          </a></span
                        >
                      </div>
                      <div class="col-4 col-md-2 col-lg-2 text-right">
                        <div *ngIf="!patient.dl_doc || patient.dl_doc == null">
                          <label
                            class="text-primary text-center"
                            for="fileInputNew"
                            style="cursor: pointer"
                          >
                            <img src="./assets/images/svg/upload-icon.svg" />
                          </label>
                          <input
                            class="d-none upload"
                            id="fileInputNew"
                            accept="image/*,.pdf"
                            type="file"
                            formControlName="imgUploader"
                            (change)="fileChangeEventDL($event)"
                            (click)="onFileInputClick($event)"
                          />
                        </div>
                        <div *ngIf="patient.dl_doc && patient.dl_doc != null">
                          <span class="m-l-15">
                            <i
                              class="fa fa-trash"
                              style="
                                color: #f05050;
                                font-size: 22px;
                                cursor: pointer;
                              "
                              (click)="deleteUploadedDoc(patient.dl_id)"
                            ></i>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-8 col-12">
              <div class="card border rounded">
                <div class="card-body p-0">
                  <p class="text-success">
                    <span *ngIf="patient.id_verification == 1"
                      ><i class="fa fa-check-circle-o"></i> Customer's Photo ID
                      Verified.</span
                    >
                    <span *ngIf="patient.people_search_verification == 1"
                      ><i class="fa fa-check-circle-o"></i> People search
                      completed & verified.</span
                    >
                  </p>
                  <div class="row m-0">
                    <div class="col-4">
                      <div class="form-group">
                        <label class="fs-14 fw-400">First Name</label>
                        <input
                          type="text"
                          readonly
                          formControlName="fname"
                          class="input_field"
                        />
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="form-group">
                        <label class="fs-14 fw-400">Last Name</label>
                        <input
                          type="text"
                          readonly
                          formControlName="lname"
                          class="input_field"
                        />
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group">
                        <label class="fs-14 fw-400">DL Number</label>
                        <input
                          type="text"
                          readonly
                          formControlName="dl_number"
                          class="input_field"
                        />
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group">
                        <label class="fs-14 fw-400">{{ patient.identity_label }}</label>
                        <div class="d-flex align-items-center input_field p-0">
                          <input
                            type="text"
                            readonly
                            class="input_field border-0 border-end m-0 flex-fill rounded-0"
                            formControlName="patient_ssn"
                          />
                        
                            <span class="bg-transparent border-0 h-100 hw-40 d-flex align-items-center justify-content-center" type="button" (click)="fullSSNModal()"
                            *ngIf="patient.patient_ssn && !show_full_ssn && !_shared.routerAuth.restricted_buttons.show_full_ssn">
                              <i class="fa fa-eye"></i>
                            </span>
                       
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group">
                        <label class="fs-14 fw-400">Date of Birth</label>
                        <input
                          type="text"
                          readonly
                          formControlName="date_of_birth"
                          class="input_field"
                        />
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group">
                        <label class="fs-14 fw-400">Date of Registration</label>
                        <input
                          type="text"
                          readonly
                          formControlName="date_registered"
                          class="input_field"
                        />
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group">
                        <label class="fs-14 fw-400">Email &nbsp;-&nbsp;<span
                            [ngClass]="{
                              'text-danger': patient.is_email_verified == 0,
                              'text-success': patient.is_email_verified != 0
                            }"
                            >{{
                              patient.is_email_verified == 0
                                ? "Not Verified"
                                : "Verified"
                            }}</span
                          ></label
                        >
                        <div class="d-flex align-items-center input_field p-0">
                          <input
                            type="email"
                            readonly
                            email
                            formControlName="email"
                            class="input_field border-0 border-end m-0 flex-fill rounded-0"
                          />
                          
                            <span
                              class="bg-transparent border-0 h-100 hw-80 d-flex align-items-center justify-content-center fs-14"
                              type="button"
                              (click)="updateEmailPop()"
                            >
                              Update
                            </span>
                        
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group">
                        <label class="fs-14 fw-400" >Phone Number
                          <i class="fa fa-check-circle-o text-success"
                            tooltip="Verified"
                            *ngIf="patient.phone_number_verification == 1"></i
                        ></label>
                        <div class="d-flex align-items-center input_field p-0">
                      
                            <span class="bg-transparent border-0 h-100 hw-40 d-flex align-items-center justify-content-center fs-16">
                              {{patient.patient_mobile_country_code }}
                            </span>
                      
                          <input
                            maxlength="12"
                            minlength="6"
                            class="input_field border-0 border-end m-0 flex-fill rounded-0"
                            readonly
                            formControlName="mobile"
                            type="text"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="d-flex justify-content-between align-items-center">
                          <label class="fs-14 fw-400">Alternate Emails</label>
                          <label class="fs-14 fw-400"
                            (click)="openAddAlternateEmailModal()"  *ngIf="alternate_emails && alternate_emails.count < 2" tappable>
                            Add New Alternate Email&nbsp;&nbsp;
                            <i class="fa fa-plus"></i>
                          </label>
                      </div>
                      <div class="card card-body border py-2 px-4 my-2">
                        <div
                          style="max-height: 250px; overflow-y: auto"
                          *ngIf="alternate_emails && alternate_emails.count > 0"
                        >
                          <div
                            *ngFor="let email of alternate_emails.emails"
                            class="col-12"
                          >
                            <div class="row border rounded p-0 py-2 mt-2">
                              <div class="col-9 p-0">
                                <p class="col-12 mb-0">
                                  <i class="fa fa-envelope"></i>&nbsp;&nbsp;{{
                                    email.alternate_email
                                  }}
                                </p>
                              </div>
                              <div class="col-3 text-right">
                                <i
                                  class="fa fa-trash m-1"
                                  tappable
                                  data-container="body"
                                  data-placement="right"
                                  tooltip="Delete Email"
                                  (click)="deleteAlternateEmail(email.pae_id)"
                                ></i>
                              </div>
                            </div>
                          </div>
                        </div>
                        <label *ngIf="alternate_emails.count == 0" class="fs-14 fw-400"
                          >No Alternate Email Added Yet</label
                        >
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="d-flex justify-content-between align-items-center">
                          <label class="fs-14 fw-400">Alternate Phone Numbers</label>
                        
                          <label class="fs-14 fw-400" (click)="openAddAlternatePhoneModal()" style="text-decoration: underline" *ngIf=" alternate_phones && alternate_phones.count < 2 " tappable >
                            Add New Alternate Phone&nbsp;&nbsp;
                            <i class="fa fa-plus" ></i>
                        </label>
                      </div>
                      <div class="card card-body border p-2 my-2">
                        <div
                          style="max-height: 250px; overflow-y: auto"
                          *ngIf="alternate_phones && alternate_phones.count > 0"
                        >
                          <div
                            *ngFor="let phone of alternate_phones.phones"
                            class="col-12"
                          >
                            <div class="d-flex align-items-center justify-content-between border rounded p-2 mt-2">
                             
                                <p class=" mb-0">
                                  <i class="fa fa-phone-square"></i
                                  >&nbsp;&nbsp;{{ phone.alternate_mobile }}
                                </p>
                            
                            
                                <i
                                  class="fa fa-trash m-1"
                                  tappable
                                  data-container="body"
                                  data-placement="right"
                                  tooltip="Delete Phone"
                                  (click)="deleteAlternatePhone(phone.pam_id)"
                                ></i>
                             
                            </div>
                          </div>
                        </div>
                        <label class="fs-14 fw-400" *ngIf="alternate_phones.count == 0"
                          >No Alternate Phone Number Added Yet</label
                        >
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="d-flex justify-content-between align-items-center">
                          <label class="fs-14 fw-400">Customer Address Details</label>
                          <label (click)="openAddNewAddressModal()" *ngIf="addresses.count < 5" style="text-decoration: underline" tappable class="fs-14 fw-400" >
                            Add New Address&nbsp;&nbsp; 
                            <i class="fa fa-plus"></i >
                            </label>
                      </div>
                      <div class="card card-body border p-3 my-2">
                        <div style="max-height: 250px; overflow-y: auto">
                          <div
                            *ngFor="let address of addresses.address_array"
                            class="col-12"
                          >
                            <div *ngIf="addresses.count > 0">
                              <div
                                class="row border rounded p-0 py-2 mt-2"
                                [ngClass]="{
                                  'bg-primary': address.is_default == 1
                                }"
                              >
                                <div class="col-9 p-0">
                                  <p class="col-12 mb-0">
                                    <i class="fa fa-home"></i>&nbsp;&nbsp;{{
                                      address.patient_address
                                    }}, {{ address.city }},
                                    {{ address.state_name }},
                                    {{
                                      address.zip
                                        ? address.zip
                                        : address.zip_canada
                                    }}
                                  </p>
                                </div>
                                <div class="col-3 text-right">
                                  <p
                                    class="m-0"
                                    *ngIf="address.is_default == 1"
                                  >
                                    <i class="fa fa-check-circle"></i
                                    >&nbsp;&nbsp;Default
                                  </p>
                                  <i
                                    class="fa fa-check-circle-o m-1"
                                    tappable
                                    data-container="body"
                                    data-placement="left"
                                    tooltip="Make Default"
                                    *ngIf="address.is_default != 1"
                                    (click)="
                                      openMakeDefaultAddressModal(
                                        address.address_id
                                      )
                                    "
                                  ></i>
                                  <i
                                    class="fa fa-trash m-1"
                                    tappable
                                    data-container="body"
                                    data-placement="right"
                                    tooltip="Delete Address"
                                    *ngIf="address.is_default != 1"
                                    (click)="deleteAddress(address.address_id)"
                                  ></i>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <label *ngIf="addresses.count == 0"
                          >No Address Added Yet</label
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </tabset>
      </form>
    </div>
  </div>
  <div class="col-12 col-sm-2">
    <div class="card bg-transparent mb-0 open-ticket-box">
      <div class="card" style="min-height: 88px">
        <div
          class="p-3 btn-primary card-body d-flex rounded justify-content-between"
        >
          <div>
            <div class="text-md value text-bold m-0">
              {{
                patient.pending_requests +
                  patient.reopen_requests +
                  patient.followup_requests
              }}
            </div>
            <p class="ticket-text">
              Open Ticket{{
                patient.pending_requests +
                patient.reopen_requests +
                patient.followup_requests
                  ? "s"
                  : ""
              }}
            </p>
          </div>
          <div
            class="btn-group"
            dropdown
            placement="bottom right"
            (isOpenChange)="onOpenChange($event)"
          >
            <span
              id="button-animated"
              tooltip="View More"
              dropdownToggle
              tappable
              class="float-right arrow-icon"
              [ngClass]="{ active: dpOpen }"
            >
              <i class="fa fa-angle-down"></i>
            </span>
            <ul
              id="dropdown-animated"
              *dropdownMenu
              class="dropdown-menu dropdown-menu-right"
              role="menu"
              aria-labelledby="button-animated"
            >
              <li role="menuitem">
                <a
                  class="dropdown-item"
                  [routerLink]="'/app/users/assignments/'"
                  [queryParams]="{
                    type: '2',
                    page: '1',
                    sD: startDate,
                    eD: endDate,
                    offset: 0,
                    filters: '0',
                    search_string: patient?.patient_email
                  }"
                  [class.disabled]="!patient.pending_requests"
                  >Pending: {{ patient.pending_requests || 0 }}</a
                >
              </li>
              <li>
                <a
                  class="dropdown-item"
                  [routerLink]="'/app/users/assignments/'"
                  [queryParams]="{
                    type: '2',
                    page: '1',
                    sD: startDate,
                    eD: endDate,
                    offset: 0,
                    filters: '3',
                    search_string: patient.patient_email
                  }"
                  [class.disabled]="!patient.followup_requests"
                  >Follow-up: {{ patient.followup_requests || 0 }}</a
                >
              </li>
              <li role="menuitem">
                <a
                  class="dropdown-item"
                  [routerLink]="'/app/users/assignments/'"
                  [queryParams]="{
                    type: '2',
                    page: '1',
                    sD: startDate,
                    eD: endDate,
                    offset: 0,
                    filters: '2',
                    search_string: patient.patient_email
                  }"
                  [class.disabled]="!patient.reopen_requests"
                  >Re-open: {{ patient.reopen_requests || 0 }}</a
                >
              </li>
              <li role="menuitem">
                <a
                  class="dropdown-item"
                  [routerLink]="'/app/users/assignments/'"
                  [queryParams]="{
                    type: '2',
                    page: '1',
                    sD: startDate,
                    eD: endDate,
                    offset: 0,
                    filters: '1',
                    search_string: patient.patient_email
                  }"
                  [class.disabled]="!patient.closed_requests"
                  >Closed: {{ patient.closed_requests || 0 }}</a
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="card b mb-2">
      <div class="card-header bg-gray-lighter text-bold">Actions</div>
      <div class="list-group">
        <a
          href="javascript:void(0)"
          class="list-group-item text-primary rounded-0"
          [routerLink]="['/app/patients']"
        >
          <i class="fa fa-angle-left"></i> Back
        </a>
        <a
          href="javascript:void(0)"
          class="list-group-item rounded-0"
          (click)="dummyLive()"
        >
          <i
            class="fa"
            [ngClass]="{
              'fa-heartbeat': patient.is_dummy == 1,
              'fa-heart-o': patient.is_dummy == 0
            }"
          ></i>
          Make
          {{ patient.is_dummy == 1 ? "Live" : "Dummy" }}
        </a>
        <a
          href="javascript:void(0)"
          class="list-group-item rounded-0"
          (click)="blockUnblock(patient.is_blocked)"
        >
          <i
            class="fa"
            [ngClass]="{
              'fa-ban': patient.is_blocked == 0,
              'fa-check': patient.is_blocked == 1
            }"
          ></i>
          {{ patient.is_blocked == 1 ? "Unblock" : "Block" }} Customer
        </a>
        <a
          href="javascript:void(0)"
          class="list-group-item rounded-0"
          (click)="approveModal()"
        >
          <i
            class="fa"
            [ngClass]="{
              'fa-ban': patient.account_approved == 1,
              'fa-check': patient.account_approved == 0
            }"
          ></i>
          {{ patient.account_approved ? "Disapprove" : "Approve" }} Customer
        </a>
        <a
          href="javascript:void(0)"
          class="list-group-item rounded-0"
          (click)="sendVerification()"
        >
          <i class="fa fa-envelope"></i> Send verification mail
        </a>
        <a
          href="javascript:void(0)"
          class="list-group-item rounded-0"
          (click)="sendCredential()"
        >
          <i class="fa fa-envelope"></i> Send credential mail
        </a>
        <a
          href="javascript:void(0)"
          class="list-group-item rounded-0"
          (click)="verifyModal()"
        >
          <i class="fa fa-check-circle-o"></i> Verify Customer
        </a>
        <a
          href="javascript:void(0)"
          class="list-group-item rounded-0"
          (click)="DoNotDisturb()"
          *ngIf="!_shared.routerAuth.restricted_buttons.dnd"
        >
          <img src="./assets/images/svg/black.svg" class="dnd" />
          {{
            patient.dnd == 0
              ? "Enable Do Not Disturb"
              : " Disable Do Not Disturb"
          }}
        </a>
        <a
          href="javascript:void(0)"
          class="list-group-item rounded-0"
          (click)="achModal()"
        >
          <i
            class="fa"
            [ngClass]="{
              'fa-ban': patient.show_ach == 1,
              'fa-check': patient.show_ach == 0
            }"
          ></i>
          {{ patient.show_ach == 1 ? "Disable" : "Enable" }} ACH
        </a>
        <a
          href="javascript:void(0)"
          class="list-group-item bakOff rounded-0"
          (click)="
            patient.bank_support_type == null ? return : openAddBankLinkModal()
          "
          *ngIf="
            patient &&
            patient.show_ach == 1 &&
            plaid_accounts &&
            plaid_accounts.length == 0 &&
            bank_accounts.length == 0 &&
            !_shared.routerAuth.restricted_buttons.add_bank_link
          "
        >
          <i class="fa fa-bank"></i>Send Add Bank Account Link
          <span class="not-supported">Bank Not Supported</span>
        </a>
        <a
          href="javascript:void(0)"
          class="list-group-item rounded-0"
          (click)="openVerifyBankLinkModal()"
          *ngIf="
            patient &&
            patient.show_ach == 1 &&
            plaid_accounts &&
            plaid_accounts.length &&
            plaid_accounts[0].status == 'pending' &&
            plaid_accounts[0].type == 1 &&
            !_shared.routerAuth.restricted_buttons.verify_bank_link
          "
        >
          <i class="fa fa-bank"></i>Send Micro Deposit Link
        </a>
        <a
          href="javascript:void(0)"
          class="list-group-item rounded-0"
          (click)="openReauthenticateLinkModal()"
          *ngIf="
            patient &&
            patient.show_ach == 1 &&
            plaid_accounts &&
            plaid_accounts.length &&
            plaid_accounts[0].status == 'reauthenticate'
          "
        >
          <i class="fa fa-bank"></i> Send Reauthentication Link
        </a>
        <!-- <a href="javascript:void(0)" class="list-group-item rounded-0" (click)="deleteBankAccount()" *ngIf="patient && patient.show_ach == 1 && plaid_accounts && plaid_accounts.length>0 && !mainService.routerAuth.restricted_buttons.delete_bank">
          <i class="fa fa-trash"></i> Delete Bank Account
        </a> -->
        <a
          href="javascript:void(0)"
          class="list-group-item rounded-0"
          (click)="activateContractModal()"
        >
          <i
            class="fa"
            [ngClass]="{
              'fa-ban': patient.can_activate_contract == 1,
              'fa-check': patient.can_activate_contract == 0
            }"
          ></i>
          {{ patient.can_activate_contract == 1 ? "Disallow" : "Allow" }}
          Contract Revival
        </a>
        <a
          href="javascript:void(0)"
          class="list-group-item rounded-0"
          (click)="changePaymentMethods()"
        >
          <i class="fa fa-money"></i>
          Change Allowed Payment Methods
        </a>
        <!-- <a *ngIf="!mainService.routerAuth.restricted_buttons.reauth_email" href="javascript:void(0)" class="list-group-item rounded-0" (click)="reAuthEmail.show()">
          <i class="fa fa-envelope"></i>
          Enable/Disable Re-Auth Email/SMS
        </a> -->
      </div>
    </div>
    <button
      (click)="addNotesModal()"
      class="btn w-100 btn-primary rounded mb-1"
    >
      Add Notes
    </button>
    <button
      class="btn w-100 btn-primary rounded mb-1"
      [routerLink]="'/app/patients/contracts/' + patient.patient_id"
      [queryParams]="{ contractType: 1 }"
    >
      All Contracts
    </button>

    <div class="card b mb-2">
      <div class="card-header bg-gray-lighter text-bold">Recent Activity</div>
      <div class="list-group recent-activity">
        <div class="row disabled-chk">
          <input type="checkbox" [checked]="webcheckbox" />
          &nbsp;<label>Web</label>
        </div>
        <div class="row" *ngIf="weblogin">
          <span>Last login: {{ weblogin | date : "medium" : timezone }}</span>
        </div>
        <div class="row mt-2 disabled-chk">
          <input type="checkbox" [checked]="androidcheckbox" />
          &nbsp;<label>Android</label>
        </div>
        <div class="row" *ngIf="androidlogin">
          <p>Last login: {{ androidlogin | date : "medium" : timezone }}</p>
        </div>
        <div class="row mt-2 disabled-chk">
          <input type="checkbox" [checked]="ioscheckbox" />
          &nbsp;<label>iOS</label>
        </div>
        <div class="row mb-2" *ngIf="ioslogin">
          <span>Last login: {{ ioslogin | date : "medium" : timezone }}</span>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="!_shared.routerAuth.restricted_buttons.special_notes">
  <app-special-note [type]="2" [id]="id"> </app-special-note>
</div>
<div class="text-center" *ngIf="patient && patient.patient_id && !load_notes">
  <button
    class="btn btn-primary rounded my-2 blink"
    (click)="load_notes = true"
  >
    View notes
  </button>
</div>
<app-common-notes-listing
  [notesId]="id"
  [type]="'patient'"
  [data]="patient"
  *ngIf="patient && patient.patient_id && load_notes"
></app-common-notes-listing>

</div>