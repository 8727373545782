<div class="main-wrapper p-3">
    <div class="d-flex justify-content-between px-2 pt-2 border-bottom pb-2 align-items-center hw-65 w-100">
        <h1 class="m-0 fs-24 fw-700 text-secondary"> Customer Complaints</h1>
        <span class="bg-primary rounded py-2 px-3 fs-18 fw-600">Total Complaints :
            {{resData.totalRecords?resData.totalRecords : 0}}</span>
    </div>
    <div [hidden]="!show_filters">
        <app-filters class="row m-0 w-100" [filters]="filters" (finalValues)="filterValues($event)"
            (resetAllFilters)="resetFilters($event)">
        </app-filters>
    </div>
    <div class="card card-body m-0 flex-fill">
        <div class="row">
            <div class="col-md-6 pt-2">
                <p class="fs-18 fw-600 text-grey">
                    {{dateRangeValue && dateRangeValue[0]?(dateRangeValue[0] | date:'mediumDate'):''}} to
                    {{dateRangeValue
                    && dateRangeValue[1]?(dateRangeValue[1]|date:'mediumDate'):''}}
                </p>
            </div>
            <div class="col-md-6 text-md-end">
                <button (click)="showFilter(!show_filters)" type="button" class="btn btn btn-primary rounded-pill py-1 px-2 me-3" [ngClass]="{'btn-primary':show_filters, 'btn-outline': !show_filters}" >
                    <span class="fs-16 me-1">{{show_filters ? "Hide Filters" : "Show Filters"}}</span>
                    <i class="fa fa-filter"></i>
                </button>
                <div class="btn-group" dropdown *ngIf="!mainService.routerAuth.restricted_buttons.export && customer_disputes && customer_disputes.length">
                    <button id="button-basic" dropdownToggle type="button"
                        class="btn btn-primary rounded-pill py-1 px-2 dropdown-toggle" aria-controls="dropdown-basic">
                        Export <span class="caret"></span>
                    </button>
                    <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu"
                        aria-labelledby="button-basic">
                        <li role="menuitem">
                            <button class="dropdown-item" (click)="exportXLSX(0)" tappable>Export XLSX</button>
                            <!-- (click)="exportXLSX(0)" -->
                        </li>
                        <li role="menuitem">
                            <button class="dropdown-item" (click)="exportXLSX(1)" tappable>Export CSV</button>
                            <!-- (click)="exportXLSX(1)" -->
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="table-responsive w-100 overflow-auto d-flex py-2 show-scroll-bar flex-fill" *ngIf="customer_disputes && customer_disputes.length; else noData">
            <table class="table">
                <thead>
                    <tr>
                        <th>S No.</th>
                        <th>Reported on</th>
                        <th>Contract ID</th>
                        <th>Payment Information</th>
                        <th>Reason</th>
                        <th>Assignment Status</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let singleSplitAppList of customer_disputes; let i=index;">
                        <td>{{((resData.pageNumber-1)*10)+(i+1)}}</td>
                        <td> {{singleSplitAppList.disputed_date | date: 'MM-dd-yyyy' : timezone}} </td>
                        <td><a class="padd_r5" [routerLink]="'/app/contracts/details/'+singleSplitAppList.contract_id">
                                {{singleSplitAppList.contract_code}}
                            </a>
                            <br />
                            <span>{{singleSplitAppList.contract_status}}</span>
                            <br />

                            <span class="badge bg-success" *ngIf="singleSplitAppList.contract_type == 2">CredeeFi
                                Financing</span>
                            <span class="badge bg-success"
                                *ngIf="singleSplitAppList.contract_type == 4">{{singleSplitAppList.ez_plan_new ?
                                'ZeroFi Financing': 'SimpleeFi Financing'}}</span>
                            <span class="badge bg-success" *ngIf="singleSplitAppList.contract_type == 6">SimpleeFi
                                Financing (With Deferred Interest)</span>
                            <span class="badge bg-success" *ngIf="singleSplitAppList.contract_type == 5">Credee Bill
                                Payment Plan</span>
                        </td>
                        <td>
                            <div class="row m-0 ">
                                <div class="col-4 p-0 text-muted">Payment Name:</div>
                                <div class="col-8 p-0 text-left">{{singleSplitAppList.pt_name || 'Other'}}</div>
                            </div>
                            <div class="row m-0">
                                <div class="col-4 p-0 text-muted">Amount Deducted:</div>
                                <div class="col-8 p-0 text-left">
                                    <span
                                        *ngIf="singleSplitAppList.amount_deducted">{{singleSplitAppList.display_logo}}{{singleSplitAppList.amount_deducted}}</span>
                                    <span *ngIf="!singleSplitAppList.amount_deducted">N.A.</span>
                                </div>
                            </div>
                            <div class="row m-0">
                                <div class="col-4 p-0 text-muted">Payment Date:</div>
                                <div class="col-8 p-0 text-left">
                                    <span *ngIf="singleSplitAppList.payment_date">{{singleSplitAppList.payment_date |
                                        date:
                                        'MM-dd-yyyy' : timezone}}</span>
                                    <span *ngIf="!singleSplitAppList.payment_date">N.A.</span>
                                </div>
                            </div>
                        </td>
                        <td>{{singleSplitAppList.disputed_reason || '-'}}</td>
                        <td>{{singleSplitAppList.status == 0 ? 'Pending' : singleSplitAppList.status == 1 ? 'Closed' :
                            singleSplitAppList.status == 2 ? 'Reopen' : singleSplitAppList.status == 3 ? 'Follow Up' :
                            '-'}}
                        </td>
                        <td>
                            <a class="btn btn-primary btn-xs m-1" style="color: white;"
                                [routerLink]="'/app/assignments/my-assignments/'+singleSplitAppList.csr_req_id">View Details
                            </a>
                        </td>

                    </tr>
                </tbody>
            </table>
        </div>
        <div class="row border-top pt-2 align-items-center  w-100" *ngIf="customer_disputes && customer_disputes.length">
            <div class="col-md-6 col-sm-12">
                <p class="text-bold">
                    Showing {{(1+(resData.pageNumber - 1) * 10)}} to {{(customer_disputes.length + (resData.pageNumber - 1) *
                    10)}} of {{resData.totalRecords || 'all'}}
                </p>
            </div>
            <div class="col-md-6 col-sm-12 ">
                <pagination class="pagination-sm  float-md-end" [totalItems]="resData.totalRecords" [maxSize]="5"
                    [boundaryLinks]="true" [rotate]="false" (pageChanged)="pageChanged($event)"
                    [(ngModel)]="resData.pageNumber">
                </pagination>
            </div>

        </div>
    </div>
    <ng-template #noData>
        <div class="col-12 text-center mt-3" *ngIf="!customer_disputes || !customer_disputes.length">
            <img src="assets/images/svg/patients.svg">
            <h4 style="color:#8592a5">No data Found</h4>
        </div>
    </ng-template>
</div>