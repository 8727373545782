import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { MainService } from 'src/app/components/shared/main.service';
import { SharedService } from 'src/app/components/shared/shared.service';
import { ApiService } from 'src/app/components/shared/api.service';
import { localStorageService } from 'src/app/components/shared/localstorage.service';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
@Component({
  selector: 'app-lead-assignment-list',
  templateUrl: './lead-assignment-list.component.html',
  styleUrls: ['./lead-assignment-list.component.scss']
})
export class LeadAssignmentListComponent implements OnInit {
  bsConfig: Partial<BsDatepickerConfig>;
  constructor(
    public _main:MainService,
    public _shared:SharedService,
    public datePipe: DatePipe,
    public _api: ApiService,
    public _local: localStorageService
    ) {
      this.bsConfig = Object.assign({}, {
        containerClass: 'theme-green',
        dateInputFormat: 'MM/DD/YYYY',
        placement:'bottom'
      });
     }
  is_dummy: any = this._local.customGetItem('is_dummy');
  dateRangeValue: any = [new Date(), new Date()];
  minDate: Date = new Date();
  maxDate: Date = new Date();
  resetBtn:any;
  search_string: any;
  searched: any;
  dataArguements: any = {
    limit: 10,
    offset: 0,
    admin_id: 0,
    search_string: '',
  };
  resData: any = {
    pageNumber: 1,
    totalRecords: 0,
  };
  leads:any = [];
  pageNumber: number = 1;
  timezone: any = this._shared.getTimezone();
  timezoneName: any = this._shared.getTimezoneName();
  ngOnInit() {
    this.minDate.setDate(this.minDate.getDate() - 30);
    // this.dateRangeValue[0].setDate(this.dateRangeValue[0].getDate() - 30);
    this.maxDate = new Date(this._shared.getMaxDate(this.timezone));
    this.dateRangeValue = [this.minDate, this.maxDate];
    this.maxDate = new Date(this._shared.getMaxDate(this.timezone));
    this.dateRangeValue = [this.minDate, this.maxDate];
    this._shared.updateDummyLive().subscribe(([isDummy, timezone, selected_country]) => {
      this.is_dummy = isDummy;
      this.timezone = timezone;
      this.getLeads();
    });
  }

  dateChange(){
    this.resetBtn = true;
    this.getLeads();
  }

  resetDate() {
    this.dateRangeValue = [this.minDate, this.maxDate];
    this.resetBtn = false;
    this.getLeads();
  }

  selectAdmin(event: any) {
    this.dataArguements.admin_id = event;
    this.dataArguements.offset = 0;
    this.getLeads();
  }

  adminClear(event: any) {
    if (event.length == 0) {
      this.dataArguements.admin_id = 0;
      this.getLeads();
    }
  }

  getLeads() {
    const data: any = {
      offset: this.dataArguements.offset,
      is_dummy: parseInt(this._local.customGetItem('is_dummy'), 10) ? 1 : 0,
      limit: this.dataArguements.limit,
      min_date_added: this.datePipe.transform(this.dateRangeValue[0], 'yyyy-MM-dd'),
      max_date_added: this.datePipe.transform(this.dateRangeValue[1], 'yyyy-MM-dd'),
    };
    if(this.dataArguements.search_string){
      data['search_string'] = this.dataArguements.search_string.trim();
      this.searched = true;
     
    }
    this._api.api(data, 'assignments/leads_list', 'get')
      .subscribe((response: any) => {
        this.leads = response;
      }, (error: any) => {
        console.log(error);
      });
  }

  pageChanged(event: any) {
    this.pageNumber = event.page;
    this.dataArguements.offset = (event.page - 1) * 10;
    this.getLeads();
  }

  exportXLSX(ext: any) {
    let data: any = {
      offset: 0,
      is_dummy: parseInt(this._local.customGetItem('is_dummy'), 10) ? 1 : 0,
      limit: this.leads.number_of_records,
      min_date_added: this.datePipe.transform(this.dateRangeValue[0], 'yyyy-MM-dd'),
      max_date_added: this.datePipe.transform(this.dateRangeValue[1], 'yyyy-MM-dd'),
    };
    if (this.dataArguements.search_string){
      data['search_string'] = this.dataArguements.search_string.trim();
    }
    this._api.api(data, 'assignments/leads_list', 'get')
      .subscribe((data: any) => {
        let list: any = [];
        let assignments;
        list[0] = {
          date_added : 'Created On',
          lead_name: 'Lead Name',
          practice_name: 'Business Name',
          lead_email: 'Lead Email',
          lead_phone: 'Lead Phone',
          ind_name: 'Industry',
          speciality_name: 'Sub Industry',
          lead_address : 'Lead Address',
          added_by : 'Added By',
          assigned_to: 'Assigned To',
          follow_up: 'Follow Up Date',
          follow_up_time: 'Follow Up Time',
          follow_up_timezone: 'Follow Up Timezone',
          status: 'Status',
        };
        
        assignments = data.paignated_leads;
        for (var i = 0; i < assignments.length; i++) {
          list[i + 1] = {};
          list[i + 1].date_added = this.datePipe.transform(assignments[i].date_added, 'MM-dd-yyyy', this.timezone)|| '-';
          list[i + 1].lead_name = (assignments[i].first_name?assignments[i].first_name+' ':'') + (assignments[i].last_name?assignments[i].last_name:'');
          list[i + 1].practice_name = assignments[i].practice_name|| '-';
          list[i + 1].lead_email = assignments[i].practice_email|| '-';
          list[i + 1].lead_phone = assignments[i].practice_mobile|| '-';
          list[i + 1].ind_name = assignments[i].ind_name || '-';
          list[i + 1].speciality_name = assignments[i].speciality_name || '-';
          
          list[i + 1].lead_address = (assignments[i].practice_address? assignments[i].practice_address + ', ':'') + (assignments[i].city? assignments[i].city + ', ':'') +
          (assignments[i].state_code?assignments[i].state_code+', ':'') + (assignments[i].zip?assignments[i].zip:'');

          list[i + 1].added_by = assignments[i].added_by_name || '-';
          list[i + 1].assigned_to = assignments[i].assigned_to_name || '-';
          list[i + 1].follow_up = assignments[i].followup_date?this.datePipe.transform(assignments[i].followup_date, 'MM-dd-yyyy', '+0000'):'-';
          list[i + 1].follow_up_time = assignments[i].followup_time?assignments[i].followup_time:'-';
          list[i + 1].follow_up_timezone = assignments[i].followup_timezone?assignments[i].followup_timezone:'-';
          list[i + 1].status = assignments[i].status_name;
        };
        
        if (ext == 0) {
          this._shared.exportAsExcelFile(list, 'lead-assignments', '.xlsx');
        } else if (ext == 1) {
          this._shared.exportAsExcelFile(list, 'lead-assignments', '.csv');
        }
      }, (error: any) => {
        console.log(error);
      });
  }
  resetRecords(){
    this.resData.pageNumber = 1;
    this.dataArguements.offset = 0;
    this.dataArguements.search_string = '';
    this.getLeads();
  }

  searchRecords(){
    if(this.dataArguements.search_string && this.dataArguements.search_string.trim()) {
      this.dataArguements.search_string = this.dataArguements.search_string.trim()
    this.dataArguements.offset = 0;
    this.resData.pageNumber = 1;
    this.getLeads();
  }
  }
}
