<div class="new-password-wrapper">
    <div class="new-password-wrapper-inner vh-100">
        <div class="new-password-header px-3 py-2">
            <img src="assets\images\icons\FM-logo.svg" alt="">
        </div>
        <div class="new-password-body d-flex align-items-center justify-content-center">
            <form [formGroup]="newPasswordForm">
                <div class="new-password-form w-100">
                    <div class="mb-3">
                        <div [ngClass]="[ (newPasswordForm.controls['new_password'].touched && newPasswordForm.controls['new_password'].invalid) ? 'custom-form  inputContainer' : 'custom-form']"
                            class="">
                            <label for="text" class="label-name fs-14 w-100">
                                New password
                            </label>
                           <div class="position-relative">
                            <input [type]="!hidePwd ? 'password':'text'" class="input_field w-100" required
                            formControlName="new_password" placeholder="Enter Password" />
                            <img [src]="hidePwd ? 'assets/images/icons/open-eye.svg' : 'assets/images/icons/close-eye.svg'" alt=""
                            class="position-absolute eye-icon cursor" (click)="hidePwd = !hidePwd">
                           </div>
                        </div>
                        <p class="mat-error text-danger w-100 d-block mb-0 fs-14"
                            *ngIf="(newPasswordForm.controls['new_password'].touched && newPasswordForm.controls['new_password'].invalid)">
                            {{newPasswordForm.controls['new_password'].errors ? 'Please enter a valid Password' : ''}}
                        </p>
                    </div>
    
                    <div class="mb-3">
                        <div [ngClass]="[ (newPasswordForm.controls['confirm_password'].touched && newPasswordForm.controls['confirm_password'].invalid) ? 'custom-form  inputContainer' : 'custom-form']"
                            class="">
                            <label for="text" class="label-name fs-14 w-100">
                                Confirm password
                            </label>
                          <div class="position-relative">
                            <input [type]="!hideConfirmPwd ? 'password':'text'" class="input_field w-100" required
                            formControlName="confirm_password" placeholder="Enter Password" />
                            <img [src]="hideConfirmPwd ? 'assets/images/icons/open-eye.svg' : 'assets/images/icons/close-eye.svg'" alt=""
                            class="position-absolute eye-icon cursor" (click)="hideConfirmPwd = !hideConfirmPwd">
                          </div>
                        </div>
                        <p class="mat-error text-danger w-100 d-block mb-0 fs-14"
                            *ngIf="(newPasswordForm.controls['confirm_password'].touched && newPasswordForm.controls['confirm_password'].invalid)">
                            {{newPasswordForm.controls['confirm_password'].errors ? 'Please enter a valid Password' : ''}}
                        </p>
                    </div>
    
                    <button class=" px-4 border-0 mb-3 btn btn-outline fs-16 fw-600 me-3" (click)="submit()">Submit</button>
                    <button class=" px-4 border-0 mb-3 btn btn-primary fs-16 fw-600" [routerLink]="'/login'">Login</button>
                </div>
            </form>
        </div>
    </div>
</div>