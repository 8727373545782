import { DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/components/shared/api.service';
import { SharedService } from 'src/app/components/shared/shared.service';
import { LeadsService } from '../leads.service';
import { localStorageService } from 'src/app/components/shared/localstorage.service';

@Component({
  selector: 'app-leads-list',
  templateUrl: './leads-list.component.html',
  styleUrl: './leads-list.component.scss'
})
export class LeadsListComponent {

  maxDate: any = new Date();
  bsConfig: Partial<BsDatepickerConfig>;
  min_date_added: any;
  max_date_added: any;
  min_followup_date: any;
  max_followup_date: any;
  search_key: any;
  leads: any;
  resData: any = {
    pageNumber: 1,
    limit: 10
  };
  checkall = false;
  checkboxLeads: any = [];
  sort: any = 1;
  sortValue: any = {
    by: '',
    order: 'DESC',
    label: 'Date Added'
  };
  filter = 0;
  timezoneLabel: any = "";
  leads_speciality: any = "0";
  leads_zipcode: any = "";
  leads_range: any = "";
  duplicate = 2;
  selectedAdmin:any = 0;
  assigned_to:any = 0;
  assigned_by:any = 0;
  delete_lead= 0;
  timezone = '';
  current_lead_id = 0;
  filterLabel = '';
  timezoneFilterValue = 0;
  duplicateLabel = '';
  zipcode_list: any = [];
  timezoneData: any = [];
  admin_list: any = [];
  lead_limits_gap: any = 10;
  searchLimits: any = [];
  industries: any = [];
  paramSubscription: Subscription;
  subscription!: Subscription;
  timezoneName = '';
  specialities: any = [];
  constructor(private route: ActivatedRoute, private _datePipe: DatePipe, private _api: ApiService, public _shared: SharedService, private _router: Router, private _leads: LeadsService, private _local: localStorageService) {
    this.paramSubscription = this.route.queryParams.subscribe(params => {
      this.resData.pageNumber = params['page'] ? isNaN(params['page']) ? 1 : parseInt(params['page']) ? parseInt(params['page']) : 1 : 1;
      this.resData.limit = isNaN(params['limit']) ? 10 : parseInt(params['limit']) ? parseInt((parseInt(params['limit']) / this.lead_limits_gap)+"") * this.lead_limits_gap : 10;
      this.resData.limit = this.resData.limit ? this.resData.limit : 10;
      this.sortValue.by = params['order_by'];
      this.sortValue.order = params['order_type'];
      this.filter = params['lead_status'];
      this.timezoneFilterValue = params['followup_timezone'];
      this.leads_range = params['range'];
      this.search_key = params['q'];
      this.leads_speciality = params['speciality'] ? params['speciality'] : 0;
      //alert(this.datePipe.transform(new Date(params["min_date_added"]), 'yyyy-MM-dd'))
      var min_date_added = new Date(params["min_date_added"]);
      this.min_date_added = (min_date_added+"").toLowerCase() === "invalid date" ? "" : min_date_added;
      var max_date_added = new Date(params["max_date_added"]);
      this.max_date_added = (max_date_added+"").toLowerCase() === "invalid date" ? "" : max_date_added;
      var min_followup_date = new Date(params["min_followup_date"]);
      this.min_followup_date = (min_followup_date+"").toLowerCase() === "invalid date" ? "" : min_followup_date;
      var max_followup_date = new Date(params["max_followup_date"]);
      this.max_followup_date = (max_followup_date+"").toLowerCase() === "invalid date" ? "" : max_followup_date;
      this.leads_zipcode = params['zip'] ? params['zip'] : "";
    });
    this.bsConfig = Object.assign({}, {
      containerClass: 'theme-green',
      dateInputFormat: 'MM/DD/YYYY',
      placement:'bottom'
    });
    this.timezone = this._shared.getTimezone();
    this.timezoneName = this._shared.getTimezoneName();
  }

  getSearchLimits(){
    this.searchLimits = [];
    for(let i = 1; i <= 100; i++)
    {
      this.searchLimits.push(i*this.lead_limits_gap);
    }
  }

  getSpeciality() {
    this._api.api({
      fetch_all_sub_ind: 1,
    }, 'doctor_speciality_list', 'get')
      .subscribe((data: any) => {
        this.specialities = data.doctor_speciality;
        this.industries = [];
        this.specialities.forEach((spec: any) => {
          if(spec.is_verified)
          {
            if(!this.industries.filter((ind: any)=>ind.ind_id === spec.ind_id).length)
            {
              this.industries.push({
                ind_id: spec.ind_id,
                ind_is_guaranteed: spec.ind_is_guaranteed,
                ind_name: spec.ind_name
              });
            }
          }
        });
        this.industries.sort((ind1: any, ind2: any)=>ind1.ind_id < ind2.ind_id ? -1 : 1);
        this.industries.map((ind: any) => {
          ind["specialities"] = this.specialities.filter((spec: any)=>spec.ind_id === ind.ind_id && spec.is_verified);
          return ind;
        });
      })
  }

  ngOnInit() {
    this.getSearchLimits();
    this.getSpeciality();
    //this.getLeads();
    // this.subscription = this.mainService.updateDummyLive().subscribe((value: any) => {
    //   this.is_dummy = value;
    // });
    this.getAdminList();
    /*if (this.route.snapshot.queryParams['min_date_added']) {
      this.min_date_added = new Date();
    }
    if (this.route.snapshot.queryParams['min_followup_date']) {
      this.min_followup_date = new Date();
    }*/
    if (this.route.snapshot.queryParams['unassigned']) {
      this.filter = 1;
      this.filterLabel = 'Unassigned';
    }
    if (this.route.snapshot.queryParams['assigned']) {
      this.filter = 2;
      this.filterLabel = 'Assigned';
    }
    if (this.route.snapshot.queryParams['today_duplicate']) {
      this.min_date_added = new Date();
      this.duplicate = 1;
      this.duplicateLabel = 'Yes';
    }
    if (this.route.snapshot.queryParams['total_duplicate']) {
      this.duplicate = 1;
      this.duplicateLabel = 'Yes';
    }
    /*if (this.route.snapshot.queryParams['upcoming_followups']) {
      this.min_followup_date = new Date();
      this.min_followup_date.setDate(this.min_followup_date.getDate() + 1);
    }
    if (this.route.snapshot.queryParams['overdue_followups']) {
      this.max_followup_date = new Date();
      this.max_followup_date.setDate(this.max_followup_date.getDate() - 1);
    }*/
    this.getLeads();
    
    this.timezoneData = this._shared.getTimezoneList();
  }

  //  get all admin
  getAdminList() {
    this._api.api({}, 'user/fetch_admin_list', 'get')
      .subscribe((response: any) => {
        this.admin_list = response.admins;
    });
  }

  ngOnDestroy() {
    if (this.paramSubscription) {
      this.paramSubscription.unsubscribe();
    }
  }

  //  download sample link
  downloadSampleLink(ext: any) {
    const list = [];
    list[0] = {
      date_added: 'Call Date',
      lead_owner: 'Agent Name',
      practice_name: 'Company Name',
      website: 'Website',
      first_name: 'Contact Person Name',
      practice_mobile: 'Phone Number',
      practice_email: 'Email ID',
      practice_address: 'Street Address',
      city: 'City',
      state: 'State',
      zip: 'Zip Code',
      ind_name: 'Industry Name',
      sub_ind_name: 'Sub Industry Name',
      comment: 'Comment',
      lead_source: 'Source Name',
      description: 'Description',
      alternate_email: 'Alternate Email',
      alternate_mobile: 'Alternate Phone',
      timezone: 'Timezone',
    };
    if (list && ext === 0) {
      this._shared.exportAsExcelFile(list, 'sample', '.xlsx');
    } else {
      this._shared.exportAsExcelFile(list, 'sample', '.csv');
    }
  }

  //  export lead data
  exportXLSX(ext: any) {
    let data: any = {
      offset: 0,
      limit: this.resData.totalRecords,
      min_date_added: this._datePipe.transform(this.min_date_added, 'yyyy-MM-dd'),
      max_date_added: this._datePipe.transform(this.max_date_added, 'yyyy-MM-dd'),
      min_followup_date: this._datePipe.transform(this.min_followup_date, 'yyyy-MM-dd'),
      max_followup_date: this._datePipe.transform(this.max_followup_date, 'yyyy-MM-dd'),
      order_by: this.sortValue.by,
      order_type: this.sortValue.order,
      lead_status: this.filter,
      search_string: this.search_key,
      is_duplicate: this.duplicate,
      doctor_speciality: parseInt(this.leads_speciality),
      zip: this.leads_zipcode
    };
    if(this.selectedAdmin > 0){
      data['added_by'] = this.selectedAdmin;
    }
    if(this.assigned_to>0){
      data['assigned_to'] = this.assigned_to;
    }
    if(this.assigned_by>0){
      data['assigned_by'] = this.assigned_by;
    }
    this._api.api(data, 'leads/leads_list', 'get')
      .subscribe((response: any) => {
        //  console.log(data);
        const list: any = [];
        const lead = response.paignated_leads;
        list[0] = {
          first_name: 'First Name',
          last_name: 'Last Name',
          practice_name: 'Business Name',
          practice_email: 'Email',
          practice_mobile: 'Phone',
          practice_address: 'Address',
          ind_name: 'Industry Name',
          speciality_name: 'Sub Industry Name',
          status_name: 'Lead Status',
          lead_owner: 'Lead Owner',
          // is_duplicate: 'Duplicate',
          assigned_to: 'Assigned To',
          assigned_by: 'Assigned By',
          date_added: 'Date Added',
          followup_date: 'Follow Up Date',
          spoke_to: 'Spoke To'
        };
        for (let i = 0; i < lead.length; i++) {
          list[i + 1] = {};
          list[i + 1].first_name = lead[i].first_name ? lead[i].first_name : '';
          list[i + 1].last_name = lead[i].last_name ? lead[i].last_name : '';
          list[i + 1].practice_name = lead[i].practice_name ? lead[i].practice_name : '';
          list[i + 1].practice_email = lead[i].practice_email ? lead[i].practice_email : '';
          list[i + 1].practice_mobile = lead[i].practice_mobile !== 0 ? '+1-' + lead[i].practice_mobile : '';
          list[i + 1].practice_address = 
            (lead[i].practice_address?lead[i].practice_address+ ', ':'') + (lead[i].city?lead[i].city+ ', ':'') + 
            (lead[i].state_code?lead[i].state_code+ ', ':'') + (lead[i].zip?lead[i].zip:'');
          list[i + 1].ind_name = lead[i].ind_name?lead[i].ind_name:'-',
          list[i + 1].speciality_name = lead[i].speciality_name?lead[i].speciality_name:'-',
          list[i + 1].status_name = lead[i].status_name ? lead[i].status_name : '';
          list[i + 1].lead_owner = lead[i].added_by_name ? lead[i].added_by_name : '';
          // list[i + 1].is_duplicate = lead[i].is_duplicate === 0 ? 'No' : 'Yes';
          list[i + 1].assigned_to = lead[i].assigned_to_name ? lead[i].assigned_to_name : '';
          list[i + 1].assigned_by = lead[i].assigned_by_name ? lead[i].assigned_by_name : '';
          list[i + 1].date_added = lead[i].date_added ? this._datePipe.transform(lead[i].date_added, 'MM-dd-yyyy',this.timezone) : '';
          list[i + 1].followup_date = lead[i].followup_date ? this._datePipe.transform(lead[i].followup_date, 'MM-dd-yyyy','+0000') : '';
          list[i + 1].spoke_to = lead[i].spoke_to ?  lead[i].spoke_to : '';
        }
        if (ext === 0) { this._shared.exportAsExcelFile(list, 'leads', '.xlsx'); }
        else if (ext === 1) { this._shared.exportAsExcelFile(list, 'leads', '.csv'); }
      });

  }

  //  date change
  dateChange() {
    this.resData.pageNumber = 1;
    this.modifyQueryString();
    this.getLeads();
  }

  selectAdmin(event:any, type: any) {
    if (event.length == 0) {
      if(type=='added_by'){
        this.selectedAdmin = 0;
      }else if(type =='assigned_to'){
        this.assigned_to = 0;
      }else if(type =='assigned_by'){
        this.assigned_by = 0;
      }
    }else{
      event.forEach((item:any, index:any)=>{
        if(type=='added_by'){
          this.selectedAdmin = item.value;
        }else if(type =='assigned_to'){
          this.assigned_to = item.value;
        }else if(type =='assigned_by'){
          this.assigned_by = item.value;
        }
      });
    }
  }

  //  sorting
  sortBy(type: any) {
    switch (parseInt(type)) {
      case 0:
        this.sortValue = { by: '', order: 'DESC', label: 'Date Added' };
        break;
      case 1:
        this.sortValue = { by: 'first_name', order: 'DESC', label: 'First Name' };
        break;
      case 2:
        this.sortValue = { by: 'last_name', order: 'DESC', label: 'Last Name' };
        break;
      case 3:
        this.sortValue = { by: 'practice_name', order: 'DESC', label: 'Business Name' };
        break;
      case 4:
        this.sortValue = { by: 'practice_email', order: 'DESC', label: 'Business Email' };
        break;
    }
  }

  //  applyFilter
  applyFilter() {
    this.resData.pageNumber = 1;
    //alert(this.datePipe.transform(new Date(params["min_date_added"]), 'yyyy-MM-dd'))
    this.modifyQueryString();
    this.getLeads();
  }

  //  resetFilter
  resetFilter() {
    this.leads_speciality = "0";
    this.leads_zipcode = "";
    this.leads_range = "";
    this.min_date_added = '';
    this.max_date_added = '';
    this.max_followup_date = '';
    this.min_followup_date = '';
    this.filter = 0;
    this.filterLabel = 'All';
    this.timezoneLabel = '';
    this.duplicate = 2;
    this.duplicateLabel = 'All';
    this.search_key = '';
    this.sortValue = { by: '', order: 'DESC', label: 'Date Added' };
    this.resData.pageNumber = 1;
    this._router.navigate(['/app/leads/list'], {queryParams: {}});
    this.selectedAdmin = 0;
    this.assigned_to = 0;
    this.assigned_by =0;
    this.zipcode_list=[];
    this.modifyQueryString();
    this.getLeads();
  }

  //  select all checkbox
  checkUncheckAll() {
    if (this.checkall) {
      this.checkall = true;
      this.leads.forEach((lead: any) => {
        lead.checked = true;
        this.checkboxLeads.push(parseInt(lead.lead_id));
      });
    } else {
      this.leads.forEach((lead: any) => {
        lead.checked = false;
        this.checkboxLeads = [];
      });
    }

  }

  //  single checkbox checked and checked
  updateCheckall(event: any, item: any) {
    const leadTotal = this.leads.length;
    let count = 0;
    if (event.target.checked) {
      this.leads.forEach((lead: any) => {
        if (lead.checked) {
          count++;
          if (this.checkboxLeads.indexOf(lead.lead_id) == -1) {
            this.checkboxLeads.push(parseInt(lead.lead_id));
          }
        }
      });
      if (leadTotal === count) {
        this.checkall = true;
      } else {
        this.checkall = false;
      }
    } else {
      count--;
      this.checkall = false;
      this.checkboxLeads = this.checkboxLeads.filter((lead_data: any) => {
        return lead_data !== item;
      });
    }
  }

  //  filter by:
  filterBy(value: any, label: any) {
    this.filter = value;
    this.filterLabel = label;
  }

  //  filter by:
  filterByTimezone(value: any, label: any) {
    this.timezoneFilterValue = value;
    this.timezoneLabel = label;
  }
  //  duplicate lead
  duplicateBy(value: any, label: any) {
    this.duplicate = value;
    this.duplicateLabel = label;
  }

  assignLead(lead: any) {
    this.current_lead_id = lead;
    this.assignLeadmodalShow();
  }

  // mode to service
  dndLead(lead: any, dnd: any) {
    this._leads.dndLead(lead.id, dnd);
    // create comfirmation modal here
    // swal({
    //   title: 'Confirmation',
    //   text: `Are you sure you want to ${dnd == 0 ? 'enabe DND' : 'disable DND'} ?`,
    //   type: 'info',
    //   showCancelButton: true,
    //   confirmButtonColor: '#5d9cec',
    //   confirmButtonText: 'OK',
    //   cancelButtonText: 'Cancel',
    //   closeOnConfirm: true,
    //   closeOnCancel: true
    // }, (isConfirm) => {
    //   if (isConfirm) {
    //     let params = {
    //       lead_id: lead.lead_id,
    //       is_dnd: dnd
    //     }
    //     this._api.api(params, 'dnd_lead')
    //       .subscribe((data: any) => {
    //         if (data.is_error == 0) {
    //           lead.is_dnd = lead.is_dnd == 1 ? 0 : 1;
    //         }
    //       }, (error: any) => {
    //         console.log(error);
    //       });
    //   }
    // })
  }

  assignLeadmodalShow() {

  }

  deleteLeadModalShow() {

  }

  assignMultipleLeadModalShow() {

  }

  modifyQueryString(){
    var queryString = this.getSearchQueryString(this.getSearchObject());
    if(queryString)
    {
      window.history.replaceState({}, "Credee - Admin Panel", window.location.pathname+"?"+queryString);
    }    
  }

  deleteLead(id: any) {
    this.delete_lead = id;
    this.deleteLeadModalShow();
  }

  getSearchQueryString(searchObj: any){
    let queryString = "";
    for (let key in searchObj) {
      // skip loop if the property is from prototype
      if (!searchObj.hasOwnProperty(key)) continue;
      queryString += (searchObj[key] ? (queryString ? "&" : "")+key+"="+searchObj[key] : "");
    }
    return queryString;
  }

  getLeads() {
    let data: any = {
      offset: (this.resData.pageNumber - 1) * this.resData.limit,
      limit: this.resData.limit,
      order_by: this.sortValue.by,
      order_type: this.sortValue.order,
      lead_status: this.filter,
      followup_timezone: this.timezoneLabel,
      range: this.leads_range ? this.leads_range : 0,
      zip: this.leads_zipcode,
      doctor_speciality: parseInt(this.leads_speciality),
      search_string: this.search_key,
      min_date_added: this._datePipe.transform(this.min_date_added, 'yyyy-MM-dd'),
      max_date_added: this._datePipe.transform(this.max_date_added, 'yyyy-MM-dd'),
      min_followup_date: this._datePipe.transform(this.min_followup_date, 'yyyy-MM-dd'),
      max_followup_date: this._datePipe.transform(this.max_followup_date, 'yyyy-MM-dd'),
      is_duplicate: this.duplicate
    };
    //alert((this.resData.pageNumber - 1) * this.resData.limit)
    if(this.selectedAdmin>0){
      data['added_by'] = this.selectedAdmin;
    }
    if(this.assigned_to>0){
      data['assigned_to'] = this.assigned_to;
    }
    if(this.assigned_by>0){
      data['assigned_by'] = this.assigned_by;
    }
    this._api.api(data, 'leads/leads_list', 'get')
      .subscribe((response: any) => {
        this.leads = response.paignated_leads;
        this.resData.totalRecords = response.number_of_records;
        this.resData.number_of_pages = response.number_of_pages;
      });
  }

  pageChanged(event: any) {
    this.resData.pageNumber = event.page;
    //const offset = (event.page - 1) * 10;
    this.checkall = false;
    this.checkboxLeads = [];
    this.modifyQueryString();
    this.getLeads();
  }

  getSearchObject(){
    var searchObject = {
      page: this.resData.pageNumber,
      limit: this.resData.limit,
      order_by: this.sortValue.by,
      order_type: this.sortValue.order,
      lead_status: this.filter,
      range: this.leads_range,
      q: this.search_key,
      speciality: this.leads_speciality,
      zip: this.leads_zipcode,
      min_date_added: (this.min_date_added ? this.getFormattedDate(this.min_date_added, 'yyyy-MM-dd') : ""),
      max_date_added: (this.max_date_added ? this.getFormattedDate(this.max_date_added, 'yyyy-MM-dd') : ""),
      min_followup_date: (this.min_followup_date ? this.getFormattedDate(this.min_followup_date, 'yyyy-MM-dd') : ""),
      max_followup_date: (this.max_followup_date ? this.getFormattedDate(this.max_followup_date, 'yyyy-MM-dd') : ""),
    }
    return searchObject;
  }

  getFormattedDate(dateString: string, dateFormat: string){
    return this._datePipe.transform(new Date(dateString), dateFormat);
  }
}
