import { Injectable } from "@angular/core";

@Injectable()
export class LeadsService {

    leadModalTypes = {
        assignLeadmodalShow: 'assignLeadmodalShow',
        deleteLeadModalShow: 'deleteLeadModalShow',
        assignMultipleLeadModalShow: 'assignMultipleLeadModalShow'
    }
    dndLead(id: any, dnd: any) {
        // create comfirmation modal here
        // swal({
        //   title: 'Confirmation',
        //   text: `Are you sure you want to ${dnd == 0 ? 'enabe DND' : 'disable DND'} ?`,
        //   type: 'info',
        //   showCancelButton: true,
        //   confirmButtonColor: '#5d9cec',
        //   confirmButtonText: 'OK',
        //   cancelButtonText: 'Cancel',
        //   closeOnConfirm: true,
        //   closeOnCancel: true
        // }, (isConfirm) => {
        //   if (isConfirm) {
        //     let params = {
        //       lead_id: lead.lead_id,
        //       is_dnd: dnd
        //     }
        //     this._api.api(params, 'dnd_lead')
        //       .subscribe((data: any) => {
        //         if (data.is_error == 0) {
        //           lead.is_dnd = lead.is_dnd == 1 ? 0 : 1;
        //         }
        //       }, (error: any) => {
        //         console.log(error);
        //       });
        //   }
        // })
    }
}