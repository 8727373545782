import { Component, OnInit, Input } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AddNoteComponent } from '../add-note/add-note.component';
import { SharedService } from '../../shared.service';
import { ApiService } from '../../api.service';

@Component({
  selector: 'app-notes-list',
  templateUrl: './notes-list.component.html',
  styleUrls: ['./notes-list.component.scss']
})
export class NotesListComponent implements OnInit {
  note: any;
  noteData: any;
  @Input() type: any;
  @Input() id: any;
  timezone!: string;
  timezoneName!: string;
  bsModalRef!: BsModalRef;
  constructor(
    private modalService: BsModalService,
    public _shared: SharedService,
    private _api: ApiService
  ) { }

  ngOnInit() {
    this.timezone = this._shared.getTimezone();
    this.timezoneName = this._shared.getTimezoneName();
    this.getNotes();
  }

  getNotes() {
    let params: any = {};
    switch (this.type) {
      case 1:
        params['doctor_id'] = this.id;
        break;
      case 2:
        params['patient_id'] = this.id;
        break;
      case 3:
        params['partner_id'] = this.id;
        break;
      case 4:
        params['contract_id'] = this.id;
        break;
      default:
        return ;
    }
    this._api.api(params, 'business/list_hidden_notes', 'get')
      .subscribe((data: any) => {
        console.log(data);
        this.noteData = data.data;
      })
  };

  addNotesModal() {
    let initialState = {
      data: { id: this.id, type: this.type }
    };
    this.bsModalRef = this.modalService.show(AddNoteComponent, { initialState, class: 'modal-lg', ignoreBackdropClick: true });
    this.modalService.onHide.subscribe((reason: string) => {
      this.bsModalRef.content?.passEntry.subscribe((notesAdded: boolean) => {
        if(notesAdded) {
          this.getNotes();
        }
      })
    });
  };

}
