<div class="lead_content">
    <form>
        <div class="row m-4 align-items-center mb-4">
            <div class="col-md-3 col-sm-4 padd_lr0">
                <div class="left_lead">
                    <!-- <img src="assets/images/png/dashboard_home.png"> -->
                    <a><span class="fs-30 fw-900 text-left theme-text">DASHBOARD</span></a>
                </div>
            </div>
            <div class="col-md-9 col-sm-8 text-end" *ngIf="!_shared.routerAuth.restricted_buttons.add">
                <a routerLink="/app/leads/create">
                    <button class="theme-green-bg text-white fs-16 fw-600 py-2 radius-8 col-md-4 cursor">Create Lead
                        <img src="assets/images/png/create_lead.png">
                    </button>
                </a>
            </div>
        </div>
        <div class="row m-4 " *ngIf="dashboard_data">
            <div class="col-md-6">
                <div class="row">
                    <div class="col-md-6 mb-4" *ngIf="!_shared.routerAuth.restricted_buttons.today_lead">
                        <div class="card p-2 cursor_pointer" routerLink="/app/leads/list"
                            [queryParams]="{min_date_added: getFormattedTodayDate()}">
                            <div class="row">
                                <div class="col-md-6">
                                    <img src="assets/images/png/today-lead.png" class="m-1">
                                </div>
                                <div class="col-md-6">
                                    <h2 class="count_txtl fs-40 fw-700 text-end">{{dashboard_data['todays_leads'] || 0}} </h2>
                                    <h5 class="lead_txt fs-16 fw-500 text-end">Today's Lead {{today_date | date : 'dd MMM YY'}}</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 mb-4" *ngIf="!_shared.routerAuth.restricted_buttons.total_leads">
                        <div class="card p-2 cursor_pointer" routerLink="/app/leads/list">
                            <div class="row">
                                <div class="col-md-6">
                                    <img src="assets/images/png/total-leads.png" class="m-1">
                                </div>
                                <div class="col-md-6">
                                    <h2 class="count_txtl fs-40 fw-700 text-end">{{dashboard_data['total_added_leads'] || 0}} </h2>
                                    <h5 class="lead_txt fs-16 fw-500 text-end">Total Leads</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 mb-4" *ngIf="!_shared.routerAuth.restricted_buttons.total_deleted">
                        <div class="card p-2">
                            <div class="row">
                                <div class="col-md-6">
                                    <img src="assets/images/png/total-deleted.png" class="m-1">
                                </div>
                                <div class="col-md-6">
                                    <h2 class="count_txtl fs-40 fw-700 text-end">{{dashboard_data['deleted_leads'] || 0}}</h2>
                                    <h5 class="lead_txt fs-16 fw-500 text-end">Total Deleted</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 mb-4" *ngIf="!_shared.routerAuth.restricted_buttons.unassigned_lead">
                        <div class="card p-2 cursor_pointer" routerLink="/app/leads/list"
                            [queryParams]="{unassigned: true}">
                            <div class="row">
                                <div class="col-md-6">
                                    <img src="assets/images/png/total-unassigned.png" class="m-1">
                                </div>
                                <div class="col-md-6">
                                    <h2 class="count_txtl fs-40 fw-700 text-end">{{dashboard_data['unassigned_leads'] || 0}} </h2>
                                    <h5 class="lead_txt fs-16 fw-500 text-end">Unassigned Leads</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 mb-4" *ngIf="!_shared.routerAuth.restricted_buttons.assigned_leads">
                        <div class="card p-2 cursor_pointer" routerLink="/app/leads/list"
                            [queryParams]="{assigned: true}">
                            <div class="row">
                                <div class="col-md-6">
                                    <img src="assets/images/png/total-unassigned.png" class="m-1">
                                </div>
                                <div class="col-md-6">
                                    <h2 class="count_txtl fs-40 fw-700 text-end">{{dashboard_data['assigned_leads'] || 0}} </h2>
                                    <h5 class="lead_txt fs-16 fw-500 text-end">Assigned Leads</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 mb-4" *ngIf="!_shared.routerAuth.restricted_buttons.today_duplicate">
                        <div class="card p-2 cursor_pointer" routerLink="/app/leads/list"
                            [queryParams]="{today_duplicate: true}">
                            <div class="row">
                                <div class="col-md-6">
                                    <img src="assets/images/png/today-duplicate.png" class="m-1">
                                </div>
                                <div class="col-md-6">
                                    <h2 class="count_txtl fs-40 fw-700 text-end">{{dashboard_data['today_duplicate_leads'] || 0}} </h2>
                                    <h5 class="lead_txt fs-16 fw-500 text-end">Today's Duplicate</h5>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6 mb-4" *ngIf="!_shared.routerAuth.restricted_buttons.total_duplicate">
                        <div class="card p-2 cursor_pointer" routerLink="/app/leads/list"
                            [queryParams]="{total_duplicate: true}">
                            <div class="row">
                                <div class="col-md-6">
                                    <img src="assets/images/png/total-duplicate.png" class="m-1">
                                </div>
                                <div class="col-md-6">
                                    <h2 class="count_txtl fs-40 fw-700 text-end">{{dashboard_data['duplicate_leads'] || 0}} </h2>
                                    <h5 class="lead_txt fs-16 fw-500 text-end">Total Duplicated</h5>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="col-md-6 mb-4" *ngIf="!_shared.routerAuth.restricted_buttons.today_follow_up">
                        <div class="card p-2 cursor_pointer" routerLink="/app/leads/list"
                            [queryParams]="{min_followup_date: getFormattedTodayDate()}">
                            <div class="row">
                                <div class="col-md-6">
                                    <img src="assets/images/png/upcoming-followups.png" class="m-1">
                                </div>
                                <div class="col-md-6">
                                    <h2 class="count_txtl fs-40 fw-700 text-end">{{dashboard_data['todays_followups'] || 0}} </h2>
                                    <h5 class="lead_txt fs-16 fw-500 text-end">Today's Follow up</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 mb-4" *ngIf="!_shared.routerAuth.restricted_buttons.upcoming_followups">
                        <div class="card p-2  cursor_pointer" routerLink="/app/leads/list"
                            [queryParams]="{min_followup_date: getFormattedTomorrowDate()}">
                            <div class="row">
                                <div class="col-md-6">
                                    <img src="assets/images/png/upcoming-followups.png" class="m-1">
                                </div>
                                <div class="col-md-6">
                                    <h2 class="count_txtl fs-40 fw-700 text-end">{{dashboard_data['upcoming_followups'] || 0}}</h2>
                                    <h5 class="lead_txt fs-16 fw-500 text-end">Upcoming Follow ups</h5>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="col-md-6 mb-4" *ngIf="!_shared.routerAuth.restricted_buttons.overdue_followups">
                        <div class="card p-2 cursor_pointer" routerLink="/app/leads/list"
                            [queryParams]="{max_followup_date: getFormattedYesterdayDate()}">
                            <div class="row">
                                <div class="col-md-6">
                                    <img src="assets/images/png/overdue-followups.png" class="m-1">
                                </div>
                                <div class="col-md-6">
                                    <h2 class="count_txtl fs-40 fw-700 text-end">{{dashboard_data['overdue_followups'] || 0}} </h2>
                                    <h5 class="lead_txt fs-16 fw-500 text-end">Overdue Follow ups</h5>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="card">
                    <h4 class="mb-4 fs-32 fw-700 text-center">Lead Status</h4>
                    <div class="card-wrapper">
                        <div class="card-body p-30">
                            <!-- <div flot [dataset]="dashboard_data && dashboard_data['pie_chart_data']" [options]="donutOptions"
                   height="500"></div> -->
                            <!-- <div>
                                <canvas baseChart [chartType]="'doughnut'" [options]="doughnutOptions"
                                    [datasets]="doughnutData.datasets" [colors]="doughnutColors"
                                    [labels]="doughnutData.labels" [legend]="false" height="170"></canvas>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>