import { Component } from '@angular/core';

@Component({
  selector: 'app-seminar-leads-list',
  templateUrl: './seminar-leads-list.component.html',
  styleUrl: './seminar-leads-list.component.scss'
})
export class SeminarLeadsListComponent {

}
