import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { SharedService } from '../shared.service';

@Component({
  selector: 'app-view-notes',
  templateUrl: './view-notes.component.html',
  styleUrl: './view-notes.component.scss'
})
export class ViewNotesComponent {
  current_notes: any;
  timezone = '';
  timezoneName = '';
  constructor(private bsModalRef: BsModalRef, public _shared: SharedService) {
    this.timezone = this._shared.getTimezone();
    this.timezoneName =this._shared.getTimezoneName();
  }
  hide() {
    this.bsModalRef.hide()
  }
  
}
