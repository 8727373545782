<div class="d-flex align-items-center justify-content-between p-3">
  <h3 class="m-0 fs-24 fw-700 text-secondary mx-2"> Proposal Requests</h3>
  <div class=" text-end">
    <span class="bg-primary rounded py-2 px-3 fs-18 fw-600">{{'Total Requests: '+
      (_proposal.resData.totalRecords?_proposal.resData.totalRecords:0) }}</span>
  </div>
</div>

<div class="row m-2 align-items-center mb-2" [hidden]="!show_filters">
  <app-filters class="row m-0 w-100" [filters]="filters" (finalValues)="filterValues($event)"
    (resetAllFilters)="resetFilters()">
  </app-filters>
</div>

<div class="card m-4" *ngIf="_proposal.proposalList">
  <div class="row m-0">
    <div class="col-sm-4 col-md-4 col-lg-4 pt-4">
      <p class="fs-18 fw-600 text-grey">
        {{ _proposal.minDate ? (_proposal.minDate | date:'mediumDate'):''}} to {{ _proposal.maxDate?
        (_proposal.maxDate | date:'mediumDate'):''}}
      </p>
    </div>
    <div class="col-sm-8 col-md-8 pt-2 text-end d-flex justify-content-end align-items-center"
      *ngIf="!_shared.routerAuth.restricted_buttons.export">
      <button class="btn btn-primary rounded-pill py-1 px-2 me-3" [disabled]="!isAnySelected"
        (click)="resendProposal(0)">Send Selected</button>
      <button class="btn btn-primary rounded-pill py-1 px-2 me-3" (click)="sendProposalToAll()">Send All</button>
      <button (click)="showFilter(!show_filters)" type="button" class="btn rounded-pill py-1 px-2 me-3 "
        [ngClass]="{'btn-primary':show_filters, 'btn-outline': !show_filters}">
        <span class="fs-16 me-1">{{show_filters ? "Hide Filters" : "Show Filters"}}</span>
        <i class="fa fa-filter"></i>
      </button>
      <div class="btn-group" dropdown>
        <button id="button-basic" dropdownToggle type="button"
          class="btn btn-primary rounded-pill py-1 px-2  dropdown-toggle" aria-controls="dropdown-basic">
          Export <span class="caret"></span>
        </button>
        <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
          <li role="menuitem">
            <button class="dropdown-item" tappable (click)="exportXLSX(0, 1)">Export XLSX</button>
          </li>
          <li role="menuitem">
            <button class="dropdown-item" tappable (click)="exportXLSX(1, 1)">Export CSV</button>
          </li>
        </ul>
      </div>

    </div>
  </div>

<div class="table-responsive" *ngIf="_proposal.proposalList && _proposal.proposalList.length">
  <table class="table">
    <thead>
      <tr>
        <th style="min-width: 60px;" class="text-center position-relative text-muted">
          <input type="checkbox" class="secondary-checkbox" [(ngModel)]="allSelected"
            (click)="updateSelection(0, 'all')" name="sr-no" id="sr-no-all">
          <label class="w-100 fs-14 fw-600 " for="sr-no-all">S No.</label>
        </th>
        <th style="min-width: 250px;" class="fs-14 fw-600 text-muted">Customer info</th>
        <th style="min-width: 250px;" class="fs-14 fw-600 text-muted">Business Owner Info</th>
        <th style="min-width: 250px;" class="fs-14 fw-600 text-muted">Proposal info</th>
        <th style="min-width: 150px;" class="fs-14 fw-600 text-muted">Current Status</th>
        <th style="min-width: 150px;" class="fs-14 fw-600 text-muted">Actions</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let proposal of _proposal.proposalList; let i=index;">
        <tr>
          <td class="text-center position-relative border-bottom-0">
            <input type="checkbox" class="secondary-checkbox"
              [disabled]="proposal.is_expired != 0 || proposal.contract_current_status != 'Proposal Sent' || proposal.ppr_status"
              [(ngModel)]="proposal.is_selected" (click)="updateSelection(i)" name="sr-no" id="sr-no{{i}}">
            <label for="sr-no{{i}}">
              {{((_proposal.resData.pageNumber-1)*10)+(i+1)}}
            </label>
          </td>
          <td class="border-bottom-0">
            <div class="row m-0 ">
              <div class="col-3 p-0 text-muted fs-14 fw-600">Name:</div>
              <div class="col-9 p-0 text-left fs-14 fw-400"
                *ngIf="proposal.patient_first_name || proposal.patient_last_name">
                <a [routerLink]="'/app/patients/profile/'+proposal.patient_id" *ngIf="proposal.patient_id"
                  class="text-primary text-bold">
                  {{ proposal.patient_first_name + ' ' + proposal.patient_last_name}}</a>
                <span *ngIf="!proposal.patient_id">{{ proposal.patient_first_name + ' ' +
                  proposal.patient_last_name}}</span>
              </div>
              <span class="fs-14 fw-600" *ngIf="!proposal.patient_first_name && !proposal.patient_last_name">
                N.A.</span>
            </div>
            <div class="row m-0">
              <div class="col-3 p-0 text-muted fs-14 fw-600">Email:</div>
              <div class="col-9 p-0 text-left fs-14 fw-400">
                {{ proposal.patient_email?proposal.patient_email:'N.A.'}}</div>
            </div>
            <div class="row m-0">
              <div class="col-3 p-0 text-muted fs-14 fw-600">Phone:</div>
              <div class="col-9 p-0 text-left fs-14 fw-400">
                {{ (proposal.patient_mobile && proposal.patient_mobile_country_code
                )?
                (proposal.patient_mobile_country_code)+'
                '+(proposal.patient_mobile): 'N.A.'}}
              </div>
            </div>
            <div class="row m-0">
              <div class="col-3 p-0 text-muted fs-14 fw-600">Address:</div>
              <div class="col-9 p-0 text-left fs-14 fw-400">
                {{ proposal.patient_address?proposal.patient_address:'N.A.'}}
              </div>
            </div>
          </td>
          <td class="border-bottom-0">
            <div class="row m-0">
              <div class="col-3 p-0 text-muted fs-14 fw-600">Name:</div>
              <div class="col-9 p-0 text-left fs-14 fw-400" *ngIf="proposal.doctor_name">
                <a [routerLink]="'/app/business/details/'+proposal.doctor_id" class="text-primary text-bold">
                  {{ proposal.doctor_name}}</a>
              </div>
              <span *ngIf="!proposal.doctor_name" class="fs-14 fw-600">N.A.</span>
            </div>
            <div class="row m-0">
              <div class="col-3 p-0 text-muted fs-14 fw-600">Email:</div>
              <div class="col-9 p-0 text-left fs-14 fw-400">
                {{ proposal.doctor_email?proposal.doctor_email:'N.A.'}}</div>
            </div>
          </td>
          <td class="border-bottom-0">
            <div class="row m-0">
              <div class="col-5 p-0 text-muted fs-14 fw-600">Contract Type:</div>
              <div class="col-7 p-0 text-left fs-14 fw-400">
                <span *ngIf="proposal.contract_type==2" class="badge bg-success">
                  CredeeFi
                </span>
                <span *ngIf="proposal.contract_type==4" class="badge bg-success">
                  {{ proposal.ez_plan_new ? 'ZeroFi Financing': 'SimpleeFi Financing' }}
                </span>
                <span *ngIf="proposal.contract_type==6" class="badge bg-primary" style="white-space: normal;">
                  SimpleeFi Payment Plan (With Deferred Interest)
                </span>
                <!-- <span *ngIf="proposal.contract_type==1" class="badge bg-warning">
                                    10% Service Fee
                                </span> -->
                <span *ngIf="proposal.contract_type==3" class="badge bg-primary">
                  KeepeeFi
                </span>
                <span *ngIf="proposal.contract_type==5" class="badge bg-success">
                  Credee Bill Payment Plan
                </span>
              </div>
            </div>
            <div class="row m-0">
              <div class="col-5 p-0 text-muted fs-14 fw-600">Request Date:</div>
              <div class="col-7 p-0 text-left fs-14 fw-400">
                {{ proposal.date_added?(proposal.date_added|date:
                'MM-dd-yyyy':timezone):'N.A.' }}
              </div>
            </div>
            <div class="row m-0">
              <div class="col-5 p-0 text-muted">Date Range:</div>
              <div class="col-7 p-0 text-left">
                {{ proposal.start_date?(proposal.start_date|date: 'MM-dd-yyyy':
                '+0000'):'N.A.' }}
                to<br>
                {{ proposal.end_date?(proposal.end_date|date: 'MM-dd-yyyy':
                '+0000'):'N.A.' }}
              </div>
            </div>
          </td>
          <td class="border-bottom-0">
            <div class="row m-0">
              <div class="col-12 p-0 fs-14 fw-400">Proposal Resent:
                {{proposal.resend_email_count ? proposal.resend_email_count : 0}}
              </div>
              <div class="col-12 p-0 fs-14 fw-400">Last Resent:
                {{proposal.last_email_sent ? (proposal.last_email_sent |
                date:'MM-dd-yyyy hh:mm a':timezone) : 'N.A.'}}
              </div>
              <div class="col-12 p-0 fs-14 fw-400">Settings Changed Count:
                {{proposal.settings_changed_count || 0}}
              </div>
              <div class="col-12 p-0 fs-14 fw-400">Last Action:
                {{proposal.last_action ? (proposal.last_action | date:'MM-dd-yyyy
                hh:mm a':timezone)
                : 'N.A.'}}
              </div>
              <div class="col-12 p-0 fs-14 fw-400">
                <span class="m-1 badge" [ngClass]="{
                                        'bg-info': proposal.proposal_status_id == 1 || proposal.proposal_status_id == 2 || proposal.proposal_status_id == 3,
                                        'bg-success': proposal.proposal_status_id == 4,
                                        'bg-danger': proposal.proposal_status_id == 5
                                    }">
                  {{proposal.proposal_status}}
                </span>

                <span class="m-1 badge bg-info">
                  {{proposal.request_from ? 'Send Payment Link' : 'Send Proposal'}}
                </span>
              </div>
              <!-- 1- request sent, 2-Link opened, 3-plan selected, 4-Financed, 5-Cancelled proposal_status_id -->
              <div>
                <a class="padd_r5" [routerLink]="'/app/contracts/details/'+proposal.contract_id"
                  *ngIf="proposal.doctor_status == 2 && proposal.patient_status == 2 && proposal.contract_id && proposal.contract_code">
                  {{proposal.contract_code}}
                </a>
                <span *ngIf="proposal.is_expired == 1" class="badge bg-danger">Expired</span>
              </div>
            </div>
          </td>
          <td class="row m-0 border-bottom-0">
            <ng-container *ngIf="!proposal.ppr_status ||  (proposal.ppr_status && proposal.ppr_status!=2)">

              <div class="col-12 p-0 text-center"
                *ngIf="proposal.is_expired == 0 && proposal.contract_current_status == 'Proposal Sent'">
                <button class="btn btn-primary rounded-pill py-1 px-2"
                  [disabled]="proposal.is_selected || isAnySelected" (click)="resendProposal(proposal)"> Resend Proposal
                </button>
              </div>

              <div class="col-12 p-0 my-2">
                <div class="d-flex align-items-center justify-content-center">
                  <img src="assets/images/icons/eyebtn.png" class="hw-32 me-3 cursor" alt=""
                    (click)="gotoproposaldetails(proposal.split_req_id)">
                  <img src="assets/images/icons/red-delete.png" class="hw-32 cursor" alt=""
                    *ngIf="proposal.can_delete == 1 && proposal.is_financed != 1"
                    (click)="deleteRequest(proposal.split_req_id)">
                </div>
              </div>

              <div class="col-12 p-0 text-center">
                <button class="btn btn-primary rounded-pill py-1 px-2"
                  *ngIf="proposal.doctor_status == 2 && proposal.patient_status == 2 && proposal.contract_id"
                  (click)="goToContract(proposal.contract_id)"> View Contract
                </button>

              </div>
            </ng-container>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</div>
<div class="border-top" *ngIf="_proposal.proposalList && _proposal.proposalList.length">
  <div class="row m-0 align-items-center mt-2">
    <div class="col">
      <p class="text-bold">
        Showing {{(1+(_proposal.resData.pageNumber - 1) * 10)}} to {{(_proposal.proposalList.length +
        (_proposal.resData.pageNumber - 1) *
        10)}} of {{_proposal.resData.totalRecords || 'all'}}
      </p>
    </div>
    <div class="col ">
      <pagination class="float-end pagination-sm" [totalItems]="_proposal.resData.totalRecords" [maxSize]="5"
        [boundaryLinks]="true" [rotate]="false" (pageChanged)="pageChanged($event)"
        [(ngModel)]="_proposal.resData.pageNumber">
      </pagination>
    </div>
  </div>
    </div>
</div>