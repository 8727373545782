import { Injectable, OnInit } from "@angular/core";
import { ToasterService } from "./toaster/toaster.service";
import { md5 } from "./md5.service";
import { localStorageService } from "./localstorage.service";
import { BehaviorSubject, combineLatest, Observable, Subject } from "rxjs";
import * as moment from "moment";
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import { MainService } from "./main.service";
import { RouteAuthService } from "./route-auth.service";

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  public timezone: string = '';
  public timezoneName: string = '';
  public selected_country$: BehaviorSubject<any> = new BehaviorSubject([]);
  private key_sort_by = '';
  public mask = '';

  public timezone$: BehaviorSubject<any> = new BehaviorSubject('-700');

  public timezoneName$: BehaviorSubject<any> = new BehaviorSubject('');

  public zone$: BehaviorSubject<any> = new BehaviorSubject('');

  public isDummy$: BehaviorSubject<any>  = new BehaviorSubject([]);
  public paymentSourceType = {
    CARD: 2,
    BANK: 3
  }
  constructor(private _toaster: ToasterService, private _local: localStorageService, private _main: MainService, public routerAuth: RouteAuthService) {
    this.device_id();
    console.log('shared');
  }

  // ngOnInit(): void {
  //   this._main.mask$.subscribe(maskValue => {
  //     this.mask = maskValue;
  //     console.log(this.mask);
  //   })
  //   this._main.selected_country$.subscribe(selectedCoun => {
  //     console.log(selectedCoun)
  //     this.selected_country$.next(selectedCoun);
  //   })
  // }

  customPopups(message: string, flag: Number) {
    if (flag == 1) {
      this._toaster.showToaster('Error', message, 'error');
    } else  if (flag == 2) {
      this._toaster.showToaster('Warning', message, 'warning');
    } else {
      this._toaster.showToaster('Success', message, 'success');
    }
  }

  crypto(tokenFromUI: string) {
    return md5(tokenFromUI);
  }

  updateMasking(format: '') {
    return format.replace(/#/g, '0');
  }

  guid() {
    const a = new Date();
    const nav = window.navigator;
    const screen = window.screen;
    let guid = nav.mimeTypes.length.toString();
    guid += nav.userAgent.replace(/\D+/g, '');
    guid += nav.plugins.length;
    guid += screen.height || '';
    guid += screen.width || '';
    guid += screen.pixelDepth || '';
    guid += a.getTime();
    return guid;
  }

  device_id() {
    if (!this._local.customGetItem('user')) {
      const guidLog = this.guid();
      this._local.customSetItem('user', guidLog);
    }
  }
  getMaxDate(timezone: string) {
    if (timezone) {
      var offset = parseInt((parseInt(timezone)/100).toFixed()) + (parseInt(timezone)%100) / 60
      var d, utc, nd;
      d = new Date();
      utc = d.getTime() + (d.getTimezoneOffset() * 60000);
      nd = new Date(utc + (3600000 * offset));
      return nd;
    } else {
      return new Date();
    }
  }

  updateDummyLive(): Observable<any> {
    return combineLatest([this.isDummy$.asObservable(), this.timezone$.asObservable(), this.selected_country$.asObservable()]);
  }

  getTimezone() {
    let timezone: any = '';
    this.timezone$.subscribe(timeZone => {
      timezone = timeZone;
    })
    return timezone;
  }

  getTimezoneName() {
    let timezoneName: any = '';
    this.timezoneName$.subscribe(timeZone => {
      timezoneName = timeZone;
    })
    return timezoneName;
  }

  getTimezoneZone() {
    let zone: any = '';
    this.zone$.subscribe(zone => {
      zone = zone;
    })
    return zone;
  }

  getTimezoneList() {
    let timezoneList: any = '';
    this._main.timezoneList$.subscribe(timezone_list => {
      timezoneList = timezone_list;
    })
    return timezoneList;
  }

  getAdminList(): Array<[]> {
    let admin_list: any = [];
    this._main.global_admin_list$.subscribe((adminList: any) => {
      admin_list = adminList;
    })
    return admin_list;
  }

  getGovernmentDocs(): Array<[]> {
    let gov_docs: any = [];
    this._main.government_docs$.subscribe((govDocs: any) => {
      gov_docs = govDocs;
    })
    return gov_docs;
  }

  getlanguage_data(): Observable<any> {
    return this._main.language_data$.asObservable();
  }

  getCountryData(): countryType[] {
    let countriesData: any = [];
    this._main.country_data$.subscribe((country_data: any) => {
      countriesData = country_data;
    })
    return countriesData;
  }

  getCurrencyData(): Array<[]> {
    let currenciesData: any = [];
    this._main.currency_data$.subscribe((currency_data: any) => {
      currenciesData = currency_data;
    })
    return currenciesData;
  }

  getMobileCodeArr() {
    let mobileCodeArr: any = [];
    this._main.mobile_code_arr$.subscribe((mobile_code_arr: any) => {
      mobileCodeArr = mobile_code_arr;
    })
    return mobileCodeArr;
  }

  getExactDate(d: Date) {
    return new Date(d.getTime() + d.getTimezoneOffset() * 60000);
  }

  getSalutations() {
    let salutation: any = [];
    this._main.salutations$.subscribe((salutations: any) => {
      salutation = salutations;
    })
    return salutation;
  }

  getIndustryList() {
    let industryList: any = [];
    this._main.industryList$.subscribe((industries: any) => {
      industryList = industries;
    })
    return industryList;
  }

  getStripeKey() {
    let stripeKey: any = '';
    this._main.stripeKey$.subscribe((stripe_key: any) => {
      stripeKey = stripe_key;
    })
    return stripeKey;
  }
  
  sortByFun(data: Array<[]>, key_sort_by: string): [][] {
    this.key_sort_by = key_sort_by;
    return data.sort((a: any, b: any) => a[key_sort_by] - b[key_sort_by]);
  }

  compare(a: any, b: any) {
    if (a && a[this.key_sort_by] < b && b[this.key_sort_by])
        return -1;
    if (a && a[this.key_sort_by] > b && b[this.key_sort_by])
        return 1;
    return 0;
  }

  getfollowupDateTimeInUTCToday(date_time: Date, timezone: string) {
    var offset = parseInt((parseInt(timezone) / 100).toFixed()) + (parseInt(timezone) % 100) / 60;
    var utc, followup_time;
    utc = new Date(date_time).getTime();
    followup_time = moment(new Date(utc - (3600000 * offset)));
    return followup_time.format('YYYY-MM-DD HH:mm:00');
  }

  getfollowupDateTimeInUTC(date_time: string, timezone: any) {
    var offset = parseInt((parseInt(timezone) / 100).toFixed()) + (parseInt(timezone) % 100) / 60;
    var utc, followup_time;
    var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if (isSafari) {
      date_time = moment(date_time).format('YYYY/MM/DD HH:mm:00');
      utc = new Date(date_time.replace(/-/g, "/")).getTime();
    } else {
      utc = new Date(date_time).getTime();
    }
    followup_time = moment(new Date(utc - (3600000 * offset)));

    if (isSafari) {
      return followup_time.format('YYYY/MM/DD HH:mm:00');
    }
    return followup_time.format('YYYY-MM-DD HH:mm:00');
  }

  getTransactionFee(contract: any, comparable_value: any) {
    let text = '';
    if (comparable_value < contract.threshold_amount) {
      if (contract.recurring_extra_percent) {
        text += `${contract.recurring_extra_percent.toFixed(2)}%`
      }
      if (contract.recurring_extra_amount && contract.recurring_extra_percent) {
        text += ' + ';
      }
      if (contract.recurring_extra_amount) {
        text += `$${contract.recurring_extra_amount.toFixed(2)}`
      }
    } else {
      if (contract.threshold_recurring_extra_percent) {
        text += `${contract.threshold_recurring_extra_percent.toFixed(2)}%`
      }
      if (contract.threshold_recurring_extra_amount && contract.threshold_recurring_extra_percent) {
        text += ' + ';
      }
      if (contract.threshold_recurring_extra_amount) {
        text += `$${contract.threshold_recurring_extra_amount.toFixed(2)}`
      }
    }
    return text;
  }

  public exportAsExcelFile(json: any[], excelFileName: string, ext: string): void {
    console.log(excelFileName)
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json, { skipHeader: true });
    const workbook: XLSX.WorkBook = { Sheets: { 'Report': worksheet }, SheetNames: ['Report'] };
    if (ext == '.xlsx') {
      const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, excelFileName, ext);
    } else if (ext == '.csv') {
      const excelBuffer: any = XLSX.write(workbook, { bookType: 'csv', type: 'array' });
      this.saveAsExcelFile(excelBuffer, excelFileName, ext);
    }
  }

  private saveAsExcelFile(buffer: any, fileName: string, extension: string): void {
    console.log(fileName)
    const data: Blob = new Blob([buffer]);
    FileSaver.saveAs(data, fileName + '_export' + extension);
  }

  

  getClaimStatus(claim_status_id: number) {
    let claim_status_array = [{ id: "-1", value: "-", color: "" },
    { id: "0", value: "Not Claimed", color: "#e5b319" },
    { id: "1", value: "Submitted", color: "#e5841c" },
    { id: "2", value: "Conditionally Approved", color: "#4cd02e" },
    { id: "3", value: "Denied", color: "#ee6060" },
    { id: "4", value: "On Hold", color: "#000000" },
    { id: "5", value: "Paid", color: "#19870e" },
    { id: "6", value: "Need More Document(s)", color: "#ee6060" },
    { id: "7", value: "Documents uploaded", color: "#4cd02e" },
    { id: "8", value: "Unpaid", color: "#18a5b7" },
    { id: "9", value: "Removed From Unpaid", color: "#ee6060" },
    { id: "10", value: "Expired : Manually", color: "#ee6060" },
    { id: "12", value: "Unpaid Pending", color: "#18a5b7" }
    ];
    let claim_status = claim_status_array.find((obj: any) => {
      return obj.id == claim_status_id;
    });
    return claim_status ? claim_status : '';
  }

}

export enum verifyType {
  Doctor = 'Doctor',
  Partner = 'Partner',
  Patient = 'Patient'
}

export interface countryType {
  country_id: '',
  cocu_id: '',
  display_logo: '',
  country_name: ''
}

export interface currencyType {
  cocu_id: number,
  currency_id: number,
  currency_name: string,
  display_logo: string
}