import { Component, OnInit, Input } from '@angular/core';
import { ContractsService } from '../contracts.service';

@Component({
  selector: 'app-hover-contract',
  templateUrl: './hover-contract.component.html',
  styleUrls: ['./hover-contract.component.scss']
})
export class HoverContractComponent implements OnInit {
  @Input() content: any;
  contract:any;

  constructor( public _contract: ContractsService) { }
  ngOnInit() {
    this.contract = this.content;
  }

}
