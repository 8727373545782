import { Component } from '@angular/core';

@Component({
  selector: 'app-leads-details',
  templateUrl: './leads-details.component.html',
  styleUrl: './leads-details.component.scss'
})
export class LeadsDetailsComponent {

}
