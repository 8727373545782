
  <div class="" id="stats">
    <div class="modal-header">
      <h4>Business Financing Information</h4>
      <button type="button" class="close" aria-label="Close" (click)="hideModal(0)">
        &times;
      </button>
    </div>
    <div class="modal-body bg-light-2">
      <div class="stats" *ngFor="let stat of stats">
        <div class="stat-row border-bottom d-flex justify-content-between shadow-sm p-3 mb-2 bg-white rounded"
          [ngClass]="{'border-success': stat.value > 0, 'border-danger blinking': stat.value < 0, 'border-primary': stat.value == 0 || stat.value.length }">
          <div><strong>{{stat.label}}</strong></div>
          <div>{{stat.value !== '' ? stat.value : 'N.A'}}</div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" *ngIf="data.value == 1" class="btn btn-primary"
        (click)="hideModal(1)">Approve</button>
        <button type="button" *ngIf="data.value == 2" class="btn btn-primary"
        (click)="hideModal(1)">Move To Unpaid Pending</button>
        <button type="button" *ngIf="data.value == 5" class="btn btn-primary"
        (click)="hideModal(1)">Move To Unpaid</button>
        <button type="button" *ngIf="data.value == 3" class="btn btn-primary"
        (click)="hideModal(1)">Remove from Unpaid</button>
        <button type="button" *ngIf="data.value == 4" class="btn btn-primary"
        (click)="hideModal(1)">Reject</button>
      <button type="button" class="btn btn-primary" (click)="hideModal(0)">Cancel</button>
    </div>
  </div>
