<div class="d-flex align-item-center justify-content-between border_10">
  <div class="custom-form flex-grow-1 px-0">
    <input ngx-gp-autocomplete type="text" #addressItem
      [(ngModel)]="address" [options]='options1' (onAddressChange)="handleAddressChange($event);address=''"
      [disabled]="disabled" [ngClass]="comp_class" class="input_field px-1 pt-2 w-100 form-control google-zip-code"
      autocomplete="false" placeholder="&nbsp;" required="false" />
    <!-- <label for="text" class="label-name">
          <span class="content-name f-c-p-3">
            Postal code
          </span>
      </label> -->
  </div>
</div>