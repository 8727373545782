import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ContractService } from 'src/app/components/pages/contracts/contracts.service';
import { MainService } from '../main.service';
import { SharedService } from '../shared.service';
import { ApiService } from '../api.service';
import { localStorageService } from '../localstorage.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-edit-contract-modal',
  templateUrl: './edit-contract-modal.component.html',
  styleUrls: ['./edit-contract-modal.component.scss']
})
export class EditContractModalComponent implements OnInit {
  public contract_details: any;
  public doctor_settings: any;
  public old_contract_data: any;
  type = 1;
  no_fee_plans: any = [];
  public edit_mode = true;
  send_customer_notification = false;
  public edit_allowed_method: any;
  financed_error = '';
  currency_logo:any;
  bank_accounts: any = [];
  editForm!: UntypedFormGroup;
  constructor(public bsModalRef: BsModalRef,
    public modalService: BsModalService,
    public contractService: ContractService,
    public mainService: MainService,
    public _shared: SharedService,
    private _api: ApiService,
    private _local: localStorageService,
    private _fb: UntypedFormBuilder,
    public cd: ChangeDetectorRef) { }

  ngOnInit() {
    this.send_customer_notification = false;
    if (this.contract_details.adjusted_payments == 0) {
      this.type = 1;
    } else {
      this.type = 0;
    }
    this.old_contract_data = this.contract_details;
    this.getContractInfo(this.contract_details.contract_id);
    this.contract_details.recovered_payments = this.contract_details.number_of_payments - this.contract_details.remaining_payments;
    if (this.contract_details.contract_type == 1) {
      //default settings of 10%
      this.doctor_settings = {
        minimum_amount: 100,
        maximum_amount: 100000,
        minimum_tenure: 2,
        maximum_tenure: 99,
        min_interest_rate: 0,
        max_interest_rate: 100,
      }
    }else if (this.contract_details.contract_type == 3) {
      //default settings of KeepeeFi Financing
      this.doctor_settings = {
        minimum_amount: 100,
        maximum_amount: 100000,
        minimum_tenure: 2,
        maximum_tenure: 99,
        min_interest_rate: 0,
        max_interest_rate: 100,
      }
    } else {
      this.doctor_settings = {
        minimum_amount: 100,
        maximum_amount: 20000,
        minimum_tenure: 12,
        maximum_tenure: 48,
        min_interest_rate: 12.99,
        max_interest_rate: 16.99,
      }
    }

    this.editForm = this._fb.group({
      additional_finance_amount: [this.contract_details.additional_finance_amount, Validators.required],
      new_downpayment_amount: [this.contract_details.new_downpayment_amount, Validators.required],
      new_interest_rate: [this.contract_details.new_interest_rate, Validators.required],
      new_term: [this.contract_details.new_term, Validators.required],
      new_financed_amount: [{value : 0}],
      new_recurring_amount: [{value : this.contract_details.new_recurring_amount}],
      notes: [''],
      send_customer_notification: [false]
    })
  }
  hideModal() {
    this.bsModalRef.hide();
  }
  getContractInfo(id: any) {
    let data = {
      contract_id: id,
      get_doctor_settings: 1
    };
    this._api.api(data, 'finance/get_contract_info', 'get')
      .subscribe(async (data: any) => {
        if (data.is_error == 1) this._shared.customPopups(data.flag, data.is_error);
        if (this.contract_details.can_edit_financing_details === 0) {
          this._shared.customPopups('You cannot edit this contract', 1);
          this.bsModalRef.hide();
        }

        if (this.contract_details.pf_status < 3) {
          this.contract_details.payment_status = "Active";
        }
        if (this.contract_details.pf_status == 3) {
          // this._shared.customPopups('The contract is overdue so you can only decrease the finance amount.', 2);
          this.contract_details.payment_status = "Overdue";
        }
        if (this.contract_details.pf_status == 4 || this.contract_details.pf_status == 5) {
          this._shared.customPopups('This contract cannot be edited', 1);
          this.bsModalRef.hide();
        }
        this.contract_details = data.contractInfo[0];
        this.contract_details['payment_sources'] = await this.getPaymentSources(this.contract_details['patient_id']);
        this.bank_accounts = this.contract_details.payment_sources.filter((data: any) => {
          return data.payment_source_type == 1;
        });
        this.contract_details.recovered_payments = this.contract_details.number_of_payments - this.contract_details.remaining_payments;
        if (this.contract_details && this.contract_details.contract_type == 2) {
          if (this.contract_details.no_fee_min_max_settings) {
            let no_fee_min_max_settings = JSON.parse(this.contract_details.no_fee_min_max_settings);
            if (no_fee_min_max_settings.length > 0) {
              this.contract_details.minimum_amount = no_fee_min_max_settings.sort(function (a: any, b: any) {
                return a.minimum_amount - b.minimum_amount;
              })[0].minimum_amount;
              this.contract_details.maximum_amount = no_fee_min_max_settings.sort(function (a: any, b: any) {
                return b.maximum_amount - a.maximum_amount;
              })[0].maximum_amount;
              console.log(this.contract_details.minimum_amount, this.contract_details.maximum_amount);
            }
          }
          this.doctor_settings = {
            minimum_amount: this.contract_details.minimum_amount,
            maximum_amount: this.contract_details.maximum_amount,
            minimum_tenure: this.contract_details.minimum_tenure,
            maximum_tenure: this.contract_details.maximum_tenure,
            min_interest_rate: 12.99,
            max_interest_rate: this.contract_details.base_interest_rate,
          }
        }
        if (this.contract_details && this.contract_details.contract_type == 4) {
          if (this.contract_details.ez_min_max_settings) {
            let ez_min_max_settings = JSON.parse(this.contract_details.ez_min_max_settings);
            if (ez_min_max_settings.length > 0) {
              this.contract_details.minimum_amount = ez_min_max_settings.sort(function (a: any, b: any) {
                return a.minimum_amount - b.minimum_amount;
              })[0].minimum_amount;
              this.contract_details.maximum_amount = ez_min_max_settings.sort(function (a: any, b: any) {
                return b.maximum_amount - a.maximum_amount;
              })[0].maximum_amount;
              console.log(this.contract_details.minimum_amount, this.contract_details.maximum_amount);
            }
          }
          this.doctor_settings = {
            minimum_amount: this.contract_details.minimum_amount,
            maximum_amount: this.contract_details.maximum_amount,
            minimum_tenure: this.contract_details.minimum_tenure,
            maximum_tenure: this.contract_details.maximum_tenure,
            min_interest_rate: 12.99,
            max_interest_rate: this.contract_details.base_interest_rate,
          }
        }
        if (this.contract_details && this.contract_details.contract_type == 5) {
          if (this.contract_details.ar_ez_min_max_settings) {
            let ar_ez_min_max_settings = JSON.parse(this.contract_details.ar_ez_min_max_settings);
            if (ar_ez_min_max_settings.length > 0) {
              this.contract_details.minimum_amount = ar_ez_min_max_settings.sort(function (a: any, b: any) {
                return a.minimum_amount - b.minimum_amount;
              })[0].minimum_amount;
              this.contract_details.maximum_amount = ar_ez_min_max_settings.sort(function (a: any, b: any) {
                return b.maximum_amount - a.maximum_amount;
              })[0].maximum_amount;
              console.log(this.contract_details.minimum_amount, this.contract_details.maximum_amount);
            }
          }
          this.doctor_settings = {
            minimum_amount: this.contract_details.minimum_amount,
            maximum_amount: this.contract_details.maximum_amount,
            minimum_tenure: this.contract_details.minimum_tenure,
            maximum_tenure: this.contract_details.maximum_tenure,
            min_interest_rate: 12.99,
            max_interest_rate: this.contract_details.base_interest_rate,
          }
        }
      
        this.contract_details.remaining_principal = this.contract_details.principal_per_recurring * this.contract_details.remaining_payments
        this.contract_details.new_interest_rate = this.contract_details.interest_rate_to_show || this.contract_details.interest_rate;
        this.contract_details.new_term = this.contract_details.remaining_payments;
        this.contract_details.additional_finance_amount = 0;
        this.edit_allowed_method = data.edit_allowed_method;
        // if (this.edit_allowed_method.normal_method == 1 && this.edit_allowed_method.adjusted_method == 1) {
        //   this.edit_mode = false;
        // } else if (this.edit_allowed_method.normal_method == 1) {
        //   this.edit_mode = false;
        // } else if (this.edit_allowed_method.adjusted_method == 1) {
        //   this.edit_mode = true;
        // }

        this.contract_details.new_downpayment_amount = 0;
        // this.changeContractEditMode()
        this.contract_details.remaining_principal = this.contract_details.principal_per_recurring * this.contract_details.remaining_payments;
        this.contract_details.new_recurring_amount = this.contract_details.recurring_amount;
        this.CalculateNewFinancedAmount();
        this.GetComparableValues();
        if (this.contract_details.is_cash_adjustment) {
          this.type = 0;
        } else {
          this.type = 1;
        }
        console.log(this.editForm.value)
      })
  }
  CalculateNewFinancedAmount() {
    let amount = this.contract_details.remaining_principal;
    if (this.contract_details.additional_finance_amount) {
      amount = amount + this.contract_details.additional_finance_amount;
    }
    if (this.contract_details.new_downpayment_amount) {
      amount = amount - this.contract_details.new_downpayment_amount;
    }
    this.contract_details.new_financed_amount = amount.toFixed(2);
    this.editForm.patchValue({
      new_interest_rate: this.contract_details.new_interest_rate,
      new_term: this.contract_details.new_term,
      additional_finance_amount: this.contract_details.additional_finance_amount,
      new_downpayment_amount: this.contract_details.new_downpayment_amount,
      new_financed_amount: Number(this.contract_details.new_financed_amount),
      new_recurring_amount: this.contract_details.new_recurring_amount,
    })
    // this.editForm.setValidators()
    this.editForm.updateValueAndValidity();
    if (this.contract_details.contract_type == 2 && this.contract_details.no_fee_min_max_settings) {
      let no_fee_min_max_settings = JSON.parse(this.contract_details.no_fee_min_max_settings);
      let comaparable_finance_amount = this.contract_details.financed_amount + this.contract_details.additional_finance_amount - this.contract_details.new_downpayment_amount;
      let including_array = no_fee_min_max_settings.filter((item: any) => parseFloat(item.minimum_amount) <= comaparable_finance_amount && parseFloat(item.maximum_amount) >= comaparable_finance_amount);
      console.log('including_array>>>>', including_array);
      if (including_array.length == 0) {
        this.doctor_settings.minimum_tenure = 2;
        this.doctor_settings.maximum_tenure = 99;
      } else {
        this.doctor_settings.minimum_tenure = including_array[0].minimum_tenure;
        this.doctor_settings.maximum_tenure = including_array[0].maximum_tenure;
      }
    }
    if (this.contract_details.contract_type == 4 && this.contract_details.ez_min_max_settings) {
      let ez_min_max_settings = JSON.parse(this.contract_details.ez_min_max_settings);
      let comaparable_finance_amount = this.contract_details.financed_amount + this.contract_details.additional_finance_amount - this.contract_details.new_downpayment_amount;
      let including_array = ez_min_max_settings.filter((item: any) => parseFloat(item.minimum_amount) <= comaparable_finance_amount && parseFloat(item.maximum_amount) >= comaparable_finance_amount);
      console.log('including_array>>>>', including_array);
      if (including_array.length == 0) {
        this.doctor_settings.minimum_tenure = 2;
        this.doctor_settings.maximum_tenure = 99;
      } else {
        this.doctor_settings.minimum_tenure = including_array[0].minimum_tenure;
        this.doctor_settings.maximum_tenure = including_array[0].maximum_tenure;
      }
    }
    if (this.contract_details.contract_type == 5 && this.contract_details.ar_ez_min_max_settings) {
      let ar_ez_min_max_settings = JSON.parse(this.contract_details.ar_ez_min_max_settings);
      let comaparable_finance_amount = this.contract_details.financed_amount + this.contract_details.additional_finance_amount - this.contract_details.new_downpayment_amount;
      let including_array = ar_ez_min_max_settings.filter((item: any) => parseFloat(item.minimum_amount) <= comaparable_finance_amount && parseFloat(item.maximum_amount) >= comaparable_finance_amount);
      console.log('including_array>>>>', including_array);
      if (including_array.length == 0) {
        this.doctor_settings.minimum_tenure = 2;
        this.doctor_settings.maximum_tenure = 99;
      } else {
        this.doctor_settings.minimum_tenure = including_array[0].minimum_tenure;
        this.doctor_settings.maximum_tenure = including_array[0].maximum_tenure;
      }
    }
  }
  maxCheckValue(num: any, no_deduct: any = 0) {
    if (!num) {
      return 0;
    }
    num = Number(num);
    return no_deduct ? num : num - 1;
  }
  interestChanged() {
    if (this.contract_details.contract_type == 1 || this.contract_details.contract_type == 3) {
      this.GetComparableValues();
    } else {
      this.CalculateNewRecurringAmount();
    }
  }
  CalculateNewRecurringAmount() {
    if (!this.contract_details.new_term) {
      // this._shared.customPopups('Please enter a valid term', 1);
      // this.contract_details.new_recurring_amount = '';
      return;
    }
    this.contract_details.new_term = Math.trunc(this.contract_details.new_term);
    if (this.edit_mode) {
      let number_of_payments = this.contract_details.new_term + (this.contract_details.number_of_payments - this.contract_details.remaining_payments);
      if (false) {
        let new_finance = this.contract_details.financed_amount + this.contract_details.additional_finance_amount - this.contract_details.new_downpayment_amount;
        let remaining_amount: number = parseFloat((new_finance * (Math.pow((1 + (this.contract_details.new_interest_rate / 1200)), number_of_payments))).toFixed(2));
        let recurring_amount = parseFloat((remaining_amount / number_of_payments).toFixed(2));
        let recovered_principal_am = this.contract_details.financed_amount - (this.contract_details.principal_per_recurring * this.contract_details.remaining_payments);
        let recovered_total_amount = recovered_principal_am + (recovered_principal_am * this.contract_details.one_dollar_interest);
        let contract_remaining_am = (recurring_amount * number_of_payments) - recovered_total_amount;
        let nop_to_recover = (number_of_payments - (this.contract_details.number_of_payments - this.contract_details.remaining_payments));
        this.contract_details.new_recurring_amount = (contract_remaining_am / nop_to_recover).toFixed(2);
        this.contract_details.interest_type = 'C';
      } else {
        if (this.no_fee_plans && this.no_fee_plans.length > 0) {
          let selected_plan: any = this.no_fee_plans.filter((item: any) => item.month == number_of_payments);
          if (selected_plan && selected_plan[0]) {
            this.contract_details.new_advance_interest_amount = selected_plan[0].advance_interest_amount;
            this.contract_details.new_recurring_amount = selected_plan[0]['recurring_amount']
            this.contract_details.new_interest_rate = selected_plan[0].interest_rate_to_show || selected_plan[0].interest_rate;
            if (selected_plan[0].interest_rate_to_show != selected_plan[0].interest_rate) {
              this.contract_details.interest_type = 'F';
            } else  {
              this.contract_details.interest_type = 'C';
            }
          } else {
            this.contract_details.new_recurring_amount = null;
          }
        } else {
          this.contract_details.new_recurring_amount = null;
        }

      }
    } else {
      let remaining_amount: number = parseFloat((this.contract_details.new_financed_amount * (Math.pow((1 + (this.contract_details.new_interest_rate / 1200)), this.contract_details.new_term))).toFixed(2));
      this.contract_details.new_recurring_amount = (remaining_amount / this.contract_details.new_term).toFixed(2);
    }
  }
  edit_financing_details_v2(pay_data: any) {
    let admin_data = JSON.parse(this._local.customGetItem('profileData'))[0]
    let data = {
      "pf_id": this.contract_details.pf_id,
      "additional_treatment_amount": this.editForm.value.additional_finance_amount,
      "downpayment_amount": this.editForm.value.new_downpayment_amount,
      "recurring_amount": this.editForm.value.new_recurring_amount,
      "interest_rate": this.editForm.value.new_interest_rate,
      "interest_rate_to_show": this.editForm.value.new_interest_rate,
      "remaining_payments": this.editForm.value.new_term,
      "number_of_payments": this.editForm.value.new_term + (this.contract_details.number_of_payments - this.contract_details.remaining_payments),
      "notes": this.editForm.value.notes,
      "cd_payment_type": "25",
      "name": admin_data.admin_name,
      "current_admin_id": admin_data.admin_id,
      "admin_id": admin_data.admin_id,
      "source_id":pay_data.source_id,
      "send_customer_notification": this.send_customer_notification ? 1 : 0,
      "payment_source_type": pay_data.payment_source_type
    };
    if(this.contract_details &&  this.editForm.value.new_downpayment_amount>0 || (this.contract_details.new_advance_interest_amount > this.contract_details.advance_interest_amount) ){
      data.source_id = pay_data.source_id;
      data.payment_source_type =  pay_data.payment_source_type;
    }
    console.log(data);
    let link = 'edit_financing_details_v3';
    // if (this.edit_mode) {
    //   link = 'edit_financing_details_v3';
    // }
    this._api.api(data, link)
      .subscribe((data: any) => {
        if (data.is_error != 1) {
          if(pay_data.payment_source_type==1){ 
            this._shared.customPopups('Payment Initiated Successfully', 0);
          }else{
            this._shared.customPopups('Contract Edited Successfully', 0);
          }
        }else if (data.err && data.original_text) {
          this._shared.customPopups(data.original_text, 1);
        } else {
          this._shared.customPopups(data.flag, data.is_error);
        }
        this.modalService.setDismissReason('true');
        this.bsModalRef.hide();
      }, (error: any) => {
        console.log(error);
      })
  }
  validateNewFinanceData() {
    
    //validating financed amount
    if (this.editForm.controls['additional_finance_amount'].value < 0) {
      this._shared.customPopups('Please enter the additional financed amount greater than $0', 1);
      return;
    }
    if (this.editForm.controls['additional_finance_amount'].value > 0) {
      this._shared.customPopups('Please enter the additional financed amount less than $0', 1);
      return;
    }

    // validating downpayment
    if (this.editForm.value.new_downpayment_amount < 0) {
      this._shared.customPopups('Please enter the downpayment amount greater than $0', 1);
      return;
    }
    if (this.editForm.value.new_downpayment_amount > 0) {
      this._shared.customPopups('Please enter the downpayment amount less than $0', 1);
      return;
    }

    // validating Interest rate
    if (this.editForm.value.new_interest_rate < this.contract_details.interest_rate) {
      this._shared.customPopups('Please enter the new interest rate greater than or equal to ' + this.contract_details.interest_rate + '%', 1);
      return;
    }
    if (this.editForm.value.new_interest_rate > this.contract_details.interest_rate) {
      this._shared.customPopups('Please enter the new interest rate less than or equal to ' + this.contract_details.interest_rate + '%', 1);
      return;
    }

    // validating remaining payments
    if (this.editForm.value.new_term < this.contract_details.remaining_payments) {
      this._shared.customPopups('Please enter the new term greater than or equal to ' + this.contract_details.remaining_payments, 1);
      return;
    }
    if (this.editForm.value.new_term > this.contract_details.remaining_payments) {
      this._shared.customPopups('Please enter the new term less than or equal to ' + this.contract_details.remaining_payments, 1);
      return;
    }
    if (this.contract_details.new_financed_amount < 1) {
      this._shared.customPopups('New financed amount should be greater than $1', 1);
      return;
    }
    this.type = 2;
  }

  Next(card?:any) {
    if (this.type === 3) {
      if ((!this.editForm.value.note || this.editForm.value.note.trim().length == 0)) {
        this._shared.customPopups('Please enter a note', 1);
      } else {
        let data ={
          source_id:0,
          payment_source_type:2
        };
        if(card && card.source_id){
          data.source_id = card.source_id;
          data.payment_source_type = card.payment_source_type;
        }
        this.edit_financing_details_v2(data);
      }
    } else if (this.type === 1) {
      this.validateNewFinanceData();
    } else {
      this.type = this.type + 1
    }
  }
  GetComparableValues() {
    let data: any = {
      pf_id: this.contract_details.pf_id,
      "treatment_amount": this.contract_details.treatment_amount + this.editForm.value.additional_finance_amount,
      "downpayment_amount": this.editForm.value.new_downpayment_amount
    };
    if (this.contract_details.contract_type == 1 || this.contract_details.contract_type == 3) {
      data['interest_rate'] = this.contract_details.new_interest_rate
    }
    this.no_fee_plans = [];
    this._api.api(data, 'finance/get_edit_finance_plans')
      .subscribe((data: any) => {
        if (data.is_error) {
          this.financed_error = data.original_text;
          this.contract_details.new_recurring_amount = null;
          // this._shared.customPopups(data.original_text, data.is_error);
        } else {
          this.financed_error = '';
          // console.log(data);
          this.no_fee_plans = data.months;
          this.CalculateNewRecurringAmount();
        }
      });

  }
  
  async getPaymentSources(patient_id: number) {
    return new Promise((resolve, reject) => {
      const data = {
        patient_id
      };
      this._api.api(data, 'finance/get_customer_payment_source', 'get').subscribe(response => {
        if (!response.is_error) {
          resolve(response.payment_sources);
        } else {
          resolve([]);
        }
      })

    })
  }
}