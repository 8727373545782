<div class="lead_content">
    <div class="row align-items-center mb-4">
      <div class="col-md-3 col-sm-4 padd_lr0">
        <div class="left_lead">
          <img src="assets/images/png/lead_icon.png">
          <span>LEADS</span>
        </div>
      </div>
      <div class="col-md-9 col-sm-8 padd_lr0">
        <a class="sample_link" *ngIf="!_shared.routerAuth.restricted_buttons.download_sample_file">
            <span (click)="downloadSampleLink(0)" style="cursor: pointer;">Download Sample File</span>
        </a>
        <div>
          <button class="export_lead col-md-4 padd_lr0" (click)="exportXLSX(0)"
            *ngIf="!_shared.routerAuth.restricted_buttons.export">Export
            <img src="assets/images/png/export.png">
          </button>
          <!-- <button class="import_lead col-md-4" *ngIf="!_shared.routerAuth.restricted_buttons.import"><input
              type="file" class="upload" (change)="importFile($event.target?.files)" accept=".xlsx, .xls, .csv">Import
            <img src="assets/images/png/import.png">
          </button> -->
          <a routerLink="/app/leads/create" *ngIf="!_shared.routerAuth.restricted_buttons.add">
            <button class="create_lead col-md-4">Create Lead
              <img src="assets/images/png/create_lead.png">
            </button>
          </a>
        </div>
      </div>
    </div>
    <div class="row card lead-card-body">
      <div class="row col-12">
        <div class="col-12 col-sm-3">
          <div class="form-group">
            <label for="range">Range (in miles)</label>
            <input name="range" required type="number" min="0" max="1000" id="range" [(ngModel)]="leads_range"
              class="form-control">
            <p class="text-danger" *ngIf="leads_range > 1000">Range must be less than 1000 miles</p>
          </div>
        </div>
        <div class="col-12 col-sm-3">
          <div class="form-group">
            <label for="zip">Zipcode</label>
            <!-- implement zip -->
          </div>
        </div>
        <div class="col-12 col-sm-3">
          <div class="form-group">
            <label for="speciality">Industry | Sub-industry</label>
            <select name="speciality" id="speciality" [(ngModel)]="leads_speciality" class="form-control">
              <option value="0" selected>All</option>
              <optgroup *ngFor="let ind of industries" label="{{ind.ind_name}}">
                <option *ngFor="let spec of ind.specialities" [value]="spec.doctor_speciality">{{spec.speciality_name}}
                </option>
              </optgroup>
            </select>
          </div>
        </div>
        <div class="col-12 col-sm-3">
          <div class="form-group">
            <label for="speciality">Limit</label>
            <select name="searchLimit" id="searchLimit" [(ngModel)]="resData.limit" class="form-control">
              <option *ngFor="let searchLimit of searchLimits" [value]="searchLimit">{{searchLimit}}</option>
            </select>
          </div>
        </div>
      </div>
      <div class="row total_leads_row padd_t20">
        <div class="total_leads col-md-12 col-sm-12 padd_r35" style="padding-bottom:25px;">
          <span class="col-md-1" *ngIf="_shared.routerAuth.restricted_buttons.assign_multiple_leads">
            <img src="assets/images/png/total_lead.png">
            <span>Total Leads</span>
          </span>
          <button class="assign_lead col-md-2 col-sm-6 disabled"
            *ngIf="(checkboxLeads && checkboxLeads.length == 0) && !_shared.routerAuth.restricted_buttons.assign_multiple_leads"
            [ngStyle]="{ 'background-color' : (checkboxLeads && checkboxLeads.length>0) ? '#1e4468' : '' }">Assign
            Multiple Leads</button>
          <button class="assign_lead col-md-2 col-sm-6"
            *ngIf="(checkboxLeads && checkboxLeads.length > 0 ) && !_shared.routerAuth.restricted_buttons.assign_multiple_leads"
            (click)="assignMultipleLeadModalShow()"
            [ngStyle]="{ 'background-color' : (checkboxLeads && checkboxLeads.length>0) ? '#1e4468' : '' }">Assign
            Multiple Leads To</button>
          <button class="delete_all col-md-1 col-sm-6 disabled"
            *ngIf="(checkboxLeads && checkboxLeads.length == 0) && !_shared.routerAuth.restricted_buttons.delete_all"
            [ngStyle]="{ 'background-color' : (checkboxLeads && checkboxLeads.length>0) ? '#ff6262' : '' }">Delete
            All</button>
          <button class="delete_all col-md-1 col-sm-6"
            *ngIf="(checkboxLeads && checkboxLeads.length > 0 )  && !_shared.routerAuth.restricted_buttons.delete_all"
            (click)="deleteLeadModalShow()"
            [ngStyle]="{ 'background-color' : (checkboxLeads && checkboxLeads.length>0) ? '#ff6262' : '' }">Delete
            All</button>
          <div class="col-md-2 col-sm-6 date_range mtn_10 pull-right "
            *ngIf="!_shared.routerAuth.restricted_buttons.follow_up_end">
            <label class="mb-0">Follow Up End Date</label>
            <input readonly type="text" bsDatepicker class="form-control" [(ngModel)]="max_followup_date "
              (ngModelChange)="dateChange()" [bsConfig]="bsConfig"
              placeholder="End Date">
          </div>
          <div class="col-md-2 col-sm-6 date_range mtn_10 pull-right "
            *ngIf="!_shared.routerAuth.restricted_buttons.follow_up_start">
            <label class="mb-0">Follow Up Start Date</label>
            <input readonly type="text" bsDatepicker class="form-control" [(ngModel)]="min_followup_date "
              (ngModelChange)="dateChange()" [bsConfig]="bsConfig"
              placeholder="Start Date">
          </div>
          <div class="col-md-2 col-sm-6 date_range pull-right mtn_10">
            <label class="mb-0">End Date</label>
            <input readonly type="text" bsDatepicker class="form-control" [(ngModel)]="max_date_added"
              (ngModelChange)="dateChange()" [bsConfig]="bsConfig"
              [maxDate]="maxDate" placeholder="End Date">
          </div>
          <div class="col-md-2 col-sm-6 date_range pull-right mtn_10">
            <label class="mb-0">Start Date</label>
            <input readonly type="text" bsDatepicker class="form-control" [(ngModel)]="min_date_added"
              (ngModelChange)="dateChange()" [bsConfig]="bsConfig"
              [maxDate]="maxDate" placeholder="Start Date">
          </div>
        </div>
      </div>
      <div class="row total_leads_row padd_lrb_20">
        <div class="col-md-12 padd_r35">
          <div class="row">
            <div class="col-md-3 col-sm-6 search_icon">
              <div class="input-group">
                <input type="text" class="form-control bord_rw_0" placeholder="Search..." [(ngModel)]="search_key"
                  (keyup.enter)="applyFilter()">
                <!-- <div class="input-group-append">
                  <button class="btn btn-secondary search_btn bord_lw0" type="submit" (click)="applyFilter()">
                    <img src="assets/images/png/search.png" alt="search">
                  </button>
                </div> -->
              </div>
            </div>
            <div class="col-md-3 col-sm-6">
              <div class=" form-group">
                <!-- <label>Added By</label> -->
                <ngx-select [(ngModel)]="selectedAdmin" [items]="admin_list" optionValueField="admin_id"
                  optionTextField="admin_name" [autoClearSearch]="true" [allowClear]="true"
                  (selectionChanges)="selectAdmin($event,'added_by')" placeholder="Added By">
                  <ng-template ngx-select-option-selected let-option>
                    {{option.data.admin_name + ' ('+option.data.admin_email+')'}}
                  </ng-template>
                  <ng-template ngx-select-option let-option let-text="text">
                    {{option.data.admin_name}} <br>
                    <small>{{option.data.admin_email}}</small>
                  </ng-template>
                  <ng-template ngx-select-option-not-found>
                    Not found
                  </ng-template>
                </ngx-select>
              </div>
            </div>
            <div class="col-md-3 col-sm-6">
              <div class=" form-group">
                <!-- <label>Added By</label> -->
                <ngx-select [(ngModel)]="assigned_to" [items]="admin_list" optionValueField="admin_id"
                  optionTextField="admin_name" [autoClearSearch]="true" [allowClear]="true"
                  (selectionChanges)="selectAdmin($event,'assigned_to')" placeholder="Assigned To">
                  <ng-template ngx-select-option-selected let-option>
                    {{option.data.admin_name + ' ('+option.data.admin_email+')'}}
                  </ng-template>
                  <ng-template ngx-select-option let-option let-text="text">
                    {{option.data.admin_name}} <br>
                    <small>{{option.data.admin_email}}</small>
                  </ng-template>
                  <ng-template ngx-select-option-not-found>
                    Not found
                  </ng-template>
                </ngx-select>
              </div>
            </div>
            <div class="col-md-3 col-sm-6">
              <div class=" form-group">
                <!-- <label>Added By</label> -->
                <ngx-select [(ngModel)]="assigned_by" [items]="admin_list" optionValueField="admin_id"
                  optionTextField="admin_name" [autoClearSearch]="true" [allowClear]="true"
                  (selectionChanges)="selectAdmin($event,'assigned_by')" placeholder="Assigned By">
                  <ng-template ngx-select-option-selected let-option>
                    {{option.data.admin_name + ' ('+option.data.admin_email+')'}}
                  </ng-template>
                  <ng-template ngx-select-option let-option let-text="text">
                    {{option.data.admin_name}} <br>
                    <small>{{option.data.admin_email}}</small>
                  </ng-template>
                  <ng-template ngx-select-option-not-found>
                    Not found
                  </ng-template>
                </ngx-select>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row total_leads_row padd_lrb_20 bord_b">
        <div class="col-md-8">
          <div class="row">

            <div dropdown class="col-md-3 col-sm-6" placement="bottom right">
              <button dropdownToggle class="filter_lead dropdown-toggle" id="dropdownMenuButton1" data-toggle="dropdown"
                aria-haspopup="true">Filter By: {{filterLabel?filterLabel:''}}
                <img src="assets/images/png/filter.png">
              </button>
              <div *dropdownMenu class="dropdown-menu" id="dropdown-basic" aria-labelledby="dropdownMenuButton1">
                <a class="dropdown-item" (click)="filterBy(0,'All')">All</a>
                <a class="dropdown-item" (click)="filterBy(1,'Unassigned')">Unassigned</a>
                <a class="dropdown-item" (click)="filterBy(2, 'Assigned')">Assigned</a>
                <a class="dropdown-item" (click)="filterBy(3, 'Hot Lead')">Hot Lead</a>
                <a class="dropdown-item" (click)="filterBy(4,'Attempted')">Attempted</a>
                <a class="dropdown-item" (click)="filterBy(5, 'Junk Lead')">Junk Lead</a>
                <a class="dropdown-item" (click)="filterBy(6, 'Lost Lead')">Lost Lead</a>
                <a class="dropdown-item" (click)="filterBy(7, 'Pre-Qualified')">Pre-Qualified</a>
                <a class="dropdown-item" (click)="filterBy(8, 'Not-Qualified')">Not-Qualified</a>
                <a class="dropdown-item" (click)="filterBy(9, 'Closed')">Closed</a>
                <a class="dropdown-item" (click)="filterBy(10, 'Follow Up')">Follow Up</a>
                <a class="dropdown-item" (click)="filterBy(11, 'Meeting Fixed')">Meeting Fixed</a>
                <a class="dropdown-item" (click)="filterBy(12, 'Enrolled')">Enrolled</a>
                <a class="dropdown-item" (click)="filterBy(13, 'Voice Mail')">Voice Mail</a>
                <a class="dropdown-item" (click)="filterBy(14, 'Not Answering')">Not Answering</a>
              </div>
  
            </div>
            <div class="col-md-3 col-sm-6">
              <button dropdownToggle class="sort_lead dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown"
                aria-haspopup="true">Sort By: {{sortValue && sortValue.label?sortValue.label:''}}
                <img src="assets/images/png/sort.png">
              </button>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <a class="dropdown-item" (click)="sortBy(0)">Date Added</a>
                <a class="dropdown-item" (click)="sortBy(1)">First Name</a>
                <a class="dropdown-item" (click)="sortBy(2)">Last Name</a>
                <a class="dropdown-item" (click)="sortBy(3)">Business Name</a>
                <a class="dropdown-item" (click)="sortBy(4)">Business Email</a>
              </div>
            </div>
            <div class="col-md-3 col-sm-6">
              <button class="sort_lead dropdown-toggle" type="button" id="dropdownMenuButton1" data-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false">Filter By Timezone: {{timezoneLabel?timezoneLabel:''}}
                <img src="assets/images/png/filter.png">
              </button>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton1" >
                <div *ngFor="let time of timezoneData; let i = index" >
                  <a class="dropdown-item" (click)="filterByTimezone(i,time.offset)" >{{time.offset}}</a>
                </div>
              </div>
            </div>
            <div class="col-md-3 col-sm-6">
              <button class="duplicate_lead dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false">Duplicate: {{duplicateLabel?duplicateLabel:''}}
                <!-- <img src="assets/images/png/sort.png"> -->
              </button>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <a class="dropdown-item" (click)="duplicateBy(2, 'All')">All</a>
                <a class="dropdown-item" (click)="duplicateBy(0,'No')">No</a>
                <a class="dropdown-item" (click)="duplicateBy(1,'Yes')">Yes</a>
  
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 col-sm-6 padd_r0">
          <div class="row pull-right">
            <div class="col-md-4">
              <button class="btn apply_btn" (click)="applyFilter()">Apply</button>
            </div>
            <div class="col-md-4">
              <button class="btn reset_btn" (click)="resetFilter()">Reset</button>
            </div>
          </div>
        </div>
      </div>
  
      <div class="table-responsive">
        <table class="table table-striped" id="lead-table" datatable>
          <thead>
            <tr>
              <th class="checkbox"
                *ngIf="!_shared.routerAuth.restricted_buttons.assign_multiple_leads || !_shared.routerAuth.restricted_buttons.delete_all"
                style="padding-left:26px;">
                <input type="checkbox" id="checkbox1" [(ngModel)]="checkall" (ngModelChange)="checkUncheckAll()">
                <label for="checkbox1" class="marg_btm0">ID</label>
              </th>
              <th
                *ngIf="_shared.routerAuth.restricted_buttons.assign_multiple_leads && _shared.routerAuth.restricted_buttons.delete_all">
                ID</th>
              <th>Lead Info.</th>
              <th>Lead Status</th>
              <th>Lead Owner</th>
              <th>Assigned To</th>
              <th>Assigned By</th>
              <th>Date Added</th>
              <th *ngIf="!_shared.routerAuth.restricted_buttons.spoke_to_column">Spoke To</th>
              <th *ngIf="!_shared.routerAuth.restricted_buttons.follow_up_column">Follow Up</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let lead of leads; let i=index;">
              <td class="checkbox padd_l_36"
                *ngIf="!_shared.routerAuth.restricted_buttons.assign_multiple_leads || !_shared.routerAuth.restricted_buttons.delete_all">
                <input type="checkbox" name="lead" [id]="lead+i" [value]="lead.lead_id" [(ngModel)]="lead.checked"
                  (change)='updateCheckall($event,lead.lead_id)'>
                <label [id]="i" for="{{lead+i}}" class="marg_b20">{{((resData.pageNumber-1)*resData.limit)+(i+1)}}</label>
              </td>
              <td
                *ngIf="_shared.routerAuth.restricted_buttons.assign_multiple_leads && _shared.routerAuth.restricted_buttons.delete_all">
                {{((resData.pageNumber-1)*resData.limit)+(i+1)}}</td>
              <td>
                <span class="text-muted text-bold">Name: </span>
                <a routerLink="/app/leads/update/{{lead.lead_id}}">
                  {{lead.first_name ?(lead.first_name +' '+ lead.last_name): ''}}
                </a><br>
                <span class="text-muted text-bold">Business: </span><a
                  routerLink="/app/leads/update/{{lead.lead_id}}">{{lead.practice_name || '-'}}</a><br>
                <span class="text-muted text-bold">Email: </span>{{lead.practice_email || 'N.A.'}}<br>
                <span class="text-muted text-bold">Phone:
                </span>{{lead.practice_mobile !=0 ? (lead.practice_mobile_country_code) +'-'+(lead.practice_mobile):'N.A.'}}<br>
  
                <span class="text-muted text-bold">Industry: </span>{{lead.ind_name? lead.ind_name: '-'}}
                <br>
                <span class="text-muted text-bold">Sub Industry: </span>{{lead.speciality_name? lead.speciality_name: '-'}}
                <br>
                <span class="text-muted text-bold">Distance (in miles):
                </span>{{lead.distance != null && lead.distance != undefined ? (lead.distance+'').split('.')[0]+((lead.distance+'').split('.')[1] ? '.'+(lead.distance+'').split('.')[1].substr(0, 2) : '') : 'N/A'}}
                <br>
                <span class="text-muted text-bold">Address:
                </span>{{lead.practice_address? lead.practice_address + ', ':''}}{{lead.city? lead.city + ', ':''}}<br>
                {{lead.state_code?lead.state_code+', ':''}}{{lead.zip?lead.zip:''}}
              </td>
              <td>
                <span class="lead_txt" [ngStyle]="{'color': lead.color_code}">{{lead.status_name}}</span>
              </td>
              <td>{{lead.added_by_name}}</td>
              <td>
                <span *ngIf="lead.assigned_to_name">{{lead.assigned_to_name || '-'}}</span>
                <span
                  *ngIf="!lead.assigned_to_name && !_shared.routerAuth.restricted_buttons.assign_single_lead && lead.lead_status != 1">
                  - </span>
                <span
                  *ngIf="!lead.assigned_to_name && _shared.routerAuth.restricted_buttons.assign_single_lead && lead.lead_status != 1">
                  - </span>
                <span
                  *ngIf="!lead.assigned_to_name && _shared.routerAuth.restricted_buttons.assign_single_lead && lead.lead_status == 1">
                  - </span>
                <button
                  *ngIf="!lead.assigned_to_name && !_shared.routerAuth.restricted_buttons.assign_single_lead && lead.lead_status == 1"
                  class="single_assign_lead" (click)="assignLead(lead)">Assign</button>
              </td>
              <td>{{lead.assigned_by_name || '-'}} </td>
              <td><span *ngIf="lead.via_csv!=1">{{(lead.date_added | date:'MM-dd-yyyy' : timezone) || '-'}} </span>
                <span *ngIf="lead.via_csv ==1">{{(lead.date_added | date:'MM-dd-yyyy' : '+0000') || '-'}} </span></td>
              <td *ngIf="!_shared.routerAuth.restricted_buttons.spoke_to_column">{{lead.spoke_to || '-'}} </td>
              <td *ngIf="!_shared.routerAuth.restricted_buttons.follow_up_column">
                {{(lead.followup_date | date:'MM/dd/yyyy hh:mm:ss aaa': timezone ) || '-'}}</td>
              <td>
                <a class="padd_r5" (click)="dndLead(lead,lead.is_dnd)">
                  <i class="fa" [ngClass]="{'fa-bell-slash': lead.is_dnd == 1 ,'fa-bell': lead.is_dnd == 0}"></i>
                </a>
                <a class="padd_r5" routerLink="/app/leads/update/{{lead.lead_id}}"
                  *ngIf="!_shared.routerAuth.restricted_buttons.edit">
                  <i class="fa fa-edit "></i>
                  <!-- <img src="assets/images/png/edit.png"> -->
                </a>
                <a class="padd_r5" *ngIf="!_shared.routerAuth.restricted_buttons.delete"
                  (click)="deleteLead(lead.lead_id)">
                  <!-- <img src="assets/images/png/delete.png"> -->
                  <i class="fa fa-trash text-danger"></i>
                </a>
                <!-- <a class="padd_r5">
                      <img src="assets/images/png/table_down.png">
                    </a>
                    <a>
                      <img src="assets/images/png/add_note.png">
                    </a> -->
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="row mt-2" *ngIf="leads && leads.length>0">
        <div class="col-md-10">
          <span style="margin-left: 20px;" *ngIf="resData.totalRecords>resData.limit">
            {{(1+(resData.pageNumber - 1) * resData.limit)}} -
            {{(leads.length + (resData.pageNumber - 1) * resData.limit)}} OUT OF
            {{resData.totalRecords || 'all'}}
          </span>
        </div>
        <div class="col-md-2" *ngIf="resData.totalRecords>resData.limit">
          <pagination style="margin-right: 10px;" class="pagination-sm float-md-right" [totalItems]="resData.totalRecords"
            [maxSize]="10" [itemsPerPage]="resData.limit" [boundaryLinks]="true" [rotate]="false"
            (pageChanged)="pageChanged($event)" [(ngModel)]="resData.pageNumber" firstText="&laquo;"
            previousText="&lsaquo;" nextText="&rsaquo;" lastText="&raquo;">
          </pagination>
        </div>
      </div>
    </div>
  </div>
  
  <div class="col-12 text-center" *ngIf="!leads || leads.length==0">
    <img src="./assets/img/SVG/empty/patients.svg">
    <h4 style="color:#8592a5">No Lead Found</h4>
  </div>