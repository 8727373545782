<div class="row mb-2 m-3 align-items-center">
    <div *ngIf="contract" class="col-md-10">
        <h3>Personal Information
            <span class="text-bold" style="font-size: 1.5rem;">(Contract ID:</span>{{contract.contract_code}})
        </h3>
    </div>
    <!-- <div class="col-md-2 text-end">
      <button class=" btn btn-primary" (click)="location.back()" [disabled]="!this.previousUrl || this.previousUrl =='/'">
        Back
      </button>
    </div> -->
</div>

<div class="card card-body m-4 mt-0" *ngIf="contract">
    <!-- <form [formGroup]="" (submit)="updateContractPersonalInfo()" *ngIf="contract"> -->
        <div class="d-flex align-items-center">
            <span class="fs-20 fw-600">Customer Information &nbsp;&nbsp;</span>
            <span class="text-danger fs-14 fw-600">(Note: </span>&nbsp;
            <span class="fs-14 fw-400 m-0">Please check the checkboxes below if you want to update the same
                information in customer profile.)</span>
        </div>
        <div class="row">
            <div class="col-12 mt-2">
                <div class="form-group mb-3">
                    <label class="fs-14 fw-400 mb-1">Is Customer a Minor ?</label>
                    <div>
                        <span>
                            <input type="radio" name="is_minor" id="is_minor_yes" [value]="1" class="secondary-radio" [(ngModel)]="contract.is_minor">
                            <label for="is_minor_yes">Yes</label>
                        </span>
                        <span class="ml-2">
                            <input type="radio" name="is_minor" id="is_minor_no" [value]="0" class="secondary-radio" [(ngModel)]="contract.is_minor">
                            <label for="is_minor_no">No</label>
                        </span>
                    </div>
                </div>
            </div>

            <div class="col-sm-4" *ngIf="contract.is_minor">
                <div class="form-group mb-3">
                    <label class="fs-14 fw-400 mb-1">Guardian Salutation</label>
                    <div class="input-group">
                        <select class="input_field" [(ngModel)]="contract.guardian_title" name="guardian_title">
                            <option *ngFor="let sal of salutation" [value]="sal.title == 'None'?'':sal.title">
                                {{sal.title}}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="col-sm-4" *ngIf="contract.is_minor">
                <div class="form-group mb-3">
                    <label class="fs-14 fw-400 mb-1">Guardian First Name</label>
                    <div class="input-group">
                        <input type="text" maxlength="30" minlength="2" [(ngModel)]="contract.guardian_first_name" name="guardian_first_name" class="input_field" trim="blur">
                    </div>
                    <!-- <p class="text-danger" *ngIf="guardian_first_name.errors?.required">Enter a valid guardian first name</p> -->
                </div>
            </div>
            <div class="col-sm-4" *ngIf="contract.is_minor">
                <div class="form-group mb-3">
                    <label class="fs-14 fw-400 mb-1">Guardian Last Name</label>
                    <div class="input-group">
                        <input type="text" maxlength="30" minlength="2"
                            [(ngModel)]="contract.guardian_last_name" name="guardian_last_name" class="input_field"
                            trim="blur">
                    </div>
                    <!-- <p class="text-danger" *ngIf="guardian_last_name.errors?.required">Enter a valid guardian last name</p> -->
                </div>
            </div>
            <div class="col-sm-4">
                <div class="form-group mb-3">
                    <label class="fs-14 fw-400 mb-1">Customer Salutation</label>
                    <div class="input-group">
                        <select class="input_field" [(ngModel)]="contract.title" name="title">
                            <option *ngFor="let sal of salutation" [value]="sal.title == 'None'?'':sal.title">
                                {{sal.title}}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="form-group mb-3">
                    <label class="fs-14 fw-400 mb-1">Customer First Name*</label>
                    <div class="input-group">
                        <input type="text" required #patient_first_name="ngModel"
                            [(ngModel)]="contract.patient_first_name" name="patient_first_name" maxlength="30"
                            minlength="2" class="input_field" trim="blur">
                    </div>
                    <!-- <p class="text-danger" *ngIf="patient_first_name.errors?.required">Enter a valid first name</p> -->
                </div>
            </div>

            <div class="col-sm-4">
                <div class="form-group mb-3">
                    <label class="fs-14 fw-400 mb-1">Customer Last Name*</label>
                    <div class="input-group">
                        <input type="text" required
                            [(ngModel)]="contract.patient_last_name" name="patient_last_name" class="input_field"
                            maxlength="30" minlength="2" trim="blur">
                    </div>
                    <!-- <p class="text-danger" *ngIf="patient_last_name.errors?.required">Enter a valid last name</p> -->
                </div>
            </div>


            <div class="col-sm-4">
                <div class="form-group mb-3">
                    <label class="fs-14 fw-400 mb-1">Customer Date of Birth</label>
                    <div class="input-group">
                        <input [(ngModel)]="contract.date_of_birth"
                            class="input_field" bsDatepicker [bsConfig]="dateConfig"
                            [maxDate]="maxDate">
                    </div>
                </div>
            </div>

            <div class="col-sm-4">
                <div class="form-group mb-3">
                    <label class="fs-14 fw-400 mb-1">Social Security Number <span *ngIf="contract.patient_ssn">({{'*****'+(contract.patient_ssn |
                            slice:5:9)}})</span></label>
                    <div class="input-group">
                        <input type="text"
                            [(ngModel)]="contract.patient_ssn" name="patient_ssn" class="input_field" [maxlength]="9"
                            [minlength]="9" trim="blur">
                    </div>
                    <!-- <p class="text-danger"
                        *ngIf="!patient_ssn.errors?.pattern && (patient_ssn.errors?.maxlength || patient_ssn.errors?.minlength)">
                        SSN should
                        contain 9 characters</p>
                    <p class="text-danger" *ngIf="patient_ssn.errors?.pattern">Please enter a valid SSN</p> -->
                </div>
            </div>

            <div class="col-sm-4">
                <div class="form-group mb-3">
                    <label class="fs-14 fw-400 mb-1">Gender</label>
                    <div class="radio radio-inline c-radio c-radio-nofont">
                        <span>
                            <input type="radio" name="patient_gender" id="gender_male" class="secondary-radio" [(ngModel)]="contract.patient_gender" [value]="1">
                            <label for="gender_male" class="fs-14">Male</label> 
                        </span>
                        <span class="ml-3">
                            <input type="radio" name="patient_gender" id="gender_female" class="secondary-radio" [(ngModel)]="contract.patient_gender" [value]="2">
                            <label for="gender_female" class="fs-14">Female</label> 
                        </span>
                        <span class="ml-3">
                            <input type="radio" name="patient_gender" id="gender_other" class="secondary-radio" [(ngModel)]="contract.patient_gender" [value]="3">
                            <label for="gender_other" class="fs-14">Others</label> 
                        </span>
                    </div>
                    <!-- <p class="text-danger" *ngIf="patient_gender.errors?.required">Select a valid gender</p> -->
                </div>
            </div>

            <div class="col-sm-4">
                <div class="form-group mb-3">
                    <label class="fs-14 fw-400 mb-1">Phone Number</label>
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <select name="patient_mobile_country_code" class="h-100 p-1"
                                [(ngModel)]="selectedPatientMobileCode"
                                (change)="changeMobileCode('patient_mobile', selectedEmployerMobileCode)" disabled>
                                <option *ngFor="let option of mobile_code_arr; let i =index" [value]="option">
                                    <span>{{option}}</span>
                                </option>
                            </select>
                        </div>
                        <input type="text" class="form-control"
                            [(ngModel)]="contract.patient_mobile" name="patient_mobile">
                            <!-- [pattern]="mainService.getPhoneRegex()" [textMask]="{mask: mainService.getPhoneMask()}" -->
                    </div>
                    <!-- <p class="text-danger" *ngIf="patient_mobile.errors?.required || patient_mobile.errors?.pattern">Enter a valid
              mobile number</p> -->
                </div>
            </div>

            <div class="col-sm-4">
                <div class="form-group mb-3">
                    <label class="fs-14 fw-400 mb-1">Customer Email Address*</label>
                    <div class="input-group">
                        <input type="email" class="input_field" required
                            [ngModel]="contract.patient_email" name="patient_email" readonly>
                    </div>
                    <!-- <p class="text-danger" *ngIf="patient_email.errors?.required">Enter a valid email</p> -->
                </div>
            </div>

            <div class="col-sm-4">
                <div class="form-group mb-3">
                    <label class="fs-14 fw-400 mb-1">DL Number</label>
                    <div class="input-group">
                        <input type="text" class="input_field" [(ngModel)]="contract.dl_number"
                            name="dl_number" trim="blur" [maxlength]="20">
                    </div>
                    <!-- <p class="text-danger" *ngIf="dl_number.errors?.required">Enter a valid DL Number</p> -->
                </div>
            </div>

            <div class="col-sm-6">
                <div class="form-group mb-3">
                    <label class="fs-14 fw-400 mb-1">Address</label>
                    <div class="input-group w-100">
                        <app-google-zip class="w-100" (setAddress)="getZipselected($event, zipTypes.zip)" id="zipPostalCode" name="zipPostalCode" [address]="contract.patient_address">
                        </app-google-zip>
                        <!-- <input type="text" class="input_field"
                            [(ngModel)]="contract.patient_address" name="patient_address" trim="blur"> -->
                    </div>
                    <!-- <p class="text-danger" *ngIf="patient_address.errors?.required">Enter a valid address</p> -->
                </div>
            </div>

            <div class="col-sm-6">
                <div class="form-group mb-3">
                    <label class="fs-14 fw-400 mb-1">Zip / Postal Code</label>
                    <input type="text" class="input_field" [ngModel]="contract.zip"
                    name="zip" readonly trim="blur">
                    
                </div>
            </div>

            <div class="col-sm-6">
                <div class="form-group mb-3">
                    <label class="fs-14 fw-400 mb-1">State / Province</label>
                    <div class="input-group">
                        <input type="text" class="input_field" [ngModel]="contract.patient_state_code"
                            name="patient_state_code" readonly trim="blur">
                    </div>
                </div>
            </div>

            <div class="col-sm-6">
                <div class="form-group mb-3">
                    <label class="fs-14 fw-400 mb-1">City</label>
                    <div class="input-group">
                        <input type="text" class="input_field" [ngModel]="contract.patient_city"
                            readonly trim="blur">
                    </div>
                </div>
            </div>

            <div class="col-sm-6">
                <div class="form-group mb-3">
                    <label class="fs-14 fw-400 mb-1">Street Address</label>
                    <div class="input-group">
                        <input type="text" class="input_field" [ngModel]="contract.patient_street" name="patient_street" readonly trim="blur">
                    </div>
                </div>
            </div>
        </div>

        <h4 class="mt-3 fs-16 fw-600">Employment Information</h4>
        <div class="d-flex">
            <label class="fs-14 fw-400 mb-1 me-3">Are you Employed? </label>
            <span class="ml-2">
                <input type="radio" value="1" [checked]="employer_yes_id == 1" [(ngModel)]="employer_yes_id" id="employed" class="secondary-radio" />
                <label for="employed" class="fs-14">  Yes</label>
            </span>
            <span class="ml-2">
                <input type="radio" value="0" [checked]="employer_yes_id == 0" [(ngModel)]="employer_yes_id" id="un_employed" class="secondary-radio" />
                <label for="un_employed" class="fs-14">No</label> 
            </span>
            <span class="ml-2">
                <input type="radio" value="2" [checked]="employer_yes_id == 2" [(ngModel)]="employer_yes_id" id="self_employed" class="secondary-radio" />
                <label for="self_employed" class="fs-14"> Self Employed</label>
            </span>
        </div>

        <div class="row" *ngIf="employer_yes_id == 1">
            <div class="col-sm-4">
                <div class="form-group mb-3">
                    <label class="fs-14 fw-400 mb-1">Employer Name*</label>
                    <div class="input-group">
                        <input type="text" [(ngModel)]="contract.employer_name" name="employer_name"
                            class="input_field" trim="blur">
                    </div>
                </div>
            </div>

            <div class="col-sm-4">
                <div class="form-group mb-3">
                    <label class="fs-14 fw-400 mb-1">Phone Number*
                    </label>
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <!-- mobile country code static -->
                            <span> +1</span>
                            <!-- <select name="employer_mobile_country_code" class="input-group-text p-1"
                                [(ngModel)]="selectedEmployerMobileCode"
                                (change)="changeMobileCode('employer_mobile', selectedEmployerMobileCode)" disabled>
                                <option *ngFor="let option of mobile_code_arr; let i =index" [value]="option">
                                    <span>{{option}}</span>
                                </option>
                            </select> -->
                        </div>
                        <input type="text" #employer_mobile="ngModel" [(ngModel)]="contract.employer_mobile"
                            name="employer_mobile" class="form-control">
                            <!-- [textMask]="{mask: mainService.getPhoneMask()}"> -->
                    </div>
                    <!-- <p class="text-danger" *ngIf="employer_mobile.errors?.pattern">Enter a
                        valid employer mobile number</p> -->
                </div>
            </div>

            <div class="col-sm-4">
                <div class="form-group mb-3">
                    <label class="fs-14 fw-400 mb-1">Employer Address*</label>
                    <div class="input-group">
                        <app-google-zip (setAddress)="getZipselected($event, zipTypes.employer_zip)" id="zipPostalCode" name="zipPostalCode" [address]="contract.employer_address">
                        </app-google-zip>
                    </div>
                    <!-- <p class="text-danger" *ngIf="employer_address.errors?.required">Enter a valid employer address</p> -->
                </div>
            </div>

            <div class="col-sm-6">
                <div class="form-group mb-3">
                    <label class="fs-14 fw-400 mb-1">Employer Zip / Postal Code*</label>
                    <input type="text" class="input_field" [ngModel]="contract.employer_zip"
                            name="employer_zip" readonly trim="blur">
                    
                </div>
            </div>

            <div class="col-sm-6">
                <div class="form-group mb-3">
                    <label class="fs-14 fw-400 mb-1">State / Province*</label>
                    <div class="input-group">
                        <input type="text" class="input_field" [ngModel]="contract.employer_state"
                            name="employer_state" readonly trim="blur">
                    </div>
                </div>
            </div>

            <div class="col-sm-6">
                <div class="form-group mb-3">
                    <label class="fs-14 fw-400 mb-1">City*</label>
                    <div class="input-group">
                        <input type="text" class="input_field" [ngModel]="contract.employer_city" name="employer_city"
                            readonly trim="blur">
                    </div>
                </div>
            </div>

            <div class="col-sm-6">
                <div class="form-group mb-3">
                    <label class="fs-14 fw-400 mb-1">Street Address</label>
                    <div class="input-group">
                        <input type="text" class="input_field" [ngModel]="contract.employer_street" name="employer_street" readonly trim="blur">
                    </div>
                </div>
            </div>

            <div class="col-sm-6">
                <div class="form-group mb-3">
                    <label class="fs-14 fw-400 mb-1">Annual Income*</label>
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <div class="input-group-text">$</div>
                        </div>
                        <input type="text" class="form-control" [(ngModel)]="contract.annual_income"
                            name="annual_income" trim="blur">
                    </div>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="employer_yes_id == 0">
            <div class="col-sm-6">
                <div class="form-group mb-3">
                    <label class="fs-14 fw-400 mb-1">How are you planning to pay for the services?</label>
                    <select class="input_field" [(ngModel)]="contract.payback_methods" (change)="service()"
                        name="contract.payback_methods">
                        <option *ngFor="let c of payment_source" [ngValue]="c.name">{{c.name}}</option>
                    </select>
                </div>
            </div>
            <div class="col-sm-6" *ngIf="other_services">
                <div class="form-group mb-3">
                    <label class="fs-14 fw-400 mb-1">Other source*</label>
                    <textarea class="input_field other-service-textarea" maxlength="300"
                        [(ngModel)]="other_service_value" (change)="otherService()"
                        rows="4"></textarea>
                </div>
            </div>

        </div>

        <!-- Self Employmetn Details -->
        <div class="row" *ngIf="employer_yes_id == 2">
            <div class="col-sm-6">
                <div class="form-group mb-3">
                    <label class="fs-14 fw-400 mb-1">Profession*</label>
                    <div class="input-group">
                        <input type="text" [(ngModel)]="contract.profession" name="profession" class="input_field"
                            trim="blur">
                    </div>
                </div>
            </div>

            <div class="col-sm-6">
                <div class="form-group mb-3 mb-1">
                    <label class="fs-14 fw-400 mb-1">Office Address*</label>
                    <div class="input-group">
                        <app-google-zip (setAddress)="getZipselected($event, zipTypes.office_zip)" id="zipPostalCode" name="zipPostalCode" [address]="contract.office_address">
                        </app-google-zip>
                        <!-- <input type="text" [(ngModel)]="contract.office_address" name="office_address"
                            class="input_field" trim="blur" [disabled]="office_address_check" /> -->
                    </div>
                    <div class="office_address pt-1">
                        <input type="checkbox" name="office_address_fill" id="office_address_fill" [(ngModel)]="office_address_check" (change)="changeOfficeAddress()" class="secondary-checkbox" />
                        <label for="office_address_fill" class="fs-14 fw-600">Send notification to customer</label>
                    </div>
                </div>
            </div>

            <div class="col-sm-6">
                <div class="form-group mb-3">
                    <label class="fs-14 fw-400 mb-1">Office Zip / Postal Code</label>
                    <div class="input-group" *ngIf="office_address_check">
                        <input type="text" [(ngModel)]="contract.office_zip" name="office_zip" class="input_field"
                            trim="blur" readonly [disabled]="office_address_check">
                    </div>
                    
                </div>
            </div>

            <div class="col-sm-6">
                <div class="form-group mb-3">
                    <label class="fs-14 fw-400 mb-1">Office State / Province</label>
                    <div class="input-group">
                        <input type="text" class="input_field" [ngModel]="contract.office_state" name="office_state" readonly trim="blur">
                    </div>
                </div>
            </div>

            <div class="col-sm-6">
                <div class="form-group mb-3">
                    <label class="fs-14 fw-400 mb-1">Office City</label>
                    <div class="input-group">
                        <input type="text" class="input_field" [ngModel]="contract.office_city" name="office_city" readonly trim="blur">
                    </div>
                </div>
            </div>

            <div class="col-sm-6">
                <div class="form-group mb-3">
                    <label class="fs-14 fw-400 mb-1">Street Address</label>
                    <div class="input-group">
                        <input type="text" class="input_field" [ngModel]="contract.office_street" name="office_city" readonly trim="blur">
                    </div>
                </div>
            </div>

            <div class="col-sm-6">
                <div class="form-group mb-3">
                    <label class="fs-14 fw-400 mb-1">Annual Income*</label>
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <div class="input-group-text">$</div>
                        </div>
                        <input type="text" class="form-control" [(ngModel)]="contract.annual_income"
                            name="annual_income" trim="blur">
                    </div>
                </div>
            </div>

        </div>

        <div class="form-group mb-3">
            <label class="fs-14 fw-400 mb-1">Note</label>
            <textarea trim="blur"  class="input_field" required rows="3" [(ngModel)]="note" name="note_follow_up"></textarea>
          </div>

        <div class="mb-2 mt-5">
            <input class="secondary-checkbox" type="checkbox" name="checkbox" [(ngModel)]="customerProfileUpdate" id="flexCheckChecked">
            <label for="flexCheckChecked" class="fs-14 fw-500">
                Update the same information in Customer Profile.
            </label>
        </div>
        <div class="mb-2">
            <input class="secondary-checkbox" type="checkbox" name="checkbox1" [(ngModel)]="customerContractsUpdate"  id="flexCheckChecked1">
            <label for="flexCheckChecked1" class="fs-14 fw-500">
                Update the same information in the other Contracts of this customer.
            </label>
        </div>

        <div class="">
            <button type="submit" class=" btn btn-primary m-1">Save</button>
            <button type="button" class=" btn btn-secondary m-1" (click)="location.back()">Cancel</button>
        </div>
    <!-- </form> -->
</div>