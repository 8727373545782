<div class="position-sticky sticky-top m-4" style="top:60px!important"  *ngIf="!onlyBusinessNotes">
  <div class="card card-body bg-warning border p-2 px-3">
    <div class="d-flex justify-content-between align-items-center">
      <h4 class="fs-18 7w-600 theme-text"> {{type && type==4 ? 'Special Note for Contract': 'Special Note'}}</h4>
        <button class="bg-white border hw-40 rounded-circle"  *ngIf="(!note || note.length == 0) && !addNewNote" (click)="addUpdateNote()">
          <i class="fa fa-plus"></i>
        </button>
      <div class="col-6 text-right" *ngIf="note && !addNewNote ">
        <button class="btn btn-xs btn-light mr-2" (click)="addUpdateNote()">
          <i class="fa fa-pencil"></i>
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-12" *ngIf="addNewNote">
        <textarea name="" id="" class="form-control" [(ngModel)]="newNote" rows="4"></textarea>
        <div class="text-end mt-2">
          <button class="btn btn-xs btn-light rounded me-2" (click)="saveNote()">Save</button>
          <button class="btn btn-xs btn-dark rounded" (click)="cancelAddUpdateNote()">Cancel</button>
        </div>
      </div>

      <div class="col-12" *ngIf="noteData && note && !addNewNote">
        <p class="small m-0">
            {{!noteData.is_updated ? 'Created by' : 'Updated by'}}
          {{noteData.admin_name ? noteData.admin_name: ''}}
          {{noteData.date_added ? 'on ' + (noteData.date_added | date:'MM-dd-yyyy hh.mm a' : timezone) : '' }}
        </p>
        <p>{{ note }}</p>
      </div>
    </div>
  </div>
</div>


<div class="position-sticky sticky-top" style="top:60px!important" *ngIf="onlyBusinessNotes && noteData && note">
  <div class="card card-body border p-2" style="background-color:darkcyan;color:#fff">
    <div class="row align-items-center">
      <h4 class="col-6 m-0 mb-2">Special Note for Business</h4>
    </div>
    <div class="row">
      <div class="col-12" *ngIf="noteData && note && !addNewNote">
        <p class="small m-0">
            {{!noteData.is_updated ? 'Created by' : 'Updated by'}}
          {{noteData.admin_name ? noteData.admin_name: ''}}
          {{noteData.date_added ? 'on ' + (noteData.date_added | date:'MM-dd-yyyy hh.mm a' : timezone) : '' }}
        </p>
        <p>{{ note }}</p>
      </div>
    </div>
  </div>
</div>