import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { localStorageService } from './localstorage.service';

@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {
  // private onlineChanges$ = fromEvent(window, 'online').pipe(mapTo(true));
  cache_data: any;

  constructor(private _local: localStorageService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler):
    Observable<HttpEvent<any>> {
    const currentLanguage = this.gt_get_cookie('googtrans').split('/').pop();
    const headers = new HttpHeaders({
      device_type: environment.device_type,
      app_type: environment.app_type,
      app_version: environment.app_version,
      device_token: environment.device_token,
      device_id: this._local.customGetItem('user') || '1',
      environment: environment.env,
      Authorization: this._local.customGetItem('token') || '',
      timezone: this._local.customGetItem('timezone_val') ? this._local.customGetItem('timezone_val') : '+530',
      country_id: this._local.customGetItem('country_id') || 1,
      is_dummy: this._local.customGetItem('is_dummy') ? this._local.customGetItem('is_dummy') : 0,
    })
    req = req.clone({ headers });
    if (req.method.toLowerCase() === 'post') {
      req = req.clone({ headers: req.headers.set('x-client-url', window.location.href) });
    }
    if (this._local.customGetItem('userProfileData') && JSON.parse(this._local.customGetItem('userProfileData'))) {
      const userProfile = JSON.parse(this._local.customGetItem('userProfileData'));
      const clientVersion = this._local.customGetItem('client_version');
      if (userProfile && userProfile.length) {
        req = req.clone({
          headers: req.headers.append('x-doctor-id', userProfile[0]['doctor_id'] ? userProfile[0]['doctor_id'] + '' : '')
        });
      }
      if (clientVersion) {
        req = req.clone({
          headers: req.headers.append('x-client-version', clientVersion)
        });
      }
    }

    req = req.clone({
      headers: req.headers.append('x-lan-code', currentLanguage || 'en')
    });


    return next.handle(req)
      .pipe(
        tap((event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            const data = event.body;
          }
        }),
        catchError((error: any) => {
          return throwError(() => error)
        })
      );
  }

  private gt_get_cookie(cname: string) {
    const name = cname + '=';
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      // tslint:disable:triple-equals
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  }
}

