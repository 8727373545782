import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ApiService } from '../api.service';
import { SharedService } from '../shared.service';
import { BsModalRef } from 'ngx-bootstrap/modal';

declare let Stripe: any;
@Component({
  selector: 'app-generate-stripe-element',
  templateUrl: './generate-stripe-element.component.html',
  styleUrl: './generate-stripe-element.component.scss'
})

export class GenerateStripeElementComponent implements OnInit {

  // this output will return reponse after card added for patient/doctor
  @Output() returnStripeData: EventEmitter<any> = new EventEmitter();
  // this element used to create stripe element from stripe key.
  public stripeElement: any;

  // this element used to create stripe address element from stripeelement.
  public stripeAddressElement: any;

  // this element used to create stripe payment element from stripeelement.
  public stripePaymentElement: any;

  // this element used to create payment request intent from stripe key for wallets (Gpay, Apple Pay).
  public paymentRequestIntent: any;

  // this element used to create stripe payment request element from stripe key with paymentrequestintent for wallets (Gpay, Apple Pay).
  public paymentRequestLinkElement: any;

  public stripeData!: stripe_required_data;
  
  public bankType = false;
  
  constructor(private _api: ApiService, public _shared: SharedService, private _bsModalRef: BsModalRef) {

  }

  ngOnInit() {
    // create client secret
    if (this.stripeData.payment_source_type == this._shared.paymentSourceType.CARD) {
      this.bankType = false;
    }
    this.stripeElement = Stripe(this._shared.getStripeKey());
    this.getClientSecret();
  }

  getClientSecret() {
    const params = {
      patient_id: this.stripeData.patient_profile.patient_id,
      payment_source_type: this.stripeData.payment_source_type,
      payment_matrix_type : this.stripeData.payment_matrix_type
    }
    this._api.api(params, '/payment/create_payment_intent').subscribe(response => {
      if (response.is_error == 0 && response.client_secret) {
        this.createStripeElmentsDynamic(response.client_secret, response.amount, this.stripeData.patient_profile)
      }
    })
  }

  createStripeElmentsDynamic(clientSecret: string, walletAmount?: number, patientProfile?: any) {
    if (walletAmount) {
      walletAmount = Number(walletAmount) / 100;
    }
    const appearance = {
      theme: 'stripe',
      labels: 'floating'
    };
    const paymentElementOptions = {
      layout: 'tabs',
    };
    // tslint:disable-next-line:max-line-length
    this.stripeElement = this.stripeElement.elements({ appearance, clientSecret });
    this.stripeAddressElement = this.stripeElement.create('address', {
      mode: 'billing',
      defaultValues: {
        name: patientProfile && patientProfile['patient_name'] || '',
        email: patientProfile && patientProfile['patient_email'],
        address: {
          line1: patientProfile && patientProfile['patient_address'] || '',
          line2: '',
          city: patientProfile && patientProfile['patient_city'] || '',
          state: patientProfile && patientProfile['patient_state'] || '',
          postal_code: patientProfile && patientProfile['patient_zip'] || null,
          country: patientProfile && patientProfile['country_code'] || 'US',
        },
      },
    });
    this.stripePaymentElement = this.stripeElement.create('payment', paymentElementOptions);

    if (walletAmount) {
      // for google and apple pay implementation

      this.paymentRequestIntent = this.stripeElement.paymentRequest({
        country: this.stripeData.currency_data.country_code,
        currency: this.stripeData.currency_data.currency_code,
        total: {
          label: 'Total',
          amount: parseFloat(((walletAmount || 1) * 100).toFixed(2)),
        },
        requestPayerName: true,
        requestPayerEmail: true,
        disableWallets: ['googlePay', 'link']
      });
      this.paymentRequestLinkElement = this.stripeElement.elements().create('paymentRequestButton', {
        paymentRequest: this.paymentRequestIntent,
        style: {
          paymentRequestButton: {
            type: 'default',
            theme: 'dark',
            height: '45px'
          }
        },
      });
      this.paymentRequestIntent.canMakePayment().then((resp: any) => {
        if (resp && (resp.applePay || resp.link)) {
          this.paymentRequestLinkElement.mount('#' + 'payment_request_button');
        } else {
          const button = document.getElementById('payment_request_button');
          if (button) {
            button.style.display = 'none';
          }
        }
      });
    }

    // mount elements to render on html
    this.stripePaymentElement.mount('#payment-element');
    if (document.getElementById('address-element')) {
      this.stripeAddressElement.mount('#address-element');
    }
  }

  confirmPaymentSetUp() {
    // tslint:disable:quotemark
    const errorMessages = [
      "Your card's expiration date is incomplete.",
      'Your card number is incomplete.',
      "Your card's security code is incomplete.",
      "Your postal code is incomplete.",
      "This field is incomplete.",
      "Your ZIP is invalid."
    ];
    this.stripeElement.confirmPaymentSetUp({
      elements: this.stripeElement,
      confirmParams: {
        // Make sure to change this to your payment completion page
        // return_url: environment.request_panel_link + 'payment-details/' + this.contractToken
      },
      redirect: 'if_required'
    }).then((setUpResp: any) => {
      if (setUpResp.setupIntent && setUpResp.setupIntent.payment_method) {
        const obj = {
          stripe_token: null,
          setup_id: setUpResp.setupIntent.id,
          payment_source_type: 2,
          contract_id: this.stripeData.contract_id || ''
        }
        // this.addPaymentSource(obj);
      } else {
        if (setUpResp.error && setUpResp.error.message) {
          this._shared.customPopups(setUpResp.error.message, 1);
        }
        if (setUpResp.error && !errorMessages.includes(setUpResp.error.message)) {
          // this.showElementTrigger.emit(false);
          // reinitialise here
        }
      }
    })

  }

  addPaymentSource(data?: any) {
    console.log(data);
  }

  cancel() {
    this.returnStripeData.emit({success: false});
    this.hide();
  }

  hide() {
    this._bsModalRef.hide();
  }
}

interface stripe_required_data {
  patient_profile?: any,
  payment_source_type?: number,
  payment_matrix_type?: string,
  currency_data?: any,
  contract_id?: number
}

