import { Component, OnInit, Input } from '@angular/core';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-contract-tabs',
  templateUrl: './contract-tabs.component.html',
  styleUrls: ['./contract-tabs.component.scss']
})
export class ContractTabsComponent implements OnInit {
  contractsType:number = 0;

  @Input() counts: any;

  constructor(
    public router: ActivatedRoute

  ) { }

  ngOnInit() {  console.log(this.counts); 
    this.router.url.subscribe((url:any)=>{
      this.router.queryParams.subscribe((data:any)=>{
        if(url && url[0] && url[0].path=="list"){
          if(data && data.type){
            this.contractsType = data.type; 
          }else{
            this.contractsType = 0;
          }
        }
      })
    })
  }
}