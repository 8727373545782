import { Component, EventEmitter, Output } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { SharedService } from '../shared.service';
import { CommonModule } from '@angular/common';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';
@Component({
  selector: 'app-confirmation-modal',
  standalone: true,
  imports: [ReactiveFormsModule, CommonModule, FormsModule, NgxMaskDirective],
  templateUrl: './confirmation-modal.component.html',
  styleUrl: './confirmation-modal.component.scss'
})
export class ConfirmationModalComponent {

  public mask: string = '000-000-0000';

  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  extra_data: modal_data = {
    label: '',
    hideNotes: false,
    modal_heading: '',
    data: [],
    confirmationType: true
  };
  notesForm!: UntypedFormGroup;
  resendLinkForm!: UntypedFormGroup
  constructor(public bsModalRef: BsModalRef, private _fb: UntypedFormBuilder, public _shared: SharedService) {
    debugger;
    this.notesForm = this._fb.group({
      notes: ''
    });
  }

  ngOnInit(): void {
    if (!this.extra_data.confirmationType) {
      this.resendLinkForm = this._fb.group({
        user_phone1: [(this.extra_data.data && this.extra_data.data['patient_mobile'] ? this.extra_data.data['patient_mobile'] : ''), [Validators.required]],
        user_email1: [(this.extra_data.data && this.extra_data.data['patient_email'] ? this.extra_data.data['patient_email'] : ''), [Validators.email, Validators.required]]
      })
    }
  }


  hide() {
    this.passEntry.emit({ success: false });
    this.bsModalRef.hide()
  }

  onSubmit() {
    if (this.resendLinkForm.invalid) {
      this.resendLinkForm.markAsDirty();
      this.resendLinkForm.markAllAsTouched();
      return;
    }
    // this.passEntry.emit({validForm: true, formValue: this.resendLinkForm.value,contract :this.extra_data.contract});
    this.hide();
  }


  confirm() {
    if (!this.extra_data.hideNotes && (!this.notesForm.value.notes || (this.notesForm.value.notes && !this.notesForm.value.notes.trim()))) {
      this._shared.customPopups('Please enter valid notes', 1);
      return;
    }
    this.passEntry.emit({ success: true, notes: this.notesForm.value.notes });
    this.hide();
  }
}

interface modal_data {
  label: string,
  hideNotes?: boolean,
  modal_heading?: string,
  confirmationType?: boolean,
  data?: any
}
