import { CommonModule, DatePipe, Location } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Route } from '@angular/router';
import { BsDatepickerConfig, BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ApiService } from 'src/app/components/shared/api.service';
import { localStorageService } from 'src/app/components/shared/localstorage.service';
import { SharedService } from 'src/app/components/shared/shared.service';

@Component({
  selector: 'app-edit-personal-info',
  templateUrl: './edit-personal-info.component.html',
  styleUrl: './edit-personal-info.component.scss'
})
export class EditPersonalInfoComponent {
  salutation: any = [];
  id: any;
  contract: any;
  dateConfig: Partial<BsDatepickerConfig>;
  zipcode_list: any = { employer: [], patient: [], office:[] };
  contract_data: any;
  note: string = '';
  // previousUrl: any;
  employer_yes_id: any;
  other_services = false;
  other_service_value = '';
  payment_source = [
    { id: 1, name: "Family" },
    { id: 2, name: "Guardian" },
    { id: 3, name: "Siblings" },
    { id: 4, name: "Spouse" },
    { id: 5, name: "Acquaintance" },
    { id: 6, name: "Social Security Benefits" },
    { id: 7, name: "Crowdfunding/Fund Raising" },
    { id: 8, name: "Others" }
 ];
  office_address_check:boolean = false;
  admin_name: any;
  customerProfileUpdate: boolean = false;
  customerContractsUpdate: boolean = false;
  mobile_code_arr: any;
  selectedEmployerMobileCode: any;
  selectedPatientMobileCode: any;
  maxDate = new Date();
  zipTypes = {
    zip: 'zip',
    office_zip: 'office_zip',
    employer_zip: 'employer_zip'
  }
  constructor(private route: ActivatedRoute, private _local: localStorageService, private _api: ApiService, private shared: SharedService, private datePipe: DatePipe, public location: Location, public _shared: SharedService) {
    this.dateConfig = Object.assign({}, {
      containerClass: 'theme-green',
      dateInputFormat: 'MM/DD/YYYY',
      placement:'bottom'
    });
    this.salutation = this._shared.getSalutations();
  }

  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id');
    this.getContractInfo(this.id);
    this.admin_name = this._local.customGetItem('admin_name');
    // this.previousUrl = this.mainService.getPreviousUrl();
    this.mobile_code_arr = this._shared.getMobileCodeArr();
    this.selectedPatientMobileCode = this.mobile_code_arr[0];
    this.selectedEmployerMobileCode = this.mobile_code_arr[0];
  }

  getContractInfo(id: any) {
    let data = {
      contract_id: id
    };
    this._api.api(data, 'pf_contract_info_v2')
      .subscribe((data: any) => {
        if (data.is_error == 1) this.shared.customPopups(data.flag, data.is_error);
        this.contract = data.contract_info[0];
        // this.contract.date_of_birth = this.contract.date_of_birth ? this.mainService.getUTCDate(this.contract.date_of_birth) : '';
        this.contract.date_of_birth = this.contract.date_of_birth ? this.datePipe.transform(this.contract.date_of_birth, 'MM/dd/yyyy', '+0000') : '';
        this.contract.next_payment_date = this.contract.next_payment_date ? this.datePipe.transform(this.contract.next_payment_date, 'yyyy-MM-dd', '+0000') : '';
        if (this.contract.pf_status > 3) {
          this.shared.customPopups('The contract has already been completed', 1);
          this.location.back();
          return false;
        }
        // this.contract.next_payment_date =this.mainService.getUTCDate(this.contract.next_payment_date)
        // var patient_mobile = this.contract.patient_mobile;
        this.selectedPatientMobileCode = this.contract.patient_mobile_country_code == null ? this.selectedPatientMobileCode :this.contract.patient_mobile_country_code;
        this.selectedEmployerMobileCode = this.contract.employer_mobile_country_code == null ?  this.selectedEmployerMobileCode :this.contract.employer_mobile_country_code;
        // if (patient_mobile) {
        //   this.contract.patient_mobile = patient_mobile.split("+1-").length > 1 ? patient_mobile.split("+1-")[1] : patient_mobile.split("+1-")[0];
        // }
        // var employer_mobile = this.contract.employer_mobile;
        // if (employer_mobile) {
        //   this.contract.employer_mobile = employer_mobile.split("+1-")[1];
        // }
        if (this.contract.employer_name || this.contract.employer_mobile || this.contract.employer_address || this.contract.employer_city_id || this.contract.annual_income) {
          this.employer_yes_id = 1;
        }
        if (this.contract.payback_methods) {
          this.employer_yes_id = 0;
          if ((this.contract.payback_methods !== 'Family') && (this.contract.payback_methods !== 'Guardian') && (this.contract.payback_methods !== 'Siblings') && (this.contract.payback_methods !== 'Spouse') && (this.contract.payback_methods !== 'Acquaintance') && (this.contract.payback_methods !== 'Social Security Benefits') && (this.contract.payback_methods !== 'Crowdfunding/Fund Raising')) {
            this.other_services = true;
            this.other_service_value = this.contract.payback_methods;
            this.contract.payback_methods = 'Others';
          }
        }
        if (this.contract.employed == 2) {
          this.employer_yes_id = 2
        }
        if (this.contract.patient_address) {
          var patient_address = this.contract.patient_address;
          patient_address = patient_address.split(', ');
          this.contract.patient_address = patient_address[0];
          this.contract.city = patient_address[1];
          this.contract.state = patient_address[2];
          this.contract.zip = patient_address[3];
        }
        return;
        // this.setInitialZips();
      }, (error: any) => {
        console.log(error);
      })
  }

  updateContractPersonalInfo() {
    if (this.employer_yes_id == 1 && this.employer_yes_id != null) {
      this.contract.payback_methods = null;
      this.contract.office_address = ''
      this.contract.office_city_id = ''
      this.contract.profession = ''
    }
    if (this.employer_yes_id == 2 && this.employer_yes_id != null) {
      this.contract.employer_name = '';
      this.contract.employer_mobile = '';
      this.contract.employer_address = '';
      this.contract.employer_city_id = '';
      this.contract.payback_methods = null;
    }
    if (this.employer_yes_id == 0 && this.employer_yes_id != null) {
      this.contract.employer_name = '';
      this.contract.employer_mobile = '';
      this.contract.employer_address = '';
      this.contract.employer_city_id = '';
      this.contract.office_address = ''
      this.contract.office_city_id = ''
      this.contract.profession = ''
    }
    this.contract_data = { ...this.contract };
    this.contract.office_address = this.contract.office_address,
    this.contract.office_city = this.contract.office_city || "",
    this.contract.office_state = this.contract.office_state || "",
    this.contract.profession = this.contract.profession || "",
    this.contract_data.access_token = this._local.customGetItem('adminToken');
    this.contract_data.next_payment_date = this.datePipe.transform(this.contract.next_payment_date, 'MM/dd/yyyy');
    this.contract_data.guarantee_type = (this.contract.guarantee_percent == 10) && 2 || (this.contract.guarantee_percent == 5) && 1;
    this.contract_data.patient_email = this.contract.patient_email.replace(/\s/g, '').toLowerCase();
    this.contract_data.patient_mobile_country_code = this.selectedPatientMobileCode || '';
    this.contract_data.patient_mobile = this.contract.patient_mobile.replace(/[^0-9]/g, "") || null;
    this.contract_data.date_of_birth = this.datePipe.transform(this.contract.date_of_birth, 'yyyy-MM-dd') || null;
    this.contract_data.patient_city = this.contract.patient_city || 0;
    this.contract_data.employer_name = this.contract.employer_name || '';
    this.contract_data.employer_mobile_country_code = this.selectedEmployerMobileCode || '';
    this.contract_data.employer_mobile = this.contract.employer_mobile && this.contract.employer_mobile.length > 5 ? this.contract.employer_mobile.replace(/[^0-9]/g, "") : '';
    this.contract_data.employer_address = this.contract.employer_address || '';
    this.contract_data.employer_zip = this.contract.employer_zip || '';
    this.contract_data.employer_city = this.contract.employer_city || 0;
    this.contract_data.annual_income = this.contract.annual_income ? this.contract.annual_income.toString().replace(/[^0-9]/g, "") : '';
    this.contract_data.payback_methods = this.contract.payback_methods == 'Others' ? this.other_service_value : this.contract.payback_methods;
    // this.contract_data.recurring_extra_amount = this.mainService.recurring_extra_amount;
    this.contract_data['patient_ssn'] = this.contract.patient_ssn;
    if (this.contract.patient_first_name.length <= 2) {
      this.shared.customPopups(`First Name should be greater than 2 words.`, 1);
      return
    }
    if (this.contract.patient_last_name.length <= 2) {
      this.shared.customPopups(`Last Name should be greater than 2 words.`, 1);
      return
    }
    this.addNoteEditContract();
  }

  // get zip
  // getZip(zip_code, type) {
  //   if (zip_code == '') {
  //     this.zipcode_list[type] = [];
  //     return false;
  //   }
  //   if (zip_code.length > 3) {
  //     this.mainService.getZip(zip_code, 1).then((zip_list) => {
  //       this.zipcode_list[type] = zip_list;
  //     });
  //   }
  // }

  // setCityNState(e, type) {
  //   var zip_keys = {
  //     patient:
  //       { zip: 'zip', state: 'patient_state_code', city: 'patient_city', city_id: 'city_id' },
  //     employer:
  //       { zip: 'employer_zip', state: 'employer_state', city: 'employer_city', city_id: 'employer_city_id' },
  //     office:
  //       { zip: 'office_zip', state: 'office_state', city: 'office_city', city_id: 'office_city_id' }
  //   };
  //   this.contract[zip_keys[type].zip] = (e[0] && e[0].data.zip) || '';
  //   this.contract[zip_keys[type].state] = (e[0] && e[0].data.state_name) || '';
  //   this.contract[zip_keys[type].city] = (e[0] && e[0].data.city) || '';
  //   this.contract[zip_keys[type].city_id] = (e[0] && e[0].data.city_id) || this.contract[zip_keys[type].city_id] || '';

  // }

  // setInitialZips() {
  //   if (this.contract.employer_zip) {
  //     this.zipcode_list.employer = [{ zip: this.contract.employer_zip, state_name: this.contract.employer_state, city: this.contract.employer_city, city_id: this.contract.employer_city_id }];
  //   }
  //   if (this.contract.zip) {
  //     this.zipcode_list.patient = [{ zip: this.contract.zip, state_name: this.contract.patient_state_code, city: this.contract.patient_city, city_id: this.contract.city_id }];
  //   }
  //   if (this.contract.office_zip) {
  //     this.zipcode_list.office = [{ zip: this.contract.office_zip, state_name: this.contract.office_state, city: this.contract.office_city, city_id: this.contract.office_city_id }];
  //   }
  // }

  maxCheckValue(num: number, no_deduct: any) {
    if (!num) {
      return 0;
    }
    num = Number(num);
    return no_deduct ? num : num - 1;
  }

  // close modal
  // closeModal(form: any) {
  //   form.resetForm();
  //   this.addNoteModal.hide();
  // }

  addNoteEditContract() {

    this.contract_data['notes'] = this.note;
    this.contract_data['cd_payment_type'] = 25;
    this.contract_data['name'] = this.admin_name;
    if (this.customerProfileUpdate == true) {
      this.contract_data['customer_details_updated'] = 1
    } else {
      this.contract_data['customer_details_updated'] = 0
    }
    if (this.customerContractsUpdate == true) {
      this.contract_data['all_customer_details_updated'] = 1
    } else {
      this.contract_data['all_customer_details_updated'] = 0
    }

    this._api.api(this.contract_data, 'edit_pf_details')
      .subscribe((data: any) => {
        if (typeof data === 'string') {
          data = JSON.parse(data);
        }
        this.location.back();
        // if (!data.is_error && (this.previousUrl && this.previousUrl != '/')) {
        // }
      }, (error: any) => {
        console.log(error);
      });
  }

  service() {
    if (this.contract.payback_methods == 'Others') {
      this.other_services = true;
    } else {
      this.other_services = false;
    }
  }

  otherService() {
    if (this.other_service_value.length < 1 || this.other_service_value.trim().length == 0) {
      this.other_service_value = '';
    }
  }


  changeOfficeAddress() {
    if (this.office_address_check) {
      console.log(this.contract)
      // this.ofcZips = this.zips;
      this.contract.office_city_id = this.contract.city_id;
      this.contract.office_city = this.contract.city || this.contract.patient_city;
      this.contract.office_state = this.contract.patient_state_name || this.contract.state_name || this.contract.patient_state_code;
      this.contract.office_zip = this.contract.patient_zip;
      this.contract.office_address = this.contract.patient_address || this.contract.address;
    }
    else {
      // this.ofcZips={};
      this.contract.office_city_id = '';
      this.contract.office_city = '';
      this.contract.office_state = '';
      this.contract.office_zip = '';
      this.contract.office_address = '';
    }
  }
  

  changeMobileCode(key: string, mobile_code: any) {
    if (key == 'employer_mobile') {
      this.selectedEmployerMobileCode = mobile_code;
    }else{
      this.selectedPatientMobileCode = mobile_code;
    }
  }

  getZipselected(address: any, type: string) {
    if (type == this.zipTypes.zip) {
      this.contract.zip = address.zip;
      this.contract.patient_state_code = address.state_name;
      this.contract.patient_city = address.city;
      this.contract.patient_address = address.item && address.item.formatted_address;
    } else if (type == this.zipTypes.office_zip) {
      this.contract.office_zip = address.zip;
      this.contract.office_state = address.state_name;
      this.contract.office_city = address.city;
      this.contract.office_address = address.item && address.item.formatted_address;
    } else if (type == this.zipTypes.employer_zip) {
      this.contract.employer_zip = address.zip;
      this.contract.employer_state = address.state_name;
      this.contract.employer_city = address.city;
      this.contract.employer_address = address.item && address.item.formatted_address;
    }
  }
}