import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/components/shared/api.service';
import { localStorageService } from 'src/app/components/shared/localstorage.service';
import { SharedService } from 'src/app/components/shared/shared.service';

@Component({
  selector: 'app-new-password',
  templateUrl: './new-password.component.html',
  styleUrl: './new-password.component.scss'
})
export class NewPasswordComponent implements OnInit{
  public newPasswordForm: UntypedFormGroup;
  hidePwd = false;
  hideConfirmPwd = false;
  constructor(private _fb: UntypedFormBuilder, private _api: ApiService, private _local: localStorageService, public _shared: SharedService, private _router: Router) {
    this.newPasswordForm = this._fb.group({
      new_password: ['', Validators.required],
      confirm_password: ['', Validators.required]
    })
  }

  ngOnInit(): void {
    console.log('in');
  }

  submit() {
    if (this.newPasswordForm.invalid) {
      this.newPasswordForm.markAsDirty();
      return;
    }

    if (this.newPasswordForm.value.new_password != this.newPasswordForm.value.confirm_password) {
      this._shared.customPopups('New password and Confirm password should be same', 1);
      return
    }

    const data = {
      reset_password_token: this._local.customGetItem('reset_password_token'),
      admin_email: this._local.customGetItem('reset_email').replace(/\s/g, '').toLowerCase(),
      new_password: this._shared.crypto(this.newPasswordForm.value.new_password),
    }

    this._api.api(data, 'auth/change_password').subscribe(response => {
        if (!response.is_error) {
          this._local.customClearAllItems();
          this._local.customSetItem('token', response.access_token);
          this._local.customSetItem('admin_profile', response.admin_profile ? response.admin_profile[0] : {});
          this._router.navigate(['app', 'dashboard']);
        }
    })
  }

}
