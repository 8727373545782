import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
// import { MainService } from '../../../core/services/main.service';
// import { ExcelService } from '../../../core/services/excel/excel.service';
import { DatePipe, DecimalPipe } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/components/shared/api.service';
import { FiltersService } from 'src/app/components/shared/filters/filters.service';
import { localStorageService } from 'src/app/components/shared/localstorage.service';
import { MainService } from 'src/app/components/shared/main.service';
import { SharedService } from 'src/app/components/shared/shared.service';
import * as momentTz from 'moment-timezone';
import { BsModalService, ModalDirective } from 'ngx-bootstrap/modal';
import { Filters } from 'src/app/components/shared/interface.component';
import { ProposalService } from '../proposal.service';
import { ProposalModalClassComponent } from '../proposal-modal/proposal-list-modal.component';


// import { Filters } from '../../../core/services/filters/interface';
// import { FiltersService } from '../../../core/services/filters/filters.service';
// import * as momentTz from 'moment-timezone';

@Component({
  selector: 'app-proposal-list',
  templateUrl: './proposal-list.component.html',
  styleUrl: './proposal-list.component.scss'
})
export class ProposalListComponent {
  minDate: any = new Date(2024, 0, 1);
  maxDate: any;
  // resetBtn: boolean;
  search: string = '';
  searched: boolean = false;
  timezone_subscription: any;
  subscription: any;
  subscription1: any;
  hadSearched = false;
  sort: any = 1;
  totalDefaultCount: any;
  timezone: string = '';
  timezoneName: string = '';
  timezoneZone: any;
  show_filters: boolean = false;
  public filters: Array<Filters> = [];
  filter_added: boolean = false;
  skip_overdue_check: any;
  display_logo: any;
  contract_type: any;
  country_subscription: any;
  
  view_track_status=0;
  is_ar_view=window.location.pathname.includes('/app/accounts-receivable/split-list')?1:0;
  status_track_value=[];
  baseUrlCopyLink: string = '';
  allSelected = false;
  isAnySelected = false;
  apiHitInProgress = false;
  lastResend = false;

  @ViewChild(ModalDirective, { static: false }) plansDetailModal!: ModalDirective;
  @ViewChild(ModalDirective, { static: false }) resendPlanModal!: ModalDirective;
  @ViewChild(ModalDirective, { static: false }) paymentSettingsModal!: ModalDirective;
  
  constructor(
    public mainService: MainService,
    private datePipe: DatePipe,
    public router: Router,
    public route: ActivatedRoute,
    public DecimalPipe: DecimalPipe,
    public filterService: FiltersService,
    private _local: localStorageService,
    private _api: ApiService,
    public _shared: SharedService,
    public _proposal: ProposalService,
    private modalService: BsModalService
  ) { }

  ngOnInit() {
    this.subscription = this._shared.updateDummyLive().subscribe(([isDummy, timezone, selected_country]) => {
      this._proposal.country_id = selected_country;
      this.timezone = timezone;
      this.getProposalList();
    })
    this.filters = this.filterService.getProposalFiltersArray(this.is_ar_view);
    this.timezoneName = this._shared.getTimezoneName();
    this.timezoneZone = this._shared.getTimezoneZone();
    this.maxDate = new Date(this._shared.getMaxDate(this.timezone));
    this._proposal.dateRangeValue = [this.minDate, this.maxDate];
    this._proposal.filter.start_date = this.datePipe.transform(this._proposal.dateRangeValue[0], "yyyy-MM-dd");
    this._proposal.filter.end_date = this.datePipe.transform(this._proposal.dateRangeValue[1], "yyyy-MM-dd");
    this._proposal.resData.pageNumber = 1;
    this.filters[1].value = new Date(this._proposal.filter.start_date);
    this.filters[2].value = new Date(this._proposal.filter.end_date);
    this.router.navigate(["/app/proposal/list"], {
      queryParams: { startDate: this._proposal.filter.start_date, endDate: this._proposal.filter.end_date, page: this._proposal.resData.pageNumber },
      queryParamsHandling: "merge"
    });

    // this.country_subscription = this._shared.selected_country$.subscribe((value) => {
    //   this.getProposalList();
    // }); 

    this.maxDate = new Date(this._shared.getMaxDate(this.timezone));
    this._proposal.dateRangeValue = [this.minDate, this.maxDate];
    this._proposal.filter.start_date = this.datePipe.transform(this._proposal.dateRangeValue[0], "yyyy-MM-dd");
    this._proposal.filter.end_date = this.datePipe.transform(this._proposal.dateRangeValue[1], "yyyy-MM-dd");

    this.subscription1 = this.route.queryParams.subscribe((data: any) => {
      if (data) {
        this.show_filters = data.showFilterTab ? data.showFilterTab : this.show_filters;
        if (!data.startDate && !data.endDate) {
          this._proposal.filter.start_date = this.datePipe.transform(this._proposal.dateRangeValue[0], "yyyy-MM-dd");
          this._proposal.filter.end_date = this.datePipe.transform(this._proposal.dateRangeValue[1], "yyyy-MM-dd");
        } else {
          this._proposal.dateRangeValue = [this._shared.getExactDate(new Date(data.startDate)), this._shared.getExactDate(new Date(data.endDate))];
        }
        this._proposal.filter.status_filter = data.status && parseInt(data.status) > 0 ? parseInt(data.status) : 0;
        this._proposal.filter.is_expired = data.activeStatus && parseInt(data.activeStatus) != -1 ? parseInt(data.activeStatus) : -1;
        this._proposal.search = data.search ? data.search : this._proposal.search
        this._proposal.sortValue.by = data.by ? data.by : 'date_added'
        this._proposal.sortValue.order = data.order ? data.order : 'DESC'
        this._proposal.resData.pageNumber = data.page && parseInt(data.page) > 0 ? parseInt(data.page) : 1;
        this._proposal.filter.ProposalType = data.ProposalType,
        this._proposal.filter.last_resent = data.last_resent,
        this.getProposalList();
      }
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    if (this.timezone_subscription) {
      this.timezone_subscription.unsubscribe();
    }
    if (this.country_subscription) {
      this.country_subscription.unsubscribe();
    }
    if (this.subscription1) {
      this.subscription1.unsubscribe();
    }
  }

  getProposalList() {
    let params = {
      access_token: this._local.customGetItem("adminToken"),
      offset: (this._proposal.resData.pageNumber - 1) * 10,
      limit: 10,
      search_string: this._proposal.search,
      status_filter: this._proposal.filter.status_filter,
      is_expired: (this._proposal.filter.status_filter != 0 && this._proposal.filter.status_filter != 1 && this._proposal.filter.status_filter != 2 && this._proposal.filter.status_filter != 3 && this._proposal.filter.status_filter != 6  && this._proposal.filter.status_filter != 8 && this._proposal.filter.status_filter != 9 && this._proposal.filter.status_filter != 13) ? -1 : this._proposal.filter.is_expired,
      sort_by: this._proposal.sortValue.by,
      sort_order: this._proposal.sortValue.order,
      min_date_added: this.datePipe.transform(this._proposal.dateRangeValue[0], 'yyyy-MM-dd'),
      max_date_added: this.datePipe.transform(this._proposal.dateRangeValue[1], 'yyyy-MM-dd'),
      country_id: this._proposal.country_id,
      is_debt_contract:window.location.pathname.includes('/app/contracts/split-list')?0:1,
      ProposalType: this._proposal.filter.ProposalType,
      last_resent: this._proposal.filter.last_resent,
    };
    if(this._proposal.search && this._proposal.search.length>0){
      params['search_string'] = this._proposal.search;
    } else{
      if (params.search_string) {
        // delete params['search_string'];
      }
    } 
    this._api.api(params, "finance/get_proposal_list", 'get').subscribe(
      (data: any) => {
        this.baseUrlCopyLink = data['copy_link_base_url'] || '';
        if (data.data && data.data.length) {
          data.data.forEach((item: any) => {
            item['last_action'] = '2024-07-04';
            item.contract_current_status = 'Proposal Sent';
            item.can_delete = 1;
            switch (true) {
              /*sent requests to patients  */
              case (item.doctor_status == 1 && item.patient_status != 2):
                item.contract_current_status = 'Proposal Sent';
                item.can_delete = 1;
                break;
              /*requests which are completed by patient with card information. */
              case (item.doctor_status == 1 && item.patient_status == 2):
                item.contract_current_status = 'Proposal Submitted to Business';
                item.can_delete = 0;
                break;
              /*approved requests or (financed requests). */
              case (item.doctor_status == 2 && item.patient_status == 2):
                item.contract_current_status = 'Customer Financed';
                item.can_delete = 0;
                break;
              /*rejected requests. */
              case (item.doctor_status == 3 && item.patient_status == 2):
                item.contract_current_status = 'Proposal Rejected by Business';
                item.can_delete = 0;
                break;
              default:
                item.contract_current_status = 'Proposal Sent';
                item.can_delete = 1;
            }
            if (!item.patient_name) {
              if (item.title || item.patient_first_name || item.patient_last_name) {
                item.patient_name = item.title + ' ' + item.patient_first_name + ' ' + item.patient_last_name;
              } else if (item.ap_title || item.ap_patient_first_name || item.ap_patient_last_name) {
                item.patient_name = item.ap_title + ' ' + item.ap_patient_first_name + ' ' + item.ap_patient_last_name;
              }
            }
          });
        }
        this._proposal.proposalList = data.data;
        this._proposal.proposalList.forEach((item: any) => {
          if (item.is_expired == 0 && item.contract_current_status === 'Proposal Sent') {
            item['is_selected'] = false;
          }
        });
        this._proposal.resData.totalRecords = data.number_of_records;
        this._proposal.resData.number_of_pages = data.number_of_pages;
        this.totalDefaultCount = data.number_of_records;
      }
    );
  }

  filterValues(event: any) {
    this._proposal.resData.pageNumber = 1;
    this._proposal.filter.start_date = this.datePipe.transform(event.data.startDate, "yyyy-MM-dd");
    this._proposal.filter.end_date = this.datePipe.transform(event.data.endDate, "yyyy-MM-dd");
    this._proposal.filter.status_filter = event.data.status;
    this._proposal.filter.is_expired = event.data.activeStatus;
    this.sort = event.data.sortBy;
    this._proposal.search = event.data.search;
    this._proposal.filter.ProposalType = event.data.ProposalType,
    this._proposal.filter.last_resent = event.data.last_resent;
    switch (parseInt(this.sort)) {
      case 1:
        this._proposal.sortValue = { by: 'date_added', order: 'DESC', label: 'Request Date' };
        break;
      case 2:
        this._proposal.sortValue = { by: 'patient_name', order: 'ASC', label: 'Customer Name A-Z' };
        break;
      case 3:
        this._proposal.sortValue = { by: 'patient_name', order: 'DESC', label: 'Customer Name Z-A' };
        break;
      case 4:
        this._proposal.sortValue = { by: 'doctor_name', order: 'ASC', label: 'Business Owner Name A-Z' };
        break;
      case 5:
        this._proposal.sortValue = { by: 'doctor_name', order: 'DESC', label: 'Business Owner Name Z-A' };
        break;
      case 6:
        this._proposal.sortValue = { by: 'plan_selected_date', order: 'DESC', label: 'Plan Selected Date' };
        break;
      default:
        this._proposal.sortValue = { by: 'date_added', order: 'DESC', label: 'Last Sent' };
        break;
    }
    this.modifyQueryParams();
  }

  resetFilters() {
    this._proposal.resData.pageNumber = 1;
    this._proposal.dateRangeValue = [this.minDate, this.maxDate];
    this._proposal.filter.start_date = this.datePipe.transform(this._proposal.dateRangeValue[0], "yyyy-MM-dd");
    this._proposal.filter.end_date = this.datePipe.transform(this._proposal.dateRangeValue[1], "yyyy-MM-dd");
    this._proposal.filter.status_filter = 0;
    this._proposal.filter.is_expired = -1;
    this._proposal.filter.ProposalType=0;
    this._proposal.sortValue = { by: 'date_added', order: 'DESC', label: 'Request Date' };
    this.sort = 1;
    this._proposal.search = '';
    this._proposal.filter.last_resent = '0';
    this.modifyQueryParams();
  }

  modifyQueryParams() {
    this.router.navigate([], {
      queryParams: {
        page: this._proposal.resData.pageNumber,
        startDate: this.datePipe.transform(this._proposal.filter.start_date, 'yyyy-MM-dd'),
        endDate: this.datePipe.transform(this._proposal.filter.end_date, 'yyyy-MM-dd'),
        status: this._proposal.filter.status_filter,
        activeStatus: (this._proposal.filter.status_filter != 0 && this._proposal.filter.status_filter != 1 && this._proposal.filter.status_filter != 2 && this._proposal.filter.status_filter != 3 && this._proposal.filter.status_filter != 6 && this._proposal.filter.status_filter != 8 && this._proposal.filter.status_filter != 9 && this._proposal.filter.status_filter != 13) ? -1 : this._proposal.filter.is_expired,
        by: this._proposal.sortValue.by,
        order: this._proposal.sortValue.order,
        sortBy: this.sort,
        search: this._proposal.search,
        showFilterTab: this.show_filters,
        ProposalType : this._proposal.filter.ProposalType,
        last_resent: this._proposal.filter.last_resent,
      },
      queryParamsHandling: "merge"
    });
  }

  showFilter(value: any) {
    this.show_filters = value;
  } 

  pageChanged(event: any) {
    this.allSelected = false;
    this._proposal.resData.pageNumber = event.page;
    this._proposal.filter.offset = (event.page - 1) * 10;    
    this.router.navigate([], { queryParams: { page: this._proposal.resData.pageNumber }, queryParamsHandling: 'merge' });
  }

  goToContract(contract_id: number) {
    this.router.navigate(['/app/contracts/' + contract_id]);
  }

  goToPaymentProcess(PPr_id: number) {
    this.router.navigate(['/app/accounts-receivable/payment/' + PPr_id]);
  }

  gotoproposaldetails(split_req_id: number){
    if(this.is_ar_view){

      this.router.navigate(['/app/accounts-receivable/details/' + split_req_id]);
    }else{
      this.router.navigate(['/app/proposal/details/' + split_req_id]);
    }
  }
  
  deleteRequest(request_id: string) {
    const modalRef = this.modalService.show(ProposalModalClassComponent, {
      initialState: {
        extra_data: {
          request_id
        },
        modalType: this._proposal.modalTypes.deleteProposal
      },
      class:'modal-dialog-centered'
    });
    modalRef.content?.passEntry.subscribe(response => {
      if (response.success) {
        this.getProposalList();
      } else {
        this._shared.customPopups(response.err, 1);
      }
    })
  }

  resendProposal(split_req: any) {
    this._proposal.splitRequest = split_req;
    const modelRef = this.modalService.show(ProposalModalClassComponent, {
      initialState: {
        modalType: this._proposal.modalTypes.resendProposalModal,
        extra_data: {
          allSelected: this.allSelected,
          view_track_status: this.view_track_status
        },
      },
      class:'modal-dialog-centered'
    })
    modelRef.content?.passEntry.subscribe((receivedEntry: any) => {
      if(receivedEntry && receivedEntry.success) {
        const data = receivedEntry.data;
        if (data.batch_status) {
          this._shared.customPopups('Proposal Email(s) sent successfully', 0);
          this.getProposalList();
          if (this.allSelected) {
            this.updateSelection(0, 'all');
          }
          this.isAnySelected = false;
        } else {
          this._shared.customPopups(data.err, 1);
        }
      } else {
        this._shared.customPopups('Something went wrong please try again later', 1);
      }
    })
    // modal.show();
  }

  openPlanDetailsModal() {
    const modelRef = this.modalService.show(ProposalModalClassComponent, {
      initialState: {
        modalType: this._proposal.modalTypes.plansDetailModal,
        extra_data: {
          view_track_status: this.view_track_status,
          status_track_value: this.status_track_value
        },
      },
      class:'modal-dialog-centered'
    })
    modelRef.content?.passEntry.subscribe((receivedEntry: any) => {
      if(receivedEntry && receivedEntry.success) {
        const data = receivedEntry.data;
        if (!data.is_error) {
          this.getProposalList();
        } else {
          this._shared.customPopups(data.err, 1);
        }
      } else {
        this._shared.customPopups('Something went wrong please try again later', 1);
      }
    })
  }

  openPaymentSettingsModal() {
    const modelRef = this.modalService.show(ProposalModalClassComponent, {
      initialState: {
        modalType: this._proposal.modalTypes.paymentSettingsModal
      },
      class:'modal-dialog-centered'
    })
    modelRef.content?.passEntry.subscribe((receivedEntry: any) => {
      if(receivedEntry && receivedEntry.success) {
        const data = receivedEntry.data;
        if (!data.is_error) {
          this.getProposalList();
        } else {
          this._shared.customPopups(data.err, 1);
        }
      } else {
        this._shared.customPopups('Something went wrong please try again later', 1);
      }
    })
  }

  viewDetails(split_req: any, from_ar=0) {
    this._proposal.splitRequest = split_req;
    this.view_track_status=from_ar;
    let params = {
      split_req_id: this._proposal.splitRequest.split_req_id,
    };
    this._api.api(params, 'finance/split_req_details', 'get')
      .subscribe((data: any) => {
        if (data && typeof data == 'string') data = JSON.parse(data);
        if (data.is_error == 0 && data.split_request_data && data.split_request_data[0]) {
          this.display_logo = data.split_request_data[0].display_logo
          this.contract_type = data.split_request_data[0].contract_type
          this._proposal.plansDetail = data.split_request_data[0]['plans_array'];
          this._proposal.plansDetail.forEach((plan: any) => {
            plan.recurring_amount = parseFloat(plan.recurring_amount);
            plan.recurring_extra_amount = data.split_request_data[0].recurring_extra_amount;
            plan.recurring_extra_percent = data.split_request_data[0].recurring_extra_percent;
            plan.threshold_amount = data.split_request_data[0].threshold_amount;
            plan.threshold_recurring_extra_amount = data.split_request_data[0].threshold_recurring_extra_amount;
            plan.threshold_recurring_extra_percent = data.split_request_data[0].threshold_recurring_extra_percent;
            plan.treatment_amount = data.split_request_data[0].treatment_amount;
            plan.downpayment_amount = data.split_request_data[0].downpayment_amount;
            
            if (data.split_request_data[0].contract_type === 1) {
              if (data.split_request_data[0] && data.split_request_data[0].payment_reserve_on_pay) {
                plan.advance_interest_amount = plan.recurring_amount;
              } else {
                plan.advance_interest_amount = 0
              }
            } else if (data.split_request_data[0].contract_type === 3) {
              if (data.split_request_data[0] && data.split_request_data[0].payment_reserve_on_pay_new_ten) {
                plan.advance_interest_amount = plan.recurring_amount;
              } else {
                plan.advance_interest_amount = 0
              }
            } else {
              plan.advance_interest_amount = plan.advance_interest_amount ? parseFloat(parseFloat(
                plan.advance_interest_amount
              ).toFixed(2)) : 0;
            }
            plan.month = parseInt(plan.month);
            let fixed_flat_fee = data.split_request_data[0].enrollment_enabled ? 0 : plan.enrollment_fee;
            plan.payable_amount_today = parseFloat(plan.downpayment_amount) + this._proposal.getCalculatedTransactionFee(plan, plan.downpayment_amount)
              + parseFloat(plan.advance_interest_amount) + this._proposal.getCalculatedTransactionFee(plan, plan.advance_interest_amount)
              + fixed_flat_fee;
            plan['getTransactionFee'] = this._proposal.GetTransactionFee(plan, plan.recurring_amount?plan.recurring_amount:plan.recurring_amount, this.display_logo)
          });
          this.openPlanDetailsModal();
          this.status_track_value= data.split_request_data[0]['tracking_reminders']
        }
      })
  }

  exportXLSX(ext: any, filters?: any) {
    let is_debt_contract = window.location.pathname.includes('/app/contracts/split-list') ? 0 : 1;
    let data: any = {
      access_token: this._local.customGetItem("adminToken"),
      offset: 0,
      country_id: this._proposal.country_id,
      is_debt_contract,
      is_export: 1,
      search_string: '',
      status_filter: 0,
      is_expired: -1,
      limit: 0,
      sort_by: 'date_added',
      sort_order: 'DESC'
    };
    if (filters == 1) {
      data['search_string'] = this._proposal.search || '';
      data['status_filter'] = this._proposal.filter.status_filter || 0;
      data['is_expired'] = (this._proposal.filter.status_filter != 0 && this._proposal.filter.status_filter != 1 && this._proposal.filter.status_filter != 2 && this._proposal.filter.status_filter != 3 && this._proposal.filter.status_filter != 6 && this._proposal.filter.status_filter != 8 && this._proposal.filter.status_filter != 9 && this._proposal.filter.status_filter != 13) ? -1 : this._proposal.filter.is_expired;
      data['limit'] = this._proposal.resData.totalRecords;
      data['min_date_added'] = this.datePipe.transform(this._proposal.dateRangeValue[0], 'yyyy-MM-dd');
      data['max_date_added'] = this.datePipe.transform(this._proposal.dateRangeValue[1], 'yyyy-MM-dd');
      data['sort_by'] = this._proposal.sortValue.by || "date_added";
      data['sort_order'] = this._proposal.sortValue.order || "DESC";
    }
    else {
      data['search_string'] = '';
      data['status_filter'] = 0;
      data['is_expired'] = -1;
      data['limit'] = this.totalDefaultCount;
      data['min_date_added'] = this.datePipe.transform(this.minDate, 'yyyy-MM-dd');
      data['max_date_added'] = this.datePipe.transform(this.maxDate, 'yyyy-MM-dd');
      data['sort_by'] = "date_added";
      data['sort_order'] = "DESC";
    }
    this._api.api(data, 'list_split_requests')
      .subscribe((data: any) => {
        if (data.data && data.data.length) {
          data.data.forEach((item: any) => {
            item['last_action'] = '2024-07-04';
            item.contract_current_status = 'Proposal Sent';
            switch (true) {
              /*sent requests to patients  */
              case (item.doctor_status == 1 && item.patient_status != 2):
                item.contract_current_status = 'Proposal Sent';
                break;
              /*requests which are completed by patient with card information. */
              case (item.doctor_status == 1 && item.patient_status == 2):
                item.contract_current_status = 'Proposal Submitted to Business';
                break;
              /*approved requests or (financed requests). */
              case (item.doctor_status == 2 && item.patient_status == 2):
                item.contract_current_status = 'Customer Financed';
                break;
              /*rejected requests. */
              case (item.doctor_status == 3 && item.patient_status == 2):
                item.contract_current_status = 'Proposal Rejected by Business';
                break;
              default:
                item.contract_current_status = 'Proposal Sent';
            }
          });
        }
        let list: any = [];
        let list1 = [];
        let requestsList = data.data;
        list[0] = {
          patient_name: 'Customer Name',
          patient_email: 'Customer Email',
          patient_address: 'Customer Address',
          patient_mobile: 'Customer Phone',
          doctor_name: 'Business Name',
          doctor_email: 'Business Email',
          contract_id: 'Contract ID',
          contract_type: 'Contract Type',
          current_status: 'Current Status',
          date_added: 'Request Date',
          start_date: 'Proposal Start Date',
          end_date: 'Proposal End Date',
          proposal_type: 'Proposal Type',
          csr_notes: 'Notes',
          is_call_made: 'Call Made Yes or No',
          is_voice_call_made: 'Voice Mail Yes or No',
          is_text_sent: 'Text Sent Yes or No',
          is_email_sent: 'Email Sent Yes or No',
          followup_date: 'Follow up date',
          assigned_to: 'Assigned to',
          proposal_resent: 'Proposal Resent',
          last_resent: 'Last  Resent',
          settings_changed_count: 'Settings Changed Count',
          last_action: 'Last Action',
          proposal_link: 'Proposal Link',
          debt_duration: '',
          payoff_amount: '',
          email_status: '-'
          // proposal_status_details: 'Proposal Status',
        };
        if (is_debt_contract > 0) {
          list[0].debt_duration = 'Debt Duration';
          list[0].payoff_amount = 'Payoff Amount';
        }
        for (let i = 0; i < requestsList.length; i++) {
          list[i + 1] = {};
          list[i + 1].patient_name = requestsList[i].patient_name ? requestsList[i].patient_name : '-';
          list[i + 1].patient_email = requestsList[i].patient_email ? requestsList[i].patient_email : '-';
          list[i + 1].patient_address = requestsList[i].patient_address ? requestsList[i].patient_address : '-';
          // if (ext == 0) {
            // tslint:disable-next-line: max-line-length
          list[i + 1].patient_mobile = requestsList[i].patient_mobile ? (requestsList[i].patient_mobile_country_code ? (ext == 0 ?  `${requestsList[i].patient_mobile_country_code}-` : `(${requestsList[i].patient_mobile_country_code})-`) : '') + requestsList[i].patient_mobile : '-';
          // } else {
          //   list[i + 1].patient_mobile = requestsList[i].patient_mobile ? requestsList[i].patient_mobile.substring(3) : '-';
          // }
          list[i + 1].doctor_name = requestsList[i].practice_name ? requestsList[i].practice_name : (requestsList[i].doctor_name ? requestsList[i].doctor_name : '-');
          list[i + 1].doctor_email = requestsList[i].practice_email ? requestsList[i].practice_email : (requestsList[i].doctor_email ? requestsList[i].doctor_email : '-');
          list[i + 1].contract_id = requestsList[i].contract_id ? (requestsList[i].proposal_status == 'Plan Created' ? requestsList[i].contract_id : '-') : '-';
          list[i + 1].contract_type = requestsList[i].contract_type == 1 ? "10% Service Fee" : requestsList[i].contract_type == 3 ? "KeepeeFi" : requestsList[i].contract_type == 4 ? (requestsList[i].ez_plan_new ? 'ZeroFi Financing' : 'SimpleeFi Financing') : requestsList[i].contract_type == 6 ? 'SimpleeFi (With Deferred Interest)' : requestsList[i].contract_type == 5 ? 'Credee Bill Payment Plan' : 'CredeeFi';
          if (is_debt_contract > 0) {
            list[i + 1].current_status = requestsList[i].split_status_for_ar ? requestsList[i].split_status_for_ar : '-';
            list[i + 1].email_status = requestsList[i].proposal_status_for_ar ? requestsList[i].proposal_status_for_ar : '-';
          } else {
            list[i + 1].current_status = requestsList[i].split_status ? requestsList[i].split_status : '-';
            list[i + 1].email_status = requestsList[i].proposal_status ? requestsList[i].proposal_status : '-';
          }
          list[i + 1].date_added = requestsList[i].date_added ? this.datePipe.transform(requestsList[i].date_added, 'MM/dd/yyyy', this.timezone) : '-';
          list[i + 1].start_date = (requestsList[i].start_date && requestsList[i].start_date != '0000-00-00 00:00:00') ? this.datePipe.transform(requestsList[i].start_date, 'MM/dd/yyyy', '+0000') : '-';
          list[i + 1].end_date = (requestsList[i].end_date && requestsList[i].end_date != '0000-00-00 00:00:00') ? this.datePipe.transform(requestsList[i].end_date, 'MM/dd/yyyy', '+0000') : '-';
          list[i + 1].proposal_type = requestsList[i].request_from ? 'Send Payment Link' : 'Send Proposal';
          list[i + 1].csr_notes = (requestsList[i].csr_notes && requestsList[i].csr_notes != '') ? requestsList[i].csr_notes : '-';
          list[i + 1].is_call_made = (requestsList[i].is_call_made && requestsList[i].is_call_made > 0) ? 'Yes' : 'No';
          list[i + 1].is_voice_call_made = (requestsList[i].is_voice_call_made && requestsList[i].is_voice_call_made > 0) ? 'Yes' : 'No';
          list[i + 1].is_text_sent = (requestsList[i].is_text_sent && requestsList[i].is_text_sent > 0) ? 'Yes' : 'No';
          list[i + 1].is_email_sent = (requestsList[i].is_email_sent && requestsList[i].is_email_sent > 0) ? 'Yes' : 'No';
          list[i + 1].followup_date = (requestsList[i].followup_date && requestsList[i].followup_date != '') ? this.datePipe.transform(requestsList[i].followup_date, 'MM/dd/yyyy', '+0000') : '-';
          list[i + 1].assigned_to = (requestsList[i].assigned_to && requestsList[i].assigned_to != '') ? requestsList[i].assigned_to : '-';

          list[i + 1].proposal_resent = requestsList[i].resend_email_count || '-';
          // tslint:disable-next-line:max-line-length
          list[i + 1].last_resent = requestsList[i].last_email_sent ? momentTz(requestsList[i].last_email_sent).tz(this.timezoneZone).format('MM/DD/YYYY hh:mm A') : '-';
          list[i + 1].settings_changed_count = requestsList[i].settings_changed_count || '-';
          list[i + 1].last_action = requestsList[i].last_action ? momentTz(requestsList[i].last_action).tz(this.timezoneZone).format('MM/DD/YYYY hh:mm A') : '-';
          list[i + 1].proposal_link = requestsList[i].contract_patient_token ? `${this.baseUrlCopyLink}${requestsList[i].contract_patient_token}` : '-';
          // list[i + 1].proposal_status_details = requestsList[i].proposal_status_details ? JSON.stringify(requestsList[i].proposal_status_details) : '-';
        }
        var excelFileName = 'split_requests';
        if (is_debt_contract > 0) {
          excelFileName = 'CredeeBill Proposal';
          list1 = [{
            'title': 'Row Labels',
            'value': 'Count'
          },
          {
            'title': 'Payment Completed',
            'value': data.total_completed
          },
          {
            'title': 'Plan Created',
            'value': data.total_plan_created
          },
          {
            'title': 'Plan Selected',
            'value': data.total_plan_selected
          },
          {
            'title': 'Link Opened',
            'value': data.total_link_opened
          },
          {
            'title': 'Request Sent',
            'value': data.total_request_sent
          },
          {
            'title': 'Grand Total',
            'value': data.ar_grand_total
          }
          ];
        } else {
          excelFileName = 'Credee Proposal Requests';
          list1 = [{
            'title': 'Row Labels',
            'value': 'Count'
          },
          {
            'title': 'Plan Created',
            'value': data.total_plan_created
          }, {
            'title': 'Plan Selected',
            'value': data.total_plan_selected
          },
          {
            'title': 'Link Opened',
            'value': data.total_link_opened
          },
          {
            'title': 'Request Sent',
            'value': data.total_request_sent
          },
          {
            'title': 'Rejected Request',
            'value': data.total_rejected
          },
          {
            'title': 'Grand Total',
            'value': data.pp_grand_total
          }
          ];
        }
        // if (ext == 0) {
        //   this.excelService.exportAsExcelFileV2(list, list1, excelFileName, '.xlsx', {  proposal_link: { type: 'hyperlink', width: 55} });
        // }
        // else if (ext == 1) {
        //   this.excelService.exportAsExcelFileV2(list, list1, excelFileName, '.csv', {  proposal_link: { type: 'hyperlink', width: 55} });
        // }
      }, (error: any) => {
        console.log(error);
      });
  }
  ViewPaymentSettings(split_req: any) {
    this._proposal.splitRequest = split_req;
    let params = {
      split_req_id: this._proposal.splitRequest.split_req_id,
    };
    this._api.api(params, 'finance/split_req_details', 'get')
      .subscribe((data: any) => {
        if (data && typeof data == 'string') data = JSON.parse(data);
        this._proposal.selected_req = data.split_request_data[0];
        this.skip_overdue_check = data.split_request_data[0].skip_overdue_contract_check;
        this._proposal.selected_req['selected_payment_matrix']['backup_required'] = this._proposal.selected_req['selected_payment_matrix']['backup_required'] == 1 ? true : false;
        if (this._proposal.selected_req) {
          this.openPaymentSettingsModal();
        }
      })
  }

  stop_resume_reminder(eve: any,split_id: any,stop_to: any){
    let is_stop=eve.target.checked ? 0 : 1
    const modelRef = this.modalService.show(ProposalModalClassComponent, {
      initialState: {
        modalType: this._proposal.modalTypes.stop_resume_reminder,
        extra_data: {
          is_stop: is_stop,
          split_id,
          stop_to
        }
      },
      class:'modal-dialog-centered'
    })

    modelRef.content?.passEntry.subscribe((receivedEntry: any) => {
      if(receivedEntry && receivedEntry.success) {
        const data = receivedEntry.data;
        if (!data.is_error) {
          this.getProposalList();
        } else {
          this._shared.customPopups(data.err, 1);
        }
      } else {
        this._shared.customPopups('Something went wrong please try again later', 1);
      }
    })
  }

  copyLink(data: any) {
    if (this.baseUrlCopyLink) {
      const textToCopy = `${this.baseUrlCopyLink}${data.contract_patient_token}`;
      const isTextCopied = this.mainService.copyToClipboard(textToCopy);
      // tslint:disable-next-line:max-line-length
      isTextCopied ? this._shared.customPopups('Link copied to clipboard', 0) : this._shared.customPopups('Unable to copy to clipboard', 1);
      return;
    }
  }

  updateSelection(index: number, type = 'single') {
    if (type === 'all') {
      this.allSelected = !this.allSelected;
      this._proposal.proposalList.forEach((item: any) => {
        if (item.is_expired == 0 && item.contract_current_status === 'Proposal Sent') {
          item['is_selected'] = this.allSelected;
        }
      });
      // this.allSelected = this._proposal.proposalList.every(item => item.is_selected);
      // this.isAnySelected = this._proposal.proposalList.some(item => item.is_selected);
      return;
    } else {
      this._proposal.proposalList[index]['is_selected'] = this._proposal.proposalList[index]['is_selected'];
      // this.allSelected = this._proposal.proposalList.every(item => item.is_selected);
      setTimeout(() => {
        this.isAnySelected = this._proposal.proposalList.some((item: any) => item.is_selected);
      }, 0);
    }
  }

  sendProposalToAll() {
    this.allSelected = true
    this.resendProposal(0);
  }
}
